import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as fromFront from './front.reducer';
import { ChildSite, OrderingType } from '~/front/models';

export const selectFrontState = createFeatureSelector<fromFront.FrontState>('front');

export const selectChildSiteState = createSelector(selectFrontState, (frontState) => frontState.childSites);

// export const selectShopSearchState = createFeatureSelector<fromFront.ShopSearchState>('shops');
export const selectShopSearchState = createSelector(selectFrontState, (frontState) => frontState.shops);
// export const selectSearchForm = createSelector(
//     selectSharedState,
//     sharedState => sharedState.searchForm
// )

// export const selectSearchFormPostCode = createSelector(
//     selectSearchForm,
//     formState => formState.value.postCode
// );

// export const selectSearchFormPostCode2 = createSelector(
//     selectSearchForm,
//     formState => formState.value.postCode2
// );

// export const selectSearchFromDate = createSelector(
//     selectSearchForm,
//     formState => formState.value.date
// )

export const selectLoading = createSelector(selectFrontState, (frontState) => frontState.loading);

export const selectSearchData = createSelector(selectFrontState, (frontState) => frontState.searchData);

export const selectAllChildSites = createSelector(selectChildSiteState, fromFront.selectAllChildSites);

export const selectAllShops = createSelector(selectShopSearchState, (shopState) => fromFront.selectAllShops(shopState));

export const selectChildSite = (childSite: string) =>
  createSelector(
    selectChildSiteState,
    (childSiteState) => fromFront.selectChildSitesEntities(childSiteState)[childSite],
  );

// export const selectTotalShops = createSelector(selectShopSearchState, fromFront.selectTotalShops);
export const selectTotalShops = createSelector(selectShopSearchState, (shopState) => {
  const result = fromFront.selectTotalShops(shopState);
  return result;
});

export const selectChildSitesLoaded = createSelector(selectChildSiteState, (childSiteState) => childSiteState.loaded);

export const selectTotalShopsBrain = createSelector(selectChildSiteState, (childSiteState) => {
  const result = childSiteState.loaded
    ? _.sum(fromFront.selectAllChildSites(childSiteState).map((childSite) => childSite.shops.length))
    : null;
  return result;
});

export const selectShopsLoaded = createSelector(selectAllChildSites, selectAllShops, (childSites, allShops) => {
  const childSitesShopCount = _.sum(childSites.map((childSite) => childSite.shops.length));
  return (
    childSitesShopCount !== 0 &&
    childSitesShopCount ===
      _.reduce(allShops, (result, shop) => result + (_.isUndefined(shop.taxonomy_classification) ? 0 : 1), 0)
  );
});

export const selectEntityTitle = createSelector(selectFrontState, (frontState) => frontState.entityTitle);

export const selectFilteredShopIds = createSelector(
  selectFrontState,
  selectShopSearchState,
  (frontState, shopState) => {
    const shops = fromFront.selectEntitiesShops(shopState);
    const filterShopIds = (ids) =>
      frontState.taxonomyFilter.length > 0
        ? ids.filter((id) => _.intersection(shops[id].taxonomy_classification, frontState.taxonomyFilter).length > 0)
        : ids;
    return filterShopIds(fromFront.selectIdsShops(shopState));
  },
);

export const selectShopsOrdering = createSelector(
  selectChildSiteState,
  (childSiteState) => childSiteState.shopsOrdering,
);

export const selectFilterIsActive = createSelector(
  selectFrontState,
  (frontState) => !_.isEmpty(frontState.taxonomyFilter),
);

export const selectSearchResult = createSelector(
  selectFrontState,
  selectFilteredShopIds,
  selectShopSearchState,
  selectChildSiteState,
  (frontState, filteredShopIds, shopState, childSiteState) => {
    const shops = fromFront.selectEntitiesShops(shopState);
    const childSites = _.cloneDeep(
      [
        {
          '@uid': null,
          image: null,
          name: null,
          totalShops: Object.keys(shops).length,
          shops: filteredShopIds,
        } as ChildSite,
      ].concat(
        fromFront.selectAllChildSites(childSiteState).map((childSite) => ({
          ...childSite,
          totalShops: childSite.shops.length,
          shops: childSite.shops.filter((id) => filteredShopIds.includes(id)),
        })),
      ),
    );

    switch (childSiteState.shopsOrdering) {
      // case OrderingType.recommended: {
      //   break;
      // }
      // case OrderingType.cheapestShipment: {
      //   childSites.map((childSite) => {
      //     childSite.shops = childSite.shops.sort((left, right): number => {
      //       if (shops[left].minmax_shipping_fee[0] < shops[right].minmax_shipping_fee[0]) return -1;
      //       if (shops[left].minmax_shipping_fee[0] > shops[right].minmax_shipping_fee[0]) return 1;
      //       return 0;
      //     });
      //   });
      //   break;
      // }
      case OrderingType.cheapestShipment: {
        childSites.map((childSite) => {
          childSite.shops = _.sortBy(childSite.shops, [(o) => shops[o].minmax_shipping_fee[0]]);
        });
        break;
      }
      case OrderingType.fastestDelivery: {
        childSites.map((childSite) => {
          childSite.shops = _.sortBy(childSite.shops, [(o) => shops[o].minimum_time]);
        });
        break;
      }
      case OrderingType.bestRating: {
        childSites.map((childSite) => {
          childSite.shops = _.orderBy(childSite.shops, [(o) => shops[o].ratings.stars || 0], ['desc']);
        });
        break;
      }
      case OrderingType.totalRatings: {
        childSites.map((childSite) => {
          childSite.shops = _.orderBy(childSite.shops, [(o) => shops[o].ratings.rating_quantity || 0], ['desc']);
        });
        break;
      }
      case OrderingType.newFirst: {
        childSites.map((childSite) => {
          childSite.shops = _.orderBy(childSite.shops, [(o) => shops[o].ratings.rating_quantity || 0], ['asc']);
        });
        break;
      }
    }

    return {
      entityTitle: frontState.entityTitle,
      totalShops: fromFront.selectTotalShops(shopState),
      totalItems: frontState.totalItems,
      childSites: childSites.map((childSite) => {
        childSite.shopsDelivery = childSite.shops.filter((shopId) => shops[shopId].delivery_on_date === true);
        childSite.shopsPickup = childSite.shops.filter(
          (shopId) => shops[shopId].delivery_on_date === false && shops[shopId].pickup_on_date === true,
        );
        childSite.shopsNoService = childSite.shops.filter(
          (shopId) => shops[shopId].delivery_on_date === false && shops[shopId].pickup_on_date === false,
        );
        const total = childSite.shops.reduce(
          (prevValue, shopUid) => {
            const shop = shops[shopUid];
            if (!_.isUndefined(shop)) {
              const items = shop.items || [];
              prevValue.totalItems += items.length ?? 0;
              prevValue.noAvailableItems = prevValue.noAvailableItems.concat(
                items.filter((item) => !item.delivery && !item.pickup).map((item) => ({ item, shop })),
              );
              prevValue.pickupItems = prevValue.pickupItems.concat(
                items.filter((item) => !item.delivery && item.pickup).map((item) => ({ item, shop })),
              );
              prevValue.deliveryItems = prevValue.deliveryItems.concat(
                items.filter((item) => item.delivery).map((item) => ({ item, shop })),
              );
            }
            return prevValue;
          },
          { totalItems: 0, noAvailableItems: [], pickupItems: [], deliveryItems: [] },
        );
        // console.log('selectSearchResult 2', total);
        return {
          ...childSite,
          ...total,
          // totalItems: _.sum(
          //   childSite.shops.map((shopUid) => (_.isUndefined(shops[shopUid]) ? 0 : shops[shopUid].items.length ?? 0)),
          // ),
        };
      }),
      // shops: fromFront.selectEntitiesShops(shopState),
    };
  },
);

export const selectEntitiesShops = createSelector(selectShopSearchState, (shopState) =>
  fromFront.selectEntitiesShops(shopState),
);

export const selectAllShopTaxonomies = createSelector(selectAllShops, (shops) =>
  _.uniq(_.flatten(shops.map((shop) => shop.taxonomy_classification))).filter((tax) => !!tax),
);

export const selectShop_try = createSelector(selectEntitiesShops, (shops, props) => {
  // console.log('selectShop', props);
  return shops[props.id];
});

export const selectShop = (shopUid: string) => {
  return createSelector(selectEntitiesShops, (shops) => {
    // console.log('selectShop', shopUid);
    return shops[shopUid];
  });
};

export const selectTotalItemsChildSite = (childSite: string) =>
  createSelector(selectChildSite(childSite), selectEntitiesShops, (childSite, shops) =>
    _.sum(childSite.shops.map((shopUid) => (shops[shopUid].items ? shops[shopUid].items.length : 0))),
  );
