import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Category } from '~/shop/models';
import * as ShopActionTypes from './shop.actions';

export interface CategoriesState extends EntityState<Category> {
  loading: boolean;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  sortComparer: (e1: Category, e2: Category) => e1.seq_no - e2.seq_no,
});

const initialState: CategoriesState = adapter.getInitialState({
  loading: false,
});

export const categoriesReducer = createReducer(
  initialState,
  on(ShopActionTypes.loadShop, (state) => adapter.removeAll({ ...state, loading: true })),
  on(ShopActionTypes.categoriesCancelled, (state) => ({ ...state, loading: false })),
  on(ShopActionTypes.categoriesRequested, (state) => ({ ...state, loading: true })),
  on(ShopActionTypes.categoriesLoaded, (state, { categories }) =>
    adapter.addAll(categories, { ...state, loading: false }),
  ),
);

export function reducer(state: CategoriesState | undefined, action: Action): CategoriesState {
  return categoriesReducer(state, action);
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
