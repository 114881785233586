<div class="register-wrapper container">
    <div *ngIf="isAuthenticated$ | async as isAuthenticated" class="user-personal-menu">
      <div *ngIf="!isAuthenticated.state">
        <a mat-raised-button color="warn" (click)="login()" role="button">{{ 'menu-register.logIn' | translate }}</a>
        <a mat-raised-button color="warn" (click)="register()" role="button">{{ 'menu-register.register' | translate }}</a>
      </div>
      <div *ngIf="isAuthenticated.state">
        <div class="user-options-desktop">
          <button id="user-info-dropdown-trigger" mat-stroked-button color="warn" [matMenuTriggerFor]="menu">{{ 'menu-register.helloUser' | translate: {userName: userName$ | async} }}</button>
          <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item [traverseTo]="userInfoPath">
              <mat-icon>face</mat-icon>
              <span>{{ 'menu-register.userInformation' | translate }}</span>
            </button>
            <button mat-menu-item [traverseTo]="userOrdersPath">
              <mat-icon>redeem</mat-icon>
              <span>{{ 'menu-register.myOrders' | translate }}</span>
            </button>
            <button mat-menu-item [traverseTo]="userAddressesPath">
              <mat-icon>location_on</mat-icon>
              <span>{{ 'menu-register.shippingAddresses' | translate }}</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>{{ 'menu-register.logOut' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="user-options-mobile">
          <mat-list role="list">
            <mat-list-item role="listitem" (click)="userInfo()">
              <mat-icon>face</mat-icon>
              <span>{{ 'menu-register.userInformation' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" (click)="orders()">
              <mat-icon>redeem</mat-icon>
              <span>{{ 'menu-register.myOrders' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" (click)="shippingAddresses()">
              <mat-icon>location_on</mat-icon>
              <span>{{ 'menu-register.shippingAddresses' | translate }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>{{ 'menu-register.logOut' | translate }}</span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
</div>
