import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Article } from '~/core/models';
import * as ShopActionTypes from './shop.actions';

export interface ArticlesState extends EntityState<Article> {
  loading: boolean;
  categories: string[];
}

function sortByCategoryAndSeqNo(a1: Article, a2: Article) {
  // const compare = a1._category_seq_no - a2._category_seq_no;

  // if (compare !== 0) {
  //     return compare;
  // } else {
  return a1._seq_no - a2._seq_no;
  // }
}

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>({
  sortComparer: sortByCategoryAndSeqNo,
});

const initialState: ArticlesState = adapter.getInitialState({
  loading: false,
  categories: [],
});

export const articlesReducer = createReducer(
  initialState,
  on(ShopActionTypes.loadShop, (state) => adapter.removeAll({ ...state, loading: true })),
  on(ShopActionTypes.articlesCancelled, (state) => ({ ...state, loading: false })),
  on(ShopActionTypes.articlesRequested, (state) => ({ ...state, loading: true })),
  on(ShopActionTypes.articlesLoaded, (state, { articles, category }) =>
    articles.length > 0
      ? adapter.addMany(articles, {
          ...state,
          loading: false,
          categories: state.categories.slice().concat([category]),
        })
      : state,
  ),
);

export function reducer(state: ArticlesState | undefined, action: Action): ArticlesState {
  return articlesReducer(state, action);
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
