<ngx-json-ld [json]="shopSchema$ | async"></ngx-json-ld>
<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<app-shop-header [shop]="context | async"></app-shop-header>
<apym-shopfilter [shop]="context | async"
                 [postCode]="searchEntityUid$ | async"
></apym-shopfilter>

<ng-container *ngIf="context | async as shop">
  <div *ngIf="shop.directions_for_use">
    <mat-accordion>
      <mat-expansion-panel class="shop-instructions">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-center">
            <span>{{ 'shop-categories.specialNotice' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="shop.directions_for_use" [innerHTML]="shop.directions_for_use?.data"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </div> <!-- end directions of use row -->
  <div class="content-wrapper">
    <div class="card-wrapper">
      <ng-container *ngFor="let category of categories$ | async" >
        <mat-card [class]="{disabled: category.articles === 0}" (click)="selectCategory(category.id)">
          <img [src]="category.image + '/preview'" />
          <h6>{{ category.name }} ({{ category.articles }})</h6>
        </mat-card>
      </ng-container>
    </div>
    <app-cart></app-cart>
  </div>
  <div class="text-center shop-web"><small *ngIf="shop.website"><a [href]="shop.website" target="_blank">{{ 'shop-categories.goToShopWebsite' | translate }}</a></small></div>
</ng-container>
<grange-breadcrumbs></grange-breadcrumbs>
