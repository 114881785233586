<mat-card class="article">
  <ng-template #popContent><img *ngIf="article.image !== null" [src]="article.image.scales.large.download" /></ng-template>
  <div class="article-pic" (click)="openZoom()" >
    <img *ngIf="article.image !== null" [src]="article.image.scales.preview.download"/>
    <span class="zoom-in"><i class="fas fa-search-plus"></i></span>
    <div><small>{{ article.image_footer }}</small></div>
  </div>
  <h3>{{ article.title }}</h3>
  <p><span>{{ 'itemresult.by' | translate }} </span><a [href]="shop['@id']">{{ shop.name }}</a></p>
  <div class="article-pickup-delivery">
    {{ shop.delivery_availability_datetime_text[article.delivery_day]?.text }}
  </div>
  <div class="shipping-cost" *ngIf="shop.minmax_shipping_fee">
    <mat-icon>delivery_dining</mat-icon>
    <span class="data" *ngIf="shop.minmax_shipping_fee[0] === shop.minmax_shipping_fee[1]">
      {{ shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2' }}
    </span>
    <span class="data" *ngIf="shop.minmax_shipping_fee[0] !== shop.minmax_shipping_fee[1]">
      {{ 'shopresult.startingFrom' | translate: {shippingFee: shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2'} }}
    </span>
    <span class="free-delivery-offer" *ngIf="shop.minimum_order_without_shipping_costs">
      ( {{ 'shopresult.moreThanXFree' | translate: {minAmount: shop.minimum_amount_without_shipping_costs | apymCurrency:'€':'symbol':'1.2-2'} }} )
    </span>
  </div>
  <div class="prices-col">
    <ng-container *ngIf="article.use_subarticle; else nosubarticle">
      <div *ngFor="let subarticle of article.subarticles" class="article-price">
        <div>
          <div class="size">{{ subarticle.title }}</div>
          <small *ngIf="subarticle.portions">{{ subarticle.portions }} <span>{{ 'itemresult.portions' | translate }}</span></small>
        </div>
        <div>
          <span class="price">{{ subarticle.price | currency:'EUR'}}</span>
          <app-addtocart [id]="subarticle.UID"
                         [shop]="shop"
                         [searchData]="searchData"
                         [price]="subarticle.price"></app-addtocart>
        </div>
      </div>
    </ng-container>
    <ng-template #nosubarticle>
      <ng-container *ngIf="variants; else simplearticle">
        <div *ngFor="let variant of variants" class="article-price">
          <div>
            <div class="size">{{ variant.name }}</div>
            <small *ngIf="variant.description">{{ variant.description }}</small>
          </div>
          <div>
            <span class="price">{{ variant.price | currency:'EUR'}}</span>
            <app-addtocart [id]="article.UID"
                           [option]="variant['@id']"
                           [shop]="shop"
                           [searchData]="searchData"
                           [price]="variant.price"></app-addtocart>
          </div>
        </div>
      </ng-container>
      <ng-template #simplearticle>
        <div class="article-price">
          <div></div>
          <div *ngIf="article.price">
            <span class="price">{{ article.price | currency:'EUR'}}</span>
            <app-addtocart [id]="article.UID"
                           [shop]="shop"
                           [searchData]="searchData"
                           [price]="article.price"></app-addtocart>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div class="description-wrapper">
    <div [innerHTML]="article.text && article.text.data || ''"></div>
  </div>
</mat-card>
