import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AuthenticatedStatus } from '@guillotinaweb/grange-core';
import { Grange } from 'grange';
import { concat, forkJoin, of, timer } from 'rxjs';
import {
  ClearAsyncErrorAction,
  SetAsyncErrorAction,
  SetUserDefinedPropertyAction,
  SetValueAction,
  StartAsyncValidationAction,
} from 'ngrx-forms';
import {
  catchError,
  combineLatest,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AppState } from '~/reducers';
import {
  selectAvailablePaymentMethods,
  selectCart,
  selectCartItems,
  selectCartShippingMethod,
  selectCartShop,
  selectCartTotals,
  selectCheckoutBuyerData,
  selectCheckoutCities,
  selectCheckoutCounties,
  selectCheckoutCountries,
  selectCheckoutCoupon,
  selectCheckoutDeliveryDate,
  selectCheckoutDeliveryOrPickup,
  selectCheckoutDeliveryTimerange,
  selectCheckoutForm,
  selectCheckoutIsDelivery,
  selectCheckoutPostcodes,
} from './cart.selectors';
import { selectShopFilterData, selectShop } from '~/shop/store/shop.selectors';
import * as CartActionTypes from './cart.actions';
import * as CartModel from '~/cart/models/cart';
import { WINDOW } from '~/core/window.service';
import { FilterData } from '~/shop/models';
import { BackendService } from '~/core/backend.service';
import { Article, TerritorialEntities, UserInfo } from '~/core/models';
import { JsonToStringTimerange, MomentTimerangeToJson } from '~/shared/models';
import { AppJsonConfig } from '~/core/appjsonconfig.service';
import { SelectitemoptionsComponent } from '~/cart/addtocart/selectitemoptions/selectitemoptions.component';
import { AddtocartBottomsheetComponent } from '~/cart/addtocart/addtocart-bottomsheet/addtocart-bottomsheet.component';
import { ArticleOptionsService } from '~/core/articleoptions.service';

@Injectable({
  providedIn: 'root',
})
export class CartEffects {
  CLEARCART_TIMEOUT = 1800 * 1000;
  // remember to change cart/checkout/checkout-delivery/checkout-delivery.component/CheckoutDeliveryComponent.useGooglePlaces
  useGooglePlaces = true;

  constructor(
    private actions$: Actions,
    private grange: Grange,
    private backend: BackendService,
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private appConfig: AppJsonConfig,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private articleOptions: ArticleOptionsService,
  ) {}

  addItemToShop = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.addItemToShop),
      map((action) => action.cartItem),
      withLatestFrom(this.store.pipe(select(selectCartShop))),
      mergeMap(([cartItemShop, cartShop]: [CartModel.CartItemShop, CartModel.CartShop]) => {
        const actions: Action[] = [];
        if (!_.isEmpty(cartShop) && cartShop.uid !== cartItemShop.shop_uid) {
          actions.push(CartActionTypes.clearCartAndCartShop());
        }
        actions.push(CartActionTypes.addItem({ cartItem: cartItemShop }));
        return actions;
      }),
    ),
  );

  addItemUid = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof CartActionTypes.addItemUid>>(CartActionTypes.addItemUid),
      withLatestFrom(this.store.pipe(select(selectShop))),
      switchMap(([action, shop]) => this.backend.articleBrain$(shop['@id'], action.itemUid)),
      //tap((r) => console.log('addItemUid', r)),
      map((article) =>
        CartActionTypes.addItem({
          cartItem: { id: article.id, name: article.title, price: article.price, quantity: 1 },
        }),
      ),
    ),
  );

  applyCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.applyCoupon),
      withLatestFrom(this.store.pipe(select(selectCart)), this.store.pipe(select(selectCartShop))),
      switchMap(([action, cart, shop]) => {
        return this.backend.discounts$(shop['@id'], { ...cart, coupon: action.coupon });
      }),
      mergeMap((discounts: CartModel.CartDiscount[]) => {
        const actions: Action[] = [];
        const coupon = discounts.reduce((acc, d) => (acc === null ? d.coupon : acc), null);
        console.log('applyCoupon$', coupon);
        if (coupon !== null) {
          actions.push(new SetValueAction('CheckoutForm.payment.coupon', coupon));
        }
        actions.push(CartActionTypes.setDiscounts({ discounts }));
        return actions;
      }),
    ),
  );

  removeCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.removeCoupon),
      mergeMap(() => [new SetValueAction('CheckoutForm.payment.coupon', null), CartActionTypes.couponRemoved()]),
    ),
  );

  // coupon$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<SetValueAction<string>>(SetValueAction.TYPE),
  //     filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.payment.coupon'),
  //     map((a) => a.value),
  //     withLatestFrom(this.store.select(selectAvailablePaymentMethods)),
  //     map(([paymentMethodId, availablePaymentMethods]) => {
  //       const paymentMethod = availablePaymentMethods.filter((pm) => pm.id === paymentMethodId);
  //       return CartActionTypes.setPaymentMethod({ paymentMethod: paymentMethod[0] });
  //     }),
  //   ),
  // );

  checkoutInitBuyerdata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.checkoutInitBuyerdata),
      withLatestFrom(this.grange.core.auth.isAuthenticated, this.store.pipe(select(selectCheckoutBuyerData))),
      switchMap(([_action, authStatus, buyerData]: [Action, AuthenticatedStatus, any]) =>
        forkJoin(authStatus.state ? this.backend.getUserInfo$() : of({}), of(buyerData)),
      ),
      mergeMap(([userInfo, buyerData]: [UserInfo, any]) => {
        let actions: Action[] = [];
        if (
          !_.isEmpty(userInfo) &&
          buyerData.billingName === '' &&
          buyerData.billingEmail === '' &&
          buyerData.billingPhone === ''
        ) {
          actions = _.concat(
            actions,
            new SetValueAction('CheckoutForm.buyerData.billingName', userInfo.fullname),
            new SetValueAction('CheckoutForm.buyerData.billingEmail', userInfo.email),
            new SetValueAction('CheckoutForm.buyerData.confirmBillingEmail', userInfo.email),
            new SetValueAction('CheckoutForm.buyerData.billingPhone', userInfo.mobileNo),
          );
        }
        return actions;
      }),
    ),
  );

  checkoutInitDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.checkoutInitDelivery),
      withLatestFrom(this.store.pipe(select(selectCartShop)), this.store.pipe(select(selectShopFilterData))),
      switchMap(([_action, shop, filterData]: [any, any, FilterData]) =>
        forkJoin(this.backend.shopCountries$(shop['@id']), of(filterData)),
      ),
      mergeMap(([countries, filterData]) => {
        const controlId = 'CheckoutForm.delivery.shippingCountry';
        const actions: Action[] = [new SetUserDefinedPropertyAction(controlId, 'COUNTRIES', countries)];
        if (countries.ids.length === 1) {
          actions.push(new SetValueAction(controlId, countries.ids[0]));
        } else if (!this.useGooglePlaces && filterData.country) {
          actions.push(new SetValueAction(controlId, filterData.country.uid));
        }
        if (!this.useGooglePlaces) {
          if (filterData.county) {
            actions.push(new SetValueAction('CheckoutForm.delivery.shippingCounty', filterData.county.uid));
          }
          if (filterData.city) {
            actions.push(new SetValueAction('CheckoutForm.delivery.shippingCity', filterData.city.uid));
          }
          if (filterData.postCode) {
            actions.push(new SetValueAction('CheckoutForm.delivery.shippingPostCode', filterData.postCode.uid));
          }
        }
        return actions;
      }),
    ),
  );

  checkoutDatetimeValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((action: SetValueAction<string>) =>
        ['CheckoutForm.deliveryDatetime.deliveryTimeRange', 'CheckoutForm.deliveryDatetime.deliveryDate'].includes(
          action.controlId,
        ),
      ),
      withLatestFrom(
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutDeliveryOrPickup)),
        this.store.pipe(select(selectCheckoutDeliveryDate)),
        this.store.pipe(select(selectCheckoutDeliveryTimerange)),
        this.store.pipe(select(selectCartItems)),
      ),
      mergeMap(([action, shop, deliveryOrPickup, date, timeRange, cartItems]) =>
        concat(
          timer(300).pipe(map(() => new StartAsyncValidationAction(action.controlId, 'available') as Action)),
          this.backend
            .cartItemsAvailability$(
              shop['@id'],
              deliveryOrPickup,
              date,
              action.controlId === 'CheckoutForm.deliveryDatetime.deliveryTimeRange' ? timeRange : null,
              cartItems.map((item) => item.id),
            )
            .pipe(
              mergeMap((result) => {
                const notAvailableItemUids = Object.keys(result).filter((k) => !result[k]);
                if (notAvailableItemUids.length === 0) {
                  const actions: Action[] = [new ClearAsyncErrorAction(action.controlId, 'available')];
                  if (action.controlId === 'CheckoutForm.deliveryDatetime.deliveryDate' && !_.isNull(timeRange)) {
                    // launch timerange validation if date is valid
                    actions.push(new SetValueAction('Checkout.deliveryDatetime.deliveryTimeRange', timeRange));
                  }
                  return actions;
                } else {
                  const notAvailableItems = cartItems.filter((cartItem) => notAvailableItemUids.includes(cartItem.id));
                  const actions: Action[] = [new SetAsyncErrorAction(action.controlId, 'available', notAvailableItems)];
                  if (action.controlId === 'CheckoutForm.deliveryDatetime.deliveryDate' && !_.isNull(timeRange)) {
                    // clear timerange if date not valid
                    actions.push(new SetValueAction('Checkout.deliveryDatetime.deliveryTimeRange', null));
                  }
                  return actions;
                }
              }),
            ),
        ),
      ),
    ),
  );

  defaultDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.checkoutInit),
      withLatestFrom(
        this.store.pipe(select(selectShopFilterData)),
        this.store.pipe(select(selectCheckoutDeliveryDate)),
      ),
      filter(([_action, filterData, date]) => !_.isNull(filterData.date) || !_.isNull(date)),
      mergeMap(([_action, filterData, date]) => {
        const actions: Action[] = [
          new SetValueAction(
            'CheckoutForm.deliveryDatetime.deliveryDate',
            !_.isNull(filterData?.date) ? filterData.date.toISOString() : date.toISOString(),
          ),
          new SetValueAction(
            'CheckoutForm.deliveryDatetime.deliveryTimeRange',
            filterData?.timeRange ? MomentTimerangeToJson(filterData.timeRange) : null,
          ),
        ];
        // if (filterData.timeRange) {
        //   actions.push(
        //     new SetValueAction(
        //       'CheckoutForm.deliveryDatetime.deliveryTimeRange',
        //       MomentTimerangeToJson(filterData.timeRange),
        //     ),
        //   );
        // `${filterData.timeRange.fromTime.format('HH:mm:ss')}-${filterData.timeRange.toTime.format('HH:mm:ss')}`));
        // }
        return actions;
      }),
    ),
  );

  deliveryPostcode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.delivery.shippingPostcode'),
      withLatestFrom(this.store.pipe(select(selectCartShop)), this.store.pipe(select(selectCheckoutPostcodes))),
      switchMap(([a, shop, postcodes]) =>
        forkJoin(
          of(a),
          a.value ? this.backend.shippingMethodForPostCode$(shop['@id'], a.value) : of(null),
          of(postcodes),
        ),
      ),
      mergeMap(
        ([a, shippingMethod, postcodes]: [SetValueAction<string>, CartModel.ShippingMethod, TerritorialEntities]) => [
          CartActionTypes.setShippingMethod({ shippingMethod }),
          new SetValueAction(
            'CheckoutForm.delivery.shippingPostcodeCaption',
            a.value && postcodes ? postcodes.entities[a.value].name : '',
          ),
        ],
      ),
    ),
  );

  extendClearCartTimeout$ = createEffect(() =>
    this.actions$.pipe(
      switchMap((_action: Action) => timer(this.CLEARCART_TIMEOUT)),
      tap(() => this.grange.traverser.traverse('')),
      mergeMap(() => [CartActionTypes.checkingout({ checkingout: false }), CartActionTypes.clearCart()]),
    ),
  );

  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof CartActionTypes.paymentMethodsRequested>>(CartActionTypes.paymentMethodsRequested),
      combineLatest(this.store.select(selectCartShop)),
      filter(([_action, cartShop]) => !!cartShop),
      mergeMap(([_action, cartShop]) => this.backend.paymentMethods$(cartShop['@id'])),
      map((result: any) =>
        result.payment_methods.map((paymentMethod) =>
          paymentMethod.widgetLogo
            ? {
                ...paymentMethod,
                widgetLogo: paymentMethod.widgetLogo
                  .replace(/&lt;/g, '<')
                  .replace(/&quot;/g, '"')
                  .replace(/&amp;/g, '&'),
              }
            : paymentMethod,
        ),
      ),
      map((paymentMethods) => CartActionTypes.paymentMethodsLoaded({ paymentMethods })),
    ),
  );

  openBottomSheet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof CartActionTypes.addItemUid>>(CartActionTypes.addItem),
        tap((_) => this.bottomSheet.open(AddtocartBottomsheetComponent)),
      ),
    { dispatch: false },
  );

  openSelectOptionsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof CartActionTypes.openSelectOptionsDialog>>(CartActionTypes.openSelectOptionsDialog),
      switchMap(({ article, articleId, articleUid, preselectedOption }) =>
        article
          ? of([article, preselectedOption])
          : this.backend.article$({ articleId, articleUid }).pipe(map((result) => [result, preselectedOption])),
      ),
      //tap((action) => console.log('openSelectOptionsDialog', action)),
      exhaustMap(([article, preselectedOption]: [Article, string]) => {
        const dialogRef = this.dialog.open(SelectitemoptionsComponent, {
          data: {
            article,
            preselectedOption,
          },
        });
        return dialogRef.afterClosed();
      }),
      map((result: any) => {
        if (result === undefined) {
          return CartActionTypes.closeSelectOptionsDialog();
        }
        return CartActionTypes.addItem({ cartItem: result });
      }),
    ),
  );

  paymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.payment.paymentMethod'),
      map((a) => a.value),
      withLatestFrom(this.store.select(selectAvailablePaymentMethods)),
      map(([paymentMethodId, availablePaymentMethods]) => {
        const paymentMethod = availablePaymentMethods.filter((pm) => pm.id === paymentMethodId);
        return CartActionTypes.setPaymentMethod({ paymentMethod: paymentMethod[0] });
      }),
    ),
  );

  postOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.postOrder),
      withLatestFrom(
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutForm)),
        this.store.pipe(select(selectCartItems)),
      ),
      switchMap(([_action, shop, checkoutForm, cartItems]) => {
        const checkout = checkoutForm.value;
        return this.backend
          .newOrder$(shop['@id'], {
            returnUrl: this.window.location.origin + shop.contextPath + '/@@payment',
            cancelUrl: this.window.location.origin + shop.contextPath + '/@@checkout',
            sourceCode: this.appConfig.getConfig('sourceCode'),
            deliveryDate: moment(checkout.deliveryDatetime.deliveryDate),
            deliveryTimeRange: JsonToStringTimerange(checkout.deliveryDatetime.deliveryTimeRange),
            pickupAddress: checkout.pickup.pickupAddress,
            billingName: checkout.buyerData.billingName,
            billingOrganization: checkout.review.billingCompany,
            billingPhone: checkout.buyerData.billingPhone,
            billingEmail: checkout.buyerData.billingEmail,
            billingAddress: checkout.review.billingAddress,
            billingPostcode: checkout.review.billingPostcode,
            billingCity: checkout.review.billingCity,
            billingCounty: checkout.review.billingCounty,
            billingCountry: checkout.review.billingCountry,
            shippingName: checkout.recipientData.hasRecipient
              ? checkout.recipientData.recipientName
              : checkout.buyerData.billingName,
            shippingOrganization: checkout.delivery.shippingOrganization,
            shippingPhone: checkout.recipientData.hasRecipient
              ? checkout.recipientData.recipientPhone
              : checkout.buyerData.billingPhone,
            shippingEmail: checkout.recipientData.hasRecipient
              ? checkout.recipientData.recipientEmail
              : checkout.buyerData.billingEmail,
            shippingAddress: checkout.delivery.shippingAddress + ', ' + checkout.delivery.shippingAddressStreetNumber,
            shippingAddressComplement: checkout.delivery.shippingAddressComplement,
            delivery: checkout.delivery.delivery,
            pickup: checkout.pickup.pickup,
            shippingPostcode: { uid: checkout.delivery.shippingPostcode },
            shippingCity: { uid: checkout.delivery.shippingCity },
            shippingCounty: { uid: checkout.delivery.shippingCounty },
            shippingCountry: { uid: checkout.delivery.shippingCountry },
            isASurprise: checkout.recipientData.isASurprise,
            cardMessage: checkout.cardMessage.cardMessage,
            comment: checkout.messageForShop.comment,
            coupon: checkout.payment.coupon,
            paymentMethod: { id: checkout.payment.paymentMethod },
            shippingPostcodeUuid: null, //
            cartItems: cartItems.map((item) => ({
              id: item.id,
              name: item.name,
              detailsText: item.optionsText,
              details: item.options,
              price: item.price,
              quantity: item.quantity,
            })),
            //cartDiscounts: [],

            // annotations
            emailTemplates: this.appConfig.getConfig('emailTemplates'),
            emailLogoAlt: this.appConfig.getConfig('emailLogoAlt'),
            emailLogoUrl: this.appConfig.getConfig('emailLogoUrl'),
            emailShopUrl: this.appConfig.getConfig('emailShopUrl'),
            geoCoordinatesLatitude: checkout.delivery.shippingLatitude,
            geoCoordinatesLongitude: checkout.delivery.shippingLongitude,
          })
          .pipe(
            catchError((err) => {
              console.log('error posting order', err);
              this.store.dispatch(CartActionTypes.postOrderCancelled());
              return of(null);
            }),
          );
      }),
      mergeMap(([payAction, order]) => [
        CartActionTypes.setPayAction({ payAction }),
        CartActionTypes.orderPosted({ order }),
      ]),
    ),
  );

  selectItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActionTypes.selectItem),
      switchMap(({ articleId, articleUid, preselectedOption }) =>
        this.backend.article$({ articleId, articleUid }).pipe(map((result) => [result, preselectedOption])),
      ),
      map(([article, preselectedOption]: [Article, string]) => {
        if (article['@type'] === 'apanymantel.cart.shopping.SubArticle') {
          return CartActionTypes.addItem({
            cartItem: {
              id: article.UID,
              name: `${article.parent.title} ${article.title}`,
              price: article.price,
              quantity: 1,
            },
          });
        } else if (this.articleOptions.hasOptions(article.options)) {
          return CartActionTypes.openSelectOptionsDialog({ article, preselectedOption });
        } else {
          return CartActionTypes.addItem({
            cartItem: {
              id: article.UID,
              name: article.title,
              price: article.price,
              quantity: 1,
            },
          });
        }
      }),
    ),
  );

  shippingCity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.delivery.shippingCity'),
      withLatestFrom(
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutCities)),
        this.store.pipe(select(selectShopFilterData)),
      ),
      filter(([_a, _shop, cities, _filterData]) => cities),
      switchMap(([a, shop, cities, filterData]) =>
        // a.value can be null if clearing delivery data
        forkJoin(
          of(a),
          a.value ? this.backend.shopPostCodes$(shop['@id'], a.value) : of(null),
          of(cities),
          of(filterData),
        ),
      ),
      //_.isNull(filterData.postCode) ? of([]) : this.grange.core.cache.get(shop['@id'] + '/shop-postcode-chain?uid=' + filterData.postCode.uid))),
      mergeMap(
        ([a, postcodes, cities, filterData]: [
          SetValueAction<string>,
          TerritorialEntities,
          TerritorialEntities,
          FilterData,
        ]) => {
          const controlId = 'CheckoutForm.delivery.shippingCityCaption';
          const childControlId = 'CheckoutForm.delivery.shippingPostcode';
          const actions: Action[] = [
            new SetUserDefinedPropertyAction(childControlId, 'POSTCODES', postcodes),
            new SetValueAction(controlId, a.value ? cities.entities[a.value].name : ''),
          ];
          if (postcodes !== null) {
            if (postcodes.ids.length === 1) {
              actions.push(new SetValueAction(childControlId, postcodes.ids[0]));
            } else if (!this.useGooglePlaces && filterData.postCode) {
              if (postcodes.ids.indexOf(filterData.postCode.uid) !== -1) {
                actions.push(new SetValueAction(childControlId, filterData.postCode.uid));
              }
            }
          }
          return actions;
        },
      ),
    ),
  );

  // shippingEntities$ = createEffect(() => this.actions$.pipe(
  //   ofType<SetValueAction<any>>(SetValueAction.TYPE),
  //   filter((a: SetValueAction<any>) => a.controlId === 'CheckoutForm.delivery.shippingEntities'),
  //   flatMap((a) => [
  //     new SetValueAction('CheckoutForm.delivery.shippingCountry', a.value.country),
  //     new SetValueAction('CheckoutForm.delivery.shippingCounty', a.value.county),
  //     new SetValueAction('CheckoutForm.delivery.shippingCity', a.value.city),
  //     new SetValueAction('CheckoutForm.delivery.shippingPostcode', a.value.postcode),
  //   ])

  // ));

  shippingCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.delivery.shippingCountry'),
      withLatestFrom(
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutCountries)),
        this.store.pipe(select(selectShopFilterData)),
      ),
      filter(([_a, _shop, countries, _filterData]) => countries),
      switchMap(([a, shop, countries, filterData]) =>
        forkJoin(
          of(a),
          // a.value can be null if clearing delivery data
          a.value ? this.backend.shopCounties$(shop['@id'], a.value) : of(null),
          of(countries),
          of(filterData),
        ),
      ),
      //_.isNull(filterData.postCode) ? of([]) : this.grange.core.cache.get(shop['@id'] + '/shop-postcode-chain?uid=' + filterData.postCode.uid))),
      mergeMap(
        ([a, counties, countries, filterData]: [
          SetValueAction<string>,
          TerritorialEntities,
          TerritorialEntities,
          FilterData,
        ]) => {
          const controlId = 'CheckoutForm.delivery.shippingCountryCaption';
          const childControlId = 'CheckoutForm.delivery.shippingCounty';
          const actions: Action[] = [
            new SetUserDefinedPropertyAction(childControlId, 'COUNTIES', counties),
            new SetValueAction(controlId, a.value ? countries.entities[a.value].name : ''),
          ];
          if (counties !== null) {
            if (counties.ids.length === 1) {
              actions.push(new SetValueAction(childControlId, counties.ids[0]));
            } else if (!this.useGooglePlaces && filterData.county) {
              if (counties.ids.indexOf(filterData.county.uid) !== -1) {
                actions.push(new SetValueAction(childControlId, filterData.county.uid));
              }
            }
          }
          return actions;
        },
      ),
    ),
  );

  shippingCounty$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((a: SetValueAction<string>) => a.controlId === 'CheckoutForm.delivery.shippingCounty'),
      withLatestFrom(
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutCounties)),
        this.store.pipe(select(selectShopFilterData)),
      ),
      filter(([_a, _shop, counties, _filterData]) => counties),
      switchMap(([a, shop, counties, filterData]) =>
        forkJoin(
          of(a),
          // a.value can be null if clearing delivery data
          a.value ? this.backend.shopCities$(shop['@id'], a.value) : of(null),
          of(counties),
          of(filterData),
        ),
      ),
      //_.isNull(filterData.postCode) ? of([]) : this.grange.core.cache.get(shop['@id'] + '/shop-postcode-chain?uid=' + filterData.postCode.uid))),
      mergeMap(
        ([a, cities, counties, filterData]: [
          SetValueAction<string>,
          TerritorialEntities,
          TerritorialEntities,
          FilterData,
        ]) => {
          const controlId = 'CheckoutForm.delivery.shippingCountyCaption';
          const childControlId = 'CheckoutForm.delivery.shippingCity';
          const actions: Action[] = [
            new SetUserDefinedPropertyAction(childControlId, 'CITIES', cities),
            new SetValueAction(controlId, a.value ? counties.entities[a.value].name : ''),
          ];
          if (cities !== null) {
            if (cities.ids.length === 1) {
              actions.push(new SetValueAction(childControlId, cities.ids[0]));
            } else if (!this.useGooglePlaces && filterData.city) {
              if (cities.ids.indexOf(filterData.city.uid) !== -1) {
                actions.push(new SetValueAction(childControlId, filterData.city.uid));
              }
            }
          }
          return actions;
        },
      ),
    ),
  );

  updateDiscounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CartActionTypes.addItem,
        CartActionTypes.incrItem,
        CartActionTypes.decrItem,
        CartActionTypes.postOrder,
        CartActionTypes.couponRemoved,
      ),
      withLatestFrom(
        this.store.pipe(select(selectCart)),
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCheckoutCoupon)),
      ),
      switchMap(([_action, cart, shop, coupon]) => {
        return this.backend.discounts$(shop['@id'], { ...cart, coupon });
      }),
      mergeMap((discounts: CartModel.CartDiscount[]) => {
        console.log('updateDiscounts$', discounts);
        return [CartActionTypes.setDiscounts({ discounts })];
      }),
    ),
  );

  updateShippingAmount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CartActionTypes.setShippingMethod,
        CartActionTypes.addItem,
        CartActionTypes.incrItem,
        CartActionTypes.decrItem,
        CartActionTypes.setShop,
      ),
      // filter((action) => action),
      withLatestFrom(
        this.store.pipe(select(selectCartShippingMethod)),
        this.store.pipe(select(selectCartShop)),
        this.store.pipe(select(selectCartTotals)),
        this.store.pipe(select(selectCheckoutIsDelivery)),
      ),
      map(([_action, shippingMethod, shop, cartTotals, isDelivery]) => {
        if (!isDelivery) {
          return CartActionTypes.setShippingAmount({ shippingAmount: 0 });
        } else if (
          shop?.minimumOrderWithoutShippingCosts &&
          cartTotals.itemsAmount - cartTotals.discountsAmount >= shop.minimumAmountWithoutShippingCosts
        ) {
          return CartActionTypes.setShippingAmount({ shippingAmount: 0 });
        } else if (cartTotals.itemsAmount === 0 || shippingMethod === null || typeof shippingMethod === 'undefined') {
          return CartActionTypes.setShippingAmount({ shippingAmount: 0 });
        } else {
          return CartActionTypes.setShippingAmount({ shippingAmount: shippingMethod.amount });
        }
      }),
    ),
  );
}
