import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Grange } from 'grange';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { BackendService } from '~/core/backend.service';
import { RatingOrder, RatingOrderItem, RatingVocab } from '~/core/models';

@Component({
  selector: 'app-rating-form',
  templateUrl: './rating-form.component.html',
  styleUrls: ['./rating-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingFormComponent extends BaseViewComponent implements OnInit {
  rateItemVocab$: Observable<RatingVocab[]>;
  rateOrderVocab$: Observable<RatingVocab[]>;
  ratingOrder$: Observable<RatingOrder>;
  model: { [key: string]: string | number } = { generalRating: null, generalComment: null, nickname: null };
  maxRating = 10.0;
  order: string;
  token: string;
  form: string;
  loading = false;
  thankyou = false;

  // constructor(@Inject(LOCALE_ID) public locale: string, public grange: Grange) {
  constructor(
    public grange: Grange,
    public seo: SeoService,
    private translate: TranslateService,
    private backend: BackendService,
    private cd: ChangeDetectorRef,
  ) {
    super(grange, seo);
  }

  ngOnInit() {
    super.ngOnInit();
    const locale: string = this.translate.currentLang;
    this.rateItemVocab$ = this.backend.rateItemVocab$(locale);
    this.rateOrderVocab$ = this.backend.rateOrderVocab$(locale);
    this.ratingOrder$ = this.context.pipe(
      withLatestFrom(
        this.grange.traverser.target.pipe(
          map((target) => [this.getParam('token', target.path), this.getParam('order', target.path)]),
        ),
      ),
      tap(([context, [token, order]]: [any, [string, string]]) => {
        this.token = token;
        this.order = order;
        this.form = context['id'];
      }),
      switchMap(([context, [token, order]]) =>
        this.backend.ratingOrder$(order, context['id'], token).pipe(
          map((result: any) => result.rating_order),
          tap((ratingOrder) => {
            if (ratingOrder !== 'already_rated') {
              this.model.nickname = ratingOrder.nickname;
              ratingOrder.items.forEach((item: RatingOrderItem) => {
                this.model['item.' + item.uid] = this.maxRating;
              });
            }
          }),
        ),
      ),
    );
  }

  getParam(name: string, path: string) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(path);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  onSubmit() {
    this.loading = true;
    this.backend.rateOrder$(this.model, this.order, this.form, this.token).subscribe((result) => {
      this.loading = false;
      this.thankyou = true;
      this.cd.detectChanges();
    });
  }
}
