import { Injectable } from '@angular/core';
import { GroupOption, GroupOptions, Option } from '~/core/models';

@Injectable()
export class ArticleOptionsService {
  getGroupOptionBasePrice(groupOptions: GroupOptions): GroupOption {
    if (groupOptions.items) {
      const gos = groupOptions.items.filter((go) => go.substitutesBasePrice);
      if (gos.length > 0) {
        return gos[0];
      }
    }
  }

  getOptionsBasePrice(groupOptions: GroupOptions): Option[] {
    const go = this.getGroupOptionBasePrice(groupOptions);
    if (go) {
      return go.options?.items;
    }
  }

  hasOptions(groupOptions: GroupOptions): boolean {
    const go = this.getGroupOptionBasePrice(groupOptions);
    return (!!go && groupOptions.items.length > 1) || groupOptions.items.length > 0;
  }
}
