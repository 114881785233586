<pa-input-icon [iconName]="iconName" [iconTooltip]="isVisible ? 'password-input.hide' : 'password-input.show'" iconColor="secondary" (iconClick)="togglePasswordVisibility()">
    <pa-input [id]="id" [type]="type" [placeholder]="placeholder" acceptHtmlTags
              [required]="required"
              [disabled]="disabled"
              [value]="password"
              [errorMessage]="errorMessage"
              (keyup)="checkPasswordStrength($event)"><ng-content></ng-content></pa-input>
</pa-input-icon>

<div *ngIf="withRules">
    <legend translate>password-input.rule-list.legend</legend>
    <ul>
        <li *ngFor="let rule of rules" [class.o-rule-checked]="rule.isValid" translate>
            <div class="pa-rule-disc"><pa-icon *ngIf="rule.isValid" name="check"></pa-icon></div>
            {{rule.label}}
        </li>
    </ul>
</div>
