import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Grange } from 'grange';
import { distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { AppState } from '~/reducers';
import {
  selectArticlesCategory,
  selectArticlesLoading,
  selectDeliveryAvailabilityDatetimeText,
  selectPickupAvailabilityDatetimeText,
  selectSelectedCategory,
  selectSelectedCategoryEntity,
  selectShop,
  selectShopFilterData,
  selectShopShopSchema,
  ShopActionTypes,
} from '~/shop/store';
import { Category } from '../models';
import { Article } from '~/core/models';
import { FilterData } from '~/shop/models/filterdata';
import * as moment from 'moment';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { CartActionTypes } from '~/cart/store';
import { AvailabilityDatetimeText, deliveryOrPickup, Shop } from '~/core/models';
import { ConfigurationService } from '@guillotinaweb/grange-core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop-articles.component.html',
  styleUrls: ['./shop-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopArticlesComponent extends BaseViewComponent implements OnInit {
  articles$: Observable<Article[]>;
  categorySelected$: Observable<string>;
  selectedCategoryEntity$: Observable<Category>;
  pickupAvailabilityDatetimeText$: Observable<AvailabilityDatetimeText>;
  deliveryAvailabilityDatetimeText$: Observable<AvailabilityDatetimeText>;
  filterData$: Observable<FilterData>;
  data$: Observable<[Category, AvailabilityDatetimeText, AvailabilityDatetimeText]>;
  // @ViewChild('articlelist') articlelist: ElementRef
  loading$: Observable<boolean>;
  shopSchema$: Observable<any>;

  constructor(
    public grange: Grange,
    public seo: SeoService,
    private configurationService: ConfigurationService,
    public store: Store<AppState>,
  ) {
    super(grange, seo);

    this.store
      .pipe(
        select(selectSelectedCategory),
        take(1),
        switchMap((category) =>
          this.grange.traverser.target.pipe(
            take(1),
            map((target) => [category, target.query.get('category')]),
          ),
        ),
      )
      .subscribe(([categoryId, categoryIdQuery]) => {
        if (categoryId || categoryIdQuery) {
          this.store.dispatch(ShopActionTypes.selectCategory({ category: categoryId || categoryIdQuery }));
        } else {
          this.grange.traverser.traverse('.');
        }
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store.dispatch(CartActionTypes.checkingout({ checkingout: false }));
    this.store
      .pipe(
        select(selectShop),
        takeUntil(this.destroy),
        withLatestFrom(this.context),
        filter(([shop, context]) => !shop || shop['@id'] !== context['@id']),
        map(([_shop, context]) => context),
      )
      .subscribe((context) => {
        this.store.dispatch(ShopActionTypes.loadShop({ shop: context as Shop }));
      });

    this.shopSchema$ = this.store.pipe(select(selectShopShopSchema));

    this.selectedCategoryEntity$ = this.store.pipe(select(selectSelectedCategoryEntity));

    this.pickupAvailabilityDatetimeText$ = this.store.pipe(select(selectPickupAvailabilityDatetimeText));

    this.deliveryAvailabilityDatetimeText$ = this.store.pipe(select(selectDeliveryAvailabilityDatetimeText));

    this.filterData$ = this.store.pipe(select(selectShopFilterData));

    this.data$ = combineLatest(
      this.store.pipe(select(selectSelectedCategoryEntity)),
      this.store.pipe(select(selectPickupAvailabilityDatetimeText)),
      this.store.pipe(select(selectDeliveryAvailabilityDatetimeText)),
    );

    this.loading$ = this.store.pipe(select(selectArticlesLoading));

    this.articles$ = combineLatest(
      this.store.pipe(
        select(selectArticlesCategory),
        distinctUntilChanged((prev, curr) => prev.length === curr.length),
        withLatestFrom(this.store.pipe(select(selectSelectedCategory))),
        tap(([articles, category]) => {
          if (articles.length === 0 && category) {
            this.store.dispatch(ShopActionTypes.articlesRequested({ category }));
          }
        }),
        map(([articles, _]) => articles),
        filter((articles) => articles.length > 0),
      ),

      // calc availability, in shop-categories the availability is calculated by the host
      this.store.pipe(select(selectShopFilterData)),
      this.store.pipe(select(selectPickupAvailabilityDatetimeText)),
      this.store.pipe(select(selectDeliveryAvailabilityDatetimeText)),
      this.grange.traverser.target.pipe(map((target) => target.query.get('article_id'))),
    ).pipe(
      map(([articles, filterData, pickupDatetime, deliveryDatetime, article_id]) =>
        article_id
          ? [articles.filter((art) => art.id === article_id), filterData, pickupDatetime, deliveryDatetime]
          : [articles, filterData, pickupDatetime, deliveryDatetime],
      ),
      map(
        ([articles, filterData, pickupDatetime, deliveryDatetime]: [
          Article[],
          FilterData,
          AvailabilityDatetimeText,
          AvailabilityDatetimeText,
        ]) => {
          if (filterData.timeRange) {
            const [hour, minute] = filterData.timeRange.asap
              ? [filterData.timeRange.toTime.hour(), filterData.timeRange.toTime.minute()]
              : [filterData.timeRange.fromTime.hour(), filterData.timeRange.fromTime.minute()];
            filterData.date.hour(hour).minute(minute);
          }
         articles.map((article) =>
           console.log(
            'filterData.pickupOrDelivery: ', filterData.pickupOrDelivery,
            'filterData.date: ', filterData.date,
            'filterData.timeRange: ', filterData.timeRange,
            'pickupDatetime[', article.pickup_day.token, ']: ', pickupDatetime[article.pickup_day.token],
            'deliveryDatetime[', article.delivery_day.token , ']: ', deliveryDatetime[article.delivery_day.token],
            'deliveryDatetime....: ', deliveryDatetime[article.delivery_day.token].from.isSameOrBefore(filterData.date),
          )
            );
          const result = articles.map((article) => ({
            ...article,
            available:
              // filterData is empty
              (!filterData.pickupOrDelivery && !filterData.date) ||
              // only date is filtered
              (!filterData.pickupOrDelivery &&
                filterData.date &&
                !filterData.timeRange &&
                (pickupDatetime[article.pickup_day.token].from.isSameOrBefore(filterData.date, 'day') ||
                  deliveryDatetime[article.delivery_day.token].from.isSameOrBefore(filterData.date, 'day'))) ||
              // only date and timeRange is filtered
              (!filterData.pickupOrDelivery &&
                filterData.date &&
                filterData.timeRange &&
                (pickupDatetime[article.pickup_day.token].from.isSameOrBefore(filterData.date) ||
                  deliveryDatetime[article.delivery_day.token].from.isSameOrBefore(filterData.date))) ||
              // is pickup and date
              (filterData.pickupOrDelivery === deliveryOrPickup.PICKUP &&
                filterData.date &&
                !filterData.timeRange &&
                pickupDatetime[article.pickup_day.token].from.isSameOrBefore(filterData.date, 'day')) ||
              // is delivery and date
              (filterData.pickupOrDelivery === deliveryOrPickup.DELIVERY &&
                filterData.date &&
                !filterData.timeRange &&
                deliveryDatetime[article.delivery_day.token].from.isSameOrBefore(filterData.date, 'day')) ||
              // is pickup and date and timeRange
              (filterData.pickupOrDelivery === deliveryOrPickup.PICKUP &&
                filterData.date &&
                filterData.timeRange &&
                pickupDatetime[article.pickup_day.token].from.isSameOrBefore(filterData.date)) ||
              // is delivery and date and timeRange
              (filterData.pickupOrDelivery === deliveryOrPickup.DELIVERY &&
                filterData.date &&
                filterData.timeRange &&
                deliveryDatetime[article.delivery_day.token].from.isSameOrBefore(filterData.date)) ||
              // is pickup
              (filterData.pickupOrDelivery === deliveryOrPickup.PICKUP &&
                !filterData.date &&
                !filterData.timeRange &&
                !!article.pickup_day.token) ||
              // is delivery
              (filterData.pickupOrDelivery === deliveryOrPickup.DELIVERY &&
                !filterData.date &&
                !filterData.timeRange &&
                !!article.delivery_day.token),
          }));
          // sorting
          result.sort((article1, article2) =>
            article1.available === article2.available ? 0 : article1.available ? -1 : 1,
          );
          return result;
        },
      ),
    );
  }

  isPickup(filterData: FilterData): boolean {
    return filterData.pickupOrDelivery === deliveryOrPickup.PICKUP;
  }

  isDelivery(filterData: FilterData): boolean {
    return filterData.pickupOrDelivery === deliveryOrPickup.DELIVERY;
  }

  isToday(filterData: FilterData): boolean {
    return filterData.date !== null && filterData.date.isSame(moment(), 'day');
  }

  backToCategories(): void {
    this.store.dispatch(ShopActionTypes.unselectCategory());
    this.grange.traverser.traverse('.');
  }

  path(id: string): string {
    return this.configurationService.urlToPath(id);
  }
}
