<ng-container *ngIf="context | async as context">
<div class="document-wrapper">
  <h1>{{ context.title }}</h1>
  <div *ngIf="context.text" [innerHTML]="sanitize(context.text.data)"></div>
  <ul *ngIf="context.items">
    <li *ngFor="let item of context.items">
      <a [traverseTo]="item['@id']">{{ item.title}}</a>
    </li>
  </ul>
</div>
<!-- <pre>{{ context | json }}</pre> -->
<apym-portlets manager="plone.leftcolumn"></apym-portlets>
<apym-portlets manager="plone.rightcolumn"></apym-portlets>
</ng-container>
