<div class="bottomsheet-header">
  <i class="fas fa-check-circle"></i>
  <span>{{ 'addtocartbottomsheet.addedToCart' | translate}}</span>
</div>
<mat-divider></mat-divider>
<ng-container *ngIf="toReachMinimum$ | async as toReachMinimum">
  <div class="min-amount-msg" *ngIf="toReachMinimum.delivery || toReachMinimum.pickup">
    <div>{{ 'addtocartbottomsheet.didNotReachMinProdAmount' | translate }}</div>
    <div *ngIf="toReachMinimum.delivery">{{ 'addtocartbottomsheet.forDeliveryStillAddSemicolon' | translate }} {{ toReachMinimum.delivery | currency:'EUR' }}</div>
    <div *ngIf="toReachMinimum.pickup">{{ 'addtocartbottomsheet.forPickupStillAddSemicolon' | translate }} {{ toReachMinimum.pickup | currency:'EUR' }}</div>
  </div>
  <div class="action-btns-wrapper">
    <div class="to-shop-btn">
      <button  mat-raised-button color="basic" (click)="continueBuying()">{{ 'addtocartbottomsheet.continueBuying' | translate }}</button>
    </div>
    <div class="to-cart-btns">
      <ng-container *ngIf="hasDelivery$ | async">
        <button mat-raised-button color="warn"
                [disabled]="toReachMinimum.delivery > 0"
                (click)="goToCheckoutDelivery()">{{ 'addtocartbottomsheet.goToCheckoutDelivery' | translate }}</button>
      </ng-container>
      <ng-container *ngIf="hasPickup$ | async">
        <button mat-raised-button color="warn"
                [disabled]="toReachMinimum.pickup > 0"
                (click)="goToCheckoutPickup()">{{ 'addtocartbottomsheet.goToCheckoutPickup' | translate }}</button>
      </ng-container>
    </div>
  </div>
</ng-container>
