import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, from, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Grange } from 'grange';
import { map, mergeMap, shareReplay, switchMap, take, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AppState } from '~/reducers';
import { SeoService } from '~/core/seo.service';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SearchData, EntityType, OrderingType } from '~/front/models';
import { HttpParams } from '@angular/common/http';
import {
  FrontActionTypes,
  selectAllChildSites,
  selectChildSitesLoaded,
  selectEntityTitle,
  selectFilteredShopIds,
  selectFilterIsActive,
  selectLoading,
  selectSearchData,
  selectSearchResult,
  selectShopsLoaded,
  selectShopsOrdering,
  selectTotalShops,
  selectTotalShopsBrain,
} from '~/front/store';
import { ShopActionTypes } from '~/shop/store';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchresultComponent extends BaseViewComponent implements OnInit {
  childSites$: Observable<any>;
  entityTitle$: Observable<string>;
  totalShops$: Observable<number>;
  totalShopsProgress$: Observable<number>;
  loading$: Observable<boolean>;
  childSitesLoaded$: Observable<boolean>;
  shopsLoaded$: Observable<boolean>;
  searchData$: Observable<SearchData>;
  searchResult$: Observable<any>;
  minWidth992$: Observable<boolean>;
  shopsOrdering$: Observable<OrderingType>;
  filteredShopsQuantity$: Observable<number>;
  orderingType = OrderingType;
  filterIsActive$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    public grange: Grange,
    public seo: SeoService,
    private backend: BackendService,
    private cd: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
  ) {
    super(grange, seo);
  }

  ngOnInit(): void {
    this.minWidth992$ = this.breakpointObserver.observe(['(min-width: 992px)']).pipe(
      map((state) => state.matches),
      shareReplay(1),
    );
    this.store.dispatch(ShopActionTypes.clearShopState());
    this.shopsOrdering$ = this.store.pipe(select(selectShopsOrdering));
    this.filterIsActive$ = this.store.pipe(select(selectFilterIsActive));
    this.searchData$ = this.grange.traverser.target.pipe(
      take(1),
      switchMap((target) => {
        // get searchData from querystring or from store
        const query: HttpParams = target.query;
        if (query.get('eu')) {
          const searchData: SearchData = {
            entity: {
              uid: query.get('eu'),
              type: EntityType[query.get('et')],
              caption: query.get('ec'),
            },
            date: query.get('dt') ? moment(query.get('dt')) : null,
            item: query.get('it'),
          };
          this.store.dispatch(FrontActionTypes.setSearchData({ searchData: searchData }));
          return of(searchData);
        } else {
          return this.store.pipe(select(selectSearchData), take(1));
        }
      }),
    );

    this.totalShops$ = this.store.pipe(select(selectTotalShopsBrain));
    this.totalShopsProgress$ = this.store.pipe(select(selectTotalShops));
    this.childSites$ = this.store.pipe(select(selectAllChildSites));
    this.entityTitle$ = this.store.pipe(select(selectEntityTitle));
    this.searchResult$ = this.store.pipe(select(selectSearchResult));
    this.loading$ = this.store.pipe(select(selectLoading));
    this.childSitesLoaded$ = this.store.pipe(select(selectChildSitesLoaded));
    this.shopsLoaded$ = this.store.pipe(select(selectShopsLoaded));
    this.filteredShopsQuantity$ = this.store.pipe(
      select(selectFilteredShopIds),
      map((ids) => ids.length || 0),
    );
  }

  removeFilter(): void {
    this.store.dispatch(FrontActionTypes.clearFilter());
    //this.scrollTop();
  }
}
