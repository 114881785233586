<ngx-json-ld [json]="shopSchema$ | async"></ngx-json-ld>
<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<div class="shop-menu-row">
  <apym-shopfilter [shop]="context | async"></apym-shopfilter>
</div>
<div class="articles-row">
  <aside class="categories">
    <mat-card class="categories-card">
      <app-categories></app-categories>
    </mat-card>
  </aside>
  <div class="articles"
       *ngIf="context | async as shop">
    <div class="shop-category-header">
      <button mat-mini-fab color="warn" class="home-button" aria-label="Go back to see all categories" (click)="backToCategories()">
        <mat-icon aria-hidden="false" aria-label="Arrow Back">arrow_back_ios_new</mat-icon>
      </button>
      <h1 class="shop-name">{{ shop.title }}</h1>
    </div>
    <ng-container *ngIf="data$ | async as data">
      <div #articlelist *ngIf="data[0]">
        <div>
          <p class="this-category" [innerHTML]=" 'shop-articles.productsOfCategory' | translate: {categoryName:data[0].name} "></p>
        </div>
        <apym-article-in-shop *ngFor="let article of articles$ | async; trackBy: trackArticle"
                              [article]="article"
                              [pickupAvailabilityDatetimeText]="data[1]"
                              [deliveryAvailabilityDatetimeText]="data[2]"
                              [link]="path(shop['@id']) + '/@@articles?category=' + data[0].id + '&article_id=' + article.id"
        ></apym-article-in-shop>
      </div>
    </ng-container>
  </div>
  <aside class="cart">
    <app-cart></app-cart>
  </aside>
</div>

<grange-breadcrumbs></grange-breadcrumbs>
