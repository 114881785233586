<mat-form-field class="full-width-form-field">
    <input matInput [matDatepicker]="picker"
           readonly
           [matDatepickerFilter]="filterDate"
           [min]="doMinDate" [max]="doMaxDate"
           placeholder="{{ 'checkout-deliverydatetime.chooseADate' | translate }}"
           [ngrxFormControlState]="formGroup.controls.deliveryDate"
           [ngrxValueConverter]="dateValueConverter"
           (click)="picker.open()"
           #deliveryDateField>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

    <!-- (dateChange)="dateChange($event)" -->
</mat-form-field>
<mat-datepicker #picker color="primary" startView="month" [startAt]="doMinDate" touchUi="touchUi()"></mat-datepicker>

<div class="alert alert-warning alert-dismissable" *ngIf="formGroup.controls.deliveryDate.errors.$available">
    <p [innerHTML]="'checkout-deliverydatetime.notSelectedDate' | translate"></p>
    <ng-container>
        <ul *ngFor="let cartItem of formGroup.controls.deliveryDate.errors.$available">
            <li><small>{{ cartItem.name }}</small></li>
        </ul>
    </ng-container>
    <p><strong>{{ 'checkout-deliverydatetime.actionProductsWrongDate' | translate}}</strong></p>
</div>

<mat-form-field class="full-width-form-field">
    <mat-select #timeRange placeholder="{{ 'checkout-deliverydatetime.selectTime' | translate}}"
                [disabled]="!formGroup.controls.deliveryDate.isValid"
                [compareWith]="compareTimeRange"
                [ngrxValueConverter]="timeRangeToJsonConverter"
                [ngrxFormControlState]="formGroup.controls.deliveryTimeRange">
        <mat-option *ngFor="let timeRange of timeRanges$ | async"
                    [value]="timeRange">
            {{ timeRange.asap ? ('checkout-deliverydatetime.asap' | translate: {fromMinute: timeRange.fromMinute, toMinute: timeRange.toMinute }) : (timeRange | timeRange ) }}
        </mat-option>
    </mat-select>
</mat-form-field>
<div class="alert alert-warning alert-dismissable" *ngIf="formGroup.controls.deliveryTimeRange.errors.$available">
    <p [innerHTML]="'checkout-deliverydatetime.notSelectedTime' | translate"></p>
    <ul *ngFor="let cartItem of formGroup.controls.deliveryTimeRange.errors.$available">
        <li><small>{{ cartItem.name }}</small></li>
    </ul>
    <p><strong>{{ 'checkout-deliverydatetime.actionProductsWrongTime' | translate}}</strong></p>
</div>

<div *ngIf="isDelivery$ | async"><small>{{ 'checkout-deliverydatetime.maybeDelays' | translate }}</small></div>
<div *ngFor="let cartItem of cartItems$ | async">
    <div *ngIf="messages.hasOwnProperty(cartItem.id)">
        {{ cartItem.name }} {{ messages[cartItem.id] }}
    </div>
</div>
