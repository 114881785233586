import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '~/reducers';
import { PaymentReturn } from '~/core/models';
import { delayedRetry } from './delayed-retry.operator';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { CartActionTypes, selectCartShop } from '~/cart/store';
import { BackendService } from '~/core/backend.service';
import { GoogleTagManagerService } from '~/core/googletagmanager.service';
import * as CartModel from '~/cart/models';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent extends BaseViewComponent implements OnInit {
  paymentReturn$: Observable<PaymentReturn>;
  paymentReturnRetries$: Observable<PaymentReturn>;
  paymentErrorMessage: string;
  cartShop$: Observable<CartModel.CartShop>;

  constructor(
    public grange: Grange,
    private store: Store<AppState>,
    public seo: SeoService,
    private backend: BackendService,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
  ) {
    super(grange, seo);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cartShop$ = this.store.pipe(select(selectCartShop));
    this.paymentReturn$ = this.context.pipe(
      withLatestFrom(this.grange.traverser.target.pipe(map((target) => target.query))),
      switchMap(([context, query]) => {
        const params = query.keys().reduce((acc, cur) => {
          acc[cur] = query.get(cur);
          return acc;
        }, {});
        return this.backend.payment$(context['@id'], params, this.translate.currentLang).pipe(
          tap((result) => {
            if (result.success) {
              const gtmTag = {
                event: 'purchase_enhanced_conversion',
                order_value: result.order.amount,
                order_id: result.order.id,
                currencyCode: 'EUR',
                enhanced_conversion_data: {
                  email: result.order.billingEmail
                },
              };
              this.gtmService.pushTag(gtmTag);
              this.store.dispatch(CartActionTypes.clearCart());
            } else {
              // not success
              // status -> wating|declined
              this.paymentErrorMessage = result.message;
              if (result.status === 'waiting') {
                throw throwError({ name: 'Waiting', result });
              }
            }
          }),
        );
      }),
    );

    this.paymentReturnRetries$ = this.paymentReturn$.pipe(
      delayedRetry(3000, 3),
      catchError((err) => {
        if (err.name === 'RetryError') {
          console.log('RetryError', err);
          return of(err.result);
        } else {
          console.log('Error payment', err);
          throw throwError(err);
        }
      }),
      tap((result) => console.log('result', result)),
    );
  }
}
