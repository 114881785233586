import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ZoomArticleDialog } from '~/shared/zoom-article/zoom-article-dialog';
import { BackendService } from '~/core/backend.service';
import { Article, AvailabilityDatetimeText, Option, SubArticle } from '~/core/models';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import { ArticleOptionsService } from '~/core/articleoptions.service';

@Component({
  selector: 'apym-article-in-shop',
  templateUrl: './article-in-shop.component.html',
  styleUrls: ['./article-in-shop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleInShopComponent implements OnInit {
  @Input() article: Article;
  @Input() pickupAvailabilityDatetimeText: AvailabilityDatetimeText;
  @Input() deliveryAvailabilityDatetimeText: AvailabilityDatetimeText;
  @Input() link: string;
  public subarticles$: Observable<SubArticle[]>;
  public variants: Option[];
  private hasOptions: boolean;

  constructor(
    private backend: BackendService,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private articleOptions: ArticleOptionsService,
  ) {}

  ngOnInit() {
    if (this.article.use_subarticle) {
      this.subarticles$ = this.backend.subarticles$(this.article['@id']);
    } else {
      this.variants = this.articleOptions.getOptionsBasePrice(this.article.options);
    }
  }

  openZoom(title: string, url: string) {
    this.dialog.open(ZoomArticleDialog, {
      data: { title, url },
    });
  }

  path(id: string) {
    return this.configurationService.urlToPath(id);
  }
}
