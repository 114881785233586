import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { fromCart } from '~/cart/store';
import { AppState } from '~/reducers';
import { selectCartShop } from '~/cart/store';
import * as CartModel from '~/cart/models';

@Component({
  selector: 'app-checkout-messageforshop',
  templateUrl: './checkout-messageforshop.component.html',
  styleUrls: ['./checkout-messageforshop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutMessageforshopComponent implements OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutMessageForShopForm>;
  cartShop$: Observable<CartModel.CartShop>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.cartShop$ = this.store.pipe(select(selectCartShop));
  }
}
