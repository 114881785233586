import { Normalizer } from 'angular-traversal';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import { Injectable } from '@angular/core';

@Injectable()
export class FullPathNormalizer extends Normalizer {
  constructor(private config: ConfigurationService) {
    super();
  }

  normalize(path: string): string {
    if (path) {
      // if ( path.startsWith('/web-pasteles') ) {
      //     path = 'https://www5test.apanymantel.com' + path.substring('/web-pasteles'.length);
      // } else if ( path.startsWith('/web-flores') ) {
      //     path = 'https://www5test.flores.apanymantel.com' + path.substring('/web-flores'.length);
      // } else if ( path.startsWith('/web-catering') ) {
      //     path = 'https://www5test.catering.apanymantel.com' + path.substring('/web-catering'.length);
      // } else if ( path.startsWith('/web-comida') ) {
      //     path = 'https://www5test.comida.apanymantel.com' + path.substring('/web-comida'.length);
      // } else if ( path.startsWith('/web-desayunos') ) {
      //     path = 'https://www5test.desayunos.apanymantel.com' + path.substring('/web-desayunos'.length);
      // }
      const base = this.config.get('BACKEND_URL');
      if (base.startsWith('/') && path.startsWith('http')) {
        path = '/' + path.split('/').slice(3).join('/');
      }
      if (path === base) {
        path = '/';
      } else if (path.startsWith(base)) {
        path = path.substring(base.length);
      }
    }
    return path;
  }
}
