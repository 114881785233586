<div mat-dialog-content>
  <div class="article-img"><img *ngIf="article.image" [src]="article.image.scales.preview.download" [height]="article.image.scales.preview.height" [width]="article.image.scales.preview.width"/></div>
  <div class="title-wrapper"><h3>{{ article.title }}</h3></div>
  <!--<div class="title-shadow-cover"></div>-->
  <div class="description-wrapper" [innerHTML]="article.text && article.text.data || ''">Article extended description</div>
  <div *ngIf="options">
    <div class="groupoption-wrapper" *ngFor="let groupOption of options.items"
         [ngClass]="{'group-option-complete': groupOption.selectedTotalQuantity >= (groupOption.minTotalQuantity || 0) && groupOption.selectedOptions >= (groupOption.minDifferentOptions || 0)}">
      <div class="group-option-header">
        <div>
          <h5>{{ groupOption.name }}</h5>
          <div *ngIf="groupOption.description">
            <small>{{ groupOption.description }}</small>
          </div>
          <div class="group-option-conf">
            <span *ngIf="groupOption.minDifferentOptions > 0 || groupOption.minTotalQuantity > 0" class="is-required">{{ 'selectitemoptions.required' | translate }}</span>
            <span *ngIf="groupOption.maxTotalQuantity >= 1" class="max-options">{{ 'selectitemoptions.chooseMax' | translate }} {{ groupOption.maxTotalQuantity }} {{ 'selectitemoptions.optionPs' | translate }}</span>
          </div>
        </div>
        <div class="group-option-complete">
          <mat-icon aria-hidden="false" aria-label="check_circle_outline">check_circle_outline</mat-icon>
        </div>
      </div>
      <ul class="list-unstyled options-list">
        <li *ngFor="let option of (groupOption.options?.items || [])"
            [ngClass]="{'option-selected': option.quantity > 0}"
            (click)="option.quantity === 0 ? selectOption($event, groupOption['@id'], option['@id']):null">
          <div class="option-qty-wrapper">
            <div>
              {{ option.name }}<small class="extra-price" *ngIf="option.price">(+{{ option.price }}€)</small>
            </div>
            <ng-container *ngIf="isMaxMinOneOption(groupOption); else optionQuantity">
              <div>
                <mat-icon class="icon-btn" *ngIf="option.quantity == 0" aria-hidden="false" aria-label="add_circle">add_circle</mat-icon>
                <mat-icon *ngIf="option.quantity === 1" aria-hidden="false" aria-label="check_circle">check_circle</mat-icon>
              </div>
            </ng-container>
            <ng-template #optionQuantity>
              <div class="opts-qty-bigger-than-one" *ngIf="option.quantity > 0">
                <div *ngIf="option.quantity > 0"
                     (click)="decrementOption($event, groupOption['@id'], option['@id'])">
                  <mat-icon class="icon-btn" aria-hidden="false" aria-label="remove_circle">remove_circle</mat-icon>
                </div>
                <div class="current-option-qty">{{ option.quantity }}</div>
                <div class="phantom-buttom" *ngIf="(option.quantity > 0 && option.quantity === option.maxQuantity) || (groupOption.selectedTotalQuantity === groupOption.maxTotalQuantity) || (groupOption.minDifferentOptions === groupOption.maxTotalQuantity); else addbutton">
                  <mat-icon aria-hidden="false" aria-label="add_circle">add_circle</mat-icon>
                </div>
                <ng-template #addbutton>
                  <div (click)="selectOption($event, groupOption['@id'], option['@id'])">
                    <mat-icon class="icon-btn" aria-hidden="false" aria-label="add_circle">add_circle</mat-icon>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </div>
          <div *ngIf="option.description"><small>{{ option.description }}</small></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="to-cart-button">
    <div class="more-options" *ngIf="!isValid">
      <div>
        <div>{{ 'selectitemoptions.scrollDown' | translate }}</div>
        <div class="arrow-container">
          <div class="arrow"></div>
          <div class="arrow"></div>
          <div class="arrow"></div>
        </div>
      </div>
    </div>
    <button mat-raised-button color="warn" [disabled]="!isValid" (click)="addItem()"><span>{{ 'selectitemoptions.addToCart' | translate }}</span><span>{{ price | currency:'EUR'}}
    </span></button>
  </div>
  <!--
       <div>
       <pre>
       debug
       Article:
       {{ article | json }}
       </pre>
       </div>
  -->
  <div mat-dialog-actions align="end">
    <a mat-button mat-dialog-close (click)="close()"><i class="fas fa-times"></i></a>
  </div>
</div>
