import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShopState } from './shop.reducer';
import { ArticlesState } from './articles.reducer';
import * as fromArticles from './articles.reducer';
import { CategoriesState } from './categories.reducer';
import * as fromCategories from './categories.reducer';
import { TimetableVM } from '~/shop/models';
import * as moment from 'moment';
import * as _ from 'lodash';

export const selectShopState = createFeatureSelector<ShopState>('shop');

export const selectCategoriesState = createFeatureSelector<CategoriesState>('categories');

export const selectArticlesState = createFeatureSelector<ArticlesState>('articles');

export const selectAllCategories = createSelector(selectCategoriesState, fromCategories.selectAll);

export const selectCategoriesLoading = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.loading,
);

export const selectShop = createSelector(selectShopState, (shopState) => shopState.shop);

export const selectShopLoading = createSelector(selectShopState, (shopState) => shopState.getShop.loading);

export const selectShopLoaded = createSelector(selectShopState, (shopState) => shopState.getShop.loaded);

export const selectArticlesLoading = createSelector(selectArticlesState, (articlesState) => articlesState.loading);

export const selectAllArticles = createSelector(selectArticlesState, fromArticles.selectAll);

export const selectSelectedCategory = createSelector(selectShopState, (shopState) => shopState.categorySelected);

export const selectSelectedCategoryEntity = createSelector(
  selectCategoriesState,
  selectSelectedCategory,
  (categoriesState, selectedCategory) => fromCategories.selectEntities(categoriesState)[selectedCategory],
);

export const selectArticlesCategory = createSelector(
  selectArticlesState,
  selectSelectedCategory,
  (articlesState: ArticlesState, selectedCategory: string) => {
    if (articlesState.categories.indexOf(selectedCategory) !== -1) {
      return fromArticles
        .selectAll(articlesState)
        .filter((article) => article.category.filter((cat) => cat.token === selectedCategory).length > 0);
    } else {
      return [];
    }
  },
);

export const selectShopFilterData = createSelector(selectShopState, (shopState) => shopState.filterData);

export const selectShopFilterPickupOrDelivery = createSelector(
  selectShopState,
  (shopState) => shopState.filterData.pickupOrDelivery,
);

export const selectShopPickupDates = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.pickup_dates : null,
);

export const selectShopDeliveryDates = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.delivery_dates : null,
);

export const selectShopDeliveryPickupDates = createSelector(selectShopState, (shopState) => {
  if (shopState.shopInfo) {
    const dates = _.unionWith(shopState.shopInfo.delivery_dates, shopState.shopInfo.pickup_dates, (arrVal, othVal) =>
      arrVal.isSame(othVal),
    );
    return dates;
  } else {
    return null;
  }
});

export const selectShopAddresses = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.addresses : [],
);

export const selectShopDeliveryCities = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.delivery_cities : null,
);

export const selectShopHasDiscounts = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.has_discounts : false,
);

export const selectShopShopInfo = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.shop_info : null,
);

export const selectShopShopSchema = createSelector(selectShopState, (shopState) =>
  shopState.shopSchema ? shopState.shopSchema.schema : null,
);

export const selectPickupAvailabilityDatetimeText = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.pickup_availability_datetime_text : null,
);

export const selectDeliveryAvailabilityDatetimeText = createSelector(selectShopState, (shopState) =>
  shopState.shopInfo ? shopState.shopInfo.delivery_availability_datetime_text : null,
);

export const selectTimetables = createSelector(selectShopState, (shopState) => {
  const parseTimetable = (day): TimetableVM => {
    return {
      date: day.date,
      timetable: day.timetable.map((timetable) => {
        return {
          fromTime: moment(timetable.from_time, 'HH:mm:ss'),
          toTime: moment(timetable.to_time, 'HH:mm:ss'),
        };
      }),
    };
  };
  if (shopState.shopInfo) {
    return {
      openingTimetable: shopState.shopInfo.timetables.opening_timetable.map(parseTimetable),
      deliveryTimetable: shopState.shopInfo.timetables.delivery_timetable.map(parseTimetable),
    };
  } else {
    return null;
  }
});
