<div class="checkout-wrapper">
  <div class="form-wrapper">
    <ng-container *ngIf="(checkoutFormState$ | async) as checkoutFormState">
      <form [ngrxFormState]="checkoutFormState">
        <div class="text-center">
          <h4>{{ 'checkout.title' | translate }}</h4>
          <small>{{ 'checkout.titleWarning' | translate }}</small>
        </div>
        <mat-vertical-stepper [linear]="true" (selectionChange)="changeStep($event)" #stepper>
          <mat-step *ngIf="!(isAuthenticated$ | async).state"
                    [completed]="checkoutFormState.controls.register.isValid">
            <ng-template matStepLabel>{{ 'checkout.register' | translate }}</ng-template>
            <app-checkout-register [formGroup]="checkoutFormState.controls.register">
            </app-checkout-register>
            <div class="nav-buttons">
              <button mat-raised-button color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.register.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step [completed]="checkoutFormState.controls.buyerData.isValid">
            <ng-template matStepLabel>{{ 'checkout.buyerData' | translate }}</ng-template>
            <app-checkout-buyerdata [formGroup]="checkoutFormState.controls.buyerData">
            </app-checkout-buyerdata>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button" >{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button mat-raised-button color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.buyerData.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step *ngIf="(isDelivery$ | async) && (cartShop$ | async)?.checkoutRecipientDifferent"
                    [completed]="checkoutFormState.controls.recipientData.isValid">
            <ng-template matStepLabel>{{ 'checkout.recipientData' | translate }}</ng-template>
            <app-checkout-recipientdata [formGroup]="checkoutFormState.controls.recipientData">
            </app-checkout-recipientdata>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button" >{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.recipientData.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step *ngIf="isPickup$ | async"
                    [completed]="checkoutFormState.controls.pickup.isValid">
            <ng-template matStepLabel>{{ 'checkout.pickupAddress' | translate }}</ng-template>
            <app-checkout-pickup [formGroup]="checkoutFormState.controls.pickup">
            </app-checkout-pickup>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button" >{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.pickup.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step *ngIf="isDelivery$ | async"
                    [completed]="checkoutFormState.controls.delivery.isValid">
            <ng-template matStepLabel>{{ 'checkout.deliveryAddress' | translate }}</ng-template>
            <app-checkout-delivery [formGroup]="checkoutFormState.controls.delivery">
            </app-checkout-delivery>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.delivery.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step [completed]="checkoutFormState.controls.deliveryDatetime.isValid && !checkoutFormState.controls.deliveryDatetime.isValidationPending">
            <ng-template *ngIf="checkoutFormState.value.delivery.delivery"
                         matStepLabel>{{ 'checkout.deliveryDateTime' | translate }}</ng-template>
            <ng-template *ngIf="checkoutFormState.value.pickup.pickup"
                         matStepLabel>{{ 'checkout.pickupDateTime' | translate }}</ng-template>

            <app-checkout-deliverydatetime [formGroup]="checkoutFormState.controls.deliveryDatetime">
            </app-checkout-deliverydatetime>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.deliveryDatetime.isValid || checkoutFormState.controls.deliveryDatetime.isValidationPending">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step *ngIf="(cartShop$ | async)?.checkoutCardMessage"
                    [completed]="checkoutFormState.controls.cardMessage.isValid" optional>
            <ng-template matStepLabel>{{ 'checkout.giftcardPersonalMessage' | translate }}</ng-template>
            <app-checkout-cardmessage [formGroup]="checkoutFormState.controls.cardMessage">
            </app-checkout-cardmessage>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.cardMessage.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step *ngIf="(cartShop$ | async)?.checkoutAdditionalMessage"
                    [completed]="checkoutFormState.controls.messageForShop.isValid" optional>
            <ng-template matStepLabel>{{ 'checkout.additionalInformation' | translate }}</ng-template>
            <app-checkout-messageforshop [formGroup]="checkoutFormState.controls.messageForShop">
            </app-checkout-messageforshop>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.messageForShop.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step [completed]="checkoutFormState.controls.payment.isValid">
            <ng-template matStepLabel>{{ 'checkout.paymentMethod' | translate }}</ng-template>
            <app-checkout-payment [formGroup]="checkoutFormState.controls.payment">
            </app-checkout-payment>
            <div class="nav-buttons">
              <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
              <button mat-raised-button  color="warn" matStepperNext type="button"
                      [disabled]="!checkoutFormState.controls.payment.isValid">{{ 'checkout.buttonNext' | translate }}</button>
            </div>
          </mat-step>

          <mat-step [completed]="checkoutFormState.controls.review.isValid" #review>
            <ng-template matStepLabel>{{ 'checkout.reviewData' | translate }}</ng-template>
            <ng-container *ngIf="payAction$ | async as payAction">
              <app-checkout-review [formGroup]="checkoutFormState.controls.review">
              </app-checkout-review>
              <div class="nav-buttons">
                <button mat-raised-button matStepperPrevious type="button">{{ 'checkout.buttonPrevious' | translate }}</button>
                <apym-paybutton *ngIf="checkoutFormState.isValid"
                                [action]="payAction"></apym-paybutton>
                <span  *ngIf="checkoutFormState.controls.review.controls.acceptPolicy.isInvalid">
                  <button mat-raised-button color="warn" disabled *ngIf="checkoutFormState.controls.review.controls.acceptPolicy.isInvalid">{{ 'checkout.' + (payAction.redirect_to_payment ? 'proceedToPay' : 'finishOrder') | translate }}</button>
                  <div class="triangle-up"></div>
                  <div class="accept-terms" *ngIf="checkoutFormState.controls.review.controls.acceptPolicy.isInvalid">{{ 'checkout.acceptTermsPolicies' | translate }}</div>
                </span>
              </div>
            </ng-container>
          </mat-step>
        </mat-vertical-stepper>
      </form>
    </ng-container>
    <div class="back-to-shop-button">
      <button mat-raised-button (click)="returnToShop()"><div>{{ 'checkout.backToShop' | translate }}</div><div><small>{{ 'checkout.continueShopping' | translate }}</small></div></button>
    </div>
  </div>
  <aside class="cart">
    <app-cart></app-cart>
  </aside>
</div>
