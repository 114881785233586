<!-- Shop Search -->
<div class="spinner-container" *ngIf="loading$ | async">
  <!-- <div class="spinner-container" > -->
  <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="searchData$ | async as searchData">
  <!-- Shop search  -->
  <mat-drawer-container class="search-result-filters-drawer" [hasBackdrop]="True" *ngIf="!searchData.item">
    <mat-drawer #drawer
                [mode]="!(minWidth992$ | async) ? 'over' : 'side'"
                [opened]="(minWidth992$ | async)">
      <div class="order-filter-wrapper">
        <app-orderfilter></app-orderfilter>
        <button mat-raised-button color="warn" (click)="drawer.toggle()">{{ 'searchresult.applyChanges' | translate }} ({{ filteredShopsQuantity$ | async }})</button>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <ng-container *ngIf="!searchData.item">
        <ng-container *ngIf="searchResult$ | async as searchResult">
          <div class="search-result-wrapper">
            <div class="total-number-shops">
                <ng-container *ngIf="!(minWidth992$ | async)">
                  <button mat-mini-fab color="warn" (click)="drawer.toggle()">
                    <mat-icon>tune</mat-icon>
                  </button>
                </ng-container>
                <div class="total-shops">
                    <div class="filter-info">
                      <i class="fas fa-store"></i>
                      <!-- Si NO hay filtro aplicado -->
                      <span *ngIf="!(filterIsActive$ | async)">
                        <span [innerHTML]=" 'search.totalNumberShops' | translate: {numberShops: (totalShops$ | async) || '-', place: searchResult.entityTitle || '-'}"></span>
                      </span>
                      <!-- Si hay filtro aplicado -->
                      <span *ngIf="filterIsActive$ | async">
                        <span [innerHTML]=" 'searchresult.numberShopsFromTotalIn' | translate: {filteredShopsAmount: (filteredShopsQuantity$ | async) || '?', totalShopsAmount: (totalShops$ | async) || '?', postcodeCity: (searchResult.entityTitle) || '?'}"></span>
                        <!-- <span>You are seeing {{ filteredShopsQuantity$ | async }} shops of the {{ totalShops$ | async }} available in {{ searchResult.entityTitle }}</span> -->
                      </span>
                    </div>
                    <div class="order-info" *ngIf="(shopsOrdering$ | async) as shopsOrdering">
                      <i class="fas fa-sort-amount-down"></i>
                      <div>
                        {{ 'search.orderedBy' | translate }}
                        <b>
                          <span *ngIf="shopsOrdering === orderingType.recommended">
                            {{ 'orderfilter.recommended' | translate }}
                          </span>
                          <span *ngIf="shopsOrdering === orderingType.cheapestShipment">
                            {{ 'orderfilter.cheapest' | translate }}
                          </span>
                          <span *ngIf="shopsOrdering === orderingType.fastestDelivery">
                            {{ 'orderfilter.fastestDelivery' | translate }}
                          </span>
                          <span *ngIf="shopsOrdering === orderingType.bestRating">
                            {{ 'orderfilter.bestRated' | translate }}
                          </span>
                          <span *ngIf="shopsOrdering === orderingType.newFirst">
                            {{ 'orderfilter.newShopsFirst' | translate }}
                          </span>
                        </b>
                      </div>
                    </div>
                </div>
            </div>
            <mat-tab-group *ngIf="(totalShops$ | async) > 0; else noShopsFound" [@.disabled]="true" mat-align-tabs="center" >
              <mat-tab *ngFor="let childSite of searchResult.childSites" disabled="1">
                <ng-template mat-tab-label>
                  <ng-container *ngIf="!childSite.name"><!-- empty object has undefined name attribute -->
                    <div class="label-wrapper" disabled>
                      <small class="tab-amount">{{ childSite.totalShops }}</small>
                      <div class="tab-icon all-shops-icon" matBadge="4" matBadgeOverlap="false"><img src="/assets/icono-all-shops.png" alt="all shops found" /></div>
                    </div>
                    <div class="tab-name">{{ 'search.allShops' | translate }}</div>
                  </ng-container>

                  <ng-container *ngIf="childSite.name">
                    <div class="label-wrapper">
                      <small class="tab-amount">{{ childSite.shops.length }}</small>
                      <div class="tab-icon"><img [src]="childSite.image || ''" [alt]="childSite.name || ''" /></div>
                    </div>
                    <div class="tab-name">{{ childSite.name }}</div>
                  </ng-container>
                </ng-template>
                <!-- Search Result Shops -->
                <div class="shopresult-grid">
                  <ng-container *ngIf="childSite.shopsDelivery.length === 0 && childSite.shopsPickup.length === 0 && childSite.shopsNoService.length === 0">
                    <ng-container
                      *ngFor="let shopUid of childSite.shops; first as isFirst">
                      <app-shopresult [shopUid]="shopUid"
                                      [searchData]="searchData"></app-shopresult>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngFor="let shopUid of childSite.shopsDelivery; first as isFirst">
                    <div class="shop-products-separator" *ngIf="isFirst"
                         [innerHTML]=" 'search.shopsDeliverOnDate' | translate: {searchDate: (searchData.date | date)} "></div>
                    <app-shopresult [shopUid]="shopUid"
                                    [searchData]="searchData"></app-shopresult>
                  </ng-container>
                  <ng-container *ngFor="let shopUid of childSite.shopsPickup; first as isFirst">
                    <div class="shop-products-separator" *ngIf="isFirst"
                         [innerHTML]=" 'search.shopsPickupOnDate' | translate: {searchDate: (searchData.date | date)} "></div>
                    <app-shopresult [shopUid]="shopUid"
                                    [searchData]="searchData"></app-shopresult>
                  </ng-container>
                  <ng-container *ngFor="let shopUid of childSite.shopsNoService; first as isFirst">
                    <div class="shop-products-separator" *ngIf="isFirst"
                         [innerHTML]=" 'search.shopsNoService' | translate: {searchDate: (searchData.date | date)} "></div>
                    <div class="no-service">
                      <app-shopresult [shopUid]="shopUid"
                                      [searchData]="{entity: searchData.entity, date: null, item: searchData.item, pickup: searchData.pickup}"></app-shopresult>
                    </div>
                  </ng-container>
                  <div class="no-shops-childsite" *ngIf="childSite.shops.length === 0">
                    <i class="fas fa-store-slash"></i>
                    <div>{{ 'searchresult.noShopsType' | translate }}</div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <ng-template #noShopsFound>
              <ng-container *ngIf="(totalShops$ | async) === 0">
                <div class="no-shops-found">
                  <i class="far fa-frown"></i>
                  <div>{{ 'searchresult.sorryNoShops' | translate }}</div>
                </div>
                <div class="text-center">
                  <a mat-raised-button color="warn" [traverseTo]="'.'">{{ 'searchresult.searchAgain' | translate }}</a>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </mat-drawer-content>
  </mat-drawer-container>
  <!-- Product Search -->
  <ng-container *ngIf="searchData.item">
    <ng-container *ngIf="searchResult$ | async as searchResult">
      <div class="search-result-wrapper">
        <div class="total-number-articles"
             [innerHTML]=" 'search.totalNumberItems' | translate: {numberItem: searchResult.totalItems === null ? '-' : searchResult.totalItems, location: searchResult.entityTitle || '-'} "></div>
        <mat-tab-group *ngIf="searchResult.totalItems !== 0 || !(shopLoaded$ | async); else noItemsFound" [@.disabled]="true" mat-align-tabs="center" >
          <mat-tab *ngFor="let childSite of searchResult.childSites">
            <ng-template mat-tab-label>
              <div class="label-wrapper">
                <small class="tab-amount">{{ childSite.totalItems }}</small>
                <div class="tab-icon">
                  <img *ngIf="childSite.image" [src]="childSite.image" [alt]="childSite.name" />
                  <img *ngIf="!childSite.image" src="/assets/icono-all-shops.png" alt="all shops found" />
                </div>
              </div>
              <div *ngIf="childSite.name" class="tab-name">{{ childSite.name }}</div>
              <div *ngIf="!childSite.name" class="tab-name">{{ 'search.allShops' | translate }}</div>
            </ng-template>
            <div class="itemresult-grid" *ngIf="searchData.date === null">
              <ng-container *ngFor="let shopitem of childSite.noAvailableItems; first as isFirst">
                <apym-itemresult [article]="shopitem.item" [shop]="shopitem.shop" [searchData]="searchData"></apym-itemresult>
              </ng-container>
            </div>
            <div *ngIf="searchData.date !== null">
              <div class="itemresult-grid">
                <ng-container *ngFor="let shopitem of childSite.deliveryItems; first as isFirst">
                  <div class="shop-products-separator" *ngIf="isFirst && searchData.date"
                       [innerHTML]=" 'search.productsDeliverOnDate' | translate: {searchDate:(searchData.date | date)} "></div>
                  <apym-itemresult [article]="shopitem.item" [shop]="shopitem.shop" [searchData]="searchData"></apym-itemresult>
                </ng-container>
              </div>
              <div class="itemresult-grid">
                <ng-container *ngFor="let shopitem of childSite.pickupItems; first as isFirst">
                  <div class="shop-products-separator" *ngIf="isFirst && searchData.date"
                       [innerHTML]=" 'search.productsPickupOnDate' | translate: {searchDate:(searchData.date | date)} "></div>
                  <apym-itemresult [article]="shopitem.item" [shop]="shopitem.shop" [searchData]="searchData"></apym-itemresult>
                </ng-container>
              </div>
              <div class="itemresult-grid">
                <ng-container *ngFor="let shopitem of childSite.noAvailableItems; first as isFirst">
                  <div class="shop-products-separator" *ngIf="isFirst && searchData.date"
                       [innerHTML]=" 'search.productsNotDeliverOnDate' | translate: {searchDate:(searchData.date | date)} "></div>
                  <div class="no-service">
                    <apym-itemresult [article]="shopitem.item" [shop]="shopitem.shop" [searchData]="searchData"></apym-itemresult>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <ng-template #noItemsFound>
          <div class="no-shops-found">
            <div><i class="far fa-sad-tear"></i></div>
            <div>{{ 'search.noItemsFound' | translate }}</div>
          </div>
          <div class="new-search-footer">
            <h4>{{ 'search.newSearch' | translate }}</h4>
            <a mat-raised-button color="warn" [traverseTo]="'/'">{{ 'search.searchAgain' | translate }}</a>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
