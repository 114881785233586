<div class="home-section">
  <div class="home-title">{{ 'plonesite.title' | translate }}</div>
  <div class="home-subtitle">{{ 'plonesite.subTitle' | translate }}</div>
  <div class="home-search-box">
    <app-search [context]="context | async"></app-search>
  </div>
  <div class="type-of-business-pics">
    <img class="rotating-pics" src="/assets/typesOfBusiness.gif" alt="Muestra de productos Apanymantel" />
    <div class="fixed-pics">
      <div>
        <img src="/assets/pastelerias.webp" alt="Pastelerías" />
        <p>{{ 'plonesite.bakeries' | translate }}</p>
      </div>
      <div>
        <img src="/assets/floristerias.webp" alt="Floristerías" />
        <p>{{ 'plonesite.flowerShops' | translate }}</p>
      </div>
      <div>
        <img src="/assets/desayunos.webp" alt="Desayunos a domicilio" />
        <p>{{ 'plonesite.breakfasts' | translate }}</p>
      </div>
      <div>
        <img src="/assets/canapes.webp" alt="Catering y Canapés" />
        <p>{{ 'plonesite.canapes' | translate }}</p>
      </div>
      <div>
        <img src="/assets/comida.webp" alt="Restaurantes" />
        <p>{{ 'plonesite.groceries' | translate }}</p>
      </div>
      <!-- <div>
           <img src="/assets/restaurantes.webp" alt="Restaurantes" />
           <p>{{ 'plonesite.restaurants' | translate }}</p>
           </div> -->
    </div>
  </div>
</div>
<div hideInApp class="home-section app-section white-section">
  <div class="home-section-title">{{ 'plonesite.downloadApp' | translate }}</div>
  <div class="app-img-wrapper">
    <img src="/assets/iPhone-Android.webp" alt="Descarga la App de Apanymantel" />
  </div>
  <div>
    <p>{{ 'plonesite.downloadAppHelp' | translate }}</p>
    <div class="app-download-btns">
      <a href="https://apps.apple.com/es/app/apanymantel/id1593167805" target="_blank" alt="Descargate la App de Apanymantel para iOS"><img src="/assets/download_apple.webp" alt="Descarga para Iphone" /></a>
      <a href="https://play.google.com/store/apps/details?id=com.apanymantel.app" target="_blank" alt="Descargate la App de Apanymantel para Android"><img src="/assets/download-google.webp" alt="Descarga para Android" /></a>
    </div>
  </div>
</div>
<div class="home-section ratings">
  <div class="home-section-title">{{ 'plonesite.ourClientsRatings' | translate }}</div>
  <div><apym-ratingstats></apym-ratingstats></div>
  <div><apym-lastratings></apym-lastratings></div>
</div>
<div class="home-section white-section">
  <div class="home-section-title">{{ 'plonesite.howItWorks' | translate }}</div>
  <div><a href="https://youtu.be/L06hgDvm03c" target="_blank"><img src="/assets/apymVideo.webp"/></a></div>
  <div class="home-section how-it-works-steps white-section">
    <div>
      <img src="/assets/encuentra_comercio.webp" />
      <small>{{ 'plonesite.findShops' | translate }}</small>
    </div>
    <div>
      <img src="/assets/elige_fecha.webp" />
      <small>{{ 'plonesite.dateTime' | translate }}</small>
    </div>
    <div>
      <img src="/assets/escribe_mensaje.webp" />
      <small>{{ 'plonesite.message' | translate }}</small>
    </div>
    <div>
      <img src="/assets/pedido_en_camino.webp" />
      <small>{{ 'plonesite.orderOnWay' | translate }}</small>
    </div>
  </div>
</div>
<div class="home-section home-pics">
  <div class="home-section-title">{{ 'plonesite.unforgetableMoments' | translate }}</div>
  <div class="pics-wrapper">
    <img src="/assets/home-pics/pasteleria-1.webp" alt="Niña Feliz al ver su tarta de cumpleaños" />
    <img src="/assets/home-pics/flores-1.webp" alt="Mujer grita de alegría al ver las flores que le regalan" />
    <img src="/assets/home-pics/desayuno-1.webp" alt="Pareja feliz desayunado en la cama" />
    <img src="/assets/home-pics/flores-2.webp" alt="Mujer encantada recibiendo un ramos de rosas enorme" />
    <img src="/assets/home-pics/pasteleria-2.webp" alt="Pareja de la tercera edad encantados recibiendo una tarta" />
    <img src="/assets/home-pics/desayuno-2.webp" alt="Mujer sorprendida al ver a su marido llevando el desayuno a la cama" />
    <img src="/assets/home-pics/catering-1.webp" alt="Compañeros de trabajo comiendo catering juntos" />
    <img src="/assets/home-pics/flores-3.webp" alt="Marido a punto de dar una sorpresa con flores a su mujer" />
    <img src="/assets/home-pics/catering-2.webp" alt="Bandeja de Canapés listos para comer" />
    <img src="/assets/home-pics/flores-4.webp" alt="Madre e hija disfrutan de un precios ramo de flores" />
  </div>
</div>
<div hideInApp class="home-section plone-portlet white-section">
  <apym-portlets manager="plone.rightcolumn"></apym-portlets>
</div>
