<div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
</div>

<div class="plone-login">
    <h2>{{ 'request-password-reset.requestPasswordReset' | translate }}</h2>
    <p *ngIf="!success">{{ 'request-password-reset.help' | translate}}</p>
    <form *ngIf="!success" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
        <div *ngIf="error" class="apym-error-msg">
            <div class="plone-system-error-advise">
                <div><i class="fas fa-exclamation-triangle"></i>
                    {{ 'general.sorryError' | translate }}
                </div>
                <p><small>{{ 'general.helpError' | translate }}</small></p>
            </div>
            <p class="error" *ngIf="error === 'This user does not exist'">{{ 'request-password-reset.emailNotRegistered' | translate}}</p>
            <p class="error" *ngIf="error === 'passwordResetFailed'">{{ 'request-password-reset.passwordResetFailed' | translate}}</p>
            <p class="error" *ngIf="error && error !== 'This user does not exist' && error !== 'passwordResetFailed'">{{ error }}</p>
        </div>
        <mat-form-field>
            <input matInput placeholder="{{ 'request-password-reset.email' | translate }}" name="login" type="email" ngModel>
        </mat-form-field>

<!--        <p><label>{{ 'request-password-reset.email' | translate}}</label><input type="text" name="login" ngModel/></p>-->
        <button mat-raised-button color="warn" type="submit">{{ 'request-password-reset.send' | translate}}</button>
    </form>
    <div *ngIf="success">{{ 'request-password-reset.successReceiveEmail' | translate }}</div>
</div>
