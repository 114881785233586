<div class="pa-field pa-field-slider">
    <input class="pa-field-control pa-field-control-range" type="range"
           [id]="id" [name]="name" [attr.aria-describedby]="helpId" [(ngModel)]="value"
           [disabled]="isDisabled" [min]="min" [max]="max" [step]="step">
    <label class="pa-field-label" [for]="id" translate>
        <ng-content></ng-content>
    </label>
    <output class="pa-field-control-output" [for]="id" aria-hidden="true">{{ value }}</output>
    <small *ngIf="help" class="pa-field-help" [id]="helpId" translate>{{ help }}</small>
</div>
