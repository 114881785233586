import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import { from } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { ShopSearchResult } from '~/core/models';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'apym-shopfolder',
  templateUrl: './shopfolder.component.html',
  styleUrls: ['./shopfolder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopfolderComponent extends BaseViewComponent implements OnInit {
  shops: ShopSearchResult[] = [];

  constructor(
    public grange: Grange,
    public seo: SeoService,
    private cd: ChangeDetectorRef,
    public configurationService: ConfigurationService,
    private backendService: BackendService,
  ) {
    super(grange, seo);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.context.pipe(take(1)).subscribe((result) => {
      const default_page = result['@components'].default_page;
      if (!_.isUndefined(default_page)) {
        this.grange.traverser.traverse(this.configurationService.urlToPath(result['@id'] + '/' + default_page));
      } else {
        this.grange.core.resource
          .find(
            { portal_type: 'apanymantel.cart.shopping.Shop', exclude_from_search: false },
            this.configurationService.urlToPath(result['@id']),
            {
              fullobjects: false,
              size: 0,
              sort_on: 'getObjPositionInParent',
              metadata_fields: ['UID'],
            },
          )
          .pipe(
            switchMap((result) =>
              from(result.items).pipe(
                //concatMap((shop) => this.grange.core.cache.get('/get-shop-search?shopuid=' + shop['UID'])),
                concatMap((shop) => this.backendService.getShopSearch$(shop['UID'])),
              ),
            ),
            map((result: any) => ({
              ...result[0],
              delivery_week_timetable: result[0].delivery_week_timetable.map((day_timetable) => {
                return {
                  date: moment(day_timetable.day, 'dddd'),
                  timetable: day_timetable.timetable.map((tt) => ({
                    fromTime: moment(tt.from_time, 'HH:mm:ss'),
                    toTime: moment(tt.to_time, 'HH:mm:ss'),
                  })),
                };
              }),
            })),
          )
          .subscribe((result: ShopSearchResult) => {
            this.shops.push(result);
            this.cd.detectChanges();
          });
      }
    });
    // this.grange.core.resource.find(
    //     {portal_type: 'apanymantel.cart.shopping.Shop'},
    //     this.configurationService.urlToPath(this.context['@id']),
    //     {fullobjects: false, size: 0, sort_on: 'getObjPositionInParent',
    //      metadata_fields: ['UID']}).pipe(
    //         switchMap((result) =>
    //           from(result.items).pipe(
    //               concatMap((shop) => this.grange.core.cache.get(
    //                   '/get-shop-search?shopuid=' + shop['UID']))))
    //      ).subscribe((result: ShopSearchResult) => {
    //          this.shops.push(result);
    //      });
  }
}
