<mat-radio-group [ngrxFormControlState]="formGroup.controls.hasRecipient">
  <mat-radio-button color="primary" [value]="false">
    {{ 'checkout-recipientdata.noRecipient' | translate }}
  </mat-radio-button>
  <mat-radio-button color="primary" [value]="true">
    {{ 'checkout-recipientdata.hasRecipientData' | translate }}
  </mat-radio-button>
</mat-radio-group>

<fieldset [hidden]="!formGroup.value.hasRecipient">
  <mat-form-field class="required full-width-form-field">
    <input matInput
           type="text"
           maxlength="50"
           [ngrxFormControlState]="formGroup.controls.recipientName"
           placeholder="{{ 'checkout-recipientdata.recipientNameAndSurname' | translate }}"
           required />
  </mat-form-field>

  <!-- <mat-form-field class="required full-width-form-field phone-input"> -->
  <!--   <input matInput -->
  <!--          type="tel" -->
  <!--          maxlength="20" -->
  <!--          [ngrxFormControlState]="formGroup.controls.recipientPhone" -->
  <!--          placeholder="{{ 'checkout-recipientdata.recipientMobilePhone' | translate }}" -->
  <!--          required /> -->
  <!-- </mat-form-field> -->

  <mat-form-field class="required full-width-form-field phone-input">
    <input matInput
            type="tel"
            placeholder="{{ 'checkout-recipientdata.recipientMobilePhone' | translate }}"
            #recipientPhone
            required />
    <div>
      <small *ngIf="formGroup.controls.recipientPhone.errors?.invalidNumber && formGroup.controls.recipientPhone.isDirty"
              class="text-danger">
          {{ 'checkout-buyerdata.invalidNumber' | translate }}</small>
      <small *ngIf="formGroup.controls.recipientPhone.errors?.invalidCountryCode && formGroup.controls.recipientPhone.isDirty"
              class="text-danger">
          {{ 'checkout-buyerdata.invalidCountryCode' | translate }}</small>
      <small *ngIf="formGroup.controls.recipientPhone.errors?.tooShort && formGroup.controls.recipientPhone.isDirty"
              class="text-danger">
          {{ 'checkout-buyerdata.tooShort' | translate }}</small>
      <small *ngIf="formGroup.controls.recipientPhone.errors?.tooLong && formGroup.controls.recipientPhone.isDirty"
              class="text-danger">
          {{ 'checkout-buyerdata.tooLong' | translate }}</small>
      <small *ngIf="formGroup.controls.recipientPhone.errors?.unknownError && formGroup.controls.recipientPhone.isDirty"
              class="text-danger">
          {{ 'checkout-buyerdata.unknownError' | translate }}</small>
    </div>
  </mat-form-field>

  <mat-form-field class="full-width-form-field">
    <input matInput
           type="email"
           maxlength="50"
           [ngrxFormControlState]="formGroup.controls.recipientEmail"
           placeholder="{{ 'checkout-recipientdata.recipientEmail' | translate }}"
    />
    <small *ngIf="formGroup.controls.recipientEmail.errors?.email && formGroup.controls.recipientEmail.isDirty"
           class="text-danger">{{ 'checkout-recipientdata.notValidEmail' | translate }}</small>
  </mat-form-field>

  <mat-form-field class="full-width-form-field">
    <input matInput
           type="email"
           maxlength="50"
           [ngrxFormControlState]="formGroup.controls.confirmRecipientEmail"
           placeholder="{{ 'checkout-recipientdata.repeatEmail' | translate }}"
    />
    <small *ngIf="formGroup.controls.confirmRecipientEmail.errors?.equalTo && formGroup.controls.confirmRecipientEmail.isDirty"
           class="text-danger">{{ 'checkout-recipientdata.emailMismatch' | translate }}</small>
  </mat-form-field>

  <ng-container *ngIf="(cartShop$ | async).checkoutIsASurprise">
    <mat-checkbox color="primary" [ngrxFormControlState]="formGroup.controls.isASurprise">
      {{ 'checkout-recipientdata.isASurprise' | translate }}
    </mat-checkbox>
    <p><small>{{ 'checkout-recipientdata.isASurpriseHelp' | translate }}</small></p>
  </ng-container>

</fieldset>
