import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from '~/reducers';
import { CartActionTypes } from '~/cart/store';
import { selectCheckoutCoupon } from '~/cart/store';

@Component({
  selector: 'app-coupondiscount',
  templateUrl: './coupon-discount.component.html',
  styleUrls: ['./coupon-discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponDiscountComponent implements OnInit {
  hasCoupon$: Observable<boolean>;
  coupon$: Observable<string>;
  couponRequested = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.coupon$ = this.store.pipe(select(selectCheckoutCoupon));

    this.hasCoupon$ = this.store.pipe(
      select(selectCheckoutCoupon),
      map((coupon: string) => !(coupon === null)),
    );
  }

  applyCoupon(coupon: string) {
    console.log('applyCoupon', coupon);
    if (coupon !== '') {
      this.store.dispatch(CartActionTypes.applyCoupon({ coupon }));
    }
    this.couponRequested = true;
  }

  removeCoupon() {
    this.couponRequested = false;
    this.store.dispatch(CartActionTypes.removeCoupon());
  }
}
