<div>
  <div *ngIf="userShippingAddresses$ | async as userShippingAddresses">
    <mat-radio-group class="saved-addresses-radio-group" aria-label="Select an option">
      <ng-container *ngFor="let shipAddress of userShippingAddresses; let last = last">
        <mat-radio-button color="primary"
                          [value]="shipAddress.id"
                          (change)="selectUserShippingAddress(shipAddress)">
          <span   [ngClass]="{selected: (selectedUserShippingAddress$ | async) === shipAddress.id}">
            <div class="saved-address-selector">
              <div><b>{{ shipAddress.addressName }}</b><br/>{{ shipAddress.organization }}</div>
              <div>{{ shipAddress.address }} {{ shipAddress.addressComplement }}, {{ shipAddress.postCode.name }} {{ shipAddress.city.name }}</div>
            </div>
          </span>
        </mat-radio-button>
        <mat-radio-button color="primary" *ngIf="last" (change)="selectUserShippingAddress(null)" [value]="null">
          <span   [ngClass]="{selected: !(selectedUserShippingAddress$ | async) }"
                  [disabled]="!(selectedUserShippingAddress$ | async)">
            {{ 'checkout-delivery.deliveryDifferentAddress' | translate }}
          </span>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>

  <ng-container *ngIf="!(selectedUserShippingAddress$ | async)">
    <mat-form-field class="full-width-form-field">
      <input matInput type="text"
             placeholder="{{ 'checkout-delivery.organization' | translate }}"
             [ngrxFormControlState]="formGroup.controls.shippingOrganization"
             maxlength="50" />
    </mat-form-field>

    <ng-container *ngIf="useGooglePlaces">
      <mat-form-field class="full-width-form-field" *ngIf="showSearchGooglePlaces$ | async; else showAddress">
        <textarea matInput type="text"
                  ngx-google-places-autocomplete
                  [options]='placesOptions'
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  size="40"
                  rows="2"
                  placeholder="{{'checkout-delivery.autocompleteAddress' | translate }}"
        ></textarea>
      </mat-form-field>
      <mat-error *ngIf="googleAddressRouteError">{{'checkout-delivery.errorAddressIncomplete' | translate }}</mat-error>
      <mat-error *ngIf="shopDoesNotShipToThisAddress">{{'checkout-delivery.errorNoShipping' | translate }}</mat-error>

      <ng-template #showAddress>
        <div class="selected-address-wrapper">
          <div class="selected-address">
            <ng-container *ngIf="shippingAddress$ | async as shippingAddress">
              <div>{{ shippingAddress.shippingAddress }}
                <span *ngIf="shippingAddress.shippingAddressStreetNumber">, {{ shippingAddress.shippingAddressStreetNumber }}</span>
              </div>
              <div>{{ shippingAddress.shippingPostcode.title }}</div>
              <!--              <div>{{ shippingAddress.shippingPostcode.title }} {{ shippingAddress.shippingCity.title }}</div>-->
              <div class="no-street-number" *ngIf="!shippingAddress.shippingAddressStreetNumber">
                <div>{{'checkout-delivery.upsNoStreetNumber' | translate }}</div>
                    <mat-form-field>
                      <input matInput
                             type="number"
                             placeholder="{{ 'checkout-delivery.streetNumber' | translate }}"
                             name="shippingAddressStreetNumber"
                             #streetNumber>
                    </mat-form-field>
                <div>
                  <button [disabled]="!streetNumber || !streetNumber.value"  mat-raised-button color="warn" (click)="setStreetNumber(streetNumber.value)">
                    {{ 'checkout-delivery.ok' | translate }}
                  </button>
                  <button id="no-number" mat-raised-button color="warn" (click)="setStreetNumberWithoutNumber()">
                    {{'checkout-delivery.streetHasNoNumber' | translate}}
                  </button>
                </div>
              </div>

            </ng-container>
          </div>
          <div>
            <button mat-mini-fab color="primary" (click)="clearShippingAddress()"><i class="fas fa-pencil-alt"></i></button>
          </div>
        </div>
      </ng-template>
      <mat-form-field class="full-width-form-field address-complement">
        <textarea matInput name="shippingAddressComplement"
                  placeholder="{{ 'checkout-delivery.addressComplement' | translate }}"
                  [ngrxFormControlState]="formGroup.controls.shippingAddressComplement"
                  maxlength="100" rows="2">
        </textarea>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!useGooglePlaces">
      <mat-form-field class="full-width-form-field">
        <textarea matInput name="shippingAddress"
                  placeholder="{{ 'checkout-delivery.address' | translate }}"
                  [ngrxFormControlState]="formGroup.controls.shippingAddress"
                  maxlength="200" rows="2">
        </textarea>
      </mat-form-field>
      <mat-form-field class="full-width-form-field address-complement">
        <textarea matInput name="shippingAddressComplement"
                  placeholder="{{ 'checkout-delivery.addressComplement' | translate }}"
                  [ngrxFormControlState]="formGroup.controls.shippingAddressComplement"
                  maxlength="100" rows="2">
        </textarea>
      </mat-form-field>

      <mat-form-field class="full-width-form-field" [hidden]="countries.ids?.length === 1"
                      *ngIf="formGroup.controls.shippingCountry.userDefinedProperties.COUNTRIES || {} as countries">
        <mat-select [ngrxFormControlState]="formGroup.controls.shippingCountry"
                    placeholder="{{ 'checkout-delivery.pleaseSelectCountry' | translate }}">
          <mat-option *ngFor="let id of countries.ids"
                      [value]="id">
            {{ countries.entities[id].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width-form-field" [hidden]="counties.ids?.length === 1"
                      *ngIf="formGroup.controls.shippingCounty.userDefinedProperties?.COUNTIES as counties">
        <mat-select [ngrxFormControlState]="formGroup.controls.shippingCounty"
                    placeholder="{{ 'checkout-delivery.pleaseSelectCounty' | translate }}">
          <mat-option *ngFor="let id of counties.ids"
                      [value]="id">
            {{ counties.entities[id].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width-form-field" [hidden]="cities.ids?.length === 1"
                      *ngIf="formGroup.controls.shippingCity.userDefinedProperties?.CITIES as cities">
        <mat-select [ngrxFormControlState]="formGroup.controls.shippingCity"
                    placeholder="{{ 'checkout-delivery.pleaseSelectCity' | translate }}">
          <mat-option *ngFor="let id of cities.ids || []"
                      [value]="id">
            {{ cities.entities[id].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width-form-field" *ngIf="formGroup.controls.shippingPostcode.userDefinedProperties?.POSTCODES as postcodes">
        <mat-select [ngrxFormControlState]="formGroup.controls.shippingPostcode"
                    [disabled]="!formGroup.value.shippingCity"
                    placeholder="{{ 'checkout-delivery.pleaseSelectPostcode' | translate }}">
          <mat-option *ngFor="let id of postcodes.ids"
                      [value]="id">
            {{ postcodes.entities[id].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>

</div>
