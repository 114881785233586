<button [ngClass]="buttonStyle"
        [disabled]="isDisabled"
        [type]="checkedType"
        [id]="id"
        [attr.aria-label]="ariaLabel | translate"
        [attr.aria-controls]="ariaControls"
        [attr.aria-expanded]="ariaExpanded"
        tabindex="0"
        (click)="onClick($event)">
    <span class="pa-button-wrapper" tabindex="-1">
        <pa-icon *ngIf="!!icon" [name]="icon" [hidden]="true"></pa-icon>
        <span #text class="pa-button-label" [class.pa-sr]="!!icon && !_iconAndText" [class.pa-with-icon]="!!icon && _iconAndText" translate><ng-content></ng-content></span>
    </span>
</button>

