import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';
import * as fromAddresses from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectAddressesState = createSelector(selectUserState, (userState) => userState.addressesState);

export const selectOrdersState = createSelector(selectUserState, (userState) => userState.ordersState);

export const selectAllAddresses = createSelector(selectAddressesState, fromAddresses.selectAll);

export const selectCanAdd = createSelector(selectAllAddresses, (addresses) => addresses.length < 4);

export const selectCSRFToken = createSelector(selectAddressesState, (addressesState) => addressesState.csrfToken);

export const selectEditingAddress = createSelector(
  selectAddressesState,
  (addressesState) => addressesState.editingAddress,
);

export const selectAddressEditForm = createSelector(selectAddressesState, (addressesState) => addressesState.editForm);

export const selectLoading = createSelector(selectUserState, (userState) => userState.loading);

export const selectOrdersCount = createSelector(selectOrdersState, (ordersState) => ordersState.count);

export const selectOrders = createSelector(selectOrdersState, (ordersState) => ordersState.orders);

export const selectPasswordResetForm = createSelector(
  selectUserState,
  (userState) => userState.passwordResetState.passwordResetForm,
);

export const selectPasswordResetResetted = createSelector(
  selectUserState,
  (userState) => userState.passwordResetState.passwordResetted,
);

export const selectRegisterUserForm = createSelector(
  selectUserState,
  (userState) => userState.registerUserState.registerUserForm,
);

export const selectRegisterUserError = createSelector(
  selectUserState,
  (userState) => userState.registerUserState.registerUserError,
);

export const selectRegisterUserRegisterOk = createSelector(
  selectUserState,
  (userState) => userState.registerUserState.registerUserOk,
);

export const selectUserInfoState = createSelector(selectUserState, (userState) => userState.userInfoState);

export const selectUserInfo = createSelector(selectUserInfoState, (userInfoState) => userInfoState.userInfo);

export const selectUserInfoEditForm = createSelector(
  selectUserInfoState,
  (userInfoState) => userInfoState.editUserInfoForm,
);

export const selectUserInfoEditing = createSelector(selectUserInfoState, (userInfo) => userInfo.editingUserInfo);

export const selectUserPasswordChangeForm = createSelector(
  selectUserInfoState,
  (userInfoState) => userInfoState.changeUserPasswordForm,
);

export const selectUserPasswordChanging = createSelector(
  selectUserInfoState,
  (userInfo) => userInfo.changingUserPassword,
);

export const selectUserInfoChangePasswordError = createSelector(
  selectUserInfoState,
  (userInfo) => userInfo.changePasswordError,
);

export const selectUserInfoChangedPassword = createSelector(
  selectUserInfoState,
  (userInfo) => userInfo.changedPassword,
);
