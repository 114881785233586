import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Grange } from 'grange';

@Injectable({
  providedIn: 'root',
})
export class HideInAppService {
  appSeen = false;

  constructor(private grange: Grange) {}

  inApp$(): Observable<boolean> {
    return this.grange.traverser.target.pipe(
      filter((target) => target.component),
      map((target) => {
        if (target.query.get('source') === 'app') {
          this.appSeen = true;
        }
        return this.appSeen;
      }),
    );
  }
}
