<div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="!thankyou; else thankyouMessage" class="ratings-form-wrapper">
    <ng-container *ngIf="ratingOrder$ | async as ratingOrder">
        <form #ratingForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="ratingOrder !== 'already_rated'; else alreadyRated">
            <ng-container *ngIf="(context | async).general_rating">
                <h2>{{ 'rating-form.buyerRating' | translate }}</h2>
                <p>{{ 'rating-form.thanksBuyer' | translate: {orderNumber: ratingOrder.id} }}</p>

                <div class="form-group" *ngIf="rateOrderVocab$ | async as rateOrderVocab">
                    <label for="generalRating">{{ 'rating-form.generalRating' | translate }}</label>
                    <mat-form-field>
                        <mat-label>{{ 'rating.SelectRating' | translate }}</mat-label>
                        <mat-select id="generalRating" name="generalRating" [(ngModel)]="model.generalRating" required>
                            <mat-option *ngFor="let rating of rateOrderVocab" [value]="rating.value">
                                {{ rating.caption}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="(context | async).item_rating">
                <h2>{{ 'rating-form.receiverRating' | translate}}</h2>
                <p>{{ 'rating-form.thanksReceiver' | translate: {orderNumber: ratingOrder.id} }}</p>
                <div class="form-group" *ngIf="rateItemVocab$ | async as rateItemVocab">
                    <label>{{ 'rating-form.likeProducts' | translate }}</label>
                    <div class="list-rateable-products">
                        <div *ngFor="let item of ratingOrder.items">
                            <mat-card class="example-card">
                                <img mat-card-image [src]="item.image_url" [height]="item.image_height" [width]="item.image_width"
                                     [alt]="item.title">
                                <mat-card-content>
                                    <label for="'rating' + item.uid">{{ item.title }}</label>
                                </mat-card-content>
                                <mat-card-actions>
                                    <mat-select [id]="'item' + item.uid" [name]="'item' + item.uid" [(ngModel)]="model['item.' + item.uid]" required>
                                        <mat-option *ngFor="let rating of rateItemVocab" [value]="rating.value">
                                            {{ rating.caption}}
                                        </mat-option>
                                    </mat-select>
                                </mat-card-actions>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="form-group general-comment">
                <label for="comment">{{ 'rating-form.generalComment' | translate }}</label>
                <textarea class="form-control" id="comment" name="comment" [(ngModel)]="model.generalComment"></textarea>
            </div>
            <div>
                <button mat-raised-button color="warn" type="submit">{{ 'rating-form.submit' | translate }}</button>
            </div>
        </form>
        <ng-template #alreadyRated>
            <h2>{{ 'rating-form.orderRating' | translate }}</h2>
            <div>{{ 'rating-form.alreadyRated' | translate }}</div>
        </ng-template>
    </ng-container>
</div>
<ng-template #thankyouMessage>
    <div class="ratings-form-wrapper">
        <h2>{{ 'rating-form.orderRating' | translate }}</h2>

        <div>{{ 'rating-form.thanksRating' | translate }}</div>
        &nbsp;
        <div>{{ 'rating-form.backSoon' | translate }}</div>
    </div>
</ng-template>
