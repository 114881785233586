import { createAction, props } from '@ngrx/store';
import * as moment from 'moment';
import { ShopSearchResult } from '~/core/models';
import { OrderingType, SearchData, SearchResult } from '~/front/models';

export const searchResultLoaded = createAction(
  '[Backend API] Search result loaded',
  props<{ searchResult: SearchResult }>(),
);

export const setSearchData = createAction('[App] Set Search Data', props<{ searchData: SearchData }>());

export const setSearchDataPickup = createAction('[App] Set Search Data Pickup', props<{ isPickup: boolean }>());

export const requestShop = createAction(
  '[Backoffice] Request Shop Data',
  props<{ uid: string | string[]; entityUid: string; date: moment.Moment; item: string }>(),
);

export const loadShops = createAction('[Backoffice] Load Shop Data', props<{ shops: ShopSearchResult[] }>());

export const orderShops = createAction('[App] Order Shops', props<{ ordering: OrderingType }>());

export const addFilter = createAction('[App] Add Filter', props<{ filter: string[] }>());

export const removeFilter = createAction('[App] Remove Filter', props<{ filter: string[] }>());

export const clearFilter = createAction('[App] Clear Filter');
