<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<h1 class="text-center">{{ 'user-orders.myOrders' | translate }}</h1>

<div class="orders-wrapper">
  <div class="order orders-header">
    <div class="order-date">{{ 'user-orders.orderingDate' | translate }}</div>
    <div class="order-number">{{ 'user-orders.orderData' | translate }}</div>
    <div class="order-details">{{ 'user-orders.shipmentDetails' | translate}}</div>
    <div class="order-items">{{ 'user-orders.itemsOrdered' | translate}}</div>
  </div>
  <ng-container *ngFor="let order of (orders$ | async)">

    <div class="order">
      <div class="order-date">{{ order.orderDatetime | date:'mediumDate' }}</div>
      <div class="order-number">
        <div>#{{ order.id }}</div>
        <div *ngIf="order.shopUrl; else noShopUrl"><a [traverseTo]="shopLink(order.shopUrl)">{{ order.shopName }}</a></div>
        <ng-template #noShopUrl><div>{{ order.shopName }}</div></ng-template>
      </div>
      <!-- <div>{{ order.state }}</div>-->
      <div class="order-details">
        <div><i class="fas fa-calendar-alt"></i>{{ order.deliveryPickupDatetime | date:'mediumDate' }}</div>
        <span *ngIf="order.shippingAddress">
          <div><i class="far fa-user"></i>{{ order.shippingName }}</div>
          <div><i class="fas fa-phone"></i>{{ order.shippingPhone }}</div>
          <div>
            <i class="fas fa-map-marker-alt"></i>
            <span *ngIf="order.shippingOrganization">{{ order.shippingOrganization }} -</span> {{ order.shippingAddress }} {{ order.shippingAddressComplement }}, {{ order.shippingPostcode }} -  {{ order.shippingCity }}
            <!-- {{ order.shippingCounty }} {{ order.shippingCountry }}-->
          </div>
        </span>
      </div>
      <div class="order-items">
        <ul class="list-group list-group-flush" >
          <li *ngFor="let item of order.orderItems" class="list-group-item"  >
            <span>{{ item.quantity }} x  {{ item.description }}</span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>

<mat-paginator [length]="ordersLength$ | async" [pageSize]="pageSizes[0]"
               [pageSizeOptions]="pageSizes"
               (page)="changePage($event)">
</mat-paginator>
