<div class="pa-field pa-field-slider" [class.disabled]="isDisabled">
    <label class="pa-field-label pa-field-label-range" [for]="id" translate>
        <ng-content></ng-content>
    </label>

    <div class="pa-slider-wrapper">
        <div class="pa-slider-output left">{{values[0]}}</div>
        <div #container class="pa-slider-container">
            <div #slider class="pa-slider">
                <div #track class="pa-slider-track"></div>
            </div>

            <div #thumbLeft class="pa-slider-thumb"></div>
            <div #thumbRight class="pa-slider-thumb"></div>
        </div>
        <div class="pa-slider-output right">{{values[1]}}</div>
    </div>


    <small *ngIf="help" class="pa-field-help" [id]="helpId" translate>{{ help }}</small>
</div>
