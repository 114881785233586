import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, map, switchMap } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BACKEND_BASE_URL } from 'angular-traversal';

import { Grange } from 'grange';
import { Authenticated } from '~/shared/models';
import { SidenavService } from '~/core/sidenav.service';
import { selectCheckingout } from '~/cart/store';
import { Store, select } from '@ngrx/store';
import { AppState } from '~/reducers';
import { BackendService } from '~/core/backend.service';
import { AppJsonConfig } from '~/core/appjsonconfig.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavComponent implements OnInit {
  isAuthenticated$: Observable<Authenticated>;
  userName$: Observable<string>;
  @ViewChild('drawer_left', { static: true }) public leftSideNav: MatSidenav;
  @ViewChild('drawer_right', { static: true }) public rightSideNav: MatSidenav;
  minWidth768$: Observable<boolean>;
  checkingout$: Observable<boolean>;
  smallLogo: string;
  largeLogo: string;
  logoUrl: string;

  constructor(
    private grange: Grange,
    private sidenav: SidenavService,
    private backend: BackendService,
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    @Inject(BACKEND_BASE_URL) private backend_base_url: string,
    private appConfig: AppJsonConfig,
  ) {}

  ngOnInit(): void {
    this.logoUrl = this.appConfig.getConfig('logoUrl', '/');
    const logo = this.appConfig.getConfig('logo');
    if (logo) {
      const subsite_url = this.backend_base_url + this.appConfig.getConfig('subsite');
      this.smallLogo = subsite_url + logo.small;
      this.largeLogo = subsite_url + logo.large;
    } else {
      this.smallLogo = '../assets/logo-basket.webp';
      this.largeLogo = '../assets/apym_hztl.webp';
    }
    this.checkingout$ = this.store.pipe(select(selectCheckingout));
    this.minWidth768$ = this.breakpointObserver.observe(['(min-width: 768px)']).pipe(map((state) => state.matches));
    this.sidenav.leftSideNav = this.leftSideNav;
    this.sidenav.rightSideNAev = this.rightSideNav;
    this.isAuthenticated$ = this.grange.core.auth.isAuthenticated;
    this.userName$ = this.isAuthenticated$.pipe(
      filter((auth) => auth.state),
      switchMap((auth) => this.backend.getUser$(auth.username)),
      map((user: any) => user.fullname || user.username),
    );
  }

  logout(): void {
    this.grange.core.auth.logout();
  }
}
