<div class="rating-wrapper">
  <div>
    <div class="date">{{ rating.datetime | date:'mediumDate' }}</div>
    <span class="name">{{ rating.nickname }}</span>
  </div>
  <div>
    <app-stars [rating]="rating.calculated_stars"
               maxRating="10"
               [word]="rating.word"></app-stars>
  </div>
  <div>
    <div class="comment" *ngIf="rating.review">{{ rating.review }}</div>
  </div>
</div>
