import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import * as _ from 'lodash';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderComponent extends BaseViewComponent implements OnInit {
  constructor(public grange: Grange, public seo: SeoService, public configurationService: ConfigurationService) {
    super(grange, seo);
  }

  ngOnInit() {
    super.ngOnInit();
    this.context.subscribe((result) => {
      const default_page = result['@components'].default_page;
      if (!_.isUndefined(default_page)) {
        this.grange.traverser.traverse(this.configurationService.urlToPath(result['@id'] + '/' + default_page));
      }
    });
  }
}
