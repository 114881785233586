<div class="categories-wrapper">
    <h4>{{ 'categories.productCategoryColon' | translate }}</h4>
    <mat-radio-group (change)="selectCategory($event)" [value]="selectedCategory$ | async">
        <mat-radio-button color="primary" *ngFor="let category of categories$ | async"
                          [value]="category.id"
                          [disabled]="category.articles === 0">
            {{ category.name }} ({{ category.articles }})
        </mat-radio-button>
    </mat-radio-group>
</div>
