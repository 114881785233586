import { Image } from './image';
import { PloneItem } from './ploneitem';
import { RichText } from './richtext';
import { Resource } from '@guillotinaweb/grange-core';
import * as moment from 'moment';

interface Seo {
  seo_description: string;
  seo_keywords: string[];
  seo_title: string;
}

interface Ratable {
  rating_quantity: number;
  stars: number;
}

interface date {
  day: string;
  from_date: string;
  to_date: string;
  repeat_every_year: boolean;
}

interface ServiceSurcharge {
  service_surcharge: number;
  payment_methods_service: string[];
}

export interface Shop extends Seo, Ratable, Resource, ServiceSurcharge {
  '@components': {
    actions: { '@id': string };
    breadcrumbs: { '@id': string };
    info: { '@id': string };
    schema: { '@id': string };
    navigation: { '@id': string };
    ratings: { '@id': string };
    workflow: { '@id': string };
  };
  '@id': string;
  '@type': string;
  UID: string;
  address: string;
  allow_discussion: boolean;
  checkout_card_message: boolean;
  checkout_is_a_surprise: boolean;
  checkout_custom_message: string;
  checkout_recipient_different: boolean;
  city: string;
  closed_dates: date[];
  contributors: string[];
  corporate_name: string;
  country: string;
  county: string;
  created: string;
  creators: string[];
  delivery_time_interval: number;
  delivery_timetable: Timetable[];
  description: string;
  directions_for_use: string;
  effective: string;
  email: string;
  expires: string;
  id: string;
  image: Image;
  is_folderish: boolean;
  is_ratable: boolean;
  items: PloneItem[];
  items_total: number;
  kg_cart_uuid: string;
  language: string;
  layout: string;
  limit_time_for_delivery: string;
  logo: Image;
  minimum_amount_delivery_order: number;
  minimum_amount_pickup_order: number;
  minimum_amount_without_shipping_costs: number;
  minimum_order_without_shipping_costs: boolean;
  minimum_ratings_to_show: number;
  modified: string;
  no_delivery_dates: date[];
  checkout_additional_message: boolean;
  opening_timetable: Timetable[];
  ordering_timetable: Timetable[];
  parent: PloneItem;
  payment_methods: string[];
  phone_no: string;
  post_code: string;
  priority_order: number;
  product_preparing_time: number;
  review_state: string;
  rights: string;
  shop_picture: Image;
  show_ratings: boolean;
  specialities: string;
  subjects: string[];
  summary: RichText;
  timezone: string;
  title: string;
  vat_rate: number;
  version: string;
  website: string;
  year_founded: string;
  warning_message: RichText;
  warning_from_date: string;
  warning_to_date: string;
}

interface Timetable {
  date: string;
  timetable: { from_time: string; to_time: string };
}

interface Address {
  uid: string;
  address: string;
  post_code: string;
  city: string;
  county: string;
  country: string;
}

interface DatetimeText {
  from: moment.Moment;
  text: string;
}

export interface AvailabilityDatetimeText {
  immediate: DatetimeText;
  same_day: DatetimeText;
  next_day: DatetimeText;
  two_days: DatetimeText;
}

export interface ShopInfo {
  timetables: {
    opening_timetable: Timetable[];
    delivery_timetable: Timetable[];
  };
  addresses: Address[];
  delivery_cities: string[];
  shop_info: {
    minmax_shipping_fee: string;
    has_pickup: boolean;
    has_delivery: boolean;
    now: string;
    timezone_offset: string;
  };
  delivery_availability_datetime_text: AvailabilityDatetimeText;
  delivery_dates: string[];
  pickup_availability_datetime_text: AvailabilityDatetimeText;
  pickup_dates: string[];
  minimum_time_string: string[];
}

export interface ShopSchema {
  '@context': string;
  '@type': string;
  image: string;
  name: string;
  potentialAction: {
    '@type': string;
    deliveryMethod: string[];
    target: {
      '@type': string;
      actionPlatform: string[];
      inLanguage: string;
      urlTempate: string;
    };
    priceSpecification?: {
      '@type': string;
      appliesToDeliveryMethod: string;
      priceCurrency: string;
      price: number;
      eligibleTransactionVolume: {
        '@type': string;
        priceCurrency: string;
        price: string;
      };
    };
  };
  url: string;
  address: {
    '@type': string;
    addressCountry: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
  };
  logo: string;
  review: {
    '@type': string;
    author: string;
    datePublished: string;
    description: string;
    name: string;
    reviewRating: {
      '@type': string;
      bestRating: string;
      worstRating: string;
      ratingValue: string;
    };
  }[];
  telephone: string;
  openingHours: string;
  priceRange: string;
  aggregateRating?: {
    '@type': string;
    ratingValue: string;
    ratingCount: string;
    worstRating: string;
    bestRating: string;
  };
}

export enum deliveryOrPickup {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

interface PaymentMethod {
  id: string;
  caption: string;
  widgetLogo: string;
  widgetLogoLabel: string;
  daysOfDelay: number;
  surcharge: number;
  orderType: deliveryOrPickup;
}

export interface PaymentMethods {
  payment_methods: PaymentMethod[];
}

interface TerritorialEntity {
  uid: string;
  name: string;
}

export interface TerritorialEntities {
  ids: string[];
  entities: { [key: string]: TerritorialEntity };
}
