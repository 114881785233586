import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';

import { fromCart } from '~/cart/store';

@Component({
  selector: 'app-checkout-cardmessage',
  templateUrl: './checkout-cardmessage.component.html',
  styleUrls: ['./checkout-cardmessage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutCardmessageComponent {
  @Input() formGroup: FormGroupState<fromCart.CheckoutCardMessageForm>;

  constructor() {}
}
