import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavService } from './sidenav.service';
import { WINDOW_PROVIDERS } from './window.service';
import { LocaleService } from './locale.service';
import { RedirectService } from './redirect.service';
import { HideInAppService } from './hideinapp.service';
import { ArticleOptionsService } from './articleoptions.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ArticleOptionsService,
    HideInAppService,
    LocaleService,
    RedirectService,
    SidenavService,
    WINDOW_PROVIDERS,
  ],
})
export class CoreModule {}
