import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BackendService } from '~/core/backend.service';
import { Portlet } from '~/core/models';
import { Grange } from 'grange';

@Component({
  selector: 'apym-portlets',
  templateUrl: './portlets.component.html',
  styleUrls: ['./portlets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortletsComponent implements OnInit {
  @Input() context: any;
  @Input() manager: string;
  @Input() path: string = null;
  private prefix: string;
  portlets$: Observable<Portlet[]>;

  constructor(
    private backend: BackendService,
    // private location: Location,
    // private domSanitizer: DomSanitizer,
    public grange: Grange,
    // @Optional() @Inject(NAVIGATION_PREFIX) prefix: string
  ) {
    // this.prefix = prefix || '';
  }

  ngOnInit() {
    // const locationPath = this.location.path();
    // const indexView = locationPath.indexOf('@@');
    // const path = locationPath.slice(this.prefix.length, indexView != -1 ? indexView - 1 : undefined );
    this.portlets$ = this.grange.traverser.target.pipe(
      take(1),
      switchMap((target) =>
        this.backend.getPortlets$(target.contextPath, this.manager).pipe(map((result) => result.portlets)),
      ),
    );
  }
}
