import { Injectable } from '@angular/core';
import { Grange } from 'grange';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppState } from '~/reducers';
import { BackendService } from '~/core/backend.service';
import * as FrontActionTypes from './front.actions';
// import { SearchData, SearchResult } from './models';
import { ShopSearchResultBrains } from '~/core/models';
import { SearchData, SearchResult } from '~/front/models';

@Injectable()
export class FrontEffects {
  constructor(
    private actions$: Actions,
    private grange: Grange,
    private store: Store<AppState>,
    private backend: BackendService,
  ) {}

  searchData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof FrontActionTypes.setSearchData>>(FrontActionTypes.setSearchData),
      // tap((action) => console.log('searchData$', action)),
      map((action) => action.searchData),
      switchMap((searchData) =>
        this.backend
          .searchShops$('', searchData.entity.uid, searchData.date)
          .pipe(map((result) => [searchData, result])),
      ),
      // tap(([searchData, result]: [SearchData, ShopSearchResultBrains]) => console.log('searchShops$', result)),
      // switchMap(([searchData, result]: [SearchData, ShopSearchResultBrains]) => {
      //   if (result.shops.length > 0) {
      //     return from(result.shops).pipe(
      //       mergeMap((shop) =>
      //         this.backend.getShopSearch$(shop['@uid'], searchData.entity.uid, searchData.date, searchData.item),
      //       ),
      //     );
      //   }
      // }),
      // map((result: ShopSearchResultBrains) => {
      //   return { shops: [], entity_title: '' };
      // }),
      map(([searchData, result]: [SearchData, ShopSearchResultBrains]) => [
        searchData,
        {
          childSites: result.childsites
            .map((childSite) => ({
              ...childSite,
              shops: result.shops.filter((shop) => shop.childsite === childSite['@uid']).map((shop) => shop['@uid']),
            }))
            .filter((childSite) => childSite.shops.length > 0),
          // items: [].concat(...result.shops.map((shop) => shop.items)), // flatten array of arrays
          shops: result.shops, //.map((shop) => ({ '@uid': shop['@uid'], '@id': shop['@id'] })),
          entityTitle: result.entity_title,
        },
      ]),
      // tap(([searchData, searchResult]) => console.log('searchResult', _.chunk(searchResult.shops, 3))),
      switchMap(([searchData, searchResult]: [SearchData, SearchResult]) => [
        FrontActionTypes.searchResultLoaded({ searchResult }),
        ..._.chunk(searchResult.shops, 3).map((shops_group) =>
          FrontActionTypes.requestShop({
            uid: shops_group.map((shop) => shop['@uid']),
            entityUid: searchData.entity.uid,
            date: searchData.date,
            item: searchData.item,
          }),
        ),
      ]),
    ),
  );

  requestShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontActionTypes.requestShop),
      concatMap((action) => this.backend.getShopSearch$(action.uid, action.entityUid, action.date, action.item)),
      // tap((result) => console.log('requestShop', result)),
      map((result) => FrontActionTypes.loadShops({ shops: result })),
    ),
  );
}
