import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Rating } from '~/core/models';

@Component({
  selector: 'apym-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
  @Input() rating: Rating;

  formatText(text: string) {
    if (!text) {
      return '';
    }
    return text;
  }
}
