import { PipeTransform, Pipe } from '@angular/core';
import { CurrencyPipe, DatePipe, Time } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { MomentTimerange } from './models';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'callback', pure: false })
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}

@Pipe({ name: 'time', pure: true })
export class TimePipe implements PipeTransform {
  transform(time: Time): string {
    const d = new Date();
    d.setHours(time.hours);
    d.setMinutes(time.minutes);
    return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
}

@Pipe({ name: 'timeRange', pure: true })
export class TimeRangePipe implements PipeTransform {
  transform(value: MomentTimerange | string | null): string | null {
    // console.log('shared.pipes', value);
    if (value !== null && typeof value !== 'undefined') {
      if (typeof value === 'string') {
        const timeranges = value.split('-');
        return (
          moment(timeranges[0], 'HH:mm:ss').format('HH:mm') + ' - ' + moment(timeranges[1], 'HH:mm:ss').format('HH:mm')
        );
      } else {
        return value.fromTime.format('HH:mm') + ' - ' + value.toTime.format('HH:mm');
      }
    }
  }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

@Pipe({ name: 'dynamicDate', pure: true })
export class DynamicDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private translate: TranslateService) {}

  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    return this.datePipe.transform(value, format, timezone, locale || this.translate.currentLang);
  }
}

@Pipe({ name: 'apymCurrency', pure: true })
export class ApymCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private translate: TranslateService) {}

  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    const floatValue = parseFloat(value);
    const decimalDigits = Math.floor(floatValue) === floatValue ? 0 : floatValue.toString().split('.')[1].length || 0;
    if (decimalDigits === 0) {
      digitsInfo = '1.2-2';
    }
    return this.currencyPipe.transform(
      floatValue,
      currencyCode,
      display,
      digitsInfo,
      locale || this.translate.currentLang,
    );
  }
}
