import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Batching, Rating, Ratings } from '~/core/models';
import { BackendService } from '~/core/backend.service';

interface DialogData {
  path: string;
}

@Component({
  selector: 'apym-ratingslist',
  templateUrl: './ratingslist.component.html',
  styleUrls: ['./ratingslist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingslistComponent implements OnInit {
  public ratings: Rating[] = [];
  public batching$: Observable<Batching | Observable<never>>;
  public ratings$: Observable<Ratings[]>;

  constructor(
    private backend: BackendService,
    public dialogRef: MatDialogRef<RatingslistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this.loadRatings();
  }

  loadRatings() {
    this.ratings$ = this.backend.ratings$(this.data.path);
    this.dialogRef.updatePosition({ top: '50px' });
  }

  goTo(path: string) {
    this.data.path = path;
    this.loadRatings();
  }
}
