<div class="apym-menu">
    <ng-container *ngFor="let menu of menues$ | async">
        <a mat-button *ngIf="menu.redirect" [href]="menu.redirect">
            {{ menu.title }}
        </a>

        <ng-container *ngIf="!menu.redirect">
            <button
                mat-button [matMenuTriggerFor]="menuOptions">
                {{ menu.title }}
            </button>

            <mat-menu #menuOptions="matMenu">
                <ng-container *ngFor="let menuOp of menu.children">
                    <a mat-button *ngIf="menuOp.redirect" [href]="menuOp.redirect">
                        {{ menuOp.title }}
                    </a>

                    <button *ngIf="!menuOp.redirect" mat-menu-item
                            (click)="menuOption(menuOp)">
                        {{ menuOp.title }}
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>
</div>
