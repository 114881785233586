import { Action, createReducer, on } from '@ngrx/store';
// import { Actions, FrontActionTypes } from './front.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as _ from 'lodash';
import * as FrontActionTypes from './front.actions';
import { ChildSite, OrderingType, SearchData } from '~/front/models';
import { ShopSearch } from '~/core/models';

export interface ShopSearchState extends EntityState<ShopSearch> {}

export interface ChildSiteState extends EntityState<ChildSite> {
  loaded: boolean;
  shopsOrdering: OrderingType;
}

const adapterShop = createEntityAdapter<ShopSearch>({
  selectId: (shop) => shop['@uid'],
});
const adapterChildSite = createEntityAdapter<ChildSite>({
  selectId: (childSite) => childSite['@uid'],
});

export interface FrontState {
  readonly searchData: SearchData;
  readonly shops: ShopSearchState;
  readonly childSites: ChildSiteState;
  readonly entityTitle: string;
  readonly loading: boolean;
  readonly totalItems: number;
  readonly taxonomyFilter: string[];
}

const shopInitialState: ShopSearchState = adapterShop.getInitialState();
const childSiteInitialState: ChildSiteState = adapterChildSite.getInitialState({
  loaded: false,
  shopsOrdering: OrderingType.recommended,
});

const initialState: FrontState = {
  searchData: null,
  shops: shopInitialState,
  childSites: childSiteInitialState,
  entityTitle: null,
  loading: false,
  totalItems: null,
  taxonomyFilter: [],
};

const frontReducer = createReducer(
  initialState,
  on(FrontActionTypes.addFilter, (state, { filter }) => ({
    ...state,
    taxonomyFilter: _.union(state.taxonomyFilter, filter),
  })),
  on(FrontActionTypes.clearFilter, (state) => ({
    ...state,
    taxonomyFilter: [],
  })),
  on(FrontActionTypes.loadShops, (state, result) => {
    const shops = result.shops;

    return {
      ...state,
      loading: false,
      totalItems: (state.totalItems ?? 0) + _.sum(shops.map((shop) => (shop.items ? shop.items.length : 0))),
      shops: adapterShop.updateMany(
        shops.map((shop) => ({ id: shop['@uid'], changes: shop })),
        state.shops,
      ),
    };
  }),
  // on(FrontActionTypes.loadShop, (state, { shop }) => ({
  //   ...state,
  //   loading: false,
  //   totalItems: (state.totalItems ?? 0) + (shop.items ? shop.items.length : 0),
  //   shops: adapterShop.updateOne({ id: shop['@uid'], changes: shop }, state.shops),
  // })),
  on(FrontActionTypes.orderShops, (state, { ordering }) => ({
    ...state,
    childSites: { ...state.childSites, shopsOrdering: ordering },
  })),
  on(FrontActionTypes.searchResultLoaded, (state, { searchResult }) => ({
    ...state,
    loading: false,
    childSites: { ...adapterChildSite.addMany(searchResult.childSites, state.childSites), loaded: true },
    shops: adapterShop.addMany(searchResult.shops, state.shops),
    entityTitle: searchResult.entityTitle,
  })),
  on(FrontActionTypes.removeFilter, (state, { filter }) => ({
    ...state,
    taxonomyFilter: _.filter(state.taxonomyFilter, (f) => !filter.includes(f)),
  })),
  on(FrontActionTypes.setSearchData, (state, { searchData }) => ({
    ...state,
    loading: true,
    childSites: { ...state.childSites, loaded: false },
    searchData: searchData,
  })),
  on(FrontActionTypes.setSearchDataPickup, (state, { isPickup }) => ({
    ...state,
    searchData: { ...state.searchData, pickup: isPickup },
  })),
);

export function reducer(state: FrontState | undefined, action: Action): FrontState {
  return frontReducer(state, action);
}

export const {
  selectAll: selectAllChildSites,
  selectEntities: selectChildSitesEntities,
  selectIds: selectIdsChildSites,
  selectTotal: selectTotalChildSites,
} = adapterChildSite.getSelectors();

export const {
  selectAll: selectAllShops,
  selectEntities: selectEntitiesShops,
  selectIds: selectIdsShops,
  selectTotal: selectTotalShops,
} = adapterShop.getSelectors();
