import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { debounce, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Grange } from 'grange';
import { Observable, of, Subject, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatAutocomplete } from '@angular/material/autocomplete';

import { EntityType } from '~/front/models';
import { AppState } from '~/reducers';
import { WINDOW } from '~/core/window.service';
import { FrontActionTypes } from '~/front/store';
import { BackendService } from '~/core/backend.service';
import { SearchPostCode } from '~/core/models';

function postCodeValidator(control: AbstractControl): { [key: string]: any } | null {
  return _.isUndefined(control.value.type) ? { invalidPostCode: { valid: false, value: control.value } } : null;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  destroy = new Subject();
  @Input() context: any;
  // boceto del búscador
  // https://www.google.com/url?q=https://docs.google.com/document/d/1a16r8MKZLjoqP2_PR6t_kvifEcrdyGdTbn-thGlpMgk/edit?usp%3Dsharing&sa=D&source=hangouts&ust=1546687773498000&usg=AFQjCNFIl4vxJyfrf6HOK3t5tc07lYLQsg
  isLoading = false;
  filteredPostCodes$: Observable<SearchPostCode[]>;
  doMinDate: Date = moment().toDate();
  doMaxDate: Date = moment().add(90, 'days').toDate();
  searchForm: FormGroup;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  constructor(
    private store: Store<AppState>,
    public grange: Grange,
    private backend: BackendService,
    private fb: FormBuilder,
    @Inject(WINDOW) private window: Window,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      entity: ['', [Validators.required, postCodeValidator]],
      date: [''],
      item: [''],
    });

    this.filteredPostCodes$ = this.searchForm.controls['entity'].valueChanges.pipe(
      // debounceTime(500),
      distinctUntilChanged(),
      filter((entityText) => entityText !== null && entityText.length >= 3),
      debounce((entityText) => (entityText.length > 3 ? timer(500) : timer(0))),
      switchMap((entityText: any) => {
        return of(entityText).pipe(
          tap(() => (this.isLoading = true)),
          switchMap((value) => this.backend.searchPostCodes$(value).pipe(finalize(() => (this.isLoading = false)))),
        );
      }),
    );
  }

  getPostCode(postCode: SearchPostCode): string {
    return postCode ? postCode.label : undefined;
  }

  /*
    saliendo con tab (>= 3 chars):
      - panelClosed
      - blur
    saliendo con tab (< 3 chars):
      - blur
    saliendo con click fuera (>= 3 chars):
      - blur
      - panelClosed
    saliendo con click fuera (< 3 chars):
      - blur
    saliendo con click en una opción:
      el primer click (no se cierra el panel):
        - blur
      el segundo click:
        - optionSelected
        - panelClosed
    seleccionando una opción con los cursores y enter:
      - optionSelected
      - panelClosed
    con una opción seleccionada, borrar algún caracter y salir con tab:
      - panelClosed
      - blur
  */

  chooseFirstOption(search: boolean): void {
    //console.log('chooseFirstoption', _.isObject(this.searchForm.value.entity) );
    //if (this.matAutocomplete.options.first) {
    /*if (this.matAutocomplete.options.first && !this.searchForm.value.entity.uid) {
      this.searchForm.patchValue({ entity: this.matAutocomplete.options.first.value });
      this.matAutocomplete.options.first.select();
    }*/
    if (search) this.search();
  }

  search(): void {
    const form = this.searchForm.value;
    const entity_uid = form.entity.value;
    const entity_caption = form.entity.label;
    const entity_type =
      form.entity.type === 'apanymantel.cart.territorialentities.PostCode' ? EntityType.postCode : EntityType.city;
    const date = form.date ? moment(form.date).format('YYYY-MM-DD') : '';
    const item = form.item;
    this.store.dispatch(
      FrontActionTypes.setSearchData({
        searchData: {
          entity: { uid: entity_uid, caption: entity_caption, type: entity_type },
          date: form.date ? form.date : null,
          item,
        },
      }),
    );
    this.grange.traverser.traverse(
      `/@@search-result?eu=${entity_uid}&ec=${entity_caption}&et=${entity_type}&dt=${date}&it=${item}`,
    );
  }

  touchUi(): boolean {
    return this.window.innerWidth < 768;
  }

  scrollTop(): void {
    this.window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.cd.detach();
    this.destroy.next();
    this.destroy.complete();
  }
}
