<mat-radio-group
    [ngrxFormControlState]="formGroup.controls.paymentMethod">
        <mat-radio-button color="primary" *ngFor="let pm of paymentMethods$ | async"
                          [id]=""
                          class="option"
                          [value]="pm.id"
                          [disabled]="!pm.isEnabled">
<mat-card>
            <div [innerHTML]="pm.caption"></div>
            <div class="payment-logo" [innerHTML]="pm.widgetLogo"></div>
            <div [innerHTML]="pm.widgetLogoLabel"></div>
            <div class="comment">{{ pm.widgetComment }}</div>
            <div><small class="help" *ngIf="!pm.isEnabled">
                {{ 'checkout-payment.availableForDeliveryDate' | translate : {date:pm.targetDate | date:'mediumDate'} }}</small></div>
            </mat-card>
        </mat-radio-button>
</mat-radio-group>
<app-coupondiscount *ngIf="hasDiscounts$ | async" [formGroup]="formGroup"></app-coupondiscount>
