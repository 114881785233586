<ul class="ratings-wrapper">
  <li *ngFor="let lastRating of lastRatings$ | async">
    <div class="rating-wrapper">
      <div class="rating-header">
        <div><b>{{ lastRating.city }}</b></div>
        <div class="date-time">{{ 'lastratings.dateTime' | translate: {date: lastRating.datetime | date:'mediumDate', time: lastRating.datetime | date: 'shortTime'} }}</div>
        <div class="shop-name">{{ lastRating.shop_name }}</div>
      </div>
      <div class="stars">
        <app-stars [rating]="lastRating.stars"
                   maxRating="10"
                   [word]="lastRating.word"></app-stars>
      </div>
      <div class="rating-description">
        <span class="font-weight-bold">{{ lastRating.nickname }}: </span>
        <span class="font-italic">{{ lastRating.review }}</span>
      </div>
    </div>
  </li>
</ul>
