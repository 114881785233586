import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupState, unbox } from 'ngrx-forms';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';

import { AppState } from '~/reducers';
import { fromCart, selectCartShop, selectPaymentMethod, selectPostedOrder } from '~/cart/store';
import { PostedOrder } from '~/core/models';
import { Observable } from 'rxjs';
import * as CartModel from '~/cart/models';

@Component({
  selector: 'app-checkout-review',
  templateUrl: './checkout-review.component.html',
  styleUrls: ['./checkout-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutReviewComponent implements OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutReviewForm>;
  postedOrder$: Observable<PostedOrder>;
  cartShop$: Observable<CartModel.CartShop>;

  constructor(private store: Store<AppState>) {}

  unbox = unbox;
  moment = moment;

  ngOnInit() {
    this.postedOrder$ = this.store.pipe(select(selectPostedOrder));
    this.cartShop$ = this.store.pipe(select(selectCartShop));
  }

  getPaymentMethod(paymentId: string) {
    return this.store.pipe(select(selectPaymentMethod(paymentId)));
  }
}
