import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { AppState } from '~/reducers';
import {
  CartActionTypes,
  fromCart,
  selectCheckoutDeliveryDate,
  selectCheckoutDeliveryOrPickup,
  selectAvailablePaymentMethods,
} from '~/cart/store';
import { selectShopHasDiscounts } from '~/shop/store';
import { CartPaymentMethod } from '~/cart/models';
import { AppJsonConfig } from '~/core/appjsonconfig.service';
import { HideInAppService } from '~/core/hideinapp.service';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutPaymentComponent implements OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutPaymentForm>;
  paymentMethods$: Observable<CartPaymentMethod[]>;
  hasDiscounts$: Observable<boolean>;

  constructor(private store: Store<AppState>, private appConfig: AppJsonConfig, private hideInApp: HideInAppService) {}

  ngOnInit(): void {
    const appBlacklistedPaymentMethods = this.appConfig.getConfig('appBlacklistedPaymentMethods', []);

    this.hasDiscounts$ = this.store.select(selectShopHasDiscounts);

    this.paymentMethods$ = combineLatest(
      this.store.select(selectCheckoutDeliveryDate),
      this.store.select(selectCheckoutDeliveryOrPickup),
    ).pipe(
      withLatestFrom(this.store.select(selectAvailablePaymentMethods)),
      tap(([[_deliveryDate, _deliveryOrPickup], paymentMethods]) => {
        if (paymentMethods === null || paymentMethods.length === 0) {
          this.store.dispatch(CartActionTypes.paymentMethodsRequested());
        }
      }),
      filter(([[_deliveryDate, _deliveryOrPickup], paymentMethods]) => paymentMethods !== null),
      withLatestFrom(this.hideInApp.inApp$()),
      map(([[[deliveryDate, deliveryOrPickup], paymentMethods], isApp]) => {
        return paymentMethods.reduce((acc, paymentMethod) => {
          if (isApp && appBlacklistedPaymentMethods.indexOf(paymentMethod.id) > -1) {
            // is app and this payment method is blacklisted
            return acc;
          }
          if (paymentMethod.orderType.indexOf(deliveryOrPickup) !== -1) {
            if (paymentMethod.daysOfDelay === null) {
              acc.push({ ...paymentMethod, isEnabled: true });
            } else {
              const today: moment.Moment = moment(0, 'HH');
              const targetDate: moment.Moment = today.add(paymentMethod.daysOfDelay, 'days');
              acc.push({
                ...paymentMethod,
                isEnabled: targetDate <= deliveryDate,
                targetDate,
              });
            }
          }
          return acc;
        }, []);
      }),
    );
  }
}
