<ng-container *ngIf="action?.type === 'get'">
    <a mat-raised-button color="warn" type="button" [href]="action.url" class="button-field">{{ 'paybutton.' + (action.redirect_to_payment ? 'proceedToPay' : 'finishOrder') | translate }}</a>
</ng-container>
<ng-container *ngIf="action?.type === 'post'">
    <form [action]="action.url" method="post">
        <input *ngFor="let param of action.params | keyvalue"
               type="hidden"
               [name]="param.key"
               [value]="param.value"/>
        <input id="form-buttons-pay" name="form.buttons.pay"
               class="submit-widget button-field"
               value="{{ 'paybutton.' + (action.redirect_to_payment ? 'proceedToPay' : 'finishOrder') | translate }}" type="submit"/>
    </form>
</ng-container>
