import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Grange } from 'grange';
import { Authenticated } from '~/shared/models';
import { SidenavService } from '~/core/sidenav.service';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'app-menu-register',
  templateUrl: './menu-register.component.html',
  styleUrls: ['./menu-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuRegisterComponent implements OnInit {
  isAuthenticated$: Observable<Authenticated>;
  userAddressesPath: string;
  userOrdersPath: string;
  userInfoPath: string;
  userName$: Observable<string>;

  constructor(private grange: Grange, private backend: BackendService, private sidenav: SidenavService) {}

  ngOnInit() {
    this.isAuthenticated$ = this.grange.core.auth.isAuthenticated;
    this.userName$ = this.isAuthenticated$.pipe(
      filter((auth) => auth.state),
      switchMap((auth) => this.backend.getUser$(auth.username)),
      map((user) => user.fullname || user.username),
    );
    this.userAddressesPath = this.grange.core.api.getFullPath('/@@user-addresses');
    this.userOrdersPath = this.grange.core.api.getFullPath('/@@user-orders');
    this.userInfoPath = this.grange.core.api.getFullPath('/@@user-info');
  }

  getUserName() {
    return this.grange.core.auth.getUsername();
  }

  register() {
    this.sidenav.leftSideNav.close();
    this.grange.traverser.traverse('./@@register');
  }

  login() {
    this.sidenav.leftSideNav.close();
    this.grange.traverser.traverse('./@@login');
  }

  logout() {
    this.sidenav.leftSideNav.close();
    this.grange.core.auth.logout();
  }

  shippingAddresses() {
    this.sidenav.leftSideNav.close();
    this.grange.traverser.traverse(this.userAddressesPath);
  }

  orders() {
    this.sidenav.leftSideNav.close();
    this.grange.traverser.traverse(this.userOrdersPath);
  }

  userInfo() {
    this.sidenav.leftSideNav.close();
    this.grange.traverser.traverse(this.userInfoPath);
  }
}
