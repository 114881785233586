import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Article } from '~/core/models';
import * as _ from 'lodash';
import { Grange } from 'grange';

import { AppState } from '~/reducers';
import { GroupOption, Option, ShopSearchResult } from '~/core/models';
import { SearchData } from '~/front/models';
import { RedirectService } from '~/core/redirect.service';
import { CartItemGroupOption, CartItemOption } from '~/cart/models';

export interface SelectitemData {
  article: Article;
  price: number;
  options: any;
  preselectedOption?: string; // the preselected option, is a option that subsitutes price
  shop?: ShopSearchResult;
  searchData?: SearchData;
}

@Component({
  selector: 'app-selectitem',
  templateUrl: './selectitemoptions.component.html',
  styleUrls: ['./selectitemoptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectitemoptionsComponent implements OnInit {
  isValid = false;
  shop: ShopSearchResult;
  searchData?: SearchData;
  article: Article;
  price: number;
  preselectedOption: string; // this is the preselectedOption from the article variant
  options: any;

  constructor(
    private store: Store<AppState>,
    private grange: Grange,
    private redirect: RedirectService,
    private dialogRef: MatDialogRef<SelectitemoptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectitemData,
  ) {}

  ngOnInit(): void {
    this.shop = this.data.shop;
    this.article = this.data.article;
    this.preselectedOption = this.data.preselectedOption;
    this.searchData = this.data.searchData;
    this.options = this.article.options;

    // Add default options
    const groupOptions = this.options.items;
    groupOptions.forEach((go) => {
      go.options.items.forEach((op) => {
        op.quantity = op.defaultOption || op['@id'] === this.preselectedOption ? 1 : 0;
      });
      this.updateGroupOption(go['@id']);
    });

    this.recalcPrice();
    this.validate();
  }

  getGroupOption(id: string): GroupOption {
    return _.filter(this.options.items, (go) => go['@id'] === id)[0];
  }

  updateGroupOption(goId: string) {
    const go = this.getGroupOption(goId);
    const selectedOptions = this.getSelectedOptions(goId);
    go.selectedOptions = selectedOptions.length;
    go.selectedTotalQuantity = _.reduce(selectedOptions, (result, op) => result + op.quantity, 0);
  }

  selectOption($event, goId: string, id: string) {
    $event.stopPropagation();
    const go = this.getGroupOption(goId);
    const selectedOptions = this.getSelectedOptions(goId);
    const alreadySelectedOption = _.filter(selectedOptions, (op) => op['@id'] === id).length > 0;
    let totalQuantity = _.reduce(selectedOptions, (result, op) => (result += op.quantity), 0);
    let differentQuantity = selectedOptions.length;

    const switchOption = this.isMaxMinOneOption(go);
    if (switchOption && differentQuantity > 0) {
      // remove all selected options
      selectedOptions.forEach((op) => (op.quantity = 0));
      totalQuantity = 0;
      differentQuantity = 0;
    }

    // increment quantity
    if (
      (!go.maxDifferentOptions || alreadySelectedOption || go.maxDifferentOptions > differentQuantity) &&
      (!go.maxTotalQuantity || go.maxTotalQuantity > totalQuantity)
    ) {
      go.options.items.forEach((op) => {
        if (op['@id'] === id && (!op.maxQuantity || op.quantity < op.maxQuantity)) {
          op.quantity += 1;
        }
      });

      this.updateGroupOption(goId);
      this.recalcPrice();
      this.validate();
    }
  }

  unselectOption($event, goId: string, id: string) {
    console.log('unselectOption', id);
    $event.stopPropagation();
    const go = this.getGroupOption(goId);

    go.options.items.forEach((op, idx) => {
      if (op['@id'] === id && op.quantity > 0) {
        go.options.items[idx].quantity = 0;
      }
    });

    this.updateGroupOption(goId);
    this.recalcPrice();
    this.validate();
  }

  decrementOption($event, goId: string, id: string) {
    console.log('decrementOption', id);
    $event.stopPropagation();
    const go = this.getGroupOption(goId);

    go.options.items.forEach((op) => {
      if (op['@id'] === id && op.quantity > 0) {
        op.quantity -= 1;
      }
    });

    this.updateGroupOption(goId);
    this.recalcPrice();
    this.validate();
  }

  isMaxMinOneOption(go: GroupOption): boolean {
    return go.maxTotalQuantity === 1 && (go.minDifferentOptions === 1 || go.minTotalQuantity === 1);
  }

  getSelectedOptions(goId: string): Option[] {
    const go = this.getGroupOption(goId);

    return _.reduce(
      go.options?.items || [],
      (result, op) => {
        if (op.quantity > 0) {
          result.push(op);
        }
        return result;
      },
      [],
    );
  }

  recalcPrice() {
    const groupOptions = this.options.items;
    let basePrice = this.article.price;
    let optionsPrice = 0;

    groupOptions.forEach((go) => {
      (go.options?.items || []).forEach((op) => {
        if (op.quantity > 0) {
          if (go.substitutesBasePrice && op.price) {
            basePrice = op.price * op.quantity;
          } else if (op.price) {
            optionsPrice += op.price * op.quantity;
          }
        }
      });
    });
    this.price = basePrice + optionsPrice;
  }

  validate() {
    const groupOptions = this.options.items;

    for (let i = 0; i < groupOptions.length; i++) {
      const groupOption = groupOptions[i];
      const goId = groupOption['@id'];

      const selectedOptions = this.getSelectedOptions(goId);
      const totalQuantity = _.reduce(selectedOptions, (result, op) => (result += op.quantity), 0);
      const differentQuantity = selectedOptions.length;
      if (
        differentQuantity < (groupOption.minDifferentOptions || 0) ||
        totalQuantity < (groupOption.minTotalQuantity || 0)
      ) {
        this.isValid = false;
        return;
      }
    }
    this.isValid = true;
  }

  getOptionsText(): string {
    const groupOptions = this.options.items;

    return _.join(
      _.flatten(
        _.reduce(
          groupOptions,
          (result, go) => {
            result.push(
              _.reduce(
                go.options?.items || [],
                (result2, op) => {
                  if (op.quantity === 1) {
                    result2.push(op.name);
                  } else if (op.quantity >= 2) {
                    result2.push(`${op.name} x ${op.quantity}`);
                  }
                  return result2;
                },
                [],
              ),
            );
            return result;
          },
          [],
        ),
      ),
      ', ',
    );
  }

  getOptions(): CartItemOption[] {
    const groupOptions = this.options.items;

    return _.reduce(
      groupOptions,
      (result, go) => {
        const ops = _.reduce(
          go.options?.items || [],
          (result2, op) => {
            if (op.quantity > 0) {
              result2.push({
                '@id': op['@id'],
                name: op.name,
                quantity: op.quantity,
              });
            }
            return result2;
          },
          [],
        );
        if (ops) {
          return result.concat(ops);
        }
        return result;
      },
      [],
    );
  }

  getGroupOptions(): CartItemGroupOption[] {
    const groupOptions = this.options.items;

    return _.reduce(
      groupOptions,
      (result, go) => {
        const ops: CartItemOption[] = _.reduce(
          go.options?.items || [],
          (result2, op) => {
            if (op.quantity > 0) {
              result2.push({
                '@id': op['@id'],
                name: op.name,
                quantity: op.quantity,
              });
            }
            return result2;
          },
          [],
        );
        if (ops.length > 0) {
          return result.concat({
            '@id': go['@id'],
            name: go.name,
            options: ops,
          } as CartItemGroupOption);
        } else {
          return result;
        }
      },
      [],
    );
  }

  addItem(): void {
    this.dialogRef.close({
      shop_uid: this.shop ? this.shop['@uid'] : undefined,
      id: this.article.UID,
      name: this.article.title,
      optionsText: this.getOptionsText(),
      options: this.getGroupOptions(),
      price: this.price,
      quantity: 1,
    });
  }

  isPickup(): boolean {
    return this.searchData.date && this.shop.pickup_on_date && !this.shop.delivery_on_date;
  }

  close(): void {
    this.dialogRef.close();
  }
}
