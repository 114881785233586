import { createAction, props } from '@ngrx/store';
import * as CartModel from '~/cart/models';
import { PayAction, PostedOrder } from '~/core/models';

export const addItem = createAction('[Cart] Add Item', props<{ cartItem: CartModel.CartItem }>());
export const addItemToShop = createAction(
  '[View Search Item] Add Item to shop',
  props<{ cartItem: CartModel.CartItemShop }>(),
);
export const addItemUid = createAction('[View Shop] Add Item from UID', props<{ itemUid: string }>());
export const applyCoupon = createAction('[View Checkout] Apply Coupon', props<{ coupon: string }>());
export const removeCoupon = createAction('[View Checkout] Remove Coupon');
export const couponRemoved = createAction('[View Checkout] Coupon removed');
export const checkingout = createAction('[View Checkout] Checkingout', props<{ checkingout: boolean }>());
export const checkoutInit = createAction('[View Checkout] Init checkout');
export const checkoutInitBuyerdata = createAction('[View Checkout] Init Buyerdata');
export const checkoutInitDelivery = createAction('[View Checkout] Init Delivery');
export const clearCart = createAction('[Cart] Clear cart');
export const clearCartAndCartShop = createAction('[Cart] Clear cart and cartshop');
//export  const clearDiscounts = createAction('[Cart] Clear Discounts');
export const clearShippingMethod = createAction('[Cart] Clear shipping method');
export const closeSelectOptionsDialog = createAction('[Select Options Dialog] Close Select Options Dialog');
export const decrItem = createAction('[Cart] Decrement Item', props<{ cartItem: CartModel.CartItem }>());
export const incrItem = createAction('[Cart] Increment Item', props<{ cartItem: CartModel.CartItem }>());
export const openSelectOptionsDialog = createAction(
  '[AddToCart] Open Select Options Dialog',
  props<{ article?: any; articleId?: string; articleUid?: string; preselectedOption?: string }>(),
);
export const orderPosted = createAction('[Backend API] OrderPosted', props<{ order: PostedOrder }>());
export const paymentMethodsLoaded = createAction(
  '[View Cart] Payment Methods loaded',
  props<{ paymentMethods: CartModel.PaymentMethod[] }>(),
);
export const paymentMethodsRequested = createAction('[View Cart] Payment Methods requested');
export const postOrder = createAction('[View Checkout] Post Order');
export const postOrderCancelled = createAction('[Backend API] Post Order cancelled');
//export  const setBillingData = createAction('[Cart] Set Billing Data', props<{}>());
//export  const setCardMessage = createAction('[Cart] Set Card Message', props<{}>());
//export  const setComment = createAction('[Cart] Set Comment', props<{}>());
export const selectItem = createAction(
  '[View AddtoCart] Select Item',
  props<{ articleId?: string; articleUid?: string; preselectedOption?: string }>(),
);
export const setDelivery = createAction('[View Cart] Set Delivery');
//export  const setDeliveryData = createAction('[Cart] Set Delivery Data', props<{}>());
//export  const setDeliveryDatetime = createAction('[Cart] Set Delivery datetime', props<{}>());
export const setDiscounts = createAction('[Cart] Set Discounts', props<{ discounts: CartModel.CartDiscount[] }>());
export const setPayAction = createAction('[Backend API] Set PayAction', props<{ payAction: PayAction }>());
export const setPaymentMethod = createAction(
  '[Cart] Set Payment Method',
  props<{ paymentMethod: CartModel.PaymentMethod }>(),
);
export const setPickup = createAction('[View Cart] Set Pickup');
//export  const setPickupData = createAction('[Cart] Set Pickup Data', props<{}>());
//export  const setServiceSurcharge = createAction('[Cart] Set Service Surcharge', props<{}>());
//export  const setShippingData = createAction('[Cart] Set Shipping Data', props<{}>());
export const setShippingAmount = createAction('[Cart] Set ShippingAmount', props<{ shippingAmount: number }>());
export const setShippingMethod = createAction(
  '[Cart] Set ShippingMethod',
  props<{ shippingMethod: CartModel.ShippingMethod }>(),
);
export const setShop = createAction('[Backend API] Set shop', props<{ shop: CartModel.CartShop }>());
//export  const setUser = createAction('[Cart] Set User', props<{}>());

// export class AddItem implements Action {
//   readonly type = CartActionTypes.AddItem;
//   constructor(public payload: CartModel.CartItem) { };
// }

// export class AddItemToShop implements Action {
//   readonly type = CartActionTypes.AddItemToShop;
//   constructor(public payload: CartModel.CartItemShop) { };
// }

// export class AddItemUid implements Action {
//   readonly type = CartActionTypes.AddItemUid;
//   constructor(public payload: string) { };
// }

// export class Checkingout implements Action {
//   readonly type = CartActionTypes.Checkingout;

//   constructor(public payload: boolean) { }
// }

// export class CheckoutInit implements Action {
//   readonly type = CartActionTypes.CheckoutInit;
// }

// export class CheckoutInitBuyerdata implements Action {
//   readonly type = CartActionTypes.CheckoutInitBuyerdata;
// }

// export class CheckoutInitDelivery implements Action {
//   readonly type = CartActionTypes.CheckoutInitDelivery;
// }

// export class ClearCart implements Action {
//   readonly type = CartActionTypes.ClearCart;
// }

// export class ClearCartAndCartShop implements Action {
//   readonly type = CartActionTypes.ClearCartAndCartShop;
// }

// export class ClearShippingMethod implements Action {
//   readonly type = CartActionTypes.ClearShippingMethod;
// }

// export class DecrItem implements Action {
//   readonly type = CartActionTypes.DecrItem;
//   constructor(public payload: string) { };
// }

// export class IncrItem implements Action {
//   readonly type = CartActionTypes.IncrItem;
//   constructor(public payload: string) { };
// }

// export class OrderPosted implements Action {
//   readonly type = CartActionTypes.OrderPosted;

//   constructor(public payload: { order: PostedOrder }) { }
// }

// export class PaymentMethodsRequested implements Action {
//   readonly type = CartActionTypes.PaymentMethodsRequested;
// }

// export class PaymentMethodsLoaded implements Action {
//   readonly type = CartActionTypes.PaymentMethodsLoaded;

//   constructor(public payload: { paymentMethods: CartModel.PaymentMethod[] }) { }
// }

// export class PostOrder implements Action {
//   readonly type = CartActionTypes.PostOrder;
// }

// export class PostOrderCancelled implements Action {
//   readonly type = CartActionTypes.PostOrderCancelled;
// }

// // export class SetDiscounts implements Action {
// //     readonly type = CartActionTypes.SetDiscounts;
// //     constructor(public discounts: CartModel.CartDiscount[]) {};
// // }

// // export class ClearDiscounts implements Action {
// //     readonly type = CartActionTypes.ClearDiscounts;
// // }

// // export class SetUser implements Action {
// //     readonly type = CartActionTypes.SetUser;
// //     constructor(public user: CartModel.CartUser) {};
// // }

// export class SetDelivery implements Action {
//   readonly type = CartActionTypes.SetDelivery;
// }

// // export class SetDeliveryData implements Action {
// //     readonly type = CartActionTypes.SetDeliveryData;
// //     constructor(public deliveryData: CartModel.DeliveryData) {};
// // }

// export class SetPayAction implements Action {
//   readonly type = CartActionTypes.SetPayAction;

//   constructor(public payload: PayAction) { };
// }

// export class SetPaymentMethod implements Action {
//   readonly type = CartActionTypes.SetPaymentMethod;
//   constructor(public payload: CartModel.PaymentMethod) { };
// }

// export class SetPickup implements Action {
//   readonly type = CartActionTypes.SetPickup;
// }

// // export class SetBillingData implements Action {
// //     readonly type = CartActionTypes.SetBillingData;
// //     constructor(public billingData: CartModel.BillingData) {};
// // }

// // export class SetPickupData implements Action {
// //     readonly type = CartActionTypes.SetPickupData;
// //     constructor(public pickupData: CartModel.PickupData) {};
// // }

// // export class SetShippingData implements Action {
// //     readonly type = CartActionTypes.SetShippingData;
// //     constructor(public shippingData: CartModel.ShippingData) {};
// // }

// // export class SetDeliveryDatetime implements Action {
// //     readonly type = CartActionTypes.SetDeliveryDatetime;
// //     constructor(public datetime: CartModel.DeliveryDatetime) {};
// // }

// export class SetShippingMethod implements Action {
//   readonly type = CartActionTypes.SetShippingMethod;
//   constructor(public payload: CartModel.ShippingMethod) { };
// }

// export class SetShippingAmount implements Action {
//   readonly type = CartActionTypes.SetShippingAmount;
//   constructor(public payload: number) { };
// }

// // export class SetCardMessage implements Action {
// //     readonly type = CartActionTypes.SetCardMessage;
// //     constructor(public message: string) {};
// // }

// // export class SetComment implements Action {
// //     readonly type = CartActionTypes.SetComment;
// //     constructor(public comment: string) {};
// // }

// // export class SetServiceSurcharge implements Action {
// //     readonly type = CartActionTypes.SetServiceSurcharge;
// //     constructor(public surcharge: number) {};
// // }

// export class SetShop implements Action {
//   readonly type = CartActionTypes.SetShop;
//   constructor(public payload: CartModel.CartShop) { };
// }

// export type Actions =
//   AddItem
//   | AddItemToShop
//   | AddItemUid
//   | Checkingout
//   | CheckoutInit
//   | CheckoutInitBuyerdata
//   | CheckoutInitDelivery
//   | ClearCart
//   | ClearCartAndCartShop
//   | ClearShippingMethod
//   | DecrItem
//   | IncrItem
//   | OrderPosted
//   | PaymentMethodsLoaded
//   | PaymentMethodsRequested
//   | PostOrder
//   | PostOrderCancelled
//   | SetDelivery
//   | SetPayAction
//   | SetPaymentMethod
//   | SetPickup
//   | SetShippingAmount
//   | SetShippingMethod
//   | SetShop;

//     // | SetDiscounts | ClearDiscounts
//     // | SetUser | SetDeliveryData | SetBillingData | SetDeliveryDatetime | SetShippingMethod | SetCardMessage
//     // | SetComment | SetPaymentMethod | SetServiceSurcharge;
