<div *ngIf="paymentReturnRetries$ | async as pr; else waitingForPayment">
  <ng-container *ngIf="pr.success; else noSuccess">
    <div *ngIf="pr.status === 'paid' || 'pending'">
      <div class="orderConfirmBanner orderOK">
        <div class="payment-header">
          <div>{{ 'payment.thanksOrder' | translate }}</div>
          <div><i class="far fa-check-circle"></i></div>
        </div>
        <div>
          <p [innerHTML]="pr.message"></p>
          <div>
            <div>{{ 'payment.receiveConfirmationEmail' | translate }}</div>
            <div class="spam-alert">{{ 'payment.spamAlert' | translate  }}</div>
          </div>
        </div>
      </div>
      <div class="order-data">
        <!-- Order ID -->
        <h4 class="order-number-title" role="alert" [innerHTML]=" 'payment.theOrderNumber' | translate: {orderNumber: pr.order.id} "></h4>
        <div class="order-data-wrapper">
          <!-- Buyer Data -->
          <div class="review-step-wrapper">
            <div class="review-step-title mat-step-label">{{ 'payment.buyerData' | translate }}</div>
            <div class="review-step-field">
              <div class="review-step-field-label">{{ 'payment.name' | translate }}</div>
              <div class="review-step-field-content">{{ pr.order.billingName }}</div>
            </div>
            <div class="review-step-field">
              <div class="review-step-field-label">{{ 'payment.mobilePhone' | translate }}</div>
              <div class="review-step-field-content">{{ pr.order.billingPhone }}</div>
            </div>
            <div class="review-step-field">
              <div class="review-step-field-label">{{ 'payment.email' | translate }}</div>
              <div class="review-step-field-content">{{ pr.order.billingEmail }}</div>
            </div>
          </div>
          <!-- Receiver Data -->
          <ng-container *ngIf="pr.order.isDelivery">
            <ng-container *ngIf="(cartShop$ | async).checkoutRecipientDifferent">
              <div class="review-step-wrapper">
                <div class="review-step-title mat-step-label">{{ 'payment.recipientData' | translate }}</div>
                <div class="review-step-field">
                  <div class="review-step-field-label">{{ 'payment.recipientName' | translate }}</div>
                  <div class="review-step-field-content">{{ pr.order.shippingName }}</div>
                </div>
                <div class="review-step-field">
                  <div class="review-step-field-label">{{ 'payment.reicpientMobilePhone' | translate }}</div>
                  <div class="review-step-field-content">{{ pr.order.shippingPhone }}</div>
                </div>
                <div class="review-step-field">
                  <div class="review-step-field-label">{{ 'payment.recipientEmail' | translate }}</div>
                  <div class="review-step-field-content">{{ pr.order.shippingEmail }}</div>
                </div>
                <div *ngIf="pr.order.annotations.apanymantel?.isASurprise" class="review-step-field">
                  <div class="review-step-field-label">{{ 'payment.surprise' | translate }}</div>
                </div>
              </div>
            </ng-container>
            <!-- Delivery Address -->
            <div class="review-step-wrapper">
              <div class="review-step-title mat-step-label">{{ 'payment.deliveryAddress' | translate }}</div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.company' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.shippingOrganization }}</div>
              </div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.address' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.shippingAddress }} {{ pr.order.shippingAddressComplement }}</div><br/>
                <div class="review-step-field-content">{{ pr.order.shippingPostcode }}</div>&nbsp;
                <div class="review-step-field-content">{{ pr.order.shippingCity }}</div>
                <span>&nbsp;-&nbsp;</span>
                <div class="review-step-field-content">{{ pr.order.shippingCounty }}</div>
              </div>
            </div>
          </ng-container>
          <!-- Pickup Addresss -->
          <ng-container *ngIf="pr.order.isPickup">
            <div class="review-step-wrapper">
              <div class="review-step-title mat-step-label">{{ 'payment.pickupAddress' | translate }}</div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.address' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.pickupAddressName }}</div>
              </div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.telephone' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.pickupAddressPhoneNo }}</div>
              </div>
            </div>
          </ng-container>
          <!-- Delivery Date/Time -->
          <div class="review-step-wrapper">
            <div class="review-step-title mat-step-label">{{ 'payment.deliveryDateTime' | translate }}</div>
            <div class="review-step-field">
              <div class="review-step-field-label">{{ 'payment.date' | translate }}</div>
              <div class="review-step-field-content">{{ pr.order.deliveryPickupDatetime | date }}</div>
            </div>
            <div class="review-step-field">
              <div class="review-step-field-label">{{ 'payment.time' | translate }}</div>
              <div *ngIf="pr.order.annotations.apanymantel.deliveryPickupAsap" class="review-step-field-content">
                {{ 'checkout-review.asap' | translate: {fromMinute: pr.order.annotations.apanymantel.deliveryPickupAsapFromMinute, toMinute: pr.order.annotations.apanymantel.deliveryPickupAsapToMinute} }}</div>
              <div *ngIf="!pr.order.annotations.apanymantel.deliveryPickupAsap" class="review-step-field-content">
                {{ pr.order.annotations.apanymantel.deliveryPickupTimeRange | timeRange }}</div>
            </div>
          </div>
          <!-- Giftcard personal message -->
          <ng-container *ngIf="pr.order.annotations.apanymantel.giftMessage">
            <div class="review-step-wrapper">
              <div class="review-step-title mat-step-label">{{ 'payment.giftcardPersonalMessage' | translate }}</div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.message' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.annotations.apanymantel.giftMessage }}</div>
              </div>
            </div>
          </ng-container>
          <!-- Message for the shop -->
          <ng-container *ngIf="pr.order.comment">
            <div class="review-step-wrapper">
              <div class="review-step-title mat-step-label">{{ 'payment.messageForShop' | translate }}</div>
              <div class="review-step-field">
                <div class="review-step-field-label">{{ 'payment.message' | translate }}</div>
                <div class="review-step-field-content">{{ pr.order.comment }}</div>
              </div>
            </div>
          </ng-container>
          <!-- Payment Method -->
          <div class="review-step-wrapper">
            <div class="review-step-title mat-step-label">{{ 'payment.paymentMethod' | translate }}</div>
            <div class="review-step-field">
              <div class="review-step-field-content">{{ pr.order.paymentMethodName }}</div>
            </div>
          </div>
          <!-- Shop where the order has been done -->
          <div class="review-step-wrapper">
            <div class="review-step-title mat-step-label">{{ 'payment.shopName' | translate }}</div>
            <div class="review-step-field">
              <div class="review-step-field-content">{{ pr.order.shopName }}</div>
            </div>
          </div>
        </div>
        <div class="product-table-wrapper">
          <!-- Products Ordered -->
          <div class="review-step-title mat-step-label">{{ 'payment.productsOrdered' | translate }}</div>
          <table class="products-table">
            <thead>
              <tr>
                <!-- <th>{{ 'payment.product' | translate }}</th>-->
                <th>{{ 'payment.unitPrice' | translate }}</th>
                <th>{{ 'payment.quantity' | translate }}</th>
                <th>{{ 'payment.totalAmount' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of pr.order.orderItems">
                <tr class="product-name-cell">
                  <td colspan="3">{{ item.description }}
                    <small *ngIf="item.options">
                      <div *ngFor="let groupOption of item.options">
                        <span class="groupoption">{{ groupOption.name }}: </span>
                        <span class="options" *ngFor="let option of groupOption.options">{{ option.name }} x{{ option.quantity }}, </span>
                      </div>
                    </small>
                  </td>
                </tr>
                <tr class="product-prices-cell">
                  <td>{{ item.unitPrice | currency:'EUR' }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.unitPrice * item.quantity | currency:'EUR' }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="pr.order.orderDiscounts.length > 0">
                <tr *ngFor="let discount of pr.order.orderDiscounts">
                  <td colspan="2">{{ discount.name }}</td>
                  <td>{{ -discount.amount | currency:'EUR' }}</td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="2">{{ 'payment.delivery' | translate }}</td>
                <td>{{ pr.order.shippingMethodAmount | currency:'EUR' }}</td>
              </tr>
              <tr *ngIf="pr.order.paymentMethodSurcharge">
                <td colspan="2">{{ 'payment.paymentMethodSurcharge' | translate }}</td>
                <td>{{ pr.order.paymentMethodSurcharge | currency:'EUR' }}</td>
              </tr>
              <tr class="total-row">
                <td>{{ 'payment.total' | translate }}</td>
                <td>
                  <span *ngIf="pr.status != 'pending'" style="color:green;">{{ 'payment.thisOrderIsPaid' | translate }}</span>
                  <span *ngIf="pr.status === 'pending'" style="color:red;">{{ 'payment.thisOrderMustStillBePaid' | translate }}</span>
                </td>
                <td>{{ pr.order.amount | currency:'EUR' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="new-order-footer">
        <h4>{{ 'payment.newOrder' | translate }}</h4>
        <a mat-raised-button color="warn" [traverseTo]="'.'">{{ 'payment.returnToShop' | translate }}</a>
      </div>
    </div>

  </ng-container>

  <!-- success === false -->
  <ng-template #noSuccess>
    <!-- something went wrong with the payment -->
    <ng-container *ngIf="pr.status === 'declined'">
      <div class="orderConfirmBanner orderNOK">
        <div class="payment-header">
          <div>{{ 'payment.paymentFailed' | translate }}</div>
          <div class="icon"><i class="far fa-times-circle"></i></div>
        </div>
        <p [innerHTML]="pr.message"></p>
      </div>
      <div class="new-order-footer">
        <h4>{{ 'payment.retryPayment' | translate }}</h4>
        <a mat-raised-button color="warn" [traverseTo]="'.'">{{ 'payment.returnToShop' | translate }}</a>
      </div>
    </ng-container>

    <!-- still waiting, but tiemout reached -->
    <ng-container *ngIf="pr.status === 'waiting'">
      <div class="orderConfirmBanner orderNoAnswer">
        <div class="payment-header">
          <div>
            <h2 class="no-answer"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
              {{ 'payment.confirmationNotReceived' | translate }}</h2>
          </div>
          <div class="icon"><i class="fas fa-info-circle"></i></div>
        </div>
        <div [innerHTML]="paymentErrorMessage"></div>
      </div>
      <div class="new-order-footer">
        <h4>{{ 'payment.newOrder' | translate }}</h4>
        <a mat-raised-button color="warn" [traverseTo]="'.'">{{ 'payment.returnToShop' | translate }}</a>
      </div>
    </ng-container>
  </ng-template>
</div>

<!-- waiting -->
<ng-template #waitingForPayment>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
    Waiting for payment...
  </div>
</ng-template>
