<div class="header-wrapper">
  <div *ngIf="shop.shop_picture" class="shop-pic" (click)="openZoom(shop.title, shop.shop_picture.scales.large.download)">
    <!-- shop picture -->
    <img [src]="shop.shop_picture.scales.mini.download" />
    <span class="zoom-in"><i class="fas fa-search-plus"></i></span>
  </div>
  <div>
    <!-- shop name -->
    <h1 class="shop-name">{{ shop.title }}</h1>
    <button class="more-info-btn" mat-icon-button color="warn" aria-label="info"
            (click)="openDetailedInfo()"
            matTooltip="+ Info">
      <mat-icon class="material-icons-outlined">info</mat-icon>
    </button>
    <!-- Beginning new data -->
    <div class="shop-header-extra-info">
      <ng-container *ngIf="shop.show_ratings">
        <!-- shop ratings -->
        <div class="no-ratings-available" *ngIf="shop.rating_quantity === 0">
          <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
          <span>{{ 'shopresult.newShop' | translate }}</span>
          <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
        </div>
        <div class="header-main-definition shop-rating" *ngIf="shop.rating_quantity > 0">
          <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
          <app-stars [rating]="shop.stars"
                     maxRating="10"
                     [quantityRatings]="shop.rating_quantity"
                     [compact]="true"
                     [word]="shop['@components'].ratings['word']"
                     [path]="shop['@components'].ratings['@id']"></app-stars>
        </div>
      </ng-container>
      <!-- shipping costs -->
      <div class="header-main-definition shipping-cost" *ngIf="shopInfo$ | async as shopInfo">
        <mat-icon aria-hidden="false" aria-label="delivery">delivery_dining</mat-icon>
        <div class="delivery-col">
          <div>
            <span *ngIf="shopInfo.minmax_shipping_fee[0] === shopInfo.minmax_shipping_fee[1]">
              {{ shopInfo.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2' }}
            </span>
            <span *ngIf="shopInfo.minmax_shipping_fee[0] !== shopInfo.minmax_shipping_fee[1]">
              {{ 'shopresult.startingFrom' | translate: {shippingFee: shopInfo.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2'} }}
            </span>
          </div>
          <div class="free-delivery-offer" *ngIf="shop.minimum_order_without_shipping_costs">
            {{ 'shopresult.moreThanXFree' | translate: {minAmount: shop.minimum_amount_without_shipping_costs | apymCurrency:'€':'symbol':'1.2-2'} }}
          </div>
        </div>
      </div>
      <!-- Minimum Delivery Price -->
      <div class="header-main-definition">
        <mat-icon aria-hidden="false" aria-label="euro symbol">euro_symbol</mat-icon>
        <div>
          Min. <span *ngIf="shop.minimum_amount_delivery_order">{{ shop.minimum_amount_delivery_order | apymCurrency:'€':'symbol':'1.2-2' }}</span>
        </div>
      </div>
      <!-- Minimum Delivery Time -->
      <div class="header-main-definition">
        <mat-icon aria-hidden="false" aria-label="schedule">schedule</mat-icon>
        <div *ngIf="shopInfo$ | async as shopInfo">
          {{ shopInfo.minimum_time_string }}
        </div>
      </div>
    </div>
    <!-- END new data -->
  </div>

</div>
