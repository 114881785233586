import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Grange } from 'grange';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from '~/reducers';
import * as _ from 'lodash';
import {
  fromUser,
  selectAddressEditForm,
  selectAllAddresses,
  selectCanAdd,
  selectEditingAddress,
  selectLoading,
  UserActionTypes,
} from '~/user/store';
import { UserShippingAddress } from '~/core/models';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '~/shared/confirmation-dialog/confirmation-dialog.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BackendService } from '~/core/backend.service';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.component.html',
  styleUrls: ['./user-addresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAddressesComponent extends BaseViewComponent implements OnInit {
  userShippingAddresses$: Observable<UserShippingAddress[]>;
  canAdd$: Observable<boolean>;
  editingAddress$: Observable<boolean>;
  loading$: Observable<boolean>;
  addresses$: Observable<UserShippingAddress[]>;
  editFormState$: Observable<FormGroupState<fromUser.AddressEditFormValue>>;
  showSearchGooglePlaces$: Observable<boolean>;
  useGooglePlaces = true;
  placesOptions = {
    types: [], //['geocode'],
    componentRestrictions: { country: 'ES' },
  };
  googleAddressRouteError = false;
  @ViewChild('removeConfirmation') private removeConfirmation: ElementRef;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  constructor(
    public grange: Grange,
    public seo: SeoService,
    public store: Store<AppState>,
    private backend: BackendService,
    public dialog: MatDialog
  ) {
    super(grange, seo);
    this.grange.core.auth.isAuthenticated.pipe(takeUntil(this.destroy)).subscribe((auth) => {
      if (!auth.state) {
        this.grange.traverser.traverse('/@@login');
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loading$ = this.store.pipe(select(selectLoading));

    this.editFormState$ = this.store.pipe(select(selectAddressEditForm));

    this.editingAddress$ = this.store.pipe(select(selectEditingAddress));

    this.showSearchGooglePlaces$ = combineLatest(this.editingAddress$, this.editFormState$).pipe(
      map(([editingAddress, editFormState]) => editingAddress && editFormState.value.address === ''),
    );

    this.addresses$ = this.store.pipe(select(selectAllAddresses));

    this.canAdd$ = this.store.pipe(select(selectCanAdd));

    this.store.dispatch(new UserActionTypes.AddressesRequested());
  }

  edit(editingAddress: UserShippingAddress): void {
    this.store.dispatch(new UserActionTypes.EditAddress(editingAddress));
  }

  cancel(): void {
    this.store.dispatch(new UserActionTypes.EditAddressCancelled());
  }

  onSubmit(): void {
    this.store.dispatch(new UserActionTypes.SaveAddress());
  }

  getEntityName(uid, entities): string {
    const entity = entities.filter((e) => e.uid === uid);
    if (entity.length > 0) {
      return entity[0].name;
    }
  }

  remove(address: UserShippingAddress): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '350px',
      data:
        `<div class="delete-content-wrapper"><b>` +
        this.removeConfirmation.nativeElement.textContent +
        `</b>` +
        `<div class="delete-name">${address.addressName}</div><div>${address.organization}</div><div>${address.address}</div><div>${address.postCode.name} -  ${address.city.name}</div><div>${address.county.name} -  ${address.country.name}</div></div>`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new UserActionTypes.RemoveAddress(address.id));
      }
    });
  }

  add(): void {
    this.store.dispatch(
      new UserActionTypes.EditAddress({
        id: null,
        addressName: '',
        organization: '',
        latitude: '',
        longitude: '',
        address: '',
        addressComplement: '',
        city: null,
        postCode: null,
        county: null,
        country: null,
      }),
    );
  }

  setStreetNumber(streetNumber: string): void {
    this.store.dispatch(new SetValueAction('addresseditform.addressStreetNumber', streetNumber));
  }

  setStreetNumberWithoutNumber(): void {
    this.store.dispatch(new SetValueAction('addresseditform.addressStreetNumber', 's/n'));
  }

  handleAddressChange(googleAddress: Address, address: fromUser.AddressEditFormValue): void {
    //console.log('address returned by google', googleAddress);
    this.googleAddressRouteError = false;
    const addressTmp: any = _.reduce(
      googleAddress.address_components,
      (add, add_comp) => {
        if (add_comp.types.includes('route')) {
          return { ...add, route: add_comp.long_name };
        } else if (add_comp.types.includes('street_number')) {
          return { ...add, street_number: add_comp.long_name };
        } else if (add_comp.types.includes('postal_code')) {
          return { ...add, postal_code: add_comp.long_name };
        } else if (add_comp.types.includes('locality')) {
          return { ...add, locality: add_comp.long_name };
        } else if (add_comp.types.includes('administrative_area_level_2')) {
          return { ...add, administrative_area_level_2: add_comp.long_name };
        } else if (add_comp.types.includes('country')) {
          return { ...add, country: add_comp.long_name };
        }
        return add;
      },
      {},
    );

    const latitude = googleAddress.geometry.location.lat();
    const longitude = googleAddress.geometry.location.lng();

    if (
      !_.has(addressTmp, 'route') ||
      !_.has(addressTmp, 'postal_code') ||
      // !_.has(addressTmp, 'street_number') ||
      !addressTmp
    ) {
      this.googleAddressRouteError = true;
      return;
    }

    this.store.dispatch(new SetValueAction('addresseditform.latitude', latitude));
    this.store.dispatch(new SetValueAction('addresseditform.longitude', longitude));
    this.store.dispatch(new SetValueAction('addresseditform.address', addressTmp.route));
    this.store.dispatch(new SetValueAction('addresseditform.addressStreetNumber', addressTmp.street_number));

    this.backend
      .resolveEntities(
        addressTmp.postal_code,
        addressTmp.locality,
        addressTmp.administrative_area_level_2,
        addressTmp.country,
      )
      .subscribe((entities) => {
        if (!_.isEmpty(entities)) {
          console.log('entities for this address', entities);
          this.store.dispatch(new SetValueAction('addresseditform.country', entities.country.uid));
          this.store.dispatch(new SetValueAction('addresseditform.county', entities.county.uid));
          this.store.dispatch(new SetValueAction('addresseditform.city', entities.city.uid));
          this.store.dispatch(new SetValueAction('addresseditform.postCode', entities.postcode.uid));
          // this.store.dispatch(
          //   new UserActionTypes.EditAddress({
          //     id: address.id,
          //     addressName: address.addressName,
          //     organization: address.organization,
          //     latitude: `${latitude}`,
          //     longitude: `${longitude}`,
          //     address: `${addressTmp.route} ${addressTmp.street_number}`,
          //     addressComplement: address.addressComplement,
          //     city: entities.city.uid,
          //     postCode: entities.postcode.uid,
          //     county: entities.county.uid,
          //     country: entities.country.uid,
          //   }),
          // );
        }
      });
  }

  clearShippingAddress(address: fromUser.AddressEditFormValue): void {
    this.store.dispatch(
      new UserActionTypes.EditAddress({
        id: address.id,
        addressName: address.addressName,
        organization: address.organization,
        latitude: '',
        longitude: '',
        address: '',
        addressComplement: '',
        city: null,
        postCode: null,
        county: null,
        country: null,
      }),
    );
  }
}
