import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Shop } from '~/front/models';
import { ShopActionTypes } from '~/shop/store';

export interface ShopSearchState extends EntityState<Shop> {
  loading: boolean;
}

export const adapter: EntityAdapter<Shop> = createEntityAdapter<Shop>({});

const initialState: ShopSearchState = adapter.getInitialState({
  loading: false,
});

export const shopSearchReducer = createReducer(
  initialState,
  on(ShopActionTypes.loadShop, (state) => adapter.removeAll({ ...state, loading: true })),
);

export function reducer(state: ShopSearchState | undefined, action: Action): ShopSearchState {
  return shopSearchReducer(state, action);
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
