<div>
  <fieldset>
    <mat-form-field class="required full-width-form-field">
      <input matInput
             type="text"
             maxlength="50"
             [ngrxFormControlState]="formGroup.controls.billingName"
             placeholder="{{ 'checkout-buyerdata.nameAndSurname' | translate }}"
             required />
    </mat-form-field>

    <!-- <mat-form-field class="required full-width-form-field"> -->
    <!--     <input matInput -->
    <!--            type="tel" -->
    <!--            maxlength="20" -->
    <!--            [ngrxFormControlState]="formGroup.controls.billingPhone" -->
    <!--            placeholder="{{ 'checkout-buyerdata.mobilePhone' | translate }}" -->
    <!--            required /> -->
    <!-- </mat-form-field> -->

    <mat-form-field class="required full-width-form-field phone-input">
      <input matInput
             type="tel"
             placeholder="{{ 'checkout-buyerdata.mobilePhone' | translate }}"
             #billingPhone
             required />
      <div>
        <small *ngIf="formGroup.controls.billingPhone.errors?.invalidNumber && formGroup.controls.billingPhone.isDirty"
               class="text-danger">
          {{ 'checkout-buyerdata.invalidNumber' | translate }}</small>
        <small *ngIf="formGroup.controls.billingPhone.errors?.invalidCountryCode && formGroup.controls.billingPhone.isDirty"
               class="text-danger">
          {{ 'checkout-buyerdata.invalidCountryCode' | translate }}</small>
        <small *ngIf="formGroup.controls.billingPhone.errors?.tooShort && formGroup.controls.billingPhone.isDirty"
               class="text-danger">
          {{ 'checkout-buyerdata.tooShort' | translate }}</small>
        <small *ngIf="formGroup.controls.billingPhone.errors?.tooLong && formGroup.controls.billingPhone.isDirty"
               class="text-danger">
          {{ 'checkout-buyerdata.tooLong' | translate }}</small>
        <small *ngIf="formGroup.controls.billingPhone.errors?.unknownError && formGroup.controls.billingPhone.isDirty"
               class="text-danger">
          {{ 'checkout-buyerdata.unknownError' | translate }}</small>
      </div>
    </mat-form-field>

    <mat-form-field class="required full-width-form-field">
      <input matInput
             type="email"
             maxlength="50"
             [ngrxFormControlState]="formGroup.controls.billingEmail"
             placeholder="{{ 'checkout-buyerdata.email' | translate }}"
             required />
      <small [hidden]="true" class="text-danger">{{ 'checkout-buyerdata.emailIsRequired' | translate }}</small>
      <small *ngIf="formGroup.controls.billingEmail.errors?.email && formGroup.controls.billingEmail.isDirty"
             class="text-danger">
        {{ 'checkout-buyerdata.notAValidEmail' | translate }}</small>
    </mat-form-field>

    <mat-form-field class="required full-width-form-field">
      <input matInput
             type="email"
             maxlength="50"
             [ngrxFormControlState]="formGroup.controls.confirmBillingEmail"
             placeholder="{{ 'checkout-buyerdata.repeatEmail' | translate }}"
             required />
      <small *ngIf="formGroup.controls.confirmBillingEmail.errors?.equalTo && formGroup.controls.confirmBillingEmail.isDirty"
             class="text-danger">{{ 'checkout-buyerdata.emailMismatch' | translate }}</small>
    </mat-form-field>

  </fieldset>

</div>
