import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Grange } from 'grange';
import { filter, map, shareReplay, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AppState } from '~/reducers';
import { BaseViewComponent } from '~/shared/base/base.component';
import {
  selectAllCategories,
  selectCategoriesLoading,
  selectShop,
  selectShopDeliveryDates,
  selectShopFilterData,
  selectShopPickupDates,
  selectShopShopSchema,
  ShopActionTypes,
} from '~/shop/store';
import { Category, FilterData } from '~/shop/models';
import { SeoService } from '~/core/seo.service';
import { CartActionTypes } from '~/cart/store';
import { SearchData, EntityType } from '~/front/models';
import { selectSearchData } from '~/front/store';
import { AvailabilityDatetimeText, deliveryOrPickup, Shop } from '~/core/models';

@Component({
  selector: 'app-shop',
  templateUrl: './shop-categories.component.html',
  styleUrls: ['./shop-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCategoriesComponent extends BaseViewComponent implements OnInit {
  pickupDatetime$: Observable<AvailabilityDatetimeText>;
  deliveryDatetime$: Observable<AvailabilityDatetimeText>;
  filterData$: Observable<FilterData>;
  categories$: Observable<Category[]>;
  cateoryFilter$: Observable<Category>;
  loading$: Observable<boolean>;
  shopSchema$: Observable<any>;
  searchDataAndFilterData$: Observable<[SearchData, FilterData]>;
  searchEntityUid$: Observable<string | null>;
  shopPostCodeChain$: Observable<string[]>;

  constructor(public grange: Grange, public seo: SeoService, public store: Store<AppState>) {
    super(grange, seo);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(CartActionTypes.checkingout({ checkingout: false }));
    this.loading$ = this.store.pipe(select(selectCategoriesLoading));
    this.categories$ = this.store.pipe(select(selectAllCategories));

    this.shopSchema$ = this.store.pipe(select(selectShopShopSchema));

    this.store
      .pipe(
        select(selectShop),
        takeUntil(this.destroy),
        withLatestFrom(this.context),
        filter(([shop, context]) => !shop || shop['@id'] !== context['@id']),
        map(([_shop, context]) => context),
      )
      .subscribe((context) => {
        this.store.dispatch(ShopActionTypes.loadShop({ shop: context as Shop }));
      });

    this.searchDataAndFilterData$ = this.grange.traverser.target.pipe(
      take(1),
      switchMap((target) => {
        // get searchData from querystring or from store
        const query: HttpParams = target.query;
        const entity_uid = query.get('eu');
        const entity_type = query.get('et');
        const entity_caption = query.get('ec');
        const pickup = query.get('pu');
        if (entity_uid) {
          const entity = { uid: entity_uid, type: EntityType[entity_type], caption: entity_caption };
          const date = query.get('dt') ? moment(query.get('dt')) : null;
          const articleUid = query.get('it');
          const preselectedOption = query.get('po');
          if (articleUid) {
            this.store.dispatch(CartActionTypes.selectItem({ articleUid, preselectedOption }));
          }
          this.grange.traverser.traverse(target.context['@id']);
          return of({ entity, date, pickup: pickup === '1' });
        } else {
          return this.store.pipe(select(selectSearchData), take(1));
        }
      }),
      withLatestFrom(this.store.pipe(select(selectShopFilterData))),
      shareReplay(1),
    );

    this.searchDataAndFilterData$
      .pipe(
        switchMap(([searchData, filterData]: [SearchData, FilterData]) => {
          if (searchData?.pickup) {
            return this.store.select(selectShopPickupDates).pipe(map((dates) => [searchData, filterData, dates]));
            // } else if (searchData.delivery) {
            //   const selectDates = selectShopDeliveryDates;
          } else {
            return this.store.select(selectShopDeliveryDates).pipe(map((dates) => [searchData, filterData, dates]));
          }
        }),
      )
      .subscribe(([searchData, filterData, dates]: [SearchData, FilterData, moment.Moment[]]) => {
        if (searchData) {
          if (!filterData.pickupOrDelivery) {
            if (searchData.pickup) {
              this.store.dispatch(
                ShopActionTypes.setFilterPickupOrDelivery({ deliveryOrPickup: deliveryOrPickup.PICKUP }),
              );
            } else {
              this.store.dispatch(
                ShopActionTypes.setFilterPickupOrDelivery({ deliveryOrPickup: deliveryOrPickup.DELIVERY }),
              );
              if (searchData.entity && searchData.entity.type === EntityType.postCode) {
                const postCode = { uid: searchData.entity.uid, caption: searchData.entity.caption };
                this.store.dispatch(ShopActionTypes.setFilterPostCode({ filterEntity: postCode }));
              }
            }
          }
          if (searchData.date && !filterData.date && dates && _.some(dates, (v) => v.isSame(searchData.date))) {
            this.store.dispatch(ShopActionTypes.setFilterDate({ date: searchData.date }));
          }
          //if (searchData.item) {
          //console.log('shop-categories.component additem', searchData, filterData, dates);
          //          this.store.dispatch(CartActionTypes.addItemUid({ itemUid: searchData.item }));
          //        }
        }
      });

    this.searchEntityUid$ = this.searchDataAndFilterData$.pipe(
      map(([searchData, filterData]) =>
        searchData && searchData.entity && !filterData.pickupOrDelivery && !filterData.date
          ? searchData.entity.uid
          : null,
      ),
    );
  }

  selectCategory(categoryId: string): void {
    this.store.dispatch(ShopActionTypes.selectCategory({ category: categoryId }));
    this.grange.traverser.traverse('./@@articles?category=' + categoryId);
  }
}
