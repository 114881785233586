<ng-container *ngIf="rating">
  <div *ngIf="compact; else notcompactview" class="compact-ratings-wrapper">
    <span><b>{{ rating | number:'1.00-2' }}</b> / 10</span>
    <span *ngIf="quantityRatings"><a class="apym-link" (click)="openRatings($event)">({{ quantityRatings }})</a></span>
  </div>
</ng-container>
<ng-template #notcompactview>
  <div class="ratings-wrapper">
    <div class="rating-col">
      <div class="stars-wrapper">
        <div class="stars-result" [ngStyle]="{width: rating * 10 + '%'}"></div>
        <div class="stars-background"></div>
      </div>
      <div class="rating-word">{{ word }}</div>
    </div>
    <div class="rating-col">
      <mat-card class="rating">{{ rating | number:'1.0-2' }} / {{ maxRating }}</mat-card>
      <small *ngIf="quantityRatings"><a class="opinions apym-link" (click)="openRatings($event)">{{ 'stars.numberOpinions' | translate: {amountOpinions:quantityRatings} }}</a></small>
    </div>
  </div>
</ng-template>
