import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private meta: Meta, public title: Title) {}

  generateTags(config: any) {
    config = {
      title: 'Apanymantel',
      description: '',
      slug: '',
      ...config,
    };

    this.title.setTitle(config.title);
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'keywords', content: config.keywords });
  }
}
