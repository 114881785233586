<pa-input-icon iconName="event"
               [class.pa-date-error]="!isValidDate && dateInput !== ''"
               (iconClick)="iconClick($event)">
               <pa-input [(ngModel)]="dateInput"
                         [placeholder]="datePlaceholder"
                         [debounceDuration]="1000"
                         (valueChange)="checkTypedDate($event)"
                         [class.pa-field-label-error]="!isValidDate && dateInput !== ''"
                         [maxCharacters]="10"
                         [id]="id"
                         [errorMessage]="!isValidDate && dateInput !== '' ? errorMessage : ''"
                         [help]="help"
                         [accent]="_accent"
                         [isLessen]="_isLessen">
                         <ng-content></ng-content>
               </pa-input>
</pa-input-icon>
<span [hidden]="true"
      #datePickerPopup="paPopupRef"
      [paPopup]="datePicker"
      [popupPosition]="datePickerPosition"></span>
<pa-date-picker #datePicker 
                [selection]="currentDate"
                (select)="selectDate($event)"
                [noFuture]="_noFuture"
                [min]="minDate"></pa-date-picker>
