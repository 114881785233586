import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CartEffects } from './store/cart.effects';
import { fromCart } from './store';
import { AddtocartComponent } from './addtocart/addtocart.component';
import { CartComponent } from './cart/cart.component';
import { CarticonComponent } from './carticon/carticon.component';
import { CheckoutBuyerdataComponent } from './checkout/checkout-buyerdata/checkout-buyerdata.component';
import { CheckoutCardmessageComponent } from './checkout/checkout-cardmessage/checkout-cardmessage.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutDeliveryComponent } from './checkout/checkout-delivery/checkout-delivery.component';
import { CheckoutDeliverydatetimeComponent } from './checkout/checkout-deliverydatetime/checkout-deliverydatetime.component';
import { CheckoutMessageforshopComponent } from './checkout/checkout-messageforshop/checkout-messageforshop.component';
import { CheckoutPaymentComponent } from './checkout/checkout-payment/checkout-payment.component';
import { CheckoutPickupComponent } from './checkout/checkout-pickup/checkout-pickup.component';
import { CheckoutRecipientdataComponent } from './checkout/checkout-recipientdata/checkout-recipientdata.component';
import { CheckoutRegisterComponent } from './checkout/checkout-register/checkout-register.component';
import { CheckoutReviewComponent } from './checkout/checkout-review/checkout-review.component';
import { CouponDiscountComponent } from './coupon-discount/coupon-discount.component';
import { PaybuttonComponent } from './paybutton/paybutton.component';
import { PaymentComponent } from './payment/payment.component';
import { NgrxFormsModule } from 'ngrx-forms';
import { SharedModule } from '~/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AddtocartBottomsheetComponent } from './addtocart/addtocart-bottomsheet/addtocart-bottomsheet.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SelectitemoptionsComponent } from './addtocart/selectitemoptions/selectitemoptions.component';

@NgModule({
  declarations: [
    AddtocartBottomsheetComponent,
    AddtocartComponent,
    CartComponent,
    CarticonComponent,
    CheckoutBuyerdataComponent,
    CheckoutCardmessageComponent,
    CheckoutComponent,
    CheckoutDeliveryComponent,
    CheckoutDeliverydatetimeComponent,
    CheckoutMessageforshopComponent,
    CheckoutPaymentComponent,
    CheckoutPickupComponent,
    CheckoutRecipientdataComponent,
    CheckoutRegisterComponent,
    CheckoutReviewComponent,
    CouponDiscountComponent,
    PaybuttonComponent,
    PaymentComponent,
    SelectitemoptionsComponent,
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CartEffects]),
    GooglePlaceModule,
    NgrxFormsModule,
    SharedModule,
    StoreModule.forFeature('cart', fromCart.reducer),
    TranslateModule.forChild(),
  ],
  exports: [AddtocartComponent, CartComponent, CarticonComponent],
  entryComponents: [CheckoutComponent, PaymentComponent],
})
export class CartModule {}
