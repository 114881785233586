/*
 * honeypot
 *
 * - set type="text" or type="email"
 * - use common name attribute like email, phone, address, name,...
 * - don't use type="hidden"
 * - don't use name="honeypot" or similar
 * - don't use display:none
 * - don't use hidden in class name
 * - wrap inputs and its labels in a div tag and position it outside the viewport
 * - set tabindex="-1" in order to no be focusable
 * - set autocomplete="nope" to avoid browser autocomplete
 * - don't raise error if a bot is detected
 *
 * */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '~/reducers';

import {
  fromUser,
  selectLoading,
  selectRegisterUserForm,
  selectRegisterUserRegisterOk,
  selectRegisterUserError,
  UserActionTypes,
} from '~/user/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterForm } from '~/user/models';
import { BackendService } from '~/core/backend.service';
import { RegisteredUser } from '~/core/models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  loading$: Observable<boolean>;
  registerFormState$: Observable<FormGroupState<fromUser.RegisterUserFormValue>>;
  registered$: Observable<RegisteredUser>;
  registerError$: Observable<fromUser.RegisterUserError>;
  formGroup: FormGroup;
  siteKey$: Observable<string>;

  constructor(
    private backend: BackendService,
    public store: Store<AppState>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new UserActionTypes.RegisterUserClear);

    this.loading$ = this.store.pipe(select(selectLoading));

    this.registerFormState$ = this.store.pipe(select(selectRegisterUserForm));

    this.registered$ = this.store.pipe(select(selectRegisterUserRegisterOk));

    this.registerError$ = this.store.pipe(select(selectRegisterUserError));

    this.siteKey$ = this.backend.getCaptchaWebsiteKey$();

    this.formGroup = this.fb.group({
      name: '',
      email: '',
      vfrfullname: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      vfremail: ['', Validators.compose([Validators.email, Validators.required, Validators.maxLength(100)])],
      sendNewsletter: true,
      acceptPolicy: [false, Validators.requiredTrue],
    });
  }

  get vfrfullname() {
    return this.formGroup.get('vfrfullname');
  }
  get vfremail() {
    return this.formGroup.get('vfremail');
  }
  get sendNewsletter() {
    return this.formGroup.get('sendNewsletter');
  }
  get name() {
    return this.formGroup.get('name');
  }
  get email() {
    return this.formGroup.get('email');
  }

  handleSuccess($event) {}
  handleExpire() {}
  handleLoad() {}
  handleReset() {}

  cancelRegister() {}

  onSubmitRegister() {
    if (this.name.value || this.email.value) {
      // honeypot
      location.reload();
    } else {
      console.log('submit', this.vfremail.value);
      this.store.dispatch(
        new UserActionTypes.RegisterUserRequest({
          fullname: this.vfrfullname.value,
          email: this.vfremail.value,
          sendNewsletter: this.sendNewsletter.value,
        } as RegisterForm),
      );
    }
  }
}
