import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { CartActionTypes, fromCart } from '~/cart/store';
import { AppState } from '~/reducers';
import { selectCheckoutBuyerData } from '~/cart/store';
import { ngIntlTelInput } from '~/cart/checkout/ng-intl-tel-input';

@Component({
  selector: 'app-checkout-buyerdata',
  templateUrl: './checkout-buyerdata.component.html',
  styleUrls: ['./checkout-buyerdata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBuyerdataComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroupState<fromCart.CheckoutBuyerDataForm>;
  @ViewChild('billingPhone') billingPhone: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(CartActionTypes.checkoutInitBuyerdata());
  }

  ngAfterViewInit() {
    if (this.billingPhone) {
      this.subscriptions.push(
        ngIntlTelInput(
          this.billingPhone,
          'CheckoutForm.buyerData.billingPhone',
          this.store,
          this.store.select(selectCheckoutBuyerData).pipe(map((buyerData) => buyerData.billingPhone)),
        ),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
