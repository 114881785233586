// https://robinraju.io/angular-loading-application-settings-from-external-file/
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class AppJsonConfig {
  private config: any = null;
  private env: any = null;

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any, defaultValue?: any): any {
    if (defaultValue !== undefined && !Object.prototype.hasOwnProperty.call(this.config, key)) {
      return defaultValue;
    } else {
      return this.config[key];
    }
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  public load() {
    return new Promise((resolve, _reject) => {
      this.http
        .get('/assets/local/env.' + window.location.hostname + '.json')
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.error.message);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
            }
            //console.log(`Configuration file "env.${window.location.hostname}.json" could not be read`);
            resolve(true);
            return EMPTY;
            //return Observable.throw(error.json().error || 'Server error');
          }),
        )
        .subscribe((responseData: any) => {
          this.config = responseData;
          resolve(true);
        });
    });
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  /*
  public load2() {
    return new Promise((resolve, reject) => {
      this.http.get('./assets/env/env.json').map((res: any) => res.json() ).catch((error: any): any => {
        console.log('Configuration file "env.json" could not be read');
        resolve(true);
        return Observable.throw(error.json().error || 'Server error');
      }).subscribe( (envResponse) => {
        this.env = envResponse;
        let request: any = null;

        switch (envResponse.env) {
          case 'production': {
            request = this.http.get('./assets/env/env.' + envResponse.env + '.json');
          } break;

          case 'development': {
            request = this.http.get('./assets/env/env.' + envResponse.env + '.json');
          } break;

          case 'default': {
            console.error('Environment file is not set or invalid');
            resolve(true);
          } break;
        }

        if (request) {
          request
            .map( res => res.json() )
            .catch((error: any) => {
              console.error('Error reading ' + envResponse.env + ' configuration file');
              resolve(error);
              return Observable.throw(error.json().error || 'Server error');
            })
            .subscribe((responseData: any) => {
              this.config = responseData;
              resolve(true);
            });
        } else {
          console.error('Env config file "env.json" is not valid');
          resolve(true);
        }
      });

    });
  }
*/
}
