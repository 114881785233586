import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Grange } from 'grange';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '~/reducers';

import {
  fromUser,
  selectLoading,
  selectPasswordResetForm,
  selectPasswordResetResetted,
  UserActionTypes,
} from '~/user/store';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetComponent implements OnInit {
  params$: Observable<{ key: string; username: string }>;
  loading$: Observable<boolean>;
  passwordResetFormState$: Observable<FormGroupState<fromUser.PasswordResetFormValue>>;
  passwordResetted$: Observable<fromUser.PasswordResettedType>;
  prt = fromUser.PasswordResettedType;

  constructor(public grange: Grange, public store: Store<AppState>) {}

  ngOnInit(): void {
    this.params$ = this.grange.traverser.target.pipe(
      map((target) => {
        const query: HttpParams = target.query;
        return {
          key: query.get('key'),
          username: query.get('username'),
        };
      }),
    );

    this.params$.subscribe((params) => {
      this.store.dispatch(new SetValueAction('passwordresetform.email', params.username));
      this.store.dispatch(new SetValueAction('passwordresetform.randomString', params.key));
    });

    this.loading$ = this.store.pipe(select(selectLoading));

    this.passwordResetFormState$ = this.store.pipe(select(selectPasswordResetForm));

    this.passwordResetted$ = this.store.pipe(select(selectPasswordResetResetted));
  }

  onCancelPasswordReset() {}

  onSubmitPasswordReset() {
    this.store.dispatch(new UserActionTypes.PasswordResetRequest());
  }
}
