import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Timetable } from '~/shared/models';

@Component({
  selector: 'apym-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimetableComponent {
  @Input() timetable: Timetable[];
  @Input() caption: string;
}
