import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { LoginInfo } from '@guillotinaweb/grange-core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  error = '';
  isLogged: boolean;
  loading: boolean;

  constructor(public grange: Grange, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    setTimeout(() =>
      this.grange.core.auth.isAuthenticated.subscribe((auth) => {
        this.isLogged = auth.state;
        if (auth.state) {
          this.grange.traverser.traverse(this.grange.traverser.target.getValue().contextPath);
        }
      }),
    );
  }

  onSubmit(data: LoginInfo) {
    this.loading = true;
    this.cd.detectChanges();
    this.grange.core.auth.login(data.login, data.password).subscribe(
      (result) => {
        console.log('login', result);
        this.isLogged = true;
        this.loading = false;
        this.grange.traverser.traverse(this.grange.traverser.target.getValue().contextPath);
        this.error = '';
      },
      (error: Error) => {
        this.loading = false;
        console.log('onsubmit', error.message);
        this.error = error.message;
        this.cd.detectChanges();
      },
    );
  }
}
