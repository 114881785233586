import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ZoomArticleData {
  title: string;
  url: string;
}

@Component({
  selector: 'zoom-article',
  templateUrl: 'zoom-article-dialog.html',
})
export class ZoomArticleDialog {
  constructor(
    private dialogRef: MatDialogRef<ZoomArticleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ZoomArticleData,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
