<mat-form-field class="full-width-form-field">
    <textarea matInput
              placeholder="{{ 'checkout-cardmessage.cardMessage' | translate }}"
              class="textarea-widget text-field"
              [ngrxFormControlState]="formGroup.controls.cardMessage"
              maxlength="200"
              rows="4">
    </textarea>
    <mat-hint align="end">{{ 'checkout-cardmessage.charactersLeft' | translate : {messageLength: 200 - (formGroup.value.cardMessage.length || 0)} }}
    </mat-hint>
</mat-form-field>
<div><small class="help">{{ 'checkout-cardmessage.giftcardHelp' | translate }}</small></div>
