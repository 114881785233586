import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RatingsStats } from '~/core/models';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'apym-ratingstats',
  templateUrl: './ratingstats.component.html',
  styleUrls: ['./ratingstats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingstatsComponent implements OnInit {
  ratingsStats$: Observable<RatingsStats>;

  constructor(private backend: BackendService) {}

  ngOnInit() {
    this.ratingsStats$ = this.backend.ratingsStats$();
  }
}
