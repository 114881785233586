import { Component, Inject, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  FormGroupState,
  NgrxValueConverters,
  NgrxValueConverter,
  ResetAction,
  SetUserDefinedPropertyAction,
  SetValueAction,
} from 'ngrx-forms';
import { catchError, combineLatest, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _ from 'lodash';

import { MomentTimerange, MomentTimerangeToJson, JsonToMomentTimerange } from '~/shared/models';
import {
  fromCart,
  selectCartItems,
  selectCartShop,
  selectCheckoutDeliveryDate,
  selectCheckoutDeliveryOrPickup,
  selectCheckoutDeliveryTimerange,
} from '~/cart/store';
import { CartDeliveryDates, CartItem } from '~/cart/models';
import { deliveryOrPickup } from '~/core/models';
import { AppState } from '~/reducers';
import { WINDOW } from '~/core/window.service';
import { selectShopFilterData } from '~/shop/store';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'app-checkout-deliverydatetime',
  templateUrl: './checkout-deliverydatetime.component.html',
  styleUrls: ['./checkout-deliverydatetime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutDeliverydatetimeComponent implements OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutDeliveryDatetimeForm>;
  doMinDate: Date;
  doMaxDate: Date = null;
  filterDate: any;
  timeRanges$: Observable<MomentTimerange[]>;
  itemsDates: { [key: string]: Date[] } = null;
  messages: any = {};
  cartItems$: Observable<CartItem[]>;
  deliveryOrPickup$: Observable<deliveryOrPickup>;
  isDelivery$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    @Inject(WINDOW) private window: Window,
    private backend: BackendService,
  ) {}

  ngOnInit(): void {
    this.cartItems$ = this.store.select(selectCartItems);
    this.initCalendar();

    this.deliveryOrPickup$ = this.store.pipe(
      select(selectCheckoutDeliveryOrPickup),
    );

    this.isDelivery$ = this.deliveryOrPickup$.pipe(
      map((delivOrPickup) => delivOrPickup === deliveryOrPickup.DELIVERY),
    );

    this.timeRanges$ = this.store.pipe(
      select(selectCheckoutDeliveryDate),
      withLatestFrom(this.store.select(selectCartShop), this.store.select(selectCheckoutDeliveryOrPickup)),
      filter(([date, shop, deliveryOrPickup]) => date !== null && shop !== null && deliveryOrPickup !== null),
      switchMap(([date, shop, deliveryOrPickup]) => this.backend.deliveryTimes$(shop['@id'], date, deliveryOrPickup)),
      withLatestFrom(this.store.select(selectCheckoutDeliveryTimerange)),
      tap(([timeRanges, checkoutTimeRange]: [MomentTimerange[], MomentTimerange]) => {
        const timeRangeControlId = 'CheckoutForm.deliveryDatetime.deliveryTimeRange';
        if (!_.isNull(checkoutTimeRange)) {
          if (checkoutTimeRange.asap) {
            const asapTimeRanges = timeRanges.filter((tr) => tr.asap);
            if (_.isEmpty(asapTimeRanges)) {
              // no asap timerange available now
              this.store.dispatch(new SetValueAction(timeRangeControlId, null));
              this.store.dispatch(new ResetAction(timeRangeControlId));
            } else if (!asapTimeRanges[0].toTime.isSame(checkoutTimeRange.toTime)) {
              this.store.dispatch(new SetValueAction(timeRangeControlId, MomentTimerangeToJson(asapTimeRanges[0])));
            }
          } else if (
            timeRanges.findIndex(
              (i: MomentTimerange) =>
                i.fromTime.isSame(checkoutTimeRange.fromTime) && i.toTime.isSame(checkoutTimeRange.toTime),
            ) === -1
          ) {
            this.store.dispatch(new SetValueAction(timeRangeControlId, null));
            this.store.dispatch(new ResetAction(timeRangeControlId));
          }
        }
      }),
      map(([timeRanges, _checkoutTimeRange]) => timeRanges),
      catchError((err) => {
        console.log('Error delivery-times', err);
        return throwError(err);
      }),
    );
  }

  compareTimeRange(tr1: MomentTimerange, tr2: MomentTimerange): boolean {
    return (
      !_.isUndefined(tr2) &&
      !_.isNull(tr2) &&
      ((tr1.fromTime.isSame(tr2.fromTime) && tr1.toTime.isSame(tr2.toTime)) || (tr1.asap === true) === tr2.asap)
    );
  }

  timeRangeToJsonConverter: NgrxValueConverter<MomentTimerange | null, string | null> = {
    convertViewToStateValue: (value: MomentTimerange | null): string | null => {
      const result = _.isNull(value) ? null : MomentTimerangeToJson(value);
      return result;
    },
    convertStateToViewValue: (value: string | null): MomentTimerange | null => {
      const result = _.isNull(value) ? null : JsonToMomentTimerange(value);
      return result;
    },
  };

  dateChange(event: MatDatepickerInputEvent<Date>): void {
    this.messages = {};
    Object.keys(this.itemsDates).forEach((uid) => {
      const dates = this.itemsDates[uid];
      const isInDates = dates.some((d) => d.getTime() === event.value.getTime());
      //console.log(`in dates ${dates.indexOf(event.value)}`);
      //console.log(`date ${event.value} dates ${dates}`)
      this.messages[uid] = `${uid} in dates ${isInDates}`;
    });
  }

  initCalendar(): void {
    this.cartItems$
      .pipe(
        combineLatest(this.store.pipe(select(selectCartShop)), this.store.pipe(select(selectCheckoutDeliveryOrPickup))),
        filter(([_, shop, deliveryOrPickup]) => shop !== null && deliveryOrPickup !== null),
        switchMap(([items, shop, deliveryOrPickup]) =>
          this.backend
            .deliveryDates$(
              shop['@id'],
              items.map((i) => i.id),
              deliveryOrPickup,
            )
            .pipe(
              map((result): CartDeliveryDates => {
                return {
                  dates: result.dates.map((d: string) => this.stringToDate(d)),
                  items: Object.keys(result.items).reduce((obj, attr) => {
                    obj[attr] = result.items[attr].map((d: string) => this.stringToDate(d));
                    return obj;
                  }, {}),
                };
              }),
              // catchError(err => {
              //   console.log('Error delivery-dates', err);
              //   return throwError(err);
              // })
            ),
        ),
        withLatestFrom(this.store.pipe(select(selectShopFilterData))),
      )
      .subscribe(([result, filterData]: [CartDeliveryDates, any]) => {
        // set doMinDate, doMaxDate, filterDate
        if (result.dates.length > 0) {
          this.doMinDate = result.dates.reduce((a, b) => (a < b ? a : b));
          this.doMaxDate = result.dates.reduce((a, b) => (a > b ? a : b));
        }
        const valueOfDates = result.dates.map((d) => d.valueOf());
        this.filterDate = (currentDate: Date): boolean => {
          return valueOfDates.indexOf(currentDate.valueOf()) !== -1;
        };
        this.itemsDates = result.items;

        // store items delivery dates in control's user defined property
        const controls = this.formGroup.controls;
        this.store.dispatch(
          new SetUserDefinedPropertyAction(controls.deliveryDate.id, fromCart.ITEM_DELIVERY_DATES, result.items),
        );

        if (filterData.date) {
          this.store.dispatch(
            new SetValueAction('CheckoutForm.deliveryDatetime.deliveryDate', filterData.date.toISOString()),
          );
        }
      });
  }

  dateValueConverter = NgrxValueConverters.dateToISOString;

  touchUi(): boolean {
    return this.window.innerWidth < 768;
  }

  stringToDate(d: string): Date {
    const parts: string[] = d.split('-');
    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
  }

  stringify(value: any): string {
    return JSON.stringify(value);
  }
}
