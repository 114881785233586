<ng-container *ngIf="ratings$ | async as ratings; else loading">
  <h4 mat-dialog-title>{{ 'ratingslist.title' | translate : {shopName:ratings.shop_name} }}</h4>
  <mat-dialog-content class="ratings">
    <div class="rating-line" *ngFor="let rating of ratings.items">
        <apym-rating [rating]="rating" ></apym-rating>
    </div>
    <nav aria-label="Page navigation">
        <ul class="pagination" *ngIf="ratings.batching">
            <li class="page-item"><a class="page-link" *ngIf="ratings.batching.first" (click)="goTo(ratings.batching.first)">{{ 'ratingslist.first' | translate }}</a></li>
            <li class="page-item"><a class="page-link" *ngIf="ratings.batching.prev" (click)="goTo(ratings.batching.prev)"><</a></li>
            <li class="page-item"><a class="page-link" *ngIf="ratings.batching.next" (click)="goTo(ratings.batching.next)">></a></li>
            <li class="page-item"><a class="page-link" *ngIf="ratings.batching.last" (click)="goTo(ratings.batching.last)">{{ 'ratingslist.last' | translate }}</a></li>
        </ul>
    </nav>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close>{{ 'ratingslist.close' | translate }}</button>
</mat-dialog-actions>
<ng-template #loading>{{ 'ratingslist.loadingThreeDots' | translate }}
</ng-template>
