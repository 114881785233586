import { ChangeDetectionStrategy, Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStep, MatStepperIntl } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { Grange } from 'grange';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SetValueAction } from 'ngrx-forms';
import { TranslateService } from '@ngx-translate/core';

import { selectShop, ShopActionTypes } from '~/shop/store';
import * as CartModel from '~/cart/models';
import { Authenticated } from '~/shared/models';
import { PayAction } from '~/core/models';
import { AppState } from '~/reducers';
import {
  CartActionTypes,
  fromCart,
  selectCartItems,
  selectCartShop,
  selectCheckoutForm,
  selectCheckoutDeliveryOrPickup,
  selectPayAction,
} from '~/cart/store';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { deliveryOrPickup, Shop } from '~/core/models';

@Injectable()
export class StepperIntl extends MatStepperIntl {
  constructor(private translate: TranslateService) {
    super();
    translate.get('checkout.optionalLabel').subscribe((res: string) => {
      this.optionalLabel = res;
    });
  }
  // the default optional label text, if unspecified is "Optional"
  //optionalLabel = 'Optional Label panish';
  // get optionalLabel() {
  //   return 'Optional Label panish';
  // }
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatStepperIntl, useClass: StepperIntl }],
})
export class CheckoutComponent extends BaseViewComponent implements OnInit, OnDestroy {
  checkoutFormState$: Observable<FormGroupState<fromCart.CheckoutForm>>;
  cartShop$: Observable<CartModel.CartShop>;
  deliveryOrPickup$: Observable<deliveryOrPickup>;
  cartItems$: Observable<CartModel.CartItem[]>;
  isDelivery$: Observable<boolean>;
  isPickup$: Observable<boolean>;
  isAuthenticated$: Observable<Authenticated>;
  payAction$: Observable<PayAction>;
  @ViewChild('stepper') stepper: MatStepperIntl;
  @ViewChild('review') review: MatStep;

  constructor(public grange: Grange, public seo: SeoService, private store: Store<AppState>) {
    super(grange, seo);
    this.store.pipe(select(selectCartItems), take(1)).subscribe((items) => {
      if (items.length === 0) {
        this.grange.traverser.traverse('');
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(selectShop),
        takeUntil(this.destroy),
        withLatestFrom(this.context),
        filter(([shop, context]) => !shop || shop['@id'] !== context['@id']),
        map(([_shop, context]) => context),
      )
      .subscribe((context) => {
        this.store.dispatch(ShopActionTypes.loadShop({ shop: context as Shop }));
      });
    this.store.dispatch(CartActionTypes.checkingout({ checkingout: true }));
    this.store.dispatch(CartActionTypes.checkoutInit());
    this.isAuthenticated$ = this.grange.core.auth.isAuthenticated;

    this.grange.core.auth.isAuthenticated.pipe(take(1)).subscribe((auth) => {
      if (auth.state) {
        this.store.dispatch(new SetValueAction('CheckoutForm.register.loginContinue', 'loggedIn'));
      }
    });
    this.checkoutFormState$ = this.store.pipe(select(selectCheckoutForm));
    this.cartShop$ = this.store.pipe(select(selectCartShop));
    this.deliveryOrPickup$ = this.store.pipe(
      select(selectCheckoutDeliveryOrPickup),
      // share()
    );
    this.isPickup$ = this.deliveryOrPickup$.pipe(
      map((delivOrPickup) => delivOrPickup === deliveryOrPickup.PICKUP),
      // share()
    );
    this.isDelivery$ = this.deliveryOrPickup$.pipe(
      map((delivOrPickup) => delivOrPickup === deliveryOrPickup.DELIVERY),
      // share()
    );
    this.cartItems$ = this.store.pipe(select(selectCartItems));

    this.payAction$ = this.store.pipe(select(selectPayAction));
  }

  ngOnDestroy(): void {
    this.store.dispatch(CartActionTypes.checkingout({ checkingout: false }));
    super.ngOnDestroy();
  }

  returnToShop(): void {
    this.grange.traverser.traverse('.');
  }

  changeStep($event: StepperSelectionEvent): void {
    if ($event.selectedStep === this.review) {
      this.store.dispatch(CartActionTypes.postOrder());
    }
  }
}
// https://www5test.apanymantel.com/web-pasteles/pastelerias/bilbao/magora-bakery/@@paypalreturn/6969652926?_authenticator=2c4c3c7d1474a22fa3abada6d3c257cfa06cfb39&token=EC-0SD99359PH454424D&PayerID=XBGVWLDUL76KQ
