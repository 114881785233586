import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Grange } from 'grange';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { LocaleService } from '~/core/locale.service';
import {
  Article,
  ArticleBrain,
  AvailabilityDatetimeText,
  Categories,
  CityEntities,
  CountryEntities,
  CountyEntities,
  DeliveryDates,
  LastRating,
  Menu,
  NewOrder,
  PayAction,
  PaymentMethods,
  PaymentReturn,
  Portlets,
  PostCodeEntities,
  PostedOrder,
  RateOrder,
  RatingOrder,
  Ratings,
  RatingsStats,
  RatingVocab,
  RatingVocabI18n,
  RegisteredUser,
  SearchPostCode,
  ShippingMethod,
  Shop,
  ShopInfo,
  ShopSchema,
  ShopSearchResult,
  ShopSearchResultBrains,
  SubArticle,
  Taxonomy,
  TerritorialEntities,
  TimeRange,
  User,
  UserInfo,
  UserOrders,
  UserShippingAddress,
  UserShippingAddressEntities,
} from '~/core/models';
import { MomentTimerange, MomentTimerangeToJson } from '~/shared/models';
import { Cart, CartDiscount } from '~/cart/models';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private grange: Grange,
    private domSanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private localeService: LocaleService,
  ) {}

  articlesInCategory$(category: string, shopId: string): Observable<Article[]> {
    // endpoint:
    return this.grange.core.resource
      .find(
        {
          portal_type: 'apanymantel.cart.shopping.Article',
          category: category,
        },
        this.configurationService.urlToPath(shopId),
        { fullobjects: true, size: 200, sort_on: 'getObjPositionInParent' },
      )
      .pipe(
        map((searchResult) => {
          return searchResult.items.map((it, idx) => ({
            ...it,
            price: parseFloat(it.price),
            stars: parseFloat(it.stars),
            _seq_no: idx,
          }));
        }),
      );
  }

  get$<T>(id: string): Observable<T> {
    return this.grange.core.cache.get(id);
  }

  article$({ articleId = null, articleUid = null }: { articleId?: string; articleUid?: string }): Observable<Article> {
    // endpoint: apanymantel.cart.shopping.restapi.articles.Article
    if (!_.isNull(articleId)) {
      return this.grange.core.cache.get(articleId);
    } else if (!_.isNull(articleUid)) {
      return this.grange.core.resource
        .find(
          {
            portal_type: ['apanymantel.cart.shopping.Article', 'apanymantel.cart.shopping.SubArticle'],
            UID: articleUid,
          },
          '/',
          { fullobjects: true },
        )
        .pipe(
          map((result: any) =>
            result.items
              ? {
                  ...result.items[0],
                  price: parseFloat(result.items[0].price),
                }
              : null,
          ),
        );
    }
  }

  articleBrain$(shopId: string, articleUid: string): Observable<ArticleBrain> {
    // endpoint: apanymantel.cart.shopping.restapi.articles.Article
    return this.grange.core.cache
      .get(`${shopId}/article?uid=${articleUid}`)
      .pipe(map((article: any) => ({ ...(article as object), price: parseFloat(article.price) } as ArticleBrain)));
  }

  cartItemsAvailability$(
    shopId: string,
    deliveryOrPickup: string,
    date: moment.Moment,
    timeRange: MomentTimerange,
    items: string[],
  ): Observable<{ [key: string]: boolean }> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.CartItemsAvailability
    const dateString: string = date.format('YYYY-MM-DD');
    const timeString = timeRange ? MomentTimerangeToJson(timeRange) : 'null';
    return this.grange.core.api.get(
      `${shopId}/cart-items-availability?deliveryOrPickup=${deliveryOrPickup}&items=${JSON.stringify(
        items,
      )}&date=${dateString}&timerange=${timeString}`,
    );
  }

  categories$(
    shopId: string,
    date: moment.Moment,
    time: moment.Moment,
    pickupOrDelivery: string,
  ): Observable<Categories> {
    // endpoint: apanymantel.cart.shopping.restapi.categories.Categories
    const params = new URLSearchParams();
    if (time && date) {
      params.append('datetime', date.format('YYYY-MM-DD') + 'T' + time.format('HH:mm:ss'));
    } else if (date) {
      params.append('date', date.format('YYYY-MM-DD'));
    }
    if (pickupOrDelivery) {
      params.append('pickup_or_delivery', pickupOrDelivery);
    }
    return this.grange.core.cache.get(`${shopId}/categories?${params.toString()}`);
  }

  cities$(countyUid: string): Observable<CityEntities> {
    // endpoint: kg.pym.userdata.restapi.shippingaddresses.CitiesGet
    return this.grange.core.cache.get(`/cities/${countyUid}`);
  }

  counties$(countryUid: string): Observable<CountyEntities> {
    // endpoint: kg.pym.userdata.restapi.shippingaddresses.CountiesGet
    return this.grange.core.cache.get(`/counties/${countryUid}`);
  }

  countries$(): Observable<CountryEntities> {
    // endpoint: kg.pym.userdata.restapi.shippingaddresses.CountriesGet
    return this.grange.core.cache.get('/countries');
  }

  deleteUserShippingAddress$(addressId: number): Observable<any> {
    // endpoint: kg.apym.restapi.shippingaddresses.ShippingAddressDelete
    return this.grange.core.api.delete(`/user-shipping-address/${addressId}`);
  }

  deliveryDates$(shopId: string, items: string[], deliveryOrPickup: string): Observable<DeliveryDates> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.DeliveryDates
    return this.grange.core.api.post(`${shopId}/delivery-dates`, { items, deliveryOrPickup });
  }

  discounts$(shopId: string, cart: Cart): Observable<CartDiscount[]> {
    return this.grange.core.api.post(`${shopId}/@discounts`, { cart })
      .pipe(map((response) => response.discounts.map((discount) => ({ ...discount, amount: parseFloat(discount.amount) }))));
  }

  availabilityDatetimeText$(shopId: string, deliveryOrPickup: string, items: string[]): Observable<any> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.AvailabilityDatetimeText
    return this.grange.core.api
      .get(`${shopId}/availability-datetime-text?deliveryOrPickup=${deliveryOrPickup}&items=${JSON.stringify(items)}`)
      .pipe(map((result) => Object.entries(result).reduce((o, [key, value]) => ({ ...o, [key]: moment(value) }), {})));
  }

  deliveryAvailabilityDatetimeText$(shopId: string): Observable<AvailabilityDatetimeText> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.DeliveryAvailabilityDatetimeText
    return this.grange.core.api.get(`${shopId}/delivery-availability-datetime-text`).pipe(
      map((result) => ({
        immediate: {
          ...result.immediate,
          from: moment(result.immediate.from),
        },
        same_day: {
          ...result.same_day,
          from: moment(result.same_day.from),
        },
        next_day: {
          ...result.next_day,
          from: moment(result.next_day.from),
        },
        two_days: {
          ...result.two_days,
          from: moment(result.two_days.from),
        },
      })),
      // map(result => Object.entries(result).reduce(
      //   (o, [key, value]) => ({ ...o, [key]: { ...value as object, from: moment(value['from']) } }), {}))
    );
  }

  deliveryTimes$(shopId: string, deliveryDate: moment.Moment, deliveryOrPickup: string): Observable<TimeRange[]> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.DeliveryTimes
    return this.grange.core.api
      .post(`${shopId}/delivery-times`, { deliveryDate: deliveryDate.format('YYYY-MM-DD'), deliveryOrPickup })
      .pipe(
        map((timeRanges) =>
          timeRanges.map(
            (tt: { fromTime: string; toTime: string; asap?: boolean; fromMinute?: number; toMinute?: number }) => {
              const fromTime = tt ? moment(tt.fromTime, 'HH:mm:ss') : null;
              const toTime = tt ? moment(tt.toTime, 'HH:mm:ss') : null;
              if (tt.asap) {
                return { fromTime, toTime, asap: tt.asap, fromMinute: tt.fromMinute, toMinute: tt.toMinute };
              }
              return { fromTime, toTime };
            },
          ),
        ),
      );
  }

  getArticleShop$(path: string): Observable<Shop> {
    return this.grange.core.cache.get(`${path}/@shop`);
  }

  getCaptchaWebsiteKey$(): Observable<string> {
    // endpoint: apanymantel.cart.shopping.restapi.add_users.RecaptchaWebsiteKey
    return this.grange.core.api.get('/get-recaptcha-website-key');
  }

  getPortlets$(path: string, manager: string): Observable<Portlets> {
    // endpoint: apanymantel.cart.shopping.restapi.portlet.GetPortlet
    if (!path.endsWith('/')) {
      path += '/';
    }
    return this.grange.core.cache.get(`${path}get-portlets?manager=${manager}`).pipe(
      map((portlets: any) => ({
        portlets: portlets.portlets.map((portlet) => ({
          ...portlet,
          html: this.domSanitizer.bypassSecurityTrustHtml(
            portlet.html.replace(
              /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?/g,
              // /^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?/,
              (match: string) => environment.normalizeURL(match),
            ),
          ),
        })),
      })),
    );
  }

  getShopSearch$(
    shopUids: string | string[],
    entityUid = '',
    date: moment.Moment = null,
    item: string = null,
  ): Observable<ShopSearchResult[]> {
    // endpoint: apanymantel.cart.shopping.restapi.search.GetShopSearch
    const dateStr = _.isNull(date) ? '' : moment(date).format('YYYY-MM-DD');
    const itemStr = _.isNull(item) ? '' : item;
    return this.grange.core.api
      .get(`/get-shop-search?shopuid=${shopUids}&searchuid=${entityUid}&searchdate=${dateStr}&searchitem=${itemStr}`)
      .pipe(
        map((shops) => {
          const transShop = (shop) => ({
            ...shop,
            minimum_time: moment(shop.minimum_time),
            minimum_amount_delivery_order: parseFloat(shop.minimum_amount_delivery_order),
            minimum_amount_without_shipping_costs: parseFloat(shop.minimum_amount_without_shipping_costs),
            minmax_shipping_fee: shop.minmax_shipping_fee.map((fee) => parseFloat(fee)),
          });
          return _.isArray(shops) ? shops.map((shop) => transShop(shop)) : [transShop(shops)];
        }),
      );
  }

  getShopTaxonomy$(): Observable<Taxonomy[]> {
    const name = 'collective.taxonomy.classification';
    return this.localeService
      .getLanguage$()
      .pipe(switchMap((lang) => this.grange.core.api.get(`/get-taxonomy?name=${name}&lang=${lang}`)));
  }

  getUser$(username: string): Observable<User> {
    // endpoint:
    return this.grange.core.cache.get(`/@users/${username}`);
  }

  getUserInfo$(): Observable<UserInfo> {
    // endpoint: kg.apym.userdata.restapi.userinfo.UserInfoGet
    return this.grange.core.cache.get('/user-info');
  }

  lastRating$(): Observable<LastRating[]> {
    // endpoint: kg.rating.restapi.lastratings.LastRatings
    return this.grange.core.api.get('/last-ratings').pipe(
      map((result: any[]) =>
        result.map((rat) => ({
          ...rat,
          datetime: moment(rat.datetime),
        })),
      ),
    );
  }

  menu$(): Observable<Menu[]> {
    // endpoint: apanymantel.cart.shopping.restapi.menu.Menu
    return this.grange.core.cache.get('/menu?path=/').pipe(
      map(
        (result: any[]) =>
          result.map((menu) => ({
            ...menu,
            path: _.has(menu, 'path') ? environment.normalizePath(menu.path) : undefined,
            children: _.has(menu, 'children')
              ? menu.children.map((child) => ({
                  ...child,
                  path: _.has(child, 'path') ? environment.normalizePath(child.path) : undefined,
                }))
              : undefined,
          })),
        //tap(result => console.log('result', result))
      ),
    );
  }

  newOrder$(shopId: string, order: NewOrder): Observable<[PayAction, PostedOrder]> {
    // endpoint: kg.cart.restapi.new_order.NewOrder
    const postingOrder = {
      ...order,
      deliveryDate: order.deliveryDate.format('YYYY-MM-DD'),
    };
    return this.grange.core.api.post(`${shopId}/new-order`, postingOrder).pipe(
      map((postedOrder: any) => [
        postedOrder.action,
        {
          ...postedOrder,
          deliveryPickupDatetime: moment(postedOrder.deliveryPickupDatetime),
          orderDatetime: moment(postedOrder.orderDeliveryDatetime),
        },
      ]),
    );
  }

  payment$(shopId: string, params: any, language: string): Observable<PaymentReturn> {
    // endpoint: kg.cart.restapi.payment.Payment
    return this.grange.core.api.post(`${shopId}/payment`, { ...params, language });
  }

  paymentMethods$(shopId: string): Observable<PaymentMethods> {
    // endpoint: apanymantel.cart.shopping.restapi.paymentmethods.PaymentMethods
    return this.grange.core.cache.get(`${shopId}/payment-methods`).pipe(
        map((result: any) =>
          ({ payment_methods: result.payment_methods.map((paymentMethod) => (
            { ...paymentMethod,
              surcharge: paymentMethod.surcharge && parseFloat(paymentMethod.surcharge),
            })) })
        ),
      );
  }

  pickupAvailabilityDatetimeText$(shopId: string): Observable<AvailabilityDatetimeText> {
    // endpoint: apanymantel.cart.shopping.restapi.deliverydates.DaliveryAvailabilityDatetimeText
    return this.grange.core.api.get(`${shopId}/pickup-availability-datetime-text`).pipe(
      map((result) => ({
        immediate: {
          ...result.immediate,
          from: moment(result.immediate.from),
        },
        same_day: {
          ...result.same_day,
          from: moment(result.same_day.from),
        },
        next_day: {
          ...result.next_day,
          from: moment(result.next_day.from),
        },
        two_days: {
          ...result.two_days,
          from: moment(result.two_days.from),
        },
      })),
      //   // map(result => Object.entries(result).reduce(
      //   //   (o, [key, value]) => ({ ...o, [key]: { ...value as object, from: moment(value['from']) } }), {}))
    );
  }

  pointInArea$(shopId: string, longitude: number, latitude: number): Observable<boolean> {
    return this.grange.core.cache.get<boolean>(`${shopId}/@point-in-area?long=${longitude}&lat=${latitude}`);
  }

  postCodeChain$(shopId: string, postCodeUid: string): Observable<string[]> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopPostCodeChain
    return this.grange.core.cache.get<string[]>(`${shopId}/shop-postcode-chain?uid=${postCodeUid}`);
  }

  postCodes$(cityUid: string): Observable<PostCodeEntities> {
    // endpoint: kg.pym.userdata.restapi.shippingaddresses.PostCodesGet
    return this.grange.core.cache.get(`/postcodes/${cityUid}`);
  }

  postUser$(user: User): Observable<RegisteredUser> {
    // endpoint:
    return this.grange.core.api.post('/@users', user);
  }

  // userShippingAddresses$() {
  //   user/user.effects
  //   // endpoint:
  // }

  postUserShippingAddress$(shippingAddress: UserShippingAddress, token: string) {
    // endpoint: kg.apym.restapi.shippingaddresses.ShippingAddressPost
    return this.grange.core.api.post('/user-shipping-address' + (shippingAddress.id ? '/' + shippingAddress.id : ''), {
      ...shippingAddress,
      postCode: shippingAddress.postCode.uid,
      city: shippingAddress.city.uid,
      county: shippingAddress.county.uid,
      country: shippingAddress.country.uid,
      _authenticator: token,
    });
  }

  putUserInfo$(userInfo: UserInfo): Observable<any> {
    // endpoint: kg.apym.userdata.restapi.userinfo.UserInfoPut
    return this.grange.core.api.put('/user-info', userInfo);
  }

  rateItemVocab$(locale: string): Observable<RatingVocab[]> {
    // endpoint: kg.rating.restapi.rateitemvocab.RateItemVocab
    return this.grange.core.cache.get('/rate-item-vocab').pipe(
      map((result: RatingVocabI18n) =>
        Object.keys(result)
          .reduce((arr, key) => {
            arr.push({ value: parseFloat(key), caption: result[key][locale] });
            return arr;
          }, [])
          .sort((a, b) => b.value - a.value),
      ),
    );
  }

  rateOrder$(rating: any, orderId: string, formId: string, token: string): Observable<RateOrder> {
    // endpoint: kg.rating.restapi.ratingorder.RateOrder
    return this.grange.core.api.post('/rate-order', {
      ...rating,
      order: orderId,
      token: token,
      form: formId,
    });
  }

  rateOrderVocab$(locale: string): Observable<RatingVocab[]> {
    // endpoint: kg.rating.restapi.rateordervocab.RateOrderVocab
    return this.grange.core.cache.get('/rate-order-vocab').pipe(
      map((result: RatingVocabI18n) =>
        Object.keys(result)
          .reduce((arr, key) => {
            arr.push({ value: parseFloat(key), caption: result[key][locale] });
            return arr;
          }, [])
          .sort((a, b) => b.value - a.value),
      ),
    );
  }

  ratingOrder$(orderId: string, formId: string, token: string): Observable<RatingOrder> {
    // endpoint: kg.rating.restapi.ratingorder.RatingOrder
    return this.grange.core.api.get(`/rating-order?token=${token}&order=${orderId}&form=${formId}`);
  }

  ratings$(path: string): Observable<Ratings[]> {
    // endpoint: kg.rating.ratingsinfo.RatingsInfoService
    return this.get$<Ratings[]>(path);
  }

  ratingsStats$(): Observable<RatingsStats> {
    // endpoint: kg.rating.restapi.ratingsstats.RatingsStats
    return this.grange.core.api.get('/ratings-stats');
  }

  resolveEntities(
    postCode: string,
    city: string,
    county: string,
    country: string,
  ): Observable<any> {
    // endpoint: kg.apym.userdata.api.resolve_entities.ResolveEntity
    return this.grange.core.cache.get(
      `/resolve-entities?postcode=${postCode}&city=${city}&county=${county}&country=${country}`,
    );
  }

  searchPostCodes$(postCode: string): Observable<SearchPostCode[]> {
    // search by postcode name
    // endpoint: apanymantel.cart.shopping.restapi.search.SearchPostCodes
    return this.grange.core.api.get(`/search-postcodes?postcode=${postCode}`);
  }

  searchShops$(childSiteId: string, entityUid: string, date: moment.Moment): Observable<ShopSearchResultBrains> {
    // endpoint: apanymantel.cart.shopping.restapi.search.SearchShops
    const dateStr = _.isNull(date) ? '' : moment(date).format('YYYY-MM-DD');
    return this.grange.core.api.get(`${childSiteId}/search-shops?searchuid=${entityUid}&searchdate=${dateStr}`);
  }

  shippingMethodForPostCode$(shopId: string, postCodeUid: string): Observable<ShippingMethod> {
    // endpoint: kg.cart.restapi.shipping_method.ShippingMethod
    return this.grange.core.cache.get(`${shopId}/shipping-method?postcode=${postCodeUid}`).pipe(
      map((shippingMethod: any) => ({
        ...(shippingMethod as ShippingMethod),
        amount: parseFloat(shippingMethod.amount),
      })),
    );
  }

  shop$(shopId: string): Observable<Shop> {
    // endpoint:
    return this.get$<Shop>(shopId);
  }

  shopCities$(shopId: string, countyUid: string): Observable<TerritorialEntities> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopCities
    return this.grange.core.cache.get(`${shopId}/shop-cities?uid=${countyUid}`);
  }

  shopCounties$(shopId: string, countryUid: string): Observable<TerritorialEntities> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopCounties
    return this.grange.core.cache.get(`${shopId}/shop-counties?uid=${countryUid}`);
  }

  shopCountries$(shopId: string): Observable<TerritorialEntities> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopCountries
    return this.grange.core.cache.get(`${shopId}/shop-countries`);
  }

  shopInfo$(shopId: string): Observable<ShopInfo> {
    // endpoint: apanymantel.cart.shopping.restapi.shop_info.ShopInfo
    return this.get$<ShopInfo>(shopId);
  }

  shopPostCodes$(shopId: string, cityUid: string): Observable<TerritorialEntities> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopPostCodes
    return this.grange.core.cache.get(`${shopId}/shop-postcodes?uid=${cityUid}`);
  }

  shopResolveEntities$(
    shopId: string,
    postCode: string,
    city: string,
    county: string,
    country: string,
  ): Observable<any> {
    // endpoint: apanymantel.cart.shopping.restapi.shopentities.ShopResolveEntities
    return this.grange.core.cache.get(
      `${shopId}/shop-resolve-entities?postcode=${postCode}&city=${city}&county=${county}&country=${country}`,
    );
  }

  shopSchema$(shopId: string): Observable<ShopSchema> {
    // endpoint: apanymantel.cart.shopping.restapi.shop_schema.ShopSchema
    return this.get$<ShopSchema>(shopId);
  }

  subarticles$(articleId: string): Observable<SubArticle[]> {
    // endpoint:
    return this.grange.core.resource
      .find({ portal_type: 'apanymantel.cart.shopping.SubArticle' }, this.configurationService.urlToPath(articleId), {
        fullobjects: true,
        size: 100,
        sort_on: 'getObjPositionInParent',
      })
      .pipe(
        map((result: any) =>
          result.items.map((subArticle) => ({ ...subArticle, price: parseFloat(subArticle.price) })),
        ),
      );
  }

  userOrders$(index: number, size: number): Observable<UserOrders> {
    // endpoint: kg.cart.restapi.userorders.UserOrdersGet
    return this.grange.core.cache.get(`/user-orders?start=${index * size}&batch=${size}`).pipe(
      map((result: any) => {
        const orders = result.orders.map((ord) => ({
          ...ord,
          orderDatetime: moment(ord.orderDatetime),
          deliveryPickupDatetime: moment(ord.deliveryPickupDatetime),
        }));
        return { ...result, orders };
      }),
    );
  }

  userResetPassword$(userId: string, newPassword: string, resetToken: string): Observable<any> {
    // endpoint:
    return this.grange.core.api.post(`/@users/${userId}/reset-password`, {
      new_password: newPassword,
      reset_token: resetToken,
    });
  }

  userShippingAddressShop$(shopId: string): Observable<UserShippingAddressEntities> {
    // endpoint: apanymantel.cart.shopping.restapi.usershippingaddresses.UserShippingAddresses
    return this.grange.core.cache.get(`${shopId}/user-shipping-addresses-shop`);
  }
}
