<!-- Review data here -->
<!-- Buyer Data -->
<ng-container *ngIf="postedOrder$ | async as postedOrder">
  <div class="review-wrapper">
    <div class="review-step-wrapper">
      <div class="review-step-title mat-step-label">{{ 'checkout-review.buyerData' | translate }}</div>
      <div class="review-step-field">
        <div class="review-step-field-label">{{ 'checkout-review.name' | translate }}</div>
        <div class="review-step-field-content">{{ postedOrder.billingName }}</div>
      </div>
      <div class="review-step-field">
        <div class="review-step-field-label">{{ 'checkout-review.mobilePhone' | translate }}</div>
        <div class="review-step-field-content">{{ postedOrder.billingPhone }}</div>
      </div>
      <div class="review-step-field">
        <div class="review-step-field-label">{{ 'checkout-review.email' | translate }}</div>
        <div class="review-step-field-content">{{ postedOrder.billingEmail }}</div>
      </div>
    </div>
    <!-- Receiver Data -->
    <ng-container *ngIf="postedOrder.isDelivery && (cartShop$ | async).checkoutRecipientDifferent">
      <div class="review-step-wrapper">
          <div class="review-step-title mat-step-label">{{ 'checkout-review.recipientData' | translate }}</div>
          <div class="review-step-field">
            <div class="review-step-field-label">{{ 'checkout-review.name' | translate }}</div>
            <div class="review-step-field-content">{{ postedOrder.shippingName }}</div>
          </div>
          <div class="review-step-field">
            <div class="review-step-field-label">{{ 'checkout-review.mobilePhone' | translate }}</div>
            <div class="review-step-field-content">{{ postedOrder.shippingPhone }}</div>
          </div>
          <div class="review-step-field">
            <div class="review-step-field-label">{{ 'checkout-review.email' | translate }}</div>
            <div class="review-step-field-content">{{ postedOrder.shippingEmail }}</div>
          </div>
        <ng-container *ngIf="(cartShop$ | async).checkoutIsASurprise">
          <div *ngIf="postedOrder.isASurprise" class="review-step-field">
            <div class="review-step-field-label">{{ 'checkout-review.isASurprise' | translate }}</div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Delivery Address -->
    <ng-container *ngIf="postedOrder.isDelivery" >
      <div class="review-step-wrapper">
        <div class="review-step-title mat-step-label">{{ 'checkout-review.deliveryAddress' | translate }}</div>
        <div class="review-step-field">
          <div class="review-step-field-label">{{ 'checkout-review.organization' | translate }}</div>
          <div class="review-step-field-content">{{ postedOrder.shippingOrganization }}</div>
        </div>
        <div class="review-step-field">
          <div class="review-step-field-label">{{ 'checkout-review.address' | translate }}</div>
          <div class="review-step-field-content">{{ postedOrder.shippingAddress }} - {{ postedOrder.shippingAddressComplement }}</div><br/>
          <div class="review-step-field-content">{{ postedOrder.shippingPostcode }} - {{ postedOrder.shippingCity }}</div>
          <span>&nbsp;-&nbsp;</span>
          <div class="review-step-field-content">{{ postedOrder.shippingCounty }}</div>
        </div>
      </div>
    </ng-container>
    <!-- Pickup Addresss -->
    <ng-container *ngIf="postedOrder.isPickup">
      <div class="review-step-wrapper">
        <div class="review-step-title mat-step-label">{{ 'checkout-review.pickupAddress' | translate }}</div>
        <div class="review-step-field">
          <div class="review-step-field-label">{{ 'checkout-review.address' | translate }}</div>
          <div class="review-step-field-content">{{ postedOrder.pickupAddressName }}</div>
        </div>
      </div>
    </ng-container>
    <!-- Delivery Date/Time -->
    <div class="review-step-wrapper">
      <div class="review-step-title mat-step-label">{{ 'checkout-review.deliveryDateTime' | translate }}</div>
      <div class="review-step-field">
        <div class="review-step-field-label">{{ 'checkout-review.date' | translate }}</div>
        <div class="review-step-field-content">{{ postedOrder.deliveryPickupDatetime | date:'mediumDate' }}</div>
      </div>
      <div class="review-step-field">
        <div class="review-step-field-label">{{ 'checkout-review.time' | translate }}</div>
        <div *ngIf="postedOrder.annotations.apanymantel.deliveryPickupAsap" class="review-step-field-content">
          {{ 'checkout-review.asap' | translate: {fromMinute: postedOrder.annotations.apanymantel.deliveryPickupAsapFromMinute, toMinute: postedOrder.annotations.apanymantel.deliveryPickupAsapToMinute} }}</div>

        <div *ngIf="!postedOrder.annotations.apanymantel.deliveryPickupAsap" class="review-step-field-content">{{ postedOrder.annotations.apanymantel.deliveryPickupTimeRange | timeRange }}</div>
      </div>
    </div>
    <!-- Giftcard personal message -->
    <ng-container *ngIf="postedOrder.annotations.apanymantel?.giftMessage">
      <div class="review-step-wrapper">
        <div class="review-step-title mat-step-label">{{ 'checkout-review.giftcardPersonalMessage' | translate }}</div>
        <div class="review-step-field">
          <div class="review-step-field-label">{{ 'checkout-review.message' | translate }}</div>
          <div class="review-step-field-content">{{ postedOrder.annotations.apanymantel.giftMessage }}</div>
        </div>
      </div>
    </ng-container>
    <!-- Message for the shop -->
    <ng-container *ngIf="postedOrder.comment">
      <div class="review-step-wrapper">
        <div class="review-step-title mat-step-label">{{ 'checkout-review.messageForShop' | translate }}</div>
        <div class="review-step-field">
          <div class="review-step-field-label">{{ 'checkout-review.message' | translate }}</div>
          <div class="review-step-field-content">{{ postedOrder.comment }}</div>
        </div>
      </div>
    </ng-container>
    <!-- Payment Method -->
    <div class="review-step-wrapper">
      <div class="review-step-title mat-step-label">{{ 'checkout-review.paymentMethod' | translate }}</div>
      <div *ngIf="(getPaymentMethod(postedOrder.paymentMethod) | async) as paymentMethod" class="review-step-field">
        <div>{{ postedOrder.paymentMethodName }}</div>
        <span class="payment-logo" [innerHTML]="paymentMethod.widgetLogo"></span>
      </div>
    </div>
  </div>
  <!-- Products and Total -->
  <div class="review-step-wrapper">
    <div class="review-step-title mat-step-label">{{ 'checkout-review.orderedProducts' | translate }}</div>
    <mat-table [dataSource]="postedOrder.orderItems" class="products-table">
      <!-- Name + Size Column -->
      <ng-container matColumnDef="description">
        <!-- <mat-header-cell *matHeaderCellDef class="product-cell"> {{ 'checkout-review.tableHeaderProduct' | translate }} </mat-header-cell>-->
        <mat-header-cell *matHeaderCellDef class="product-cell"></mat-header-cell>-->
        <mat-cell *matCellDef="let element" class="product-cell">
          <div class="product-name">{{ element.description }}</div>
          <div *ngIf="element.options">
            <div *ngFor="let groupOption of element.options">
              <span class="groupname">{{ groupOption.name }}:</span>
              <span class="options" *ngFor="let option of groupOption.options">
                {{ option.quantity }} x {{option.name}}<span>,</span>
              </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef class="quantity-cell"> {{ 'checkout-review.tableHeaderQuantity' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="quantity-cell"> x {{ element.quantity }} </mat-cell>
      </ng-container>

      <!-- Unit Price Column -->
      <ng-container matColumnDef="unitPrice">
        <mat-header-cell *matHeaderCellDef class="unit-price-cell"> {{ 'checkout-review.tableHeaderUnitPrice' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="unit-price-cell"> {{ element.unitPrice | apymCurrency:'EUR'}} </mat-cell>
      </ng-container>

      <!-- Total Item Price Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef class="price-cell"> {{ 'checkout-review.tableHeaderPrice' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="price-cell"> {{ element.amount | apymCurrency:'EUR'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['description', 'quantity', 'unitPrice', 'amount']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['description', 'quantity', 'unitPrice', 'amount'];"></mat-row>
    </mat-table>

    <div *ngIf="postedOrder.orderDiscounts.length !== 0">
      <div class="products-bottom-rows discounts" *ngFor="let discount of postedOrder.orderDiscounts">
        <mat-icon aria-hidden="false" aria-label="discount">discount</mat-icon>
        <div>{{ discount.name }}</div>
        <div>{{ -discount.amount | apymCurrency:'EUR' }}</div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div *ngIf="postedOrder.shippingMethodAmount">
      <div class="products-bottom-rows">
        <div>{{ 'checkout-review.deliveryCost' | translate }}</div>
        <div>{{ postedOrder.shippingMethodAmount | apymCurrency:'EUR'}}</div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div *ngIf="postedOrder.paymentMethodSurcharge">
      <div class="products-bottom-rows">
        <div>{{ 'checkout-review.paymentFormSurcharge' | translate }}</div>
        <div>{{ postedOrder.paymentMethodSurcharge | apymCurrency:'EUR'}}</div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="products-bottom-rows total">
      <div>{{ 'checkout-review.total' | translate }}</div>
      <div>{{ postedOrder.amount | apymCurrency:'EUR'}}</div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="accept-terms-checkbox" >
    <mat-checkbox color="primary" [ngrxFormControlState]="formGroup.controls.acceptPolicy"><span [innerHTML]="'checkout-review.acceptTermsAndPolicies' | translate "></span> </mat-checkbox>
  </div>
</ng-container>
