import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentComponent extends BaseViewComponent implements OnInit {
  constructor(public grange: Grange, public seo: SeoService, private domSanitizer: DomSanitizer) {
    super(grange, seo);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  sanitize(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
