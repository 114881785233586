<div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<div class="plone-login">
    <h2>{{ 'login.login' | translate }}</h2>
    <form #form="ngForm" (ngSubmit)="onSubmit(form.value)">
        <div *ngIf="error" class="apym-error-msg">
            <div class="plone-system-error-advise">
                <div><i class="fas fa-exclamation-triangle"></i>
                    {{ 'general.sorryError' | translate }}
                </div>
                <p><small>{{ 'general.helpError' | translate }}</small></p>
            </div>
<!--            <p class="error" *ngIf="error">{{ error }}</p>-->
            <p class="error" *ngIf="error === 'Wrong login and/or password.'">{{ 'login.errorWrongLoginOrPass' | translate}}</p>

        </div>
        <mat-form-field>
            <input matInput placeholder="{{ 'login.email' | translate }}" name="login" type="email" ngModel>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="{{ 'login.pasword' | translate }}" name="password" type="password" ngModel>
        </mat-form-field>


        <!--
        <p><label>Email <input type="text" name="login" ngModel/></label></p>
        <p><label>{{ 'login.password' | translate }}<input type="password" name="password" ngModel/></label></p>
        -->


        <button mat-raised-button color="warn" type="submit">{{ 'login.login' | translate }}</button>
        <p><span>{{ 'login.loginTrouble' | translate }}</span>&nbsp;<a traverseTo="/@@request-password-reset">{{ 'login.changeYourPassword' | translate }}</a></p>
    </form>
</div>
