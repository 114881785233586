<ng-container *ngIf="context | async as context">
    <h1>{{ context.title }}</h1>
    <li *ngFor="let item of context.items">
        <a [traverseTo]="item['@id']">{{ item.title }}</a>
    </li>
    <div>
<!--        {{ context | json }} -->
    </div>
</ng-container>

