import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Grange } from 'grange';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from '~/reducers';
import { selectShop, selectShopFilterData } from './shop.selectors';
import * as ShopActionTypes from './shop.actions';
import { CartActionTypes, selectCartShop } from '~/cart/store';
import { StateShopInfo } from '~/shop/models';
import { Article, Shop } from '~/core/models';
import { CartShop } from '~/cart/models';
import * as moment from 'moment';
import { Target } from 'angular-traversal';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BackendService } from '~/core/backend.service';

const compareMoment = function (a: null | moment.Moment, b: null | moment.Moment) {
  return (_.isNull(a) && _.isNull(b)) || (!_.isNull(a) && _.isNull(b) && a.isSame(b)) || false;
};

@Injectable()
export class ShopEffects {
  constructor(
    private actions$: Actions,
    private grange: Grange,
    private store: Store<AppState>,
    private backend: BackendService,
    private translate: TranslateService,
  ) {}

  getArticleShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActionTypes.getArticleShop),
      switchMap((action) => {
        console.log('getArticleShop$', action);
        return this.backend.getArticleShop$(action.path).pipe(
          map((result) => ShopActionTypes.getArticleShopSuccess({ shop: result })),
          catchError((error) => of(ShopActionTypes.getArticleShopFail(error))),
        );
      }),
    ),
  );

  loadShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof ShopActionTypes.loadShop>>(ShopActionTypes.loadShop),
      mergeMap((action) =>
        forkJoin(
          this.backend.shopInfo$(
            `${action.shop['@components'].info['@id']}?set_language=${this.translate.currentLang}`,
          ),
          this.backend.shopSchema$(action.shop['@components'].schema['@id']),
        ).pipe(map(([info, schema]) => [info, schema, action.shop])),
      ),
      // convert iso datetime to moment type
      map(([shopInfo, shopSchema, shop]: [any, any, Shop]) => [
        {
          ...shopInfo,
          pickup_availability_datetime_text: Object.entries(shopInfo.pickup_availability_datetime_text).reduce(
            (o, [key, value]) => ({
              ...o,
              [key]: { ...(value as Record<string, unknown>), from: moment(value['from']) },
            }),
            {},
          ),
          delivery_availability_datetime_text: Object.entries(shopInfo.delivery_availability_datetime_text).reduce(
            (o, [key, value]) => ({
              ...o,
              [key]: { ...(value as Record<string, unknown>), from: moment(value['from']) },
            }),
            {},
          ),
          pickup_dates: shopInfo.pickup_dates.map((d: string) => moment(d)),
          delivery_dates: shopInfo.delivery_dates.map((d: string) => moment(d)),
          shop_info: {
            ...shopInfo.shop_info,
            now: moment(shopInfo.shop_info.now),
          },
        },
        shopSchema,
        shop,
      ]),
      withLatestFrom(this.store.pipe(select(selectCartShop)), this.grange.traverser.target),
      concatMap(([[shopInfo, shopSchema, shop], cartShop, target]: [[StateShopInfo, any, Shop], CartShop, Target]) => {
        shopSchema['@id'] = undefined;
        let actions: Action[] = [
          ShopActionTypes.shopInfoLoaded({ shopInfo: shopInfo }),
          ShopActionTypes.shopSchemaLoaded({ shopSchema: shopSchema }),
        ];
        if (!_.isEmpty(cartShop) && cartShop.uid !== shop.UID) {
          actions.push(CartActionTypes.clearCartAndCartShop());
        }
        actions = actions.concat([
          CartActionTypes.setShop({
            shop: {
              '@id': shop['@id'],
              contextPath: target.contextPath,
              uid: shop.UID,
              minimumAmountWithoutShippingCosts: shop.minimum_amount_without_shipping_costs,
              minimumOrderWithoutShippingCosts: shop.minimum_order_without_shipping_costs,
              minimumAmountDeliveryOrder: shop.minimum_amount_delivery_order,
              minimumAmountPickupOrder: shop.minimum_amount_pickup_order,
              minMaxShippingFee: shopInfo.shop_info.minmax_shipping_fee,
              hasDelivery: shopInfo.shop_info.has_delivery,
              hasPickup: shopInfo.shop_info.has_pickup,
              checkoutAdditionalMessage: shop.checkout_additional_message,
              checkoutCardMessage: shop.checkout_card_message,
              checkoutIsASurprise: shop.checkout_is_a_surprise,
              checkoutRecipientDifferent: shop.checkout_recipient_different,
              checkoutCustomMessage: shop.checkout_custom_message,
            },
          }),
          ShopActionTypes.categoriesRequested(),
        ]);
        return actions;
      }),
    ),
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof ShopActionTypes.categoriesRequested>>(ShopActionTypes.categoriesRequested),
      combineLatest(this.store.pipe(select(selectShop)), this.store.pipe(select(selectShopFilterData))),
      filter(([_currAction, currShop, currFilterData]) => !_.isUndefined(currShop)),
      distinctUntilChanged(
        ([_prevAction, prevShop, prevFilterData], [_currAction, currShop, currFilterData]) =>
          prevShop['@id'] === currShop['@id'] &&
          prevFilterData.pickupOrDelivery === currFilterData.pickupOrDelivery &&
          prevFilterData.country === currFilterData.country &&
          prevFilterData.county === currFilterData.county &&
          prevFilterData.city === currFilterData.city &&
          prevFilterData.postCode === currFilterData.postCode &&
          compareMoment(prevFilterData.date, currFilterData.date),
      ),
      mergeMap(([_action, shop, filterData]) => {
        return this.backend
          .categories$(
            shop['@id'],
            filterData.date,
            filterData.timeRange
              ? filterData.timeRange.asap
                ? filterData.timeRange.toTime
                : filterData.timeRange.fromTime
              : null,
            filterData.pickupOrDelivery,
          )
          .pipe(
            catchError((err) => {
              console.log('error loading categories ', err);
              this.store.dispatch(ShopActionTypes.categoriesCancelled());
              return of({ categories: [] });
            }),
          );
      }),
      map((result: any) => ShopActionTypes.categoriesLoaded({ categories: result.categories })),
    ),
  );

  loadArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof ShopActionTypes.articlesRequested>>(ShopActionTypes.articlesRequested),
      withLatestFrom(
        // fixing combineLatest(
        this.store.pipe(select(selectShop)),
      ),
      mergeMap(([action, shop]: [ReturnType<typeof ShopActionTypes.articlesRequested>, any]) =>
        this.backend
          .articlesInCategory$(action.category, shop['@id'])
          .pipe(map((articles) => [articles, action.category])),
      ),
      map(([items, category]: [Article[], string]) =>
        ShopActionTypes.articlesLoaded({ articles: items, category: category }),
      ),
    ),
  );

  setFilterPostCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof ShopActionTypes.setFilterEntities>>(ShopActionTypes.setFilterEntities),
      withLatestFrom(this.store.pipe(select(selectShop))),
      mergeMap(([action, shop]) =>
        _.isNull(action.entities.postCode)
          ? of(null)
          : this.backend.shippingMethodForPostCode$(shop['@id'], action.entities.postCode.uid),
      ),
      concatMap((shippingMethod) => [
        CartActionTypes.setDelivery(),
        CartActionTypes.setShippingMethod({ shippingMethod }),
      ]),
    ),
  );
}
