import { BACKEND_BASE_URL, Resolver } from 'angular-traversal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { AppJsonConfig } from '~/core/appjsonconfig.service';

@Injectable()
export class MyHttpResolver extends Resolver {
  constructor(
    private http: HttpClient,
    @Inject(BACKEND_BASE_URL) private backend: string,
    private config: AppJsonConfig,
  ) {
    super();
  }

  resolve(path: string, _view: string, _queryString?: string): Observable<any> {
    const subsite = this.config.getConfig('subsite');
    const headers = new HttpHeaders().append('Accept', 'application/json').append('Content-Type', 'application/json');
    return this.http.get(this.backend + subsite + path, { headers });
  }
}
// https://robinraju.io/angular-loading-application-settings-from-external-file/
