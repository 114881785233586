import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SearchData } from '~/front/models/searchresult';
import { ZoomArticleDialog } from '~/shared/zoom-article/zoom-article-dialog';
import { ArticleSearchResult, ShopSearchResult } from '~/core/models';
import { ArticleOptionsService } from '~/core/articleoptions.service';
import { Option } from '~/core/models';

@Component({
  selector: 'apym-itemresult',
  templateUrl: './itemresult.component.html',
  styleUrls: ['./itemresult.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemresultComponent implements OnInit {
  @Input() article: ArticleSearchResult;
  @Input() shop: ShopSearchResult;
  @Input() searchData: SearchData;
  variants: Option[];
  private hasOptions: boolean;

  constructor(public dialog: MatDialog, private articleOptions: ArticleOptionsService) {}

  ngOnInit(): void {
    if (!this.article.use_subarticle) {
      this.variants = this.articleOptions.getOptionsBasePrice(this.article.options);
      this.hasOptions = this.articleOptions.hasOptions(this.article.options);
    }
  }

  openZoom(): void {
    this.dialog.open(ZoomArticleDialog, {
      data: {
        title: this.article.name,
        url: this.article.image.scales.large.download,
      },
    });
  }
}
