<span class="pa-badge {{colorClass}}"
      [id]="id"
      [class.pa-badge-accent]="isAccented"
      [class.pa-badge-small]="isSmall"
      [class.pa-badge-error]="isError"
      [class.pa-badge-avatar]="!!_avatar"
      [ngStyle]="colorStyle">

    <pa-avatar *ngIf="!!_avatar" [avatar]="_avatar" size="small"></pa-avatar>

    <span *ngIf="!_value"
          class="pa-badge-value"
          [ngStyle]="colorStyle"
          [class.pa-badge-ellipsis]="!!maxWidth"
          [style.max-width]="maxWidth"
          [paTooltip]="text" #textContent><ng-content></ng-content></span>
    <pa-button *ngFor="let btn of buttons"
                 size="small" [icon]="btn.icon" [color]="btn.color" (click)="btn.onClick()">{{btn.name}}</pa-button>
    <pa-button *ngIf="canBeRemoved" aria-label="Remove"
                 size="small" icon="clear" color="secondary" (click)="remove.emit($event)">Remove</pa-button>
    <ng-container *ngIf="!!_value || _value === 0">{{ _value | number }}<span *ngIf="of"><abbr title="of">/</abbr>{{ of | number }}</span></ng-container>
</span>

