<div *ngIf="ratingsStats$ | async as ratingsStats">
    <div class="wrapper">
        <div>
            <p [innerHTML]=" 'ratingstats.generalRatingOf' | translate"></p>
            <div class="stars-wrapper">
                <app-stars [rating]="ratingsStats.average_rating"
                            maxRating="10"
                            [word]="">
                </app-stars>
            </div>
            <p [innerHTML]=" 'ratingstats.basedOn' | translate : {totalRatings:ratingsStats.count | number} "></p>
        </div>
        <div class="badge-rating">
            <img src="/assets/opiniones-verificadas-badge-text.webp" />
        </div>
    </div>
</div>
