import { Time } from '@angular/common';
import * as moment from 'moment';

export enum pickupDeliveryDay {
  PICKUP_SAME_DAY = 0,
  PICKUP_NEXT_DAY = 1,
  PICKUP_TWO_DAYS = 2,
  DELIVERY_SAME_DAY = 3,
  DELIVERY_NEXT_DAY = 4,
  DELIVERY_TWO_DAYS = 5,
}

export interface MomentTimerange {
  fromTime: moment.Moment;
  toTime: moment.Moment;
  asap?: boolean;
  fromMinute?: number;
  toMinute?: number;
}

export interface StringTimerange {
  fromTime: string;
  toTime: string;
  asap?: boolean;
  fromMinute?: number;
  toMinute?: number;
}

export interface Timerange {
  fromTime: Time;
  toTime: Time;
}

export interface Timetable {
  date: Date;
  timetable: Timerange[];
}

export interface UserOptionsData {}

export function MomentTimerangeToJson(value: MomentTimerange): string {
  return JSON.stringify({
    ...value,
    fromTime: value.fromTime.format('HH:mm:ss'),
    toTime: value.toTime.format('HH:mm:ss'),
  });
}

export function JsonToMomentTimerange(value: string): MomentTimerange {
  const result = JSON.parse(value);
  return {
    ...result,
    fromTime: moment(result.fromTime, 'HH:mm:ss'),
    toTime: moment(result.toTime, 'HH:mm:ss'),
  };
}

export function JsonToStringTimerange(value: string): StringTimerange {
  return JSON.parse(value);
}
