import { ActionReducer, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

const stateInactivity = 20 * 1000; // milliseconds

export interface AppState {
  // _timestamp: number;
}

const initialState: AppState = {
  _timestamp: new Date().getTime(),
};

export const reducers: ActionReducerMap<AppState> = {};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['cart'],
    storage: sessionStorage || window.sessionStorage,
    rehydrate: true,
  })(reducer);
}

function clearStateInactive(reducer: ActionReducer<any>): ActionReducer<any> {
  return function newReducer(state, action) {
    const now = new Date().getTime();
    if (typeof state !== 'undefined' && (!state.hasOwnProperty('_timestamp') || state._timestamp > now - stateInactivity)) {
      state = { ...state, _timestamp: now };
    } else {
      console.log('clearing state');
      state = undefined;
    }
    console.log('clearStateInactive 1', typeof state !== 'undefined' ? state._timestamp : null);
    const nextState = reducer(state, action);
    console.log('clearStateInactive 2', typeof state !== 'undefined' ? state._timestamp : null);
    return nextState;
  };
}

// only use localStorage on browser
export const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer];
// export const metaReducers: MetaReducer<any, any>[] =
//     environment.production ? [localStorageSyncReducer, clearStateInactive]
//     : [localStorageSyncReducer, clearStateInactive, storeFreeze];
