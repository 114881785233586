<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<span class="translation" #removeConfirmation>{{ 'user-addresses.areYouSure' | translate }}
</span>

<div class="content-wrapper">
  <h1>{{ 'user-addresses.yourAddresses' | translate }}</h1>
  <p>{{ 'user-addresses.listAddresses' | translate }}</p>
  <p>{{ 'user-addresses.addUpToFour' | translate }}</p>

  <ng-container *ngIf="useGooglePlaces">
    <ng-container *ngIf="editingAddress$ | async; else showAddresses">
      <h2>{{ 'user-addresses.editShippingAddress' | translate }}</h2>
      <div *ngIf="(editFormState$ | async) as editFormState">
        <form novalidate [ngrxFormState]="editFormState">

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.giveName' | translate }}" id="name"
                   name="name" [ngrxFormControlState]="editFormState.controls.addressName"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.company' | translate }}" id="organization"
                   name="organization" [ngrxFormControlState]="editFormState.controls.organization"/>
          </mat-form-field>

          <mat-form-field *ngIf="showSearchGooglePlaces$ | async; else showAddress">
            <textarea matInput type="text"
                      ngx-google-places-autocomplete
                      [options]='placesOptions'
                      #placesRef="ngx-places"
                      (onAddressChange)="handleAddressChange($event, editFormState.value)"
                      size="40"
                      rows="2"
                      placeholder="{{'checkout-delivery.autocompleteAddress' | translate }}"
            ></textarea>
          </mat-form-field>
          <mat-error *ngIf="googleAddressRouteError">{{'checkout-delivery.errorAddressIncomplete' | translate }}</mat-error>

          <ng-template #showAddress>
            <div class="selected-address-wrapper">
              <div class="selected-address">
                <div>{{ editFormState.value.address }} {{ editFormState.value.addressStreetNumber }}
                  <span *ngIf="editFormState.value.addressComplement">, {{ editFormState.value.addressComplement }}</span>
                </div>
                <ng-container *ngIf="editFormState.controls.postCode.userDefinedProperties.POSTCODES || [] as postCodes">
                  {{ getEntityName(editFormState.value.postCode, postCodes) }}
                </ng-container>
 <!--               <ng-container *ngIf="editFormState.controls.city.userDefinedProperties.CITIES || [] as cities">
                  {{ getEntityName(editFormState.value.city, cities) }}
                </ng-container>
               <ng-container *ngIf="editFormState.controls.county.userDefinedProperties.COUNTIES || [] as counties">
                  {{ getEntityName(editFormState.value.county, counties) }}
                </ng-container>
                <ng-container *ngIf="editFormState.controls.country.userDefinedProperties.COUNTRIES || [] as countries">
                  {{ getEntityName(editFormState.value.country, countries) }}
                </ng-container>
                -->
              </div>
              <div>
                <button mat-mini-fab color="primary" (click)="clearShippingAddress(editFormState.value)"><i class="fas fa-pencil-alt"></i></button>
              </div>
            </div>
          </ng-template>

          <div class="no-street-number" *ngIf="!editFormState.value.addressStreetNumber">
            <div>{{'checkout-delivery.upsNoStreetNumber' | translate }}</div>
                <mat-form-field>
                  <input matInput
                         placeholder="{{ 'checkout-delivery.streetNumber' | translate }}"
                         name="addressStreetNumber"
                         #streetNumber>
                </mat-form-field>
            <div>
              <button [disabled]="!streetNumber.value"  mat-raised-button color="warn" (click)="setStreetNumber(streetNumber.value)">
                {{ 'checkout-delivery.ok' | translate }}</button>
              <button id="no-number" mat-raised-button color="warn" (click)="setStreetNumberWithoutNumber()">
                {{'checkout-delivery.streetHasNoNumber' | translate}}
              </button>
            </div>
          </div>
          <mat-form-field class="address-complement">
            <textarea matInput name="shippingAddressComplement"
                      placeholder="{{ 'checkout-delivery.addressComplement' | translate }}"
                      [ngrxFormControlState]="editFormState.controls.addressComplement"
                      maxlength="100" rows="2">
            </textarea>
          </mat-form-field>
          <button mat-raised-button (click)="cancel()">{{ 'user-addresses.cancel' | translate }}</button>
          <button mat-raised-button color="warn" [disabled]="!editFormState.isValid" (click)="onSubmit()">{{ 'user-addresses.save' | translate }}</button>
        </form>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!useGooglePlaces">
    <ng-container *ngIf="editingAddress$ | async; else showAddresses">
      <h2>{{ 'user-addresses.editShippingAddress' | translate }}</h2>
      <div *ngIf="(editFormState$ | async) as editFormState">
        <form novalidate [ngrxFormState]="editFormState">

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.giveName' | translate }}" id="name"
                   name="name" [ngrxFormControlState]="editFormState.controls.addressName"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.company' | translate }}" id="organization"
                   name="organization" [ngrxFormControlState]="editFormState.controls.organization"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.streetNumberDoor' | translate }}" id="address"
                   name="address" [ngrxFormControlState]="editFormState.controls.address"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="{{ 'user-addresses.complement' | translate }}" id="address"
                   name="address" [ngrxFormControlState]="editFormState.controls.addressComplement"/>
          </mat-form-field>

          <mat-form-field *ngIf="editFormState.controls.country.userDefinedProperties.COUNTRIES || [] as countries"
                          [hidden]="countries.length === 1">
            <mat-label>{{ 'user-addresses.selectCountry' | translate }}</mat-label>
            <mat-select [ngrxFormControlState]="editFormState.controls.country">
              <mat-option *ngFor="let country of countries"
                          [value]="country.uid">
                  {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editFormState.controls.county.userDefinedProperties.COUNTIES || [] as counties"
                          [hidden]="counties.length === 0">
            <mat-label>{{ 'user-addresses.selectCounty' | translate }}</mat-label>
            <mat-select [ngrxFormControlState]="editFormState.controls.county"
                        [disabled]="!editFormState.value.country">
              <mat-option *ngFor="let county of counties"
                          [value]="county.uid">
                  {{ county.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editFormState.controls.city.userDefinedProperties.CITIES || [] as cities"
                          [hidden]="cities.length === 0">
            <mat-label>{{ 'user-addresses.selectCity' | translate }}</mat-label>
            <mat-select [ngrxFormControlState]="editFormState.controls.city"
                        [disabled]="!editFormState.value.county">
              <mat-option *ngFor="let city of cities"
                          [value]="city.uid">
                  {{ city.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editFormState.controls.postCode.userDefinedProperties.POSTCODES || [] as postCodes"
                          [hidden]="postCodes.length === 0">
            <mat-label>{{ 'user-addresses.selectPostCode' | translate }}</mat-label>
            <mat-select [ngrxFormControlState]="editFormState.controls.postCode"
                        [disabled]="!editFormState.value.city">
              <mat-option *ngFor="let postCode of postCodes"
                          [value]="postCode.uid">
                  {{ postCode.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button (click)="cancel()">{{ 'user-addresses.cancel' | translate }}</button>
          <button mat-raised-button color="warn" (click)="onSubmit()">{{ 'user-addresses.save' | translate }}</button>
        </form>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #showAddresses>
    <div class="addresses-wrapper">
      <div *ngFor="let address of addresses$ | async">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ address.addressName }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="address-organization">{{ address.organization }}</div>
            <div>{{ address.address }}, {{ address.addressComplement }}</div>
            <!--<div>{{ address.addressComplement }}</div>-->
            <div>{{ address.postCode?.name }} {{ address.city?.name }}</div>
            <!--<div>{{ address.county?.name }} - {{ address.country?.name }}</div>-->
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="warn" (click)="edit(address)">{{ 'user-addresses.edit' | translate }}</button>
            <button mat-raised-button color="warn" (click)="remove(address)">{{ 'user-addresses.delete' | translate }}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <div class="add-new-address">
      <button mat-raised-button color="basic" *ngIf="canAdd$ | async" (click)="add()">{{ 'user-addresses.addNewAddress' | translate }}</button>
    </div>
  </ng-template>
</div>
