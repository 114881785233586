import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { CartModule } from './cart/cart.module';
import { CoreModule } from './core/core.module';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { environment, extModules } from '../environments/environment';
import { FrontModule } from './front/front.module';
import { GrangeRootModule } from 'grange';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { BACKEND_BASE_URL, Marker, NAVIGATION_PREFIX, Normalizer, Resolver } from 'angular-traversal';
// All material modules are declared here due to a patch
// that is provided fir ngrx-forms
// See: https://github.com/MrWolfZ/ngrx-forms/issues/62
import { MaterialModule } from './material';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
// import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaFormsModule, RecaptchaSettings } from 'ng-recaptcha';
import { reducers, metaReducers } from './reducers';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { ShopModule } from './shop/shop.module';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TypeMarker, AppComponent } from './app.component';
import { UserModule } from './user/user.module';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';
import localeGl from '@angular/common/locales/gl';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AppJsonConfig } from './core/appjsonconfig.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MyHttpResolver } from './resolver';
import { FullPathNormalizer } from './normalizer';

_('languages.ca');
_('languages.en');
_('languages.es');
_('languages.eu');
_('languages.gl');

registerLocaleData(localeCa, 'ca');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEu, 'eu');
registerLocaleData(localeGl, 'gl');

function initConfig(config: AppJsonConfig) {
  return () => config.load();
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#fff',
    },
    button: {
      background: '#f44336',
    },
  },
  content: {
    href: '/legal/directiva-de-cookies',
  },
  theme: 'edgeless',
  type: 'info',
  //type: 'opt-out'
};

export function LocaleIdFactory(translate: TranslateService): string {
  return translate.currentLang;
}

export class DynamicLocaleId extends String {
  get locale(): string {
    return this.translate.currentLang;
  }

  constructor(private translate: TranslateService) {
    super();
  }

  toString(): string {
    return this.translate.currentLang;
  }
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    //BrowserModule,
    // nguniversal
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    MaterialModule,
    extModules,
    GooglePlaceModule,
    GrangeRootModule.forRoot(),
    EffectsModule.forRoot([]),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    // NgxCaptchaModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    LayoutModule,
    ShopModule,
    CartModule,
    FrontModule,
    SharedModule,
    CoreModule,
    UserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    Meta,
    DatePipe,
    CurrencyPipe,
    CookieService,
    AppJsonConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppJsonConfig],
      multi: true,
    },
    {
      provide: 'CONFIGURATION',
      useValue: {
        get BACKEND_URL() {
          return environment.ploneBackendUrl();
        },
        CLIENT_TIMEOUT: 30000,
      },
    },
    // { provide: NAVIGATION_PREFIX, useValue: '/web-pasteles/pastelerias/kortezubi/pasteleria-el-dulzon' },
    // { provide: BACKEND_BASE_URL, useValue: 'http://localhost:8080/www_apanymantel_com' },
    { provide: Marker, useClass: TypeMarker },
    { provide: Normalizer, useClass: FullPathNormalizer },
    { provide: Resolver, useClass: MyHttpResolver },
    { provide: BACKEND_BASE_URL, useValue: environment.ploneBackendUrl() },
    // { provide: LOCALE_ID, useValue: 'es' },
    // { provide: LOCALE_ID, useFactory: (translate: TranslateService) => translate.currentLang, deps: [TranslateService] },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    // {
    //     provide: RECAPTCHA_SETTINGS,
    //     useValue: { siteKey: '6LfGFv4UAAAAADuKk9aMreTo0V7aU6iNf872TkbS' } as RecaptchaSettings,
    //     //useValue: { siteKey: '6Lf44CcTAAAAANBsxk5keGncXGQ1QMFoEHnBeiNb' } as RecaptchaSettings,
    // },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
