import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '~/reducers';
import {
  fromUser,
  selectLoading,
  selectUserInfo,
  selectUserInfoChangePasswordError,
  selectUserInfoEditForm,
  selectUserInfoEditing,
  selectUserPasswordChangeForm,
  selectUserPasswordChanging,
  selectUserInfoChangedPassword,
  UserActionTypes,
} from '~/user/store';
import { PasswordError } from '~/user/models';
import { FormGroupState } from 'ngrx-forms';
import { takeUntil } from 'rxjs/operators';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { UserInfo } from '~/core/models';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent extends BaseViewComponent implements OnInit {
  loading$: Observable<boolean>;
  editing$: Observable<boolean>;
  changingPassword$: Observable<boolean>;
  userInfo$: Observable<UserInfo>;
  editUserInfoFormState$: Observable<FormGroupState<fromUser.UserInfoEditFormValue>>;
  changeUserPasswordFormState$: Observable<FormGroupState<fromUser.UserPasswordChangeFormValue>>;
  changePasswordError$: Observable<PasswordError>;
  changedPassword$: Observable<boolean>;

  constructor(public grange: Grange, public seo: SeoService, public store: Store<AppState>) {
    super(grange, seo);
    this.grange.core.auth.isAuthenticated.pipe(takeUntil(this.destroy)).subscribe((auth) => {
      if (!auth.state) {
        this.grange.traverser.traverse('/@@login');
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading$ = this.store.pipe(select(selectLoading));

    this.editUserInfoFormState$ = this.store.pipe(select(selectUserInfoEditForm));

    this.changeUserPasswordFormState$ = this.store.pipe(select(selectUserPasswordChangeForm));

    // this.changePasswordForm = this.fb.group({
    //     'currentPassword': ['', Validators.required],
    //     'newPassword': ['', Validators.required],
    //     'newPasswordConfirm': ['', Validators.required]
    // }, {validator: MustMatch('newPassword', 'newPasswordConfirm')});

    this.userInfo$ = this.store.pipe(select(selectUserInfo));

    this.changePasswordError$ = this.store.pipe(select(selectUserInfoChangePasswordError));

    this.editing$ = this.store.pipe(select(selectUserInfoEditing));

    this.changingPassword$ = this.store.pipe(select(selectUserPasswordChanging));

    this.store.dispatch(new UserActionTypes.UserInfoRequested());

    this.changedPassword$ = this.store.pipe(select(selectUserInfoChangedPassword));
  }

  // convenience getter for easy access to form fields
  // f() { return this.changePasswordForm.controls; }

  edit(editingUserInfo: UserInfo) {
    this.store.dispatch(new UserActionTypes.EditUserInfo(editingUserInfo));
  }

  cancelEditUserInfo() {
    this.store.dispatch(new UserActionTypes.EditUserInfoCancelled());
  }

  onSubmitUserInfo() {
    this.store.dispatch(new UserActionTypes.UserInfoSaveRequest());
  }

  cancelChangeUserPassword() {
    this.store.dispatch(new UserActionTypes.ChangeUserPasswordCancelled());
  }

  changePassword() {
    this.store.dispatch(new UserActionTypes.ChangeUserPassword());
  }

  onSubmitUserPassword() {
    this.store.dispatch(new UserActionTypes.ChangeUserPasswordRequest());
  }
}
