import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Grange } from 'grange';
import { WINDOW } from '~/core/window.service';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-plonesite',
  templateUrl: './plonesite.component.html',
  styleUrls: ['./plonesite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PloneSiteComponent extends BaseViewComponent {
  constructor(public grange: Grange, public seo: SeoService, @Inject(WINDOW) private window: Window) {
    super(grange, seo);
  }

  scrollTop(): void {
    this.window.scroll(0, 0);
  }
}
