<div class="mas-info">
<!--  <div class="all-shop-features">-->
    <div class="shop-features">
      <mat-icon>redeem</mat-icon>
      <h5>{{ 'shop-header.specialitiesColon' | translate }}</h5>
      <div class="content">{{ shop.specialities }}</div>
    </div>
    <div class="shop-features">
      <mat-icon>delivery_dining</mat-icon>
      <h5>{{ 'shop-header.homeDeliveryColonSpace' | translate }}</h5>
      <div class="content">
        <div>
          <b>{{ 'shop-header.minimumOrder' | translate }}:</b>
          <span>{{ shop.minimum_amount_delivery_order | apymCurrency:'€':'symbol':'1.2-2'}}</span>
        </div>
        <div>
          <b>{{ 'shop-header.citiesWithDelivery' | translate }}:</b>
          <div class="places-wrapper">{{ (deliveryCities$ | async)?.join(' * ') }}</div>
        </div>
      </div>
    </div>
    <div class="shop-features">
      <mat-icon>store</mat-icon>
      <h5>{{ 'shop-header.pickupColonSpace' | translate }}</h5>
      <div class="content">
        <div>
          <b>{{ 'shop-header.minimumOrder' | translate }}:</b>
          <span>{{ shop.minimum_amount_pickup_order | apymCurrency:'€':'symbol':'1.2-2'}}</span>
        </div>
        <div><b>{{ 'shop-header.shopsWherePickup' | translate }}:</b>
          <ul class="places-wrapper">
            <li *ngFor="let shopAddress of shopAddresses$ | async">
              {{ shopAddress.address }} ({{ shopAddress.city }})
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="shop-features">
      <mat-icon>calendar_month</mat-icon>
      <h5>{{ 'shop-header.deliveryPickupTimetables' | translate }}</h5>
      <div class="content">
        <div class="delivery-pickup-timetable" *ngIf="timetables$ | async as timetables">
          <mat-tab-group mat-stretch-tabs>
            <mat-tab>
              <ng-template mat-tab-label><b [innerHTML]=" 'shop-header.deliveryTimetable' | translate "></b></ng-template>
              <apym-timetable caption="{{ 'shop-header.deliveryTimetable' | translate }}" [timetable]="timetables.deliveryTimetable"></apym-timetable>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label><b [innerHTML]=" 'shop-header.pickupTimetable' | translate "></b></ng-template>
              <apym-timetable caption="{{ 'shop-header.openingTimetable' | translate }}" [timetable]="timetables.openingTimetable"></apym-timetable>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
    <div class="shop-features">
      <mat-icon>euro_symbol</mat-icon>
      <h5>{{ 'shop-header.paymentMethods' | translate }}</h5>
      <div class="content">
        <div class="payments-list">
          <ul>
            <li class="payment-option cash" data-toggle="tooltip" title="Pagar al recibir el Producto"></li>
            <li class="payment-option bizum" data-toggle="tooltip" title="Bizum"></li>
            <li class="payment-option visa" data-toggle="tooltip" title="Tarjeta VISA"></li>
            <li class="payment-option visa-electron" data-toggle="tooltip" title="Tarjeta VISA ELECTRON"></li>
            <li class="payment-option mastercard" data-toggle="tooltip" title="Tarjeta MASTERCARD"></li>
            <li class="payment-option amex" data-toggle="tooltip" title="Tarjeta AMERICAN EXPRESS"></li>
            <li class="payment-option paypal" data-toggle="tooltip" title="PAYPAL"></li>
            <li class="payment-option ingreso-en-cuenta" data-toggle="tooltip" title="Ingreso en Cuenta - 3 días de antelación"></li>
            <li class="payment-option transferencia hidden-xs" data-toggle="tooltip" title="Transferencia - 5 días de antelación"></li>
          </ul>
        </div>
      </div>
    </div>
<!--  </div>-->
</div>
