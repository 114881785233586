import { Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';

import { AppState } from '~/reducers';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';
import { selectShop, ShopActionTypes } from '~/shop/store';
import { Shop } from '~/core/models';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent extends BaseViewComponent implements OnInit {
  shop$: Observable<Shop>;

  constructor(public grange: Grange, public seo: SeoService, public store: Store<AppState>) {
    super(grange, seo);
  }

  ngOnInit(): void {
    // this.store.dispatch(ShopActionTypes.getArticleShop({ path: this.context['@id'] }));
    this.shop$ = this.store.pipe(
      select(selectShop),
      withLatestFrom(this.context.pipe(take(1))),
      tap((result) => console.log('ArticleComponent', result)),
      map(([shop, article]) => {
        if (_.isUndefined(shop)) {
          this.store.dispatch(ShopActionTypes.getArticleShop({ path: article['@id'] }));
        }
        return shop;
      }),
    );
  }
}
