import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroupState, SetErrorsAction } from 'ngrx-forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { fromCart } from '~/cart/store';
import { AppState } from '~/reducers';
import { selectCartShop, selectCheckoutRecipientData } from '~/cart/store';
import * as CartModel from '~/cart/models';
import { ngIntlTelInput } from '~/cart/checkout/ng-intl-tel-input';

@Component({
  selector: 'app-checkout-recipientdata',
  templateUrl: './checkout-recipientdata.component.html',
  styleUrls: ['./checkout-recipientdata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutRecipientdataComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutRecipientDataForm>;
  cartShop$: Observable<CartModel.CartShop>;
  @ViewChild('recipientPhone', { static: false }) recipientPhone: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cartShop$ = this.store.pipe(select(selectCartShop));
  }

  ngAfterViewInit() {
    const controlId = 'CheckoutForm.recipientData.recipientPhone';
    if (this.recipientPhone) {
      this.subscriptions.push(
        this.store.select(selectCheckoutRecipientData)
        .pipe(map((recipientData) => recipientData.hasRecipient))
        .subscribe((required) => {
          if (required) {
            this.subscriptions.push(
              ngIntlTelInput(
                this.recipientPhone,
                controlId,
                this.store,
                this.store.select(selectCheckoutRecipientData).pipe(map((recipientData) => recipientData.recipientPhone)),
              ),
            );
          } else {
            this.store.dispatch(new SetErrorsAction(controlId, {}));
          }
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
