<mat-radio-group [ngrxFormControlState]="formGroup.controls.loginContinue">
    <mat-radio-button color="primary" value="loggedIn">
        {{ 'checkout-register.alreadyAccount' | translate }}
    </mat-radio-button>
    <div *ngIf="formGroup.value.loginContinue === 'loggedIn'">
        <mat-error *ngIf="loginError"><div class="apym-error-msg">{{ 'checkout-register.loginError' | translate }}</div></mat-error>
        <div class="checkout-login">
            <mat-form-field>
                <input matInput
                       #userName
                       type="email"
                       placeholder="Email"
                       value="" />
            </mat-form-field>
            <mat-form-field>
                <input matInput
                       #passwd
                       type="password"
                       placeholder="{{ 'checkout-register.password' | translate }}"
                       value=""/>
            </mat-form-field>
            <button mat-raised-button color="warn" (click)="login(userName.value, passwd.value)">{{ 'checkout-register.login' | translate }}</button>
        </div>
    </div>

    <mat-radio-button color="primary" value="anonymous">
        <span>{{ 'checkout-register.noRegister' | translate }}</span>
        <div><small>({{ 'checkout-register.laterRegister' | translate }})</small></div>
    </mat-radio-button>

</mat-radio-group>
