import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Grange } from 'grange';

import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'apym-shopitem',
  templateUrl: './shopitem.component.html',
  styleUrls: ['./shopitem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopitemComponent extends BaseViewComponent {
  constructor(public grange: Grange, public seo: SeoService) {
    super(grange, seo);
  }
}
