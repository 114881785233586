<ng-container *ngIf="shop$ | async as shop">
  <!-- shop: {{ !href(shop['@id'], shop.pickup_on_date, shop.delivery_on_date) }} -->
  <!-- <ng-container *ngTemplateOutlet="shopResult"> -->
  <!-- </ng-container> -->
  <a *ngIf="href(shop['@id'], shop.pickup_on_date, shop.delivery_on_date)" [href]="href(shop['@id'], shop.pickup_on_date, shop.delivery_on_date)">
    <ng-container *ngTemplateOutlet="shopResult">
    </ng-container>
  </a>
  <a *ngIf="!href(shop['@id'], shop.pickup_on_date, shop.delivery_on_date)" [traverseTo]="traverse(shop['@id'], shop.pickup_on_date, shop.delivery_on_date)">
    <ng-container *ngTemplateOutlet="shopResult">
    </ng-container>
  </a>
  <ng-template #shopResult>
    <mat-card>
      <!-- First Image -->
      <div class="pic-container">
        <img mat-card-image [src]="shop.image.large"/>
        <div class="centered-text">
          <h3 class="shop-name">{{ shop.name }}</h3>
          <div class="specialities-wrapper"><span class="specialities"><i>{{ shop.specialities }}</i></span></div>
        </div>
      </div>
      <mat-card-content>
        <div class="shop-data-wrapper">
        <!-- shop ratings -->
        <div class="shop-rating" *ngIf="shop.ratings">
          <div class="no-ratings-available" *ngIf="shop.ratings.rating_quantity === 0">
            <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
            <div>{{ 'shopresult.newShop' | translate }}</div>
            <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
          </div>
          <div class="shop-data" *ngIf="shop.ratings.rating_quantity > 0">
            <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
            <div class="ratings-col data">
              <app-stars [rating]="shop.ratings.stars"
                         maxRating="10"
                         [quantityRatings]="shop.ratings.rating_quantity"
                         [word]="shop.ratings.word"
                         [compact]="true"
                         [path]="shop.ratings['@id']"></app-stars>
            </div>
          </div>
        </div>
        <!-- shipping costs -->
        <div class="shop-data" *ngIf="shop.minmax_shipping_fee">
          <mat-icon aria-hidden="false" aria-label="delivery">delivery_dining</mat-icon>
          <div class="delivery-col data">
            <div>
              <span class="data" *ngIf="shop.minmax_shipping_fee[0] === shop.minmax_shipping_fee[1]">
                {{ shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2' }}
              </span>
              <span class="data" *ngIf="shop.minmax_shipping_fee[0] !== shop.minmax_shipping_fee[1]">
                {{ 'shopresult.startingFrom' | translate: {shippingFee: shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2'} }}
              </span>
            </div>
            <div class="free-delivery-offer" *ngIf="shop.minimum_order_without_shipping_costs">
              {{ 'shopresult.moreThanXFree' | translate: {minAmount: shop.minimum_amount_without_shipping_costs | apymCurrency:'€':'symbol':'1.2-2'} }}
            </div>
          </div>
        </div>
        <!-- Minimum Delivery Price -->
        <div class="shop-data">
          <mat-icon aria-hidden="false">euro_symbol</mat-icon>
          <div class="data">
            Min. <span *ngIf="shop.minimum_amount_delivery_order">{{ shop.minimum_amount_delivery_order | apymCurrency:'€':'symbol':'1.2-2' }}</span>
          </div>
        </div>
        <!-- Minimum Delivery Time -->
        <div class="shop-data">
          <mat-icon aria-hidden="false">schedule</mat-icon>

          <div class="data">
            {{ shop.minimum_time_string }}
          </div>
        </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
