<div class="pa-toast in" [class.is-sibling]="isSibling" [class.out]="isDismissed" role="status" aria-live="polite"
     attr.aria-labelledby="{{ariaLabeledBy}}" #toastContainer tabindex="-1" (keyup)="dismissWithESC($event)">

    <header class="pa-toast-header">
        <h2 class="pa-toast-title" [id]="!parsedMessage ? ariaLabeledBy : null">
            <!-- Leading icon -->
            <pa-icon *ngIf="!!toast && !!toast.icon" [name]="toast.icon" [hidden]="true"></pa-icon>

            <div *ngIf="parsedMessage" [innerHTML]="parsedMessage" [id]="parsedMessage ? ariaLabeledBy : null"></div>
            <ng-container *ngIf="!parsedMessage && !!toast">
                {{toast.message | translate:toast.translateParams}}
            </ng-container>
        </h2>
    </header>
    <footer class="pa-toast-footer" *ngIf="!!toast && toast.buttons.length > 0">
        <div class="pa-toast-footer-wrapper">
            <!-- Customized buttons -->
            <ng-container *ngIf="!!toast && toast.buttons.length > 0">
                <pa-button *ngFor="let button of toast.buttons; let i = index"
                           [color]="button.color"
                           [icon]="button.icon"
                           [paTooltip]="button.tooltip"
                           tabindex="{{i}}"
                           (click)="handleDismiss(button.text)">
                    <span translate>{{button.text}}</span>
                </pa-button>
            </ng-container>
        </div>
    </footer>
</div>
