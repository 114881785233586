import { Component, OnDestroy, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { TraverserSelectors } from '@guillotinaweb/ngx-state-traverser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Grange } from 'grange';
import { Resource } from '@guillotinaweb/grange-core';
import { SeoService } from '~/core/seo.service';
import { SeoResource } from '~/shared/models';

@Component({
  template: '',
})
export class BaseViewComponent implements OnDestroy, OnInit {
  destroy = new Subject();
  context = TraverserSelectors.TraverserContext<Resource>(this.grange.store).pipe(takeUntil(this.destroy));
  contextPath = this.grange.store.pipe(takeUntil(this.destroy), select(TraverserSelectors.getContextPath));
  parentPath = this.grange.store.pipe(takeUntil(this.destroy), select(TraverserSelectors.getParentPath));
  isForbidden = this.grange.store.pipe(takeUntil(this.destroy), select(TraverserSelectors.isForbidden));
  constructor(public grange: Grange, public seo: SeoService) {
    this.isForbidden.subscribe((isForbidden) => {
      if (isForbidden) {
        this.grange.traverser.traverse('./@@login');
      }
    });
    /*this.grange.core.auth.isAuthenticated.pipe(takeUntil(this.destroy)).subscribe(auth => {
        if (!auth.state) {
            this.grange.traverser.traverse('./@@login');
        }
    });*/
  }

  ngOnInit() {
    this.context.subscribe((result: SeoResource) => {
      this.seo.generateTags({
        description: result.seo_description || '',
        keywords: result.seo_keywords || '',
        title: result.seo_title || '',
      });
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
