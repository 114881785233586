import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { HideInAppService } from '~/core/hideinapp.service';

@Directive({
  selector: '[hideInApp]',
})
export class HideInAppDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(private hideinapp: HideInAppService, private renderer2: Renderer2, private elementRef: ElementRef) {}

  // ngOnInit(): void {
  //   this.subscription = this.grange.traverser.target
  //     .pipe(map((target) => target.query))
  //     .subscribe((query: HttpParams) => {
  //       if (query.get('source') === 'app') {
  //         //this.renderer2.removeChild(this.elementRef.nativeElement);
  //         this.elementRef.nativeElement.remove();  //style.display = 'none';
  //       }
  //     });
  // }
  ngOnInit(): void {
    this.subscription = this.hideinapp.inApp$().subscribe((inApp) => {
      if (inApp) {
        this.elementRef.nativeElement.remove(); //style.display = 'none';
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
