<mat-form-field class="full-width-form-field">
    <textarea matInput
              placeholder="{{ 'checkout-messageforshop.commentForShop' | translate }}"
              class="textarea-widget text-field"
              [ngrxFormControlState]="formGroup.controls.comment"
              maxlength="200"
              rows="4">
    </textarea>
    <mat-hint align="end">{{ 'checkout-messageforshop.charactersLeft' | translate : {messageLength: 200 - (formGroup.value.comment.length || 0)} }}
    </mat-hint>
</mat-form-field>
<div id="important-note">
    <small>
       <p *ngIf="(cartShop$ | async).checkoutCustomMessage as checkoutCustomMessage" class="help">{{ checkoutCustomMessage }}</p>
       <p class="help">{{ 'checkout-messageforshop.notes1' | translate }}</p>
        <!-- <p class="help">{{ 'checkout-messageforshop.notes2' | translate }}</p>-->
    </small>
</div>
