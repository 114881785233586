import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
// import 'rxjs/add/operator/share';
import { combineLatest } from 'rxjs';
import { Grange } from 'grange';

import { AppState } from '~/reducers';
import {
  CartActionTypes,
  CartState,
  selectCartShop,
  selectCartState,
  selectCartTotals,
  selectCheckingout,
  selectAmountToReachMinimum,
  selectCheckoutIsDelivery,
} from '~/cart/store';
import { CartItem, CartTotals } from '~/cart/models';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  public checkingout$: Observable<boolean>;
  public totals$: Observable<CartTotals>;
  public cart$: Observable<CartState>;
  public checkoutDelivery$: Observable<CartTotals>;
  public checkoutPickup$: Observable<CartTotals>;
  public toReachMinimum$: Observable<{ delivery: number; pickup: number }>;
  public checkoutIsDelivery$: Observable<boolean>;

  constructor(public grange: Grange, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.checkingout$ = this.store.select(selectCheckingout);
    this.totals$ = this.store.select(selectCartTotals);
    this.cart$ = this.store.select(selectCartState);
    this.checkoutDelivery$ = combineLatest(
      this.totals$,
      this.cart$.pipe(filter((cart) => cart.shop !== null)),
      (totals, cart) => (cart.shop.hasDelivery ? totals : null),
    );
    this.checkoutPickup$ = combineLatest(
      this.totals$,
      this.cart$.pipe(filter((cart) => cart.shop !== null)),
      (totals, cart) => (cart.shop.hasPickup ? totals : null),
    );
    this.toReachMinimum$ = this.store.select(selectAmountToReachMinimum);
    this.checkoutIsDelivery$ = this.store.select(selectCheckoutIsDelivery);
  }

  incrementQuantity(cartItem: CartItem): void {
    this.store.dispatch(CartActionTypes.incrItem({ cartItem }));
  }

  decrementQuantity(cartItem: CartItem): void {
    this.store.dispatch(CartActionTypes.decrItem({ cartItem }));
  }

  checkoutAsDelivery(): void {
    if (this.sidenav) {
      this.sidenav.close();
    }
    // this.store.dispatch(new ShopActions.FilterCategory(null));
    // this.store.dispatch(new ShopActions.FilterDay(null));
    this.store.dispatch(CartActionTypes.setDelivery());
    // this.grange.traverser.traverse('./@@checkout')
    this.store
      .pipe(select(selectCartShop), take(1))
      .subscribe((cartShop) => this.grange.traverser.traverse(cartShop['@id'] + '/@@checkout'));
  }

  checkoutAsPickup(): void {
    if (this.sidenav) {
      this.sidenav.close();
    }
    // this.store.dispatch(new ShopActions.FilterCategory(null));
    // this.store.dispatch(new ShopActions.FilterDay(null));
    this.store.dispatch(CartActionTypes.setPickup());
    // this.grange.traverser.traverse('./@@checkout');
    this.store
      .pipe(select(selectCartShop), take(1))
      .subscribe((cartShop) => this.grange.traverser.traverse(cartShop['@id'] + '/@@checkout'));
  }
}
