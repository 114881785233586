import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AppState } from '~/reducers';
import { Category } from '~/shop/models';
import { ShopActionTypes, selectAllCategories, selectSelectedCategory } from '~/shop/store';
import { WINDOW } from '~/core/window.service';
import { Grange } from 'grange';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesComponent implements OnInit {
  public categories$: Observable<Category[]>;
  public selectedCategory$: Observable<string>;

  constructor(private store: Store<AppState>, public grange: Grange, @Inject(WINDOW) private window: Window) {}

  ngOnInit(): void {
    this.categories$ = this.store.pipe(select(selectAllCategories));
    this.selectedCategory$ = this.store.pipe(select(selectSelectedCategory));
  }

  selectCategory(event): void {
    this.window.scrollTo(0, 0);
    this.store.dispatch(ShopActionTypes.selectCategory({ category: event.value }));
    this.grange.traverser.traverse('./@@articles?category=' + event.value);
  }
}
