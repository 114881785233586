<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<div class="new-registry-wrapper">
  <h2>{{ 'register.registerFormNewUser' | translate }}</h2>
  <div *ngIf="(registered$ | async) as registered; else registerForm">
    <h3><i class="fas fa-check-circle"></i>{{ 'register.userRegistered' | translate }}</h3>
    <p>{{ 'register.userRegisteredHelp' | translate }}</p>
    <div class="new-registry-data">
      <p><b>{{ 'register.nameColon' | translate }}</b> {{ registered.fullname }}</p>
      <p><b>{{ 'register.emailColon' | translate }}</b> {{ registered.email }}</p>
    </div>
  </div>
  <ng-template #registerForm>
    <div *ngIf="(registerError$ | async) as registerError">
      <div class="apym-error-msg">
        <div class="plone-system-error-advise">
          <div><i class="fas fa-exclamation-triangle"></i>
            {{ 'general.sorryError' | translate }}</div>
          <p><small>{{ 'general.helpError' | translate }}</small></p>
        </div>
        <p *ngIf="registerError.type == 'MissingParameterError'">{{ 'register.enterValidEmailAddress' | translate }}</p><!-- You must enter a valid e-mail address -->
        <p *ngIf="registerError.type == 'WrongParameterError'">{{ 'register.emailIsRequiredField' | translate }}</p><!-- WrongParameterError - Email is required -->
        <div *ngIf="registerError.type != 'MissingParameterError' && registerError.type != 'WrongParameterError'">
                    <!--                    <p>registered error type {{ registerError.type }}</p>-->
          <p>registered error message {{ registerError.message }}</p>
        </div>
      </div>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmitRegister()">
      <div class="ohnoyenoh">
        <label for="name"></label>
        <input tabindex="-1" autocomplete="nope" type="text" id="name" name="name" [formControl]="name" placeholder="Your name here">
        <label for="email"></label>
        <input tabindex="-1" autocomplete="nope" type="email" id="email" name="email" [formControl]="email" placeholder="Your e-mail here">
      </div>
      <mat-form-field>
        <input matInput placeholder="{{ 'register.nameAndSurname' | translate }}" [formControl]="vfrfullname"
               [errorStateMatcher]="matcher">
          <mat-error *ngIf="vfrfullname.invalid && (vfrfullname.dirty || vfrfullname.touched)">
            <div *ngIf="vfrfullname.errors.required">
              {{ 'register.fullnameIsRequired' | translate }}
            </div>
            <div *ngIf="vfrfullname.errors.maxlength">
              {{ 'register.fullnameMaxLength' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="">
          <input matInput placeholder="{{ 'register.email' | translate }}" [formControl]="vfremail"
                 [errorStateMatcher]="matcher">
          <mat-error *ngIf="vfremail.invalid && (vfremail.dirty || vfremail.touched)">
            <div *ngIf="vfremail.errors.required">
              {{ 'register.emailIsRequired' | translate }}
            </div>
            <div *ngIf="vfremail.errors.email">
              {{ 'register.emailNotValid' | translate }}
            </div>
            <div *ngIf="vfremail.errors.maxlength">
              {{ 'register.emailMaxLength' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
        <div class="checkbox-wrapper">
          <mat-checkbox formControlName="sendNewsletter" >{{ 'register.sendNewsletter' | translate }}</mat-checkbox>
          <mat-checkbox formControlName="acceptPolicy" >{{ 'register.acceptPolicy' | translate }}</mat-checkbox>
          <small>{{ 'register.acceptPolicyHelp' | translate }}</small>
        </div>

        <button type="submit" mat-raised-button color="warn"
                [disabled]="!formGroup.valid">{{ 'register.send' | translate }}</button>
     </form>
  </ng-template>
</div>
