import * as moment from 'moment';
import { ShopSearchResult } from '~/core/models';

export enum EntityType {
  country = 'country',
  county = 'county',
  city = 'city',
  postCode = 'postCode',
}

export interface Entity {
  uid: string;
  type: EntityType;
  caption: string;
}

export interface SearchData {
  entity: Entity;
  date: moment.Moment;
  item?: string;
  pickup?: boolean;
}

// export interface SearchResult {
//   '@id': string;
//   items: {
//     '@id': string;
//     '@type': string;
//     description: string;
//     review_state: string;
//     title: string;
//     [key: string]: any;
//   }[];
//   items_total: number;
// }

export interface ShopSearch {
  shops: any[];
  entity_title: string;
  childsites: any[];
}

export interface Shop {
  '@id': string;
  '@uid': string;
  name: string;
}

export interface ChildSite {
  '@uid': string;
  image: string;
  name: string;
  shops: string[];
  totalShops: number;
  shopsDelivery: string[];
  shopsPickup: string[];
  shopsNoService: string[];
}

export interface SearchResult {
  childSites: ChildSite[];
  shops: ShopSearchResult[];
  entityTitle: string;
}
