import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { UserOrder } from '~/core/models';
import { AppState } from '~/reducers';
import { selectLoading, selectOrders, selectOrdersCount, UserActionTypes } from '~/user/store';
import { PageEvent } from '@angular/material/paginator';
import { ConfigurationService } from '@guillotinaweb/grange-core';
import { BaseViewComponent } from '~/shared/base/base.component';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserOrdersComponent extends BaseViewComponent implements OnInit {
  orders$: Observable<UserOrder[]>;
  loading$: Observable<boolean>;
  pageSizes: number[] = [5, 10];
  ordersLength$: Observable<number>;

  constructor(
    public grange: Grange,
    public seo: SeoService,
    public store: Store<AppState>,
    public configurationService: ConfigurationService,
  ) {
    super(grange, seo);
    this.grange.core.auth.isAuthenticated.pipe(takeUntil(this.destroy)).subscribe((auth) => {
      if (!auth.state) {
        this.grange.traverser.traverse('/@@login');
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading$ = this.store.pipe(select(selectLoading));

    // this.orders$ = this.services.cache.get('/user-orders').pipe(
    //     map((result: any) => result.orders.map(ord => ({...ord, orderDatetime: moment(ord.orderDatetime)}))));
    this.orders$ = this.store.pipe(select(selectOrders));

    this.ordersLength$ = this.store.pipe(select(selectOrdersCount));

    this.store.dispatch(new UserActionTypes.OrdersRequested({ index: 0, size: this.pageSizes[0] }));
  }

  shopLink(url: string) {
    if (url) {
      return this.configurationService.urlToPath(url);
    }
  }

  changePage(event: PageEvent) {
    this.store.dispatch(new UserActionTypes.OrdersRequested({ index: event.pageIndex, size: event.pageSize }));
  }
}
