import { ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '~/reducers';
import { take } from 'rxjs/operators';
import { MarkAsDirtyAction, SetErrorsAction, SetValueAction } from 'ngrx-forms';
import * as intlTelInput from 'intl-tel-input';

export function ngIntlTelInput(
  phone: ElementRef,
  controlId: string,
  store: Store<AppState>,
  data$: Observable<string>,
): Subscription {
  const phoneInput = phone.nativeElement;
  const iti = intlTelInput(phoneInput, {
    initialCountry: 'es',
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js',
  });
  phoneInput.addEventListener('blur', () => {
    if (phoneInput.value.trim()) {
      store.dispatch(new MarkAsDirtyAction(controlId));
      if (iti.isValidNumber()) {
        iti.setNumber(iti.getNumber());
        store.dispatch(new SetValueAction(controlId, iti.getNumber()));
        store.dispatch(new SetErrorsAction(controlId, {}));
      } else {
        const errorCode = iti.getValidationError();
        const error = {
          0: { invalidNumber: true },
          1: { invalidCountryCode: true },
          2: { tooShort: true },
          3: { tooLong: true },
          4: { invalidNumber: true },
        }[errorCode] || { unknownError: true };
        console.log('Error', controlId, errorCode, error);
        store.dispatch(new SetValueAction(controlId, ''));
        store.dispatch(new SetErrorsAction(controlId, error));
      }
    } else {
      store.dispatch(new SetValueAction(controlId, ''));
      console.log('error required');
      store.dispatch(new SetErrorsAction(controlId, { required: { actual: '' } }));
    }
  });
  phoneInput.addEventListener('change', () => {
    //store.dispatch(new SetErrorsAction(controlId, {}));
  });
  phoneInput.addEventListener('keyup', () => {
    //store.dispatch(new SetErrorsAction(controlId, {}));
  });
  return data$.pipe(take(1)).subscribe((n) => {
    //console.log('ngtIntlTelInput', controlId, n);
    if (n === '') {
      store.dispatch(new SetErrorsAction(controlId, { required: { actual: n } }));
    }
    return iti.setNumber(n);
  });
}
