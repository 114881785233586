import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { FormGroupState, ResetAction, SetValueAction } from 'ngrx-forms';
import { Grange } from 'grange';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subject } from 'rxjs';
import { combineLatest, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as _ from 'lodash';

import { AppState } from '~/reducers';
import { TerritorialEntities, UserShippingAddress } from '~/core/models';
import { ShippingAddress } from '~/cart/models';
import {
  CartActionTypes,
  fromCart,
  selectCartShop,
  selectCheckoutCities,
  selectCheckoutCounties,
  selectCheckoutPostcodes,
  selectCheckoutShippingAdd,
  selectCheckoutUserShippingAddress,
} from '~/cart/store';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'app-checkout-delivery',
  templateUrl: './checkout-delivery.component.html',
  styleUrls: ['./checkout-delivery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutDeliveryComponent implements OnDestroy, OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutDeliveryForm>;
  destroy = new Subject();
  countries$: Observable<TerritorialEntities>;
  counties$: Observable<TerritorialEntities>;
  cities$: Observable<TerritorialEntities>;
  postcodes$: Observable<TerritorialEntities>;
  userShippingAddresses$: Observable<UserShippingAddress[]>;
  shippingAddress$: Observable<ShippingAddress>;
  selectedUserShippingAddress$: Observable<UserShippingAddress | null>;
  showSearchGooglePlaces$: Observable<boolean>;
  // remember to change cart/carft.effects/CartEffects.useGooglePlaces
  useGooglePlaces = true;
  placesOptions = {
    types: [], //['geocode'],
    componentRestrictions: { country: 'ES' },
  };
  googleAddressRouteError = false;
  shopDoesNotShipToThisAddress = false;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  constructor(
    private grange: Grange,
    private backend: BackendService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.userShippingAddresses$ = this.grange.core.auth.isAuthenticated.pipe(
      combineLatest(this.store.select(selectCartShop)),
      filter(([_isAuthenticated, shop]) => !!shop),
      switchMap(([_isAuthenticated, shop]) =>
        this.backend.userShippingAddressShop$(shop['@id']).pipe(
          map((value) => {
            const ids = value.ids.filter((id) => value.entities[id].enabled);
            return ids.filter((id) => value.entities[id].enabled).map((id) => value.entities[id]);
          }),
        ),
      ),
    );

    this.selectedUserShippingAddress$ = this.userShippingAddresses$.pipe(
      combineLatest(this.store.select(selectCheckoutUserShippingAddress)),
      map(([shippingAddresses, userShippingAddress]) => {
        if (userShippingAddress !== null) {
          const shippingAddress = shippingAddresses.filter((add) => add.id === parseInt(userShippingAddress));
          if (shippingAddress) {
            return shippingAddress[0];
          }
        }
        return null;
      }),
    );

    this.shippingAddress$ = this.store.select(selectCheckoutShippingAdd);

    this.showSearchGooglePlaces$ = this.shippingAddress$.pipe(
      map((shippingAddress: ShippingAddress) => shippingAddress.shippingAddress === ''),
    );

    this.store.dispatch(CartActionTypes.checkoutInitDelivery());

    // this.placesRef.options.componentRestrictions = new ComponentRestrictions({
    //   country: 'ES',
    // });

    // this.placesRef.reset();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  selectUserShippingAddress(shippingAddress: UserShippingAddress): void {
    if (shippingAddress) {
      this.store.dispatch(new SetValueAction('CheckoutForm.delivery.userShippingAddress', shippingAddress.id));
      this.store.dispatch(
        new SetValueAction('CheckoutForm.delivery.shippingOrganization', shippingAddress.organization),
      );
      this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddress', shippingAddress.address));
      this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressStreetNumber', '.'));
      this.store.dispatch(
        new SetValueAction('CheckoutForm.delivery.shippingAddressComplement', shippingAddress.addressComplement),
      );
      this.setGeoCoordinates({ latitude: shippingAddress.latitude, longitude: shippingAddress.longitude });
      this.setShippingEntities({
        countryUid: shippingAddress.country.uid,
        countyUid: shippingAddress.county.uid,
        cityUid: shippingAddress.city.uid,
        postcodeUid: shippingAddress.postCode.uid,
      });
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCountry', shippingAddress.country.uid));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCounty', shippingAddress.county.uid));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCity', shippingAddress.city.uid));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingPostcode', shippingAddress.postCode.uid));
    } else {
      this.clearShippingAddress();
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.userShippingAddress', null));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingOrganization', ''));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddress', ''));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressComplement', ''));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingPostcode', null));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCity', null));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCounty', null));
      // this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCountry', null));
      this.store.dispatch(new ResetAction('CheckoutForm.delivery'));
      this.store.dispatch(CartActionTypes.checkoutInitDelivery());
      // this.store.dispatch(new SetValueAction(
      //     'CheckoutForm.delivery.delivery', true));
    }
  }

  handleAddressChange(googleAddress: Address): void {
    //console.log('address returned by google', googleAddress);
    this.googleAddressRouteError = false;
    this.shopDoesNotShipToThisAddress = false;
    const address: any = _.reduce(
      googleAddress.address_components,
      (add, add_comp) => {
        if (add_comp.types.includes('route')) {
          return { ...add, route: add_comp.long_name };
        } else if (add_comp.types.includes('street_number')) {
          return { ...add, street_number: add_comp.long_name };
        } else if (add_comp.types.includes('postal_code')) {
          return { ...add, postal_code: add_comp.long_name };
        } else if (add_comp.types.includes('locality')) {
          return { ...add, locality: add_comp.long_name };
        } else if (add_comp.types.includes('administrative_area_level_2')) {
          return { ...add, administrative_area_level_2: add_comp.long_name };
        } else if (add_comp.types.includes('country')) {
          return { ...add, country: add_comp.long_name };
        }
        return add;
      },
      {},
    );

    address.latitude = googleAddress.geometry.location.lat();
    address.longitude = googleAddress.geometry.location.lng();

    if (!_.has(address, 'route') || !_.has(address, 'postal_code') || !address) {
      this.googleAddressRouteError = true;
      return;
    }

    this.store
      .select(selectCartShop)
      .pipe(
        switchMap((shop) =>
          forkJoin(
            this.backend.shopResolveEntities$(
              shop['@id'],
              address.postal_code,
              address.locality,
              address.administrative_area_level_2,
              address.country,
            ),
            this.backend.pointInArea$(
              shop['@id'],
              address.longitude,
              address.latitude,
            ),
          ),
        ),
        take(1),
      )
      .subscribe(([entities, pointInArea]) => {
        if (!_.isEmpty(entities) && pointInArea) {
          //console.log('shop entities for this address', entities);
          this.shopDoesNotShipToThisAddress = false;
          this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddress', address.route));
          if (address.street_number) {
            this.store.dispatch(
              new SetValueAction('CheckoutForm.delivery.shippingAddressStreetNumber', address.street_number),
            );
          }
          this.setGeoCoordinates({ latitude: address.latitude, longitude: address.longitude });
          this.setShippingEntities({
            countryUid: entities.country.uid,
            countyUid: entities.county.uid,
            cityUid: entities.city.uid,
            postcodeUid: entities.postcode.uid,
          });
        } else {
          this.shopDoesNotShipToThisAddress = true;
          this.cd.detectChanges();
        }
      });

    //console.log('address after extracted needed values', address);
  }

  setGeoCoordinates({ latitude, longitude }): void {
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingLongitude', longitude));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingLatitude', latitude));
  }

  setStreetNumber(streetNumber: string): void {
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressStreetNumber', streetNumber));
  }

  setStreetNumberWithoutNumber(): void {
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressStreetNumber', 's/n'));
  }

  setShippingEntities({ countryUid, countyUid, cityUid, postcodeUid }): void {
    this.store
      .select(selectCheckoutCounties)
      .pipe(
        takeUntil(this.destroy),
        filter((counties) => counties),
        take(1),
      )
      .subscribe((counties: TerritorialEntities) => {
        if (counties.ids.includes(countyUid)) {
          this.store
            .select(selectCheckoutCities)
            .pipe(
              takeUntil(this.destroy),
              filter((cities) => cities),
              take(1),
            )
            .subscribe((cities: TerritorialEntities) => {
              if (cities.ids.includes(cityUid)) {
                this.store
                  .select(selectCheckoutPostcodes)
                  .pipe(
                    takeUntil(this.destroy),
                    filter((postcodes) => postcodes),
                    take(1),
                  )
                  .subscribe((postcodes: TerritorialEntities) => {
                    if (postcodes.ids.includes(postcodeUid)) {
                      this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingPostcode', postcodeUid));
                    }
                  });
                this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCity', cityUid));
              }
            });
          this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCounty', countyUid));
        }
      });
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCountry', countryUid));
  }

  clearShippingAddress(): void {
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.userShippingAddress', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingOrganization', ''));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingLatitude', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingLongitude', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddress', ''));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressStreetNumber', ''));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingAddressComplement', ''));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingPostcode', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCity', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCounty', null));
    this.store.dispatch(new SetValueAction('CheckoutForm.delivery.shippingCountry', null));
  }
}
