import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Grange } from 'grange';
import * as _ from 'lodash';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { AppState } from '~/reducers';
import { CartActionTypes } from '~/cart/store';
import { SearchData } from '~/front/models';
import { deliveryOrPickup, ShopSearchResult } from '~/core/models';
import { environment } from 'environments/environment';
import { RedirectService } from '~/core/redirect.service';
import { ShopActionTypes } from '~/shop/store';

@Component({
  selector: 'app-addtocart',
  templateUrl: './addtocart.component.html',
  styleUrls: ['./addtocart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddtocartComponent {
  /*//
   * Types of articles that can be added to the cart:
   * - Simple article: An article without options and without subarticles, it must have the price filled.
   * - Subarticle: It's a subarticle from inside an article.
   * - Article option: Each option selectable from an article that only has one groupoption that substitutes
   *   the price from the article. This type of article behaves like an article wth subarticles.
   * - Article with options: An article with several options that must be selected one by one.
   *
   * Adding an article to the cart depends on the article type, and from:
   * - Inside a shop
   * - Article search result, shop same domain
   * - Article search result, shop other domain
   *
   * Use Cases
   * =========
   *
   * - Simple article, subarticle, article option:
   *    - Inside shop: The article is added to the cart, the bottomsheet is displayed
   *    - Article search result, shop same domain: The article is added to the cart,
   *      navigate to the shop and display the bottomsheet.
   *    - Article search result, shop other domain: Change to the shop URL passing search parameters (refresh page),
   *      add the article to the cart. No bottomsheet.
   * - Article with options:
   *    - Inside shop: Display se options dialog. When finished the bottomsheet is displayed.
   *    - Article search result, shop same domain: Navigate to the article page.
   *    - Article search result, shop other domain: Change to the article URL passing search parameters (refresh page),
   *      no article is added nor the options dialog is displayed, the user must readd the article clicking on the
   *      button.
   *
   */
  @Input() id: string;
  @Input() option: string;
  @Input() shop: ShopSearchResult; // from search result
  @Input() price: number;
  @Input() searchData?: SearchData;

  constructor(
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private grange: Grange,
    private dialog: MatDialog,
    private redirect: RedirectService,
  ) {}

  selectItem() {
    if (_.isUndefined(this.shop)) {
      this.store.dispatch(CartActionTypes.selectItem({ articleUid: this.id, preselectedOption: this.option }));
      // Inside shop
      // if (this.hasOptions) {
      //   // Article with options
      //   // We should use this to open the dialog, instead of the current method. This way
      //   // it's easier to get the article by http
      //   // https://dev.to/this-is-angular/moving-material-dialog-lifecycle-into-ngrx-effects-5d99
      //   this.store.dispatch(
      //     CartActionTypes.openSelectOptionsDialog({
      //       articleId: this.article['@id'],
      //       preselectedOption: this.id,
      //     }));
      // } else {
      //   // Subarticle, simple article, article option
      //   this.store.dispatch(
      //     CartActionTypes.addItem({ cartItem: { id: this.id, name: this.name, price: this.price, quantity: 1 }}),
      //   );
      // }
    } else {
      // Article search result
      const url = environment.normalizeShopUrl(this.shop['@id']);
      console.log('addtocart article search result', url, environment.normalizeShopUrl(this.shop['@id']));

      if (_.isUndefined(url)) {
        // shop same domain
        if (this.isPickup()) {
          this.store.dispatch(ShopActionTypes.setFilterPickupOrDelivery({ deliveryOrPickup: deliveryOrPickup.PICKUP }));
        }

        this.grange.traverser.traverse(this.shop['@id']);

        this.store.dispatch(CartActionTypes.selectItem({ articleUid: this.id, preselectedOption: this.option }));
      } else {
        // shop other domain
        if (this.searchData) {
          const searchDate = this.searchData.date ? this.searchData.date.format('YYYY-MM-DD') : '';
          if (this.isPickup()) {
            const redirectTo = `${url}?pu=1&dt=${searchDate}&it=${this.id}`;
            this.redirect.redirect(this.option ? `${redirectTo}&po=${this.option}` : redirectTo, '_self');
          } else {
            const redirectTo = `${url}?eu=${this.searchData.entity.uid}&ec=${this.searchData.entity.caption}&et=${this.searchData.entity.type}&dt=${searchDate}&it=${this.id}`;
            this.redirect.redirect(this.option ? `${redirectTo}&po=${this.option}` : redirectTo, '_self');
          }
        }
        return;
      }
    }
  }

  isPickup(): boolean {
    return this.searchData.date && this.shop.pickup_on_date && !this.shop.delivery_on_date;
  }
}
