<div id="external-footer">
  <a href="{{ poweredByHref }}" target="_blank">
    <p>Powered by <img src="/assets/local/powered-by.png" /> {{ poweredBy }}</p></a>
</div>
<div id="vat-included">{{ 'footer.vatIncluded' | translate }}</div>
<div hideInApp class="footer-copyright-wrapper">
  <div id="apym-info-menu">
    <app-plone-navigation-menu></app-plone-navigation-menu>
  </div>

  <!-- Footer -->
  <footer id="footer">
    <div>
      <h6>{{ 'footer.downloadApp' | translate }}</h6>
      <div class="app-download-btns">
        <a href="https://apps.apple.com/es/app/apanymantel/id1593167805" target="_blank" alt="Descargate la App de Apanymantel para iOS"><img src="/assets/download_apple.webp" alt="Descarga para Iphone" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.apanymantel.app" target="_blank" alt="Descargate la App de Apanymantel para Android"><img src="/assets/download-google.webp" alt="Descarga para Android" /></a>
      </div>
    </div>
    <div>
      <apym-portlets manager="plone.footerportlets"></apym-portlets>
    </div>
    <div class="social-payment">
      <div class="social-icons">
        <h6>{{ 'footer.social' | translate }}</h6>
        <div class="social">
          <ul class="list-inline">
            <li id="facebook" class="list-inline-item"><a href="http://www.facebook.com/apanymantel" target="_new"><i class="fab fa-facebook-square"></i></a></li>
            <li id="Twitter" class="list-inline-item"><a href="http://twitter.com/#!/Apanymantel" target="_new"><i class="fab fa-twitter-square"></i></a></li>
            <li id="Pinterest" class="list-inline-item"><a href="https://www.instagram.com/i_apanymantel" target="_new"><i class="fab fa-instagram"></i></a></li>
            <li id="Pinterest" class="list-inline-item"><a href="http://pinterest.com/apanymantel/" target="_new"><i class="fab fa-pinterest-square"></i></a></li>
            <li id="Blog" class="list-inline-item"><a href="http://blog.apanymantel.com/" target="_new"><i class="fas fa-rss-square"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="payment-icons">
        <h6>{{ 'footer.formasDePago' | translate }}</h6>
        <div class="payment-forms payment-visa-electron" alt="Payment Visa Electron" placement="top" ngbTooltip="Pay with Visa Electron"></div>
        <div class="payment-forms payment-bizum" alt="Bizum" placement="top" ngbTooltip="Pay with Bizum"></div>
        <div class="payment-forms payment-visa" alt="Payment Visa" placement="top" ngbTooltip="Pay with VISA"></div>
        <div class="payment-forms payment-mastercard" alt="Payment Mastercard" placement="top" ngbTooltip="Pay with Mastercard"></div>
        <div class="payment-forms payment-amex" alt="Payment American Express" placement="top" ngbTooltip="Pay with American Express"></div>
        <div class="payment-forms payment-paypal" alt="Payment PayPal" placement="top" ngbTooltip="Pay with PayPal"></div>
        <!-- <div class="payment-forms payment-bitcoin" alt="Payment Bitcoin"></div> -->
        <div class="payment-forms payment-banktransfer" alt="Payment Bank Transfer" placement="top" ngbTooltip="Pay with Bank Transfer"></div>
        <div class="payment-forms payment-bankcash" alt="Payment Bank Cash" placement="top" ngbTooltip="Pay in Cash at the Bank"></div>
      </div>
    </div>
  </footer>
  <!-- Copyright -->
  <div id="copyright">
    <div [innerHTML]=" 'footer.termsAndServices' | translate " ></div>
  </div>
</div>
