import { StoreDevtoolsModule } from '@ngrx/store-devtools';

const hostMap = {
  'www5test.apanymantel.com': {
    path: '/api/web-pasteles',
    backend: window.location.origin + '/api/web-pasteles',
    remap: [
      { regex: /^\/web-flores/g, replace: 'http://www5test.flores.apanymantel.com' },
      { regex: /^\/web-catering/g, replace: 'http://www5test.catering.apanymantel.com' },
      { regex: /^\/web-comida/g, replace: 'http://www5test.comida.apanymantel.com' },
      { regex: /^\/web-desayunos/g, replace: 'http://www5test.desayunos.apanymantel.com' },
    ],
  },
  'www5test.flores.apanymantel.com': {
    path: '/api/web-flores',
    backend: window.location.origin + '/api/web-flores',
    remap: [
      { regex: /^\/web-pasteles/g, replace: 'http://www5test.apanymantel.com' },
      { regex: /^\/web-catering/g, replace: 'http://www5test.catering.apanymantel.com' },
      { regex: /^\/web-comida/g, replace: 'http://www5test.comida.apanymantel.com' },
      { regex: /^\/web-desayunos/g, replace: 'http://www5test.desayunos.apanymantel.com' },
    ],
  },
  'www5test.catering.apanymantel.com': {
    path: '/api/web-catering',
    backend: window.location.origin + '/api/web-catering',
    remap: [
      { regex: /^\/web-pasteles/g, replace: 'http://www5test.apanymantel.com' },
      { regex: /^\/web-flores/g, replace: 'http://www5test.flores.apanymantel.com' },
      { regex: /^\/web-comida/g, replace: 'http://www5test.comida.apanymantel.com' },
      { regex: /^\/web-desayunos/g, replace: 'http://www5test.desayunos.apanymantel.com' },
    ],
  },
  'www5test.comida.apanymantel.com': {
    path: '/api/web-comida',
    backend: window.location.origin + '/api/web-comida',
    remap: [
      { regex: /^\/web-pasteles/g, replace: 'http://www5test.apanymantel.com' },
      { regex: /^\/web-flores/g, replace: 'http://www5test.flores.apanymantel.com' },
      { regex: /^\/web-catering/g, replace: 'http://www5test.catering.apanymantel.com' },
      { regex: /^\/web-desayunos/g, replace: 'http://www5test.desayunos.apanymantel.com' },
    ],
  },
  'www5test.desayunos.apanymantel.com': {
    path: '/api/web-desayunos',
    backend: window.location.origin + '/api/web-desayunos',
    remap: [
      { regex: /^\/web-pasteles/g, replace: 'http://www5test.apanymantel.com' },
      { regex: /^\/web-flores/g, replace: 'http://www5test.flores.apanymantel.com' },
      { regex: /^\/web-catering/g, replace: 'http://www5test.catering.apanymantel.com' },
      { regex: /^\/web-comida/g, replace: 'http://www5test.comida.apanymantel.com' },
    ],
  },
};

export const environment = {
  production: false,
  searchIndexing: false,
  cookieDomain: 'apanymantel.com',
  ploneBackendUrl: (): string => hostMap[window.location.hostname]?.backend || window.location.origin + '/api',
  normalizeShopUrl: (url: string): string => {
    for (const remap of hostMap[window.location.hostname]?.remap || []) {
      if (remap.regex.test(url)) {
        return url.replace(remap.regex, remap.replace);
      }
    }
  },
  normalizeURL: (url: string): string => url.replace(hostMap[window.location.hostname]?.path || '/apì', ''),
  normalizePath: (path: string): string => path.replace(hostMap[window.location.hostname]?.path || '/api', ''),
  googleTagManagerId: 'GTM-PRNSS4',
};

export const extModules = [
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: false,
  }),
];
