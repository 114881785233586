import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { Observable } from 'rxjs';
import { BackendService } from '~/core/backend.service';
import { Menu } from '~/core/models';

@Component({
  selector: 'app-plone-navigation-menu',
  templateUrl: './plone-navigation-menu.component.html',
  styleUrls: ['./plone-navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PloneNavigationMenuComponent implements OnInit {
  menues$: Observable<Menu[]>;

  constructor(private backend: BackendService, public grange: Grange) {}

  ngOnInit(): void {
    this.menues$ = this.backend.menu$();
  }

  menuOption(menuOp): void {
    this.grange.traverser.traverse(menuOp.path);
  }
}
