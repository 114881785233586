<div class="timetable-wrapper">
  <dl class="timetable-wrapper">
    <ng-container *ngFor="let day of timetable">
      <dt>{{ day.date | dynamicDate:'EEEE' }}</dt>
      <dd *ngIf="day.timetable.length > 0">
        <span *ngFor="let timetable of day.timetable">{{ timetable | timeRange }}</span>
      </dd>
      <dd *ngIf="day.timetable.length === 0">{{ 'timetable.closed' | translate }}</dd>
    </ng-container>
  </dl>
</div>
