<ng-template #label>
    <span (click)="onClick.emit($event)" class="pa-button-wrapper" tabindex="-1" [class.inline]="buttonStyle['pa-button-link']">
        <pa-icon *ngIf="icon" [name]="icon" [hidden]="true"></pa-icon>
        <span #text class="pa-button-label" [class.pa-sr]="!!icon && !_iconAndText" [class.pa-with-icon]="!!icon && _iconAndText" translate><ng-content></ng-content></span>
    </span>
</ng-template>
<a *ngIf="!!route && !traverseTo" href="#"
   [routerLink]="route"
   [ngClass]="buttonStyle"
   [attr.aria-label]="ariaLabel"
   [attr.aria-controls]="ariaControls"
   [attr.aria-expanded]="ariaExpanded"
   tabindex="0">
    <ng-container *ngTemplateOutlet="label"></ng-container>
</a>
<a *ngIf="!!traverseTo" href="#"
   [traverseTo]="traverseTo"
   [ngClass]="buttonStyle"
   [attr.aria-label]="ariaLabel"
   [attr.aria-controls]="ariaControls"
   [attr.aria-expanded]="ariaExpanded"
   tabindex="0">
    <ng-container *ngTemplateOutlet="label"></ng-container>
</a>
<a *ngIf="!traverseTo && !route" href="#"
   [ngClass]="buttonStyle"
   [attr.aria-label]="ariaLabel"
   [attr.aria-controls]="ariaControls"
   [attr.aria-expanded]="ariaExpanded"
   tabindex="0">
    <ng-container *ngTemplateOutlet="label"></ng-container>
</a>