<mat-card>
    <div class="cart-header">
        <h4>{{ 'cart.yourOrder' | translate }}</h4>
        <img src="assets/cesta-mini.png"/>
    </div>
    <ng-template #empty_cart>
      <div #empty_cart class="cart-empty">
          <img src="assets/empty-cart.png" />
          <h5>{{ 'cart.yourCartEmpty' | translate }}</h5>
      </div>
    </ng-template>
    <ng-container *ngIf="cart$ | async as cart">
      <ng-container *ngIf="totals$ | async as totals">
        <ng-container *ngIf="cart.cartItems.length > 0 && cart.shop; else empty_cart">
          <div *ngIf="!(checkingout$ | async)" class="cart-action-buttons text-center">
            <ng-container *ngIf="toReachMinimum$ | async as toReachMinimum">
                <ng-container *ngIf="checkoutDelivery$ | async as checkoutDelivery">
                  <button mat-raised-button color="warn" type="button"
                          (click)="checkoutAsDelivery()"
                          [disabled]="toReachMinimum.delivery > 0">
                    <span>{{ 'cart.orderForDelivery' | translate }}</span>
                    <div *ngIf="toReachMinimum.delivery > 0">
                      {{ 'cart.toReachMinDelivery' | translate : {amountToReachDelivery: toReachMinimum.delivery | currency:'EUR'} }}
                    </div>
                  </button>
                </ng-container>
                <ng-container *ngIf="checkoutPickup$ | async as checkoutPickup">
                  <button mat-raised-button color="warn" type="button"
                          (click)="checkoutAsPickup()"
                          [disabled]="toReachMinimum.pickup > 0">
                    <span>{{ 'cart.orderForPickup' | translate }}</span>
                    <div *ngIf="toReachMinimum.pickup > 0">
                      {{ 'cart.toReachMinPickup' | translate : {amountToReachPickup: toReachMinimum.pickup | currency:'EUR'} }}
                    </div>
                  </button>
                </ng-container>
            </ng-container>
          </div>
          <div class="cart-products">
            <div *ngFor="let cartItem of cart.cartItems" class="cart-item">
              <div>
                <b> {{ cartItem.name }}</b>
                <ng-container *ngIf="cartItem.options">
                  <ul>
                    <li *ngFor="let groupOption of cartItem.options">
                      <span class="groupname">{{ groupOption.name }}:</span>
                      <span class="options" *ngFor="let option of groupOption.options">
                        {{ option.quantity }}x {{option.name}}<span>,</span>
                      </span>
                    </li>
                  </ul>
                </ng-container>
              </div>
              <div>
                <div class="cart-item-control">
                  <div *ngIf="(checkingout$ | async); else not_in_checkout" class="cart-item-qty">x {{ cartItem.quantity }}</div>
                  <ng-template #not_in_checkout>
                    <mat-icon class="icon-btn" (click)="decrementQuantity(cartItem)" aria-hidden="false" aria-label="remove_circle">remove_circle</mat-icon>
                    <span class="cart-item-qty">{{ cartItem.quantity }}</span>
                    <mat-icon class="icon-btn" (click)="incrementQuantity(cartItem)" aria-hidden="false" aria-label="add_circle">add_circle</mat-icon>
                  </ng-template>
                </div>
                <div class="cart-item-total-price">{{ cartItem.quantity * cartItem.price | apymCurrency:'EUR' }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="totals.itemsAmount" class="cart-footer-wrapper">
            <div class="cart-subtotal">
              <div>{{ 'cart.subtotal' | translate }}</div>
              <div>{{ totals.itemsAmount | apymCurrency:'EUR' }}</div>
            </div>

            <ng-container *ngIf="cart.cartDiscounts.length > 0">
              <div class="cart-subtotal discounts"
                   *ngFor="let discount of cart.cartDiscounts">
                <mat-icon aria-hidden="false" aria-label="discount">discount</mat-icon>
                <div>{{ discount.name }}</div>
                <div>{{ -discount.amount | apymCurrency:'EUR' }}</div>
              </div>
            </ng-container>

            <ng-container *ngIf="checkoutIsDelivery$ | async">
              <ng-container *ngIf="cart.shippingMethod['uid']; else no_shipping_method">
                <div *ngIf="cart.shop.minimumOrderWithoutShippingCosts
                            && totals.itemsAmount >= cart.shop.minimumAmountWithoutShippingCosts; else shipping_amount" class="cart-shipping-amount">
                  <div>{{ 'cart.delivery' | translate }}</div>
                  <div>{{ 'cart.free' | translate }}</div>
                </div>
                <ng-template #shipping_amount>
                  <div class="cart-shipping-amount">
                    <div>{{ 'cart.delivery' | translate }}</div>
                    <div>{{ totals.shippingAmount | apymCurrency:'EUR' }}</div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #no_shipping_method>
                <div class="cart-no-shipping-data">
                  <div>{{ 'cart.delivery' | translate }}</div>
                  <div *ngIf="cart.shop.minMaxShippingFee[0] === cart.shop.minMaxShippingFee[1]">{{ cart.shop.minMaxShippingFee[0] | apymCurrency:'EUR' }}</div>
                  <div *ngIf="cart.shop.minMaxShippingFee[0] !== cart.shop.minMaxShippingFee[1]">{{ 'cart.startingFrom' | translate : {var1:cart.shop.minMaxShippingFee[0] | apymCurrency:'EUR'} }}</div>
                </div>
              </ng-template>
            </ng-container>
            <div *ngIf="totals.surchargeAmount">
              <div class="cart-surcharge">
                <div>{{ 'cart.surchargeAmount' | translate }}</div>
                <div>{{ totals.surchargeAmount | apymCurrency:'EUR' }}</div>
              </div>
            </div>
          </div>
          <div class="cart-total">{{ 'cart.total' | translate : {total: totals.total | apymCurrency:'EUR'} }}</div>
        </ng-container>
      </ng-container>
    </ng-container>
</mat-card>
