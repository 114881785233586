<div class="order-configuration" *ngIf="filterData$ | async as filterData">
  <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" (closed)="setStep(null)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <i class="fas fa-map-marker-alt"></i>
        <div *ngIf="!filterData.postCode && filterData.pickupOrDelivery !== PICKUP">
          <div>{{ 'datefilter.pickupOrDelivery' | translate }}</div>
          <small>{{ 'datefilter.calculateDeliveryPrice' | translate }}</small>
        </div>
      </mat-panel-title>
      <mat-panel-description *ngIf="step !== 1">
        <span *ngIf="filterData.pickupOrDelivery === PICKUP">{{ 'datefilter.iWillPickup' | translate }}</span>
        <span *ngIf="filterData.pickupOrDelivery === DELIVERY && filterData.postCode">
          <ng-container *ngIf="deliveryText$ | async as deliveryText">
            <div class="postcode-city">{{ deliveryText.caption }}</div>
            <div *ngIf="deliveryText.amount; else freeDelivery" class="delivery-cost">({{ deliveryText.amount | apymCurrency:'€':'symbol':'1.2-2' }})</div>
            <ng-template #freeDelivery>&nbsp;({{ 'datefilter.freeDelivery' | translate }})</ng-template>
          </ng-container>
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field>
      <mat-label>{{ 'datefilter.selectTheDeliveryType' | translate }}</mat-label>
      <mat-select (selectionChange)="setFilterPickupOrDelivery($event)" [value]="filterData.pickupOrDelivery" aria-label="Type of Deliver select">
        <mat-option [value]="PICKUP">{{ 'datefilter.pickup' | translate }}</mat-option>
        <mat-option [value]="DELIVERY">{{ 'datefilter.delivery' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="defaultEntities$ | async as defaultEntities">
      <app-select-postcode *ngIf="filterData.pickupOrDelivery === DELIVERY"
                           (selectionChange)="setFilterPostCode($event)"
                           [defaultEntities]="defaultEntities"
                           [shopId]="shop['@id']"></app-select-postcode>
    </ng-container>
  </mat-expansion-panel>
  <mat-expansion-panel [disabled]="!filterData.pickupOrDelivery" [expanded]="step === 2" (opened)="setStep(2)" (closed)="setStep(null)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <i class="fas fa-calendar-alt"></i>
        <div *ngIf="!filterData.timeRange">
          <div>{{ 'datefilter.selectDateAndTime' | translate }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-description *ngIf="step !== 2 && filterData.timeRange">
        <span>{{ filterData.date | date }} ({{ filterData.timeRange.asap ? ('datefilter.asap' | translate: {fromMinute: filterData.timeRange.fromMinute, toMinute: filterData.timeRange.toMinute }) : (filterData.timeRange | timeRange) }})</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field color="primary">
      <input matInput [matDatepicker]="picker"
             readonly
             [value]="filterData.date"
             [disabled]="!filterData.pickupOrDelivery"
             [matDatepickerFilter]="filterDateFunction$ | async"
             placeholder="{{ 'datefilter.deliveryDate' | translate }}"
             (dateChange)="setFilterDate($event.value)"
             (click)="picker.open()"/>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="month" touchUi="touchUi()"></mat-datepicker>
    </mat-form-field>

    <mat-form-field color="primary">
      <mat-select (selectionChange)="setFilterTimeRange($event)"
                  [compareWith]="compareTimeRange"
                  [value]="filterData.timeRange"
                  placeholder="{{ 'datefilter.deliveryTimeRange' | translate }}">
        <mat-option *ngFor="let timeRange of timeRanges$ | async"
                    [value]="timeRange">
          {{ timeRange.asap ? ('datefilter.asap' | translate: {fromMinute: timeRange.fromMinute, toMinute: timeRange.toMinute }) : (timeRange | timeRange) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-expansion-panel>
</div>
