import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserAddressesComponent } from './user-addresses/user-addresses.component';
import { SharedModule } from '~/shared/shared.module';
import { fromUser, UserEffects } from './store';
import { NgrxFormsModule } from 'ngrx-forms';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { LoginComponent } from './login/login.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterComponent } from './register/register.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    UserAddressesComponent,
    UserOrdersComponent,
    LoginComponent,
    RequestPasswordResetComponent,
    UserInfoComponent,
    RegisterComponent,
    PasswordResetComponent,
  ],
  imports: [
    EffectsModule.forFeature([UserEffects]),
    FormsModule,
    GooglePlaceModule,
    NgrxFormsModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('user', fromUser.reducer),
    TranslateModule.forChild(),
  ],
  exports: [],
  entryComponents: [
    LoginComponent,
    PasswordResetComponent,
    RegisterComponent,
    RequestPasswordResetComponent,
    UserAddressesComponent,
    UserInfoComponent,
    UserOrdersComponent,
  ],
})
export class UserModule {}
