import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, Subject } from 'rxjs';
import { combineLatest, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { MatRadioChange } from '@angular/material/radio';
import { WINDOW } from '~/core/window.service';
import { OrderingType } from '~/front/models';
import { AppState } from '~/reducers';
import { FrontActionTypes, selectAllShopTaxonomies, selectShopsLoaded } from '~/front/store';
import { BackendService } from '~/core/backend.service';

export class FilterItemNode {
  children?: FilterItemNode[];
  item: string;
  id: string;
}

export class FilterItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  id: string;
}

// const FILTER_TREE: FilterItemNode[] = [
//   {
//     item: 'PASTELERIA',
//     children: [
//       { item: 'PasteleríaTop' },
//       { item: 'Otro filtro' },
//     ],
//   },
//   {
//     item: 'FLORISTERIA',
//     children: [],
//   },
//   {
//     item: 'CATERING',
//     children: [],
//   },
//   {
//     item: 'DESAYUNOS',
//     children: [],
//   },
//   {
//     item: 'COMIDA',
//     children: [],
//   },
//   {
//     item: 'RESTAURANTE',
//     children: [],
//   },
// ];

@Component({
  selector: 'app-orderfilter',
  templateUrl: './orderfilter.component.html',
  styleUrls: ['./orderfilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderfilterComponent implements OnDestroy, OnInit {
  ordering = OrderingType;
  orderingSelected: OrderingType = OrderingType.recommended;
  shopsLoaded$: Observable<boolean>;
  destroy = new Subject();
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FilterItemFlatNode, FilterItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FilterItemNode, FilterItemFlatNode>();
  /** A selected parent node to be inserted */
  selectedParent: FilterItemFlatNode | null = null;
  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<FilterItemFlatNode>;

  treeFlattener: MatTreeFlattener<FilterItemNode, FilterItemFlatNode>;

  dataSource: MatTreeFlatDataSource<FilterItemNode, FilterItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FilterItemFlatNode>(true /* multiple */);

  constructor(
    public store: Store<AppState>,
    public backendService: BackendService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FilterItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    this.shopsLoaded$ = this.store.pipe(select(selectShopsLoaded));
    this.dataSource.data = [];
    this.backendService
      .getShopTaxonomy$() //, this.store.pipe(select(selectAllShopTaxonomies)))
      .pipe(
        takeUntil(this.destroy),
        combineLatest(this.store.pipe(select(selectAllShopTaxonomies))),
        map(([taxonomy, allShopTaxonomies]) => {
          const mapTo = (items) =>
            items
              ? items
                  .filter((item) => allShopTaxonomies.includes(item.id))
                  .map((item) => ({ id: item.id, item: item.name, children: mapTo(item.items) }))
              : [];
          return mapTo(taxonomy);
        }),
      )
      .subscribe((data: FilterItemNode[]) => {
        this.dataSource.data = data;
      });
    this.checklistSelection.changed.pipe(takeUntil(this.destroy)).subscribe((s) => {
      this.store.dispatch(FrontActionTypes.addFilter({ filter: s.added.map((i) => i.id) }));
      this.store.dispatch(FrontActionTypes.removeFilter({ filter: s.removed.map((i) => i.id) }));
      this.scrollTop();
    });

    // expand tree after all shops are loaded
    this.shopsLoaded$
      .pipe(
        filter((loaded) => loaded),
        take(1),
      )
      .subscribe((loaded) => {
        this.treeControl.expandAll();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  scrollTop(): void {
    this.window.scroll(0, 0);
  }

  removeFilter(): void {
    this.store.dispatch(FrontActionTypes.clearFilter());
    this.checklistSelection.clear();
    this.scrollTop();
  }

  selectOrdering($event: MatRadioChange): void {
    this.store.dispatch(FrontActionTypes.orderShops({ ordering: $event.value }));
    this.scrollTop();
  }

  // apply(): void {
  //   this.store.dispatch(FrontActionTypes.orderShops({ ordering: this.orderingSelected }));
  // }
  getLevel = (node: FilterItemFlatNode): number => node.level;

  isExpandable = (node: FilterItemFlatNode): boolean => node.expandable;

  getChildren = (node: FilterItemNode): FilterItemNode[] => node.children;

  hasChild = (_: number, _nodeData: FilterItemFlatNode): boolean => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FilterItemFlatNode): boolean => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FilterItemNode, level: number): FilterItemFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new FilterItemFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FilterItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FilterItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  filterItemSelectionToggle(node: FilterItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  filterLeafItemSelectionToggle(node: FilterItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FilterItemFlatNode): void {
    let parent: FilterItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FilterItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FilterItemFlatNode): FilterItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
