<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer_left
    class="sidenav"
    fixedInViewport="true">
    <ng-container *ngIf="isAuthenticated$ | async as isAuthenticated">
      <mat-toolbar *ngIf="isAuthenticated.state" color="primary">{{ 'main-nav.hello' | translate:{name: userName$ | async} }}</mat-toolbar>
      <div class="menu-item-wrapper">
        <div class="menu-title" *ngIf="!isAuthenticated.state">
          <mat-icon>login</mat-icon>
          <span>{{ 'main-nav.logInRegister' | translate }}</span>
        </div>
        <app-menu-register></app-menu-register>
      </div>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="menu-item-wrapper">
      <div class="menu-title">
        <mat-icon>language</mat-icon>
        <span>{{ 'main-nav.selectLanguage' | translate }}</span>
      </div>
      <div class="text-center">
        <apym-languages></apym-languages>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav
    #drawer_right
    position="end"
    class="right-sidenav sidenav"
    fixedInViewport="true">
    <app-cart *ngIf="!(checkingout$ | async)" [sidenav]="drawer_right"></app-cart>
  </mat-sidenav>

  <mat-sidenav-content>
    <div id="header">
      <button
        type="button"
        aria-label="Toggle left sidenav"
        mat-icon-button
        color="warn"
        class="user-pref-btn"
        (click)="drawer_left.toggle()">
        <span class="icon-wrapper"><i class="far fa-user"></i></span>
      </button>
      <div class="logo-col">
        <span *ngIf="(minWidth768$ | async); else below768">
          <a [href]="logoUrl" class="logo"><img [src]="largeLogo" class="img-fluid" /></a>
        </span>
        <ng-template #below768>
          <a [href]="logoUrl" class="logo"><img [src]="smallLogo" class="img-fluid" /></a>
        </ng-template>
      </div>
      <div class="menu-col">
        <div class="languages-col">
          <apym-languages></apym-languages>
        </div>
        <div class="user-menu">
          <app-menu-register></app-menu-register>
        </div>
      </div>
      <button
        type="button"
        color="warn"
        aria-label="Toggle right sidenav"
        mat-icon-button
        class="cart-btn"
        (click)="drawer_right.toggle()">
        <app-carticon *ngIf="!(checkingout$ | async)"></app-carticon>
      </button>
    </div>
    <ng-content></ng-content>
    <apym-footer></apym-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
