<progress *ngIf="percentValue"
          class="pa-progress"
          [class.pa-progress-secondary]="isSecondary"
          [class.pa-progress-small]="isSmall"
          value="{{percentValue}}"
          min="0"
          max="100">
    <span>{{percentValue}}</span>%
</progress>

<div *ngIf="isIndeterminate"
     class="pa-progress pa-progress-indeterminate"
     [class.pa-progress-secondary]="isSecondary"
     [class.pa-progress-small]="isSmall"
     role="progressbar"
     tabindex="0"
     aria-valuemin="0"
     aria-valuetext="indeterminate"
     aria-valuemax="100"></div>

<div *ngIf="!isIndeterminate && value === 0"
     class="pa-progress pa-progress-buffer"
     [class.pa-progress-secondary]="isSecondary"
     [class.pa-progress-small]="isSmall"
     role="progressbar"
     aria-valuenow="0"
     tabindex="0"
     aria-valuemin="0"
     aria-valuetext="0 percent"
     aria-valuemax="100"></div>
