import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

import { AppState } from '~/reducers';
import { SearchData } from '~/front/models';
import { selectShop } from '~/front/store';
import { ShopSearch } from '~/core/models';

@Component({
  selector: 'app-shopresult',
  templateUrl: './shopresult.component.html',
  styleUrls: ['./shopresult.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopresultComponent implements OnInit {
  @Input() shopUid: string;
  //@Input() shop: ShopSearchResult;
  @Input() searchData: SearchData;
  shop$: Observable<ShopSearch>;
  // url: string;

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    //this.store.dispatch(FrontActionTypes.setSearchData({ searchData: this.searchData }));
    this.shop$ = this.store.pipe(select(selectShop(this.shopUid)), share());
    // this.shop$ = this.store.pipe(select(selectShop(this.shopUid)), share());
    // this.href = this.shop$.pipe(
    //   map((shop) => {
    //     const result = { href: null, traverse: null };
    //     const url = environment.normalizeShopUrl(shop['@id']);
    //     const isPickup = this.searchData.date && shop.pickup_on_date && !shop.delivery_on_date;
    //     if (url) {
    //       if (this.searchData) {
    //         const searchDate = this.searchData.date ? this.searchData.date.format('YYYY-MM-DD') : '';
    //         if (isPickup) {
    //           result.href = `${url}?pu=1&dt=${searchDate}&it=${this.searchData.item}`;
    //         } else {
    //           result.href = `${url}?eu=${this.searchData.entity.uid}&ec=${this.searchData.entity.caption}&et=${this.searchData.entity.type}&dt=${searchDate}&it=${this.searchData.item}`;
    //         }
    //       } else {
    //         result.href = url;
    //       }
    //     }
    //     result.traverse = () => {
    //       this.store.dispatch(FrontActionTypes.setSearchDataPickup({ isPickup }));
    //       return shop['@id'];
    //     };
    //     return result;
    //   }),
    // );
    // //this.url = environment.normalizeShopUrl(this.shop['@id']);
  }

  url(shop_id: string): string {
    return environment.normalizeShopUrl(shop_id);
  }

  href(shopId: string, pickup_on_date: moment.Moment, delivery_on_date: moment.Moment): string {
    const url = this.url(shopId);
    if (url) {
      if (this.searchData) {
        const searchDate = this.searchData.date ? this.searchData.date.format('YYYY-MM-DD') : '';
        if (this.isPickup(pickup_on_date, delivery_on_date)) {
          return `${url}?pu=1&dt=${searchDate}&it=${this.searchData.item}`;
        } else {
          return `${url}?eu=${this.searchData.entity.uid}&ec=${this.searchData.entity.caption}&et=${this.searchData.entity.type}&dt=${searchDate}&it=${this.searchData.item}`;
        }
      } else {
        return url;
      }
    }
  }

  normalizeUrl(url: string): string {
    return environment.normalizeShopUrl(url);
  }

  isPickup(pickup_on_date: moment.Moment, delivery_on_date: moment.Moment): boolean {
    return this.searchData.date && pickup_on_date && !delivery_on_date;
  }

  // traverse(
  //   traversing: boolean,
  //   shopId: string,
  //   pickup_on_date: moment.Moment,
  //   delivery_on_date: moment.Moment,
  // ): string {
  //   const url = this.url(shopId);
  //   if (traversing) {
  //     this.store.dispatch(
  //       FrontActionTypes.setSearchDataPickup({ isPickup: this.isPickup(pickup_on_date, delivery_on_date) }),
  //     );
  //   }
  //   if (!url) {
  //     return shopId;
  //   }
  // }

  traverse(shopId: string, pickup_on_date: moment.Moment, delivery_on_date: moment.Moment): string {
    //this.store.dispatch(FrontActionTypes.setSearchData({ searchData: this.searchData }));
    const url = this.url(shopId);
    // this.store.dispatch(
    //   FrontActionTypes.setSearchDataPickup({ isPickup: this.isPickup(pickup_on_date, delivery_on_date) }),
    // );
    if (!url) {
      return shopId;
    }
  }
}
