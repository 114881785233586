import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserActionTypes, Actions } from './user.actions';
import { createFormGroupState, FormGroupState, formGroupReducer, updateGroup, validate } from 'ngrx-forms';
import { equalTo, required } from 'ngrx-forms/validation';
import { PasswordError } from '~/user/models';
import { RegisteredUser, UserInfo, UserOrder, UserShippingAddress } from '~/core/models';

export interface AddressEditFormValue {
  id: number;
  addressName: string;
  organization: string;
  latitude: string;
  longitude: string;
  address: string;
  addressStreetNumber: string;
  addressComplement: string;
  postCode: string;
  city: string;
  county: string;
  country: string;
}

const ADDRESS_FORM_ID = 'addresseditform';

const initialAddressEditFormState = createFormGroupState<AddressEditFormValue>(ADDRESS_FORM_ID, {
  id: null,
  addressName: '',
  organization: '',
  latitude: '',
  longitude: '',
  address: '',
  addressStreetNumber: ' ',
  addressComplement: '',
  postCode: null,
  city: null,
  county: null,
  country: null,
});

const validateAddressEditFormState = updateGroup<AddressEditFormValue>({
  addressName: validate(required),
  latitude: validate(required),
  longitude: validate(required),
  address: validate(required),
  addressStreetNumber: validate(required),
  postCode: validate(required),
  city: validate(required),
  county: validate(required),
  country: validate(required),
});

export interface AddressesState extends EntityState<UserShippingAddress> {
  editForm: FormGroupState<AddressEditFormValue>;
  editingAddress: boolean;
  csrfToken: string;
}

export const adapter: EntityAdapter<UserShippingAddress> = createEntityAdapter<UserShippingAddress>({
  //sortComparer: (e1: ShippingAddress, e2: ShippingAddress) => e1.id - e2.id
});

const initialAddressesState: AddressesState = adapter.getInitialState({
  editForm: initialAddressEditFormState,
  editingAddress: false,
  csrfToken: null,
});

export interface OrdersState {
  start: number;
  count: number;
  orders: UserOrder[];
}

const initialOrdersState: OrdersState = {
  start: 0,
  count: null,
  orders: null,
};

export interface UserInfoEditFormValue {
  fullname: string;
  email: string;
  mobileNo: string;
  sendNewsletter: boolean;
}

const USER_INFO_FORM_ID = 'userinfoeditform';

const initialUserInfoEditFormState = createFormGroupState<UserInfoEditFormValue>(USER_INFO_FORM_ID, {
  fullname: '',
  email: '',
  mobileNo: '',
  sendNewsletter: false,
});

export interface UserPasswordChangeFormValue {
  login: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const USER_PASSWORD_FORM_ID = 'userpasswordeditform';

const initialUserPasswordEditFormState = (login: string) =>
  createFormGroupState<UserPasswordChangeFormValue>(USER_PASSWORD_FORM_ID, {
    login: login,
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

const validateUserPasswordFormState = updateGroup<UserPasswordChangeFormValue>({
  login: validate(required),
  currentPassword: validate(required),
  newPassword: validate(required),
  repeatNewPassword: (repeatNewPassword, parent) => validate(repeatNewPassword, equalTo(parent.value.newPassword)),
});

export interface UserInfoState {
  editUserInfoForm: FormGroupState<UserInfoEditFormValue>;
  userInfo: UserInfo;
  editingUserInfo: boolean;
  changeUserPasswordForm: FormGroupState<UserPasswordChangeFormValue>;
  changingUserPassword: boolean;
  changePasswordError: PasswordError;
  changedPassword: boolean;
}

const initialUserInfoState: UserInfoState = {
  editUserInfoForm: initialUserInfoEditFormState,
  userInfo: null,
  editingUserInfo: false,
  changeUserPasswordForm: initialUserPasswordEditFormState(''),
  changingUserPassword: false,
  changePasswordError: null,
  changedPassword: false,
};

// Register User
export interface RegisterUserFormValue {
  fullname: string;
  email: string;
  recaptcha: string;
}

const REGISTER_FORM_ID = 'registerform';

const initialRegisterFormState = createFormGroupState<RegisterUserFormValue>(REGISTER_FORM_ID, {
  fullname: '',
  email: '',
  recaptcha: '',
});

export interface RegisterUserError {
  type: string;
  message: string;
  response: any;
}

export interface RegisterUserState {
  registerUserForm: FormGroupState<RegisterUserFormValue>;
  registerUserOk: RegisteredUser;
  registerUserError: RegisterUserError;
}

const initialRegisterUserState: RegisterUserState = {
  registerUserForm: initialRegisterFormState,
  registerUserOk: null,
  registerUserError: null,
};

// Password Reset
export interface PasswordResetFormValue {
  email: string;
  randomString: string;
  newPassword: string;
  repeatNewPassword: string;
}

const PASSWORD_RESET_FORM_ID = 'passwordresetform';

const initialPasswordResetFormState = createFormGroupState<PasswordResetFormValue>(PASSWORD_RESET_FORM_ID, {
  email: '',
  randomString: '',
  newPassword: '',
  repeatNewPassword: '',
});

const validatePasswordResetFormState = updateGroup<PasswordResetFormValue>({
  email: validate(required),
  randomString: validate(required),
  newPassword: validate(required),
  repeatNewPassword: (repeatNewPassword, parent) => validate(repeatNewPassword, equalTo(parent.value.newPassword)),
});

export enum PasswordResettedType {
  Ok = 'Ok',
  UnknownToken = 'Unknown Token',
  ExpiredToken = 'Expired Token',
  NotFound = 'Not Found',
}

export interface PasswordResetState {
  passwordResetForm: FormGroupState<PasswordResetFormValue>;
  passwordResetted: PasswordResettedType;
}

const initialPasswordResetState: PasswordResetState = {
  passwordResetForm: initialPasswordResetFormState,
  passwordResetted: null,
};

//
export interface UserState {
  readonly loading: boolean;
  readonly addressesState: AddressesState;
  readonly ordersState: OrdersState;
  readonly userInfoState: UserInfoState;
  readonly registerUserState: RegisterUserState;
  readonly passwordResetState: PasswordResetState;
}

const initialUserState: UserState = {
  loading: false,
  addressesState: initialAddressesState,
  ordersState: initialOrdersState,
  userInfoState: initialUserInfoState,
  registerUserState: initialRegisterUserState,
  passwordResetState: initialPasswordResetState,
};

export function reducer(state = initialUserState, action: Actions): UserState {
  const addressEditForm = validateAddressEditFormState(formGroupReducer(state.addressesState.editForm, action));
  if (addressEditForm !== state.addressesState.editForm) {
    state = { ...state, addressesState: { ...state.addressesState, editForm: addressEditForm } };
  }

  const editUserInfoForm = formGroupReducer(state.userInfoState.editUserInfoForm, action);
  if (editUserInfoForm !== state.userInfoState.editUserInfoForm) {
    state = { ...state, userInfoState: { ...state.userInfoState, editUserInfoForm } };
  }

  const registerUserForm = formGroupReducer(state.registerUserState.registerUserForm, action);
  if (registerUserForm !== state.registerUserState.registerUserForm) {
    state = { ...state, registerUserState: { ...state.registerUserState, registerUserForm } };
  }

  const passwordResetForm = validatePasswordResetFormState(
    formGroupReducer(state.passwordResetState.passwordResetForm, action),
  );
  if (passwordResetForm !== state.passwordResetState.passwordResetForm) {
    state = { ...state, passwordResetState: { ...state.passwordResetState, passwordResetForm } };
  }

  const changeUserPasswordForm = validateUserPasswordFormState(
    formGroupReducer(state.userInfoState.changeUserPasswordForm, action),
  );
  if (changeUserPasswordForm !== state.userInfoState.changeUserPasswordForm) {
    state = { ...state, userInfoState: { ...state.userInfoState, changeUserPasswordForm } };
  }

  switch (action.type) {
    case UserActionTypes.AddressesCancelled:
      return { ...state, loading: false };
    case UserActionTypes.AddressesLoaded:
      return {
        ...state,
        loading: false,
        addressesState: adapter.addAll(action.payload.addresses, state.addressesState),
      };
    case UserActionTypes.AddressesRequested:
      return { ...state, loading: true };

    // Change User Password
    case UserActionTypes.ChangeUserPassword:
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          changeUserPasswordForm: initialUserPasswordEditFormState(state.userInfoState.userInfo.email),
          changePasswordError: null,
          changingUserPassword: true,
          changedPassword: false,
        },
      };
    case UserActionTypes.ChangeUserPasswordChanged:
      return {
        ...state,
        loading: false,
        userInfoState: {
          ...state.userInfoState,
          changeUserPasswordForm: initialUserPasswordEditFormState(state.userInfoState.userInfo.email),
          changingUserPassword: false,
          changedPassword: true,
        },
      };
    case UserActionTypes.ChangeUserPasswordCancelled:
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          changeUserPasswordForm: initialUserPasswordEditFormState(state.userInfoState.userInfo.email),
          changePasswordError: null,
          changingUserPassword: false,
        },
      };
    case UserActionTypes.ChangeUserPasswordError:
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          changePasswordError: action.payload,
          changingUserPassword: false,
        },
      };

    //
    case UserActionTypes.CitiesCancelled:
      return { ...state, loading: false };
    case UserActionTypes.CountiesCancelled:
      return { ...state, loading: false };
    case UserActionTypes.CountriesCancelled:
      return { ...state, loading: false };
    case UserActionTypes.CSRFLoaded:
      return { ...state, addressesState: { ...state.addressesState, csrfToken: action.payload.csrfToken } };
    case UserActionTypes.EditAddress:
      const addressEditForm = createFormGroupState(ADDRESS_FORM_ID, {
        id: action.payload.id,
        addressName: action.payload.addressName,
        organization: action.payload.organization,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        address: action.payload.address,
        addressStreetNumber: ' ',
        addressComplement: action.payload.addressComplement,
        postCode: action.payload.postCode ? action.payload.postCode.uid : null,
        city: action.payload.city ? action.payload.city.uid : null,
        county: action.payload.county ? action.payload.county.uid : null,
        country: action.payload.country ? action.payload.country.uid : null,
      });
      return {
        ...state,
        addressesState: {
          ...state.addressesState,
          editingAddress: true,
          editForm: addressEditForm,
        },
      };
    case UserActionTypes.EditAddressCancelled:
      return {
        ...state,
        addressesState: {
          ...state.addressesState,
          editingAddress: false,
          editForm: initialAddressEditFormState,
        },
      };
    case UserActionTypes.EditUserInfo:
      const editUserInfoForm = createFormGroupState(USER_INFO_FORM_ID, {
        fullname: action.payload.fullname,
        email: action.payload.email,
        mobileNo: action.payload.mobileNo,
        sendNewsletter: action.payload.sendNewsletter,
      });
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          editingUserInfo: true,
          editUserInfoForm,
        },
      };
    case UserActionTypes.EditUserInfoCancelled:
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          editingUserInfo: false,
          editUserInfoForm: initialUserInfoEditFormState,
        },
      };
    case UserActionTypes.OrdersLoaded:
      return {
        ...state,
        loading: false,
        ordersState: {
          ...state.ordersState,
          orders: action.payload.orders,
          count: action.payload.count,
        },
      };
    case UserActionTypes.OrdersRequested:
      return { ...state, loading: true };

    // PasswordReset
    case UserActionTypes.PasswordResetted:
      return {
        ...state,
        loading: false,
        passwordResetState: {
          ...state.passwordResetState,
          passwordResetted: PasswordResettedType.Ok,
        },
      };
    case UserActionTypes.PasswordResetRequest:
      return { ...state, loading: true };
    case UserActionTypes.PasswordResetCancelled:
      return {
        ...state,
        loading: false,
        passwordResetState: {
          ...state.passwordResetState,
          passwordResetted: action.payload,
        },
      };

    case UserActionTypes.PostCodesCancelled:
      return { ...state, loading: false };

    // RegisterUser
    case UserActionTypes.RegisterUserCancelled:
      return {
        ...state,
        loading: false,
        registerUserState: {
          ...state.registerUserState,
          registerUserForm: initialRegisterFormState,
          registerUserError: action.payload,
        },
      };
    case UserActionTypes.RegisterUserClear:
      return { ...state, loading: false, registerUserState: initialRegisterUserState };
    case UserActionTypes.RegisterUserRegistered:
      return {
        ...state,
        loading: false,
        registerUserState: {
          ...state.registerUserState,
          registerUserOk: action.payload,
        },
      };
    case UserActionTypes.RegisterUserRequest:
      return {
        ...state,
        loading: true,
        registerUserState: {
          ...state.registerUserState,
          registerUserOk: null,
          registerUserError: null,
        },
      };

    case UserActionTypes.SaveAddress:
      return { ...state, loading: true, addressesState: { ...state.addressesState, editingAddress: false } };
    case UserActionTypes.SaveAddressCancelled:
      return {
        ...state,
        loading: false,
        addressesState: {
          ...state.addressesState,
          editingAddress: false,
          editForm: initialAddressEditFormState,
        },
      };

    case UserActionTypes.UserInfoSaveRequest:
      return { ...state, loading: true, userInfoState: { ...state.userInfoState, editingUserInfo: false } };
    case UserActionTypes.UserInfoSaveCancelled:
      return { ...state, loading: false };

    case UserActionTypes.SetLoading:
      return { ...state, loading: action.payload };

    case UserActionTypes.UserInfoEditPassword:
      return {
        ...state,
        userInfoState: {
          ...state.userInfoState,
          changingUserPassword: action.payload,
        },
      };
    case UserActionTypes.UserInfoLoaded:
      return {
        ...state,
        loading: false,
        userInfoState: { ...state.userInfoState, userInfo: action.payload.userInfo },
      };
    case UserActionTypes.UserInfoRequested:
      return { ...state, loading: true };
    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
