<mat-card class="article">
  <a [traverseTo]="link">Link</a>
  <div class="not-available-message" [ngClass]="{'not-available': !article.available, 'available': article.available}">{{ 'article.productNotAvailableDate' | translate }}</div>
  <div class="article-grid">
    <div (click)="openZoom(article.title, article.image.scales.large.download)">
      <div class="article-pic">
        <img *ngIf="article.image !== null" [src]="article.image.scales.mini.download" [height]="article.image.scales.mini.height" [width]="article.image.scales.mini.width"/>
        <div hideInApp class="zoom-in"><i class="fas fa-search-plus"></i></div>
      </div>
      <div><small>{{ article.image_footer }}</small></div>
    </div>
    <div>
      <div class="article-delivery">
        <h2>{{ article.title }}</h2>
        <ul>
          <li *ngIf="deliveryAvailabilityDatetimeText && deliveryAvailabilityDatetimeText[article.delivery_day.token]"><div>{{ deliveryAvailabilityDatetimeText[article.delivery_day.token].text }}</div></li>
          <li *ngIf="pickupAvailabilityDatetimeText && pickupAvailabilityDatetimeText[article.pickup_day.token]"><div>{{ pickupAvailabilityDatetimeText[article.pickup_day.token].text }}</div></li>
        </ul>
      </div>
      <!-- Prices -->
      <ng-container *ngIf="article.use_subarticle; else nosubarticle">
        <div *ngFor="let subarticle of subarticles$ | async" class="article-price">
          <div>
            <div class="size">{{ subarticle.title }}</div>
            <small *ngIf="subarticle.portions">{{ subarticle.portions }} <span>{{ 'article.portions' | translate }}</span></small>
          </div>
          <div>
            <span class="price">{{ subarticle.price | currency:'EUR'}}</span>
            <app-addtocart [id]="subarticle.UID"
                           [price]="subarticle.price"></app-addtocart>
          </div>
        </div>
      </ng-container>
      <ng-template #nosubarticle>
        <ng-container *ngIf="variants; else simplearticle">
          <div *ngFor="let variant of variants" class="article-price">
            <div>
              <div class="size">{{ variant.name }}</div>
              <small *ngIf="variant.description">{{ variant.description }}</small>
            </div>
            <div>
              <span class="price">{{ variant.price | currency:'EUR' }}</span>
              <app-addtocart [id]="article.UID"
                             [option]="variant['@id']"
                             [price]="variant.price"></app-addtocart>
            </div>
          </div>
        </ng-container>
        <ng-template #simplearticle>
          <div class="article-price">
            <div></div>
            <div *ngIf="article.price">
              <span class="price">{{ article.price | currency:'EUR' }}</span>
              <app-addtocart [id]="article.UID"
                             [price]="article.price"></app-addtocart>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <!-- End Prices -->
    </div>
  </div>
  <div class="article-description" [innerHTML]="article.text && article.text.data || ''"></div>
</mat-card>
<ng-template #loading>{{ 'article.loading' | translate }}</ng-template>
