import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { LanguagesComponent } from './header/languages/languages.component';
//import { MenuComponent } from './header/menu/menu.component';
import { PortletsComponent } from './portlets/portlets.component';
import { MenuRegisterComponent } from './header/menu-register/menu-register.component';
import * as Pipes from './shared.pipes';
import { PloneNavigationMenuComponent } from './plone-navigation-menu/plone-navigation-menu.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GrangeModule } from 'grange';
import { TraversalModule } from 'angular-traversal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { BaseViewComponent } from './base/base.component';
import { TimetableComponent } from './timetable/timetable.component';
import { TawkChatComponent } from './tawk-chat/tawk-chat.component';
import { ZoomArticleDialog } from './zoom-article/zoom-article-dialog';
import { SelectPostcodeComponent } from './select-postcode/select-postcode.component';
import { MaterialModule } from '~/material';
import { HideInAppDirective } from './hide-in-app/hide-in-app.directive';
import { DownloadAppComponent } from './download-app/download-app.component';

export function LocaleIdFactory(translate: TranslateService): string {
  return translate.currentLang;
}

@NgModule({
  declarations: [
    BaseViewComponent,
    ConfirmationDialogComponent,
    FooterComponent,
    LanguagesComponent,
    //MenuComponent,
    MenuRegisterComponent,
    Pipes.CallbackPipe,
    Pipes.SafePipe,
    Pipes.TimePipe,
    Pipes.TimeRangePipe,
    Pipes.DynamicDatePipe,
    Pipes.ApymCurrencyPipe,
    PloneNavigationMenuComponent,
    PortletsComponent,
    TimetableComponent,
    TawkChatComponent,
    ZoomArticleDialog,
    SelectPostcodeComponent,
    HideInAppDirective,
    DownloadAppComponent,
  ],
  imports: [CommonModule, MaterialModule, GrangeModule, TraversalModule, TranslateModule, NgxJsonLdModule],
  exports: [
    FooterComponent,
    LanguagesComponent,
    MaterialModule,
    PortletsComponent,
    MenuRegisterComponent,
    PloneNavigationMenuComponent,
    Pipes.CallbackPipe,
    Pipes.TimePipe,
    Pipes.TimeRangePipe,
    Pipes.SafePipe,
    Pipes.DynamicDatePipe,
    Pipes.ApymCurrencyPipe,
    CommonModule,
    GrangeModule,
    TraversalModule,
    // TranslateModule,
    NgxJsonLdModule,
    BaseViewComponent,
    TimetableComponent,
    TawkChatComponent,
    ZoomArticleDialog,
    SelectPostcodeComponent,
    HideInAppDirective,
    DownloadAppComponent,
  ],
  entryComponents: [ConfirmationDialogComponent, TimetableComponent, SelectPostcodeComponent],
  providers: [{ provide: LOCALE_ID, useFactory: LocaleIdFactory, deps: [TranslateService] }],
})
export class SharedModule {}
