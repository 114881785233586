import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Grange } from 'grange';
import { Error } from '@guillotinaweb/grange-core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '~/reducers';

import { selectLoading, UserActionTypes } from '~/user/store';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestPasswordResetComponent implements OnInit {
  loading$: Observable<boolean>;
  error: string;
  success: boolean;
  isLogged: boolean;

  constructor(public grange: Grange, public store: Store<AppState>) {}

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectLoading));

    setTimeout(() =>
      this.grange.core.auth.isAuthenticated.subscribe((auth) => {
        this.isLogged = auth.state;
        if (auth.state) {
          this.grange.traverser.traverse(this.grange.traverser.target.getValue().contextPath);
        }
      }),
    );
  }

  onSubmit(data: any) {
    this.store.dispatch(new UserActionTypes.SetLoading(true));

    this.grange.core.auth.requestPasswordReset(data.login).subscribe(
      () => {
        this.error = '';
        this.success = true;
        this.store.dispatch(new UserActionTypes.SetLoading(false));
        //this.grange.traverser.traverse('/@@login');
      },
      (error: Error) => {
        console.log('error request password reset', error);
        if (error.response && error.response.status === 404) {
          this.error = 'This user does not exist';
        } else if (error.response && error.response.status < 500) {
          this.error = error.message;
        } else {
          this.error = 'Password reset failed.';
        }
        this.store.dispatch(new UserActionTypes.SetLoading(false));
      },
    );
  }
}
