import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ShopAddress, ShopInfoTimeTablesVM } from '~/shop/models';
import { Shop } from '~/core/models';
import { selectShopAddresses, selectShopDeliveryCities, selectTimetables } from '~/shop/store';
import { AppState } from '~/reducers';

export interface ShopDetailedInfoData {
  shop: Shop;
}

@Component({
  selector: 'app-shop-detailed-info',
  templateUrl: './shop-detailed-info.component.html',
  styleUrls: ['./shop-detailed-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopDetailedInfoComponent implements OnInit {
  shop: Shop;
  timetables$: Observable<ShopInfoTimeTablesVM>;
  deliveryCities$: Observable<string[]>;
  shopAddresses$: Observable<ShopAddress[]>;

  constructor(
    public store: Store<AppState>,
    private dialogRef: MatDialogRef<ShopDetailedInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShopDetailedInfoData,
  ) {
    this.shop = data.shop;
  }

  ngOnInit() {
    this.timetables$ = this.store.select(selectTimetables);
    this.deliveryCities$ = this.store.select(selectShopDeliveryCities);
    this.shopAddresses$ = this.store.select(selectShopAddresses);
  }

  close() {
    this.dialogRef.close();
  }
}
