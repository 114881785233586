<grange-auth>
  <form #form="ngForm" (ngSubmit)="onSubmit(form.value)">
    <p class="error" *ngIf="error">{{ error }}</p>

    <pa-input name="login" ngModel>login.username</pa-input>

    <footer>
      <pa-button border type="submit">login.button.forgot</pa-button>
    </footer>

  </form>
</grange-auth>
