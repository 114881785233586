import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class LocaleService {
  _lang = new BehaviorSubject<string>(null);
  //constructor() {}

  getLanguage(): string {
    if (localStorage) {
      return localStorage['language'] || '';
    } else {
      return '';
    }
  }

  getLanguage$(): Observable<string> {
    return this._lang.asObservable();
  }

  setLanguage(language: string): void {
    if (localStorage) {
      localStorage['language'] = language;
    }
    this._lang.next(language);
  }
}
