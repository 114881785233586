<ng-container *ngIf="countries$ | async as countries">
    <mat-form-field [hidden]="countries.ids.length === 1" class="country">
        <mat-select [(value)]="selectedCountry"
                    (selectionChange)="selectingCountry.next($event.value)"
                    placeholder="{{ 'select-postcode.selectCountry' | translate }}">
            <mat-option *ngFor="let id of countries.ids" [value]="id">{{ countries.entities[id].name }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="counties$ | async as counties">
    <mat-form-field [hidden]="counties.ids.length === 1">
        <mat-select [disabled]="!selectedCountry"
                    [(value)]="selectedCounty"
                    (selectionChange)="selectingCounty.next($event.value)"
                    placeholder="{{ 'select-postcode.selectCounty' | translate }}">
            <mat-option *ngFor="let id of counties.ids" [value]="id">{{ counties.entities[id].name }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="cities$ | async as cities">
    <mat-form-field [hidden]="cities.ids.length === 1">
        <mat-select [disabled]="!selectedCounty"
                    [(value)]="selectedCity"
                    (selectionChange)="selectingCity.next($event.value)"
                    placeholder="{{ 'select-postcode.selectCity' | translate }}">
            <mat-option *ngFor="let id of cities.ids" [value]="id">{{ cities.entities[id].name }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="postCodes$ | async as postCodes">
    <mat-form-field>
        <mat-select [disabled]="!selectedCity"
                    [(value)]="selectedPostCode"
                    (selectionChange)="selectPostCodeEvent($event)"
                    placeholder="{{ 'select-postcode.selectPostCode' | translate }}">
            <mat-option *ngFor="let id of postCodes.ids" [value]="id">{{ postCodes.entities[id].name }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>
