import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromArticles, fromCategories, fromShop, ShopEffects } from './store';
import { ArticleInShopComponent } from './article-in-shop/article-in-shop.component';
import { ShopfilterComponent } from './shopfilter/shopfilter.component';
import { ShopitemComponent } from './shopitem/shopitem.component';
import { SharedModule } from '~/shared/shared.module';
import { CartModule } from '~/cart/cart.module';
import { RatingModule } from '~/rating/rating.module';
import { ShopDetailedInfoComponent } from './shop-header/shop-detailed-info/shop-detailed-info.component';
import { ShopHeaderComponent, WarningMessageDialogComponent } from './shop-header/shop-header.component';
import { ShopCategoriesComponent } from './shop-categories/shop-categories.component';
import { ShopArticlesComponent } from './shop-articles/shop-articles.component';
import { CategoriesComponent } from './categories/categories.component';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleComponent } from './article/article.component';

@NgModule({
  declarations: [
    ArticleInShopComponent,
    ShopDetailedInfoComponent,
    ShopfilterComponent,
    ShopitemComponent,
    ShopHeaderComponent,
    ShopArticlesComponent,
    ShopCategoriesComponent,
    CategoriesComponent,
    WarningMessageDialogComponent,
    ArticleComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('shop', fromShop.reducer),
    StoreModule.forFeature('categories', fromCategories.reducer),
    StoreModule.forFeature('articles', fromArticles.reducer),
    EffectsModule.forFeature([ShopEffects]),
    SharedModule,
    TranslateModule.forChild(),
    CartModule,
    RatingModule,
  ],
  exports: [],
  entryComponents: [ShopArticlesComponent, ShopCategoriesComponent],
})
export class ShopModule {}
