// https://medium.com/angular-in-depth/retry-failed-http-requests-in-angular-f5959d486294
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, tap } from 'rxjs/operators';


const getErrorMessage = (maxRetry: number) =>
    `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;


const DEFAULT_MAX_RETRIES = 3;


export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
    let retries = maxRetry;

    return (src: Observable<any>) =>
        src.pipe(
            retryWhen((errors: Observable<any>) => errors.pipe(
                tap(error => console.log('retrying', error)),
                delay(delayMs),
                mergeMap(error => retries-- > 0 ? of(error) : throwError({name: 'RetryError', result: error, message: getErrorMessage(maxRetry)})
                        ))
                     ));
}
