import { createAction, props } from '@ngrx/store';
import { Category, FilterEntity, StateShopInfo } from '~/shop/models';
import { Article, deliveryOrPickup, Shop } from '~/core/models';
import { MomentTimerange } from '~/shared/models';
import * as moment from 'moment';
import { SelectEntities } from '~/shared/select-postcode/select-postcode.component';

export const articlesCancelled = createAction('[Backend API] Articles cancelled');
export const articlesLoaded = createAction(
  '[Backend API] Articles loaded',
  props<{ articles: Article[]; category: string }>(),
);
export const articlesRequested = createAction('[View Shop] Articles requested', props<{ category: string }>());
export const categoriesCancelled = createAction('[Backend API] Categories cancelled');
export const categoriesLoaded = createAction(
  '[Backend API] Article categories loaded',
  props<{ categories: Category[] }>(),
);
export const categoriesRequested = createAction('[View shop categories] Categories requested');
export const clearShopState = createAction('[View search] Clear shop state');
export const getArticleShop = createAction('[View] Get Article Shop', props<{ path: string }>());
export const getArticleShopFail = createAction('[Get Article Shop] Fail', props<{ error: any }>());
export const getArticleShopPending = createAction('[Get Article Shop] Pending');
export const getArticleShopSuccess = createAction('[Get Article Shop] Success', props<{ shop: Shop }>());
export const loadShop = createAction('[View Shop] Load Shop', props<{ shop: Shop }>());
export const selectArticle = createAction('[View Shop] Select article', props<{ articleUid: string }>());
export const selectCategory = createAction('[View Shop] Select category', props<{ category: string }>());
export const setFilterDate = createAction('[View Shop] Set filter date', props<{ date: moment.Moment }>());
export const setFilterEntities = createAction('[View Shop] Set filter entities', props<{ entities: SelectEntities }>());
export const setFilterPickupOrDelivery = createAction(
  '[View Shop] Set filter pickup or delivery',
  props<{ deliveryOrPickup: deliveryOrPickup }>(),
);
export const setFilterPostCode = createAction(
  '[View Shop] Set filter postcode',
  props<{ filterEntity: FilterEntity }>(),
);
export const setFilterTimeRange = createAction(
  '[View Shop] Set filter timerange',
  props<{ timeRange: MomentTimerange }>(),
);
export const shopInfoLoaded = createAction('[Backend API] Shop info loaded', props<{ shopInfo: StateShopInfo }>());
export const shopSchemaLoaded = createAction('[Backend API] Shop schema loaded', props<{ shopSchema: any }>());
export const unselectCategory = createAction('[View Shop] Unselect category');
