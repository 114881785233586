import { Component, Inject, Injectable, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Grange } from 'grange';
import { GrangeViews } from 'grange';
import { Store } from '@ngrx/store';
import { LoginComponent } from './user/login/login.component';
import { RequestPasswordResetComponent } from './user/request-password-reset/request-password-reset.component';
import { ViewportScroller } from '@angular/common';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver } from '@angular/cdk/layout';
//import { CookieService } from 'ngx-cookie-service';

import { AppState } from './reducers';
import { ArticleComponent } from './shop/article/article.component';
import { CheckoutComponent } from './cart/checkout/checkout.component';
import { CollectionComponent } from './front/collection/collection.component';
import { DocumentComponent } from './front/document/document.component';
import { FolderComponent } from './front/folder/folder.component';
//import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { PaymentComponent } from './cart/payment/payment.component';
import { PloneSiteComponent } from './front/plonesite/plonesite.component';
import { RatingFormComponent } from './rating/ratingform/rating-form.component';
import { ShopCategoriesComponent } from './shop/shop-categories/shop-categories.component';
import { ShopArticlesComponent } from './shop/shop-articles/shop-articles.component';
import { ShopfolderComponent } from './front/shopfolder/shopfolder.component';
import { ShopitemComponent } from './shop/shopitem/shopitem.component';
import { UserAddressesComponent } from './user/user-addresses/user-addresses.component';
import { UserOrdersComponent } from './user/user-orders/user-orders.component';
import { UserInfoComponent } from './user/user-info/user-info.component';
import { SearchresultComponent } from './front/searchresult/searchresult.component';
import { TraverserActions } from '@guillotinaweb/ngx-state-traverser';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './core/locale.service';
import { RegisterComponent } from './user/register/register.component';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import { Traverser } from 'angular-traversal';
import { GoogleTagManagerService } from './core/googletagmanager.service';
import { environment } from '../environments/environment';
import { HideInAppService } from '~/core/hideinapp.service';
import { DownloadAppComponent } from './shared/download-app/download-app.component';
import { AppJsonConfig } from '~/core/appjsonconfig.service';
import { WINDOW } from '~/core/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  //title = 'apanymantel';
  _environment = environment;
  isApp: boolean;
  tawkId: string;
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    public views: GrangeViews,
    public grange: Grange,
    private store: Store<AppState>,
    private translate: TranslateService,
    private localeService: LocaleService,
    private traverser: Traverser,
    private gtmService: GoogleTagManagerService,
    //private ccService: NgcCookieConsentService,
    readonly viewportScroller: ViewportScroller,
    private metaService: Meta,
    private hideInApp: HideInAppService,
    public breakpointObserver: BreakpointObserver,
    private _snackBar: MatSnackBar,
    private appConfig: AppJsonConfig,
    private renderer: Renderer2,
    @Inject(WINDOW) private window: Window,
    //private cookieService: CookieService,
  ) {
    if (!this._environment.searchIndexing) {
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
    }
    this.views.initialize();
    this.store.dispatch({ type: TraverserActions.Types.Watch });
    this.grange.traverser.addView('user-addresses', 'Plone Site', UserAddressesComponent);
    this.grange.traverser.addView('user-addresses', 'Child Site', UserAddressesComponent);
    this.grange.traverser.addView('user-addresses', 'apanymantel.cart.shopping.Shop', UserAddressesComponent);
    this.grange.traverser.addView('user-orders', 'Plone Site', UserOrdersComponent);
    this.grange.traverser.addView('user-orders', 'Child Site', UserOrdersComponent);
    this.grange.traverser.addView('user-orders', 'apanymantel.cart.shopping.Shop', UserOrdersComponent);
    this.grange.traverser.addView('user-info', 'Plone Site', UserInfoComponent);
    this.grange.traverser.addView('user-info', 'Child Site', UserInfoComponent);
    this.grange.traverser.addView('user-info', 'apanymantel.cart.shopping.Shop', UserInfoComponent);
    this.grange.traverser.addView('view', 'Plone Site', PloneSiteComponent);
    this.grange.traverser.addView('search-result', 'Plone Site', SearchresultComponent);
    this.grange.traverser.addView('search-result', 'Child Site', SearchresultComponent);
    this.grange.traverser.addView('view', 'Collection', CollectionComponent);
    this.grange.traverser.addView('view', 'Child Site', PloneSiteComponent);
    this.grange.traverser.addView('view', 'Folder', FolderComponent);
    this.grange.traverser.addView('view', 'Document', DocumentComponent);
    this.grange.traverser.addView('view', 'apanymantel.cart.shopping.ShopFolder', ShopfolderComponent);
    this.grange.traverser.addView('view', 'apanymantel.cart.shopping.Shop', ShopCategoriesComponent);
    this.grange.traverser.addView('view', 'apanymantel.cart.shopping.Article', ArticleComponent);
    this.grange.traverser.addView('articles', 'apanymantel.cart.shopping.Shop', ShopArticlesComponent);
    this.grange.traverser.addView('checkout', 'apanymantel.cart.shopping.Shop', CheckoutComponent);
    this.grange.traverser.addView('view', 'apanymantel.cart.shopping.ShopItem', ShopitemComponent);
    this.grange.traverser.addView('payment', 'apanymantel.cart.shopping.Shop', PaymentComponent);
    this.grange.traverser.addView('view', 'kg.rating.RatingForm', RatingFormComponent);
    this.grange.traverser.addView('login', '*', LoginComponent);
    this.grange.traverser.addView('register', '*', RegisterComponent);
    this.grange.traverser.addView('request-password-reset', '*', RequestPasswordResetComponent);
    this.grange.traverser.addView('password-reset', '*', PasswordResetComponent);
    this.grange.traverser.addView('passwordreset', '*', PasswordResetComponent);

    this.translate.addLangs(['es', 'en', 'ca', 'eu', 'gl']);
    this.translate.setDefaultLang('en');
    const selectedLang = this.localeService.getLanguage();
    if (selectedLang && this.translate.getLangs().indexOf(selectedLang) !== -1) {
      // use previously selected lang
      this.translate.use(selectedLang);
    } else if (this.translate.getLangs().indexOf(this.translate.getBrowserLang()) !== -1) {
      // use browser lang if available
      this.translate.use(this.translate.getBrowserLang());
    } else {
      // use default language
      this.translate.use(this.translate.getDefaultLang());
    }

    this.traverser.target.subscribe((target) => {
      // console.log('firing gtm', target.path);
      const gtmTag = {
        event: 'page',
        pageName: target.path,
      };
      this.gtmService.pushTag(gtmTag);
      setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]), 1000);
    });
  }

  ngOnInit(): void {
    const bodyClasses = this.appConfig.getConfig('bodyClasses', '');
    if (bodyClasses) {
      const classArray = bodyClasses.split(' ');
      for (let i = 0; i < classArray.length; i++) {
        this.renderer.addClass(document.body, classArray[i]);
      }
    }

    const metaThemeColor = this.appConfig.getConfig('metaThemeColor', '');
    if (metaThemeColor) {
      const metaThemeColorDom = document.getElementById('metaThemeColor');
      if (metaThemeColorDom) {
        metaThemeColorDom.setAttribute('content', metaThemeColor);
      }
    }

    this.tawkId = this.appConfig.getConfig('tawkId');

    /*this.hideInApp
      .inApp$()
      .pipe(
        tap((isApp) => {
          if (isApp) {
            this.isApp = true;
            this.ccService.destroy();
          }
          //console.log('isApp', isApp)
        }),
        filter((isApp) => !isApp),
        switchMap((_) =>
          this.translate.get([
            'cookie.header',
            'cookie.message',
            'cookie.dismiss',
            'cookie.allow',
            'cookie.deny',
            'cookie.link',
            'cookie.policy',
          ]),
        ),
      )
      .subscribe((data) => {
        this.ccService.getConfig().cookie.domain = this.window.location.hostname;
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy(); //remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
*/
/*
    // Open snackbar for download app
    this.hideInApp
      .inApp$()
      .pipe(
        filter((isApp) => !isApp && this.appConfig.getConfig('showApp', false)),
        switchMap((isApp) => this.breakpointObserver.observe(['(max-width: 768px)'])),
        take(1),
        map((state) => state.matches),
      )
      .subscribe((matches) => {
        if (matches) {
          const snackBar = this._snackBar.openFromComponent(DownloadAppComponent, {
            panelClass: 'snackbar',
            data: {
              preClose: () => {
                this.cookieService.set('showedApp', '1', {
                  expires: 1, // days
                  domain: this.appConfig.getConfig('showApp').domain,
                });
                snackBar.dismiss();
              },
            },
          });
        }
      });
*/
  }

  scrollTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TypeMarker {
  mark(context: any) {
    if (context.is_childsite) {
      /* Add 'Child Site' when this property comes form Plone,
         a collective.lineage.interfaces.IChildSite marked object
         comes with this property, the Adapter a.c.s.restapi.SerializeFolderToJsonNavRoot
         is in charge of this.*/
      return ['Child Site', context['@type']];
    } else {
      return context['@type'];
    }
  }
}
