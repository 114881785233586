import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { LastRating } from '~/core/models';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'apym-lastratings',
  templateUrl: './lastratings.component.html',
  styleUrls: ['./lastratings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastratingsComponent implements OnInit {
  lastRatings$: Observable<LastRating[]>;

  constructor(private backend: BackendService) {}

  ngOnInit() {
    this.lastRatings$ = this.backend.lastRating$();
  }
}
