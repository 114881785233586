<h2>{{ 'search.orderBy' | translate }}</h2>
<div class="order-radio-buttons-wrapper">
    <mat-radio-group [disabled]="!(shopsLoaded$ | async)" aria-label="Select an option" (change)="selectOrdering($event)">
        <mat-radio-button color="primary" [value]="ordering.recommended">{{ 'orderfilter.recommended' | translate }}</mat-radio-button>
        <mat-radio-button color="primary" [value]="ordering.cheapestShipment">{{ 'orderfilter.cheapest' | translate }}</mat-radio-button>
        <mat-radio-button color="primary" [value]="ordering.fastestDelivery">{{ 'orderfilter.fastestDelivery' | translate }}</mat-radio-button>
        <mat-radio-button color="primary" [value]="ordering.bestRating">{{ 'orderfilter.bestRated' | translate }}</mat-radio-button>
        <mat-radio-button color="primary" [value]="ordering.totalRatings">{{ 'orderfilter.totalRatings' | translate }}</mat-radio-button>
        <!-- <mat-radio-button color="primary" [value]="ordering.newFirst">{{ 'orderfilter.newShopsFirst' | translate }}</mat-radio-button> -->
    </mat-radio-group>
</div>
<mat-divider></mat-divider>
<h2>{{ 'search.filterBy' | translate }}</h2>

<div class="clear-filters"><a mat-raised-button (click)="removeFilter()">{{ 'orderfilter.resetFilters' | translate }}</a></div>

<div class="filter-tree-wrapper">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox [disabled]="!(shopsLoaded$ | async)"
                          class="checklist-leaf-node"
                          color="primary"
                          [checked]="checklistSelection.isSelected(node)"
                          (change)="filterLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button [disabled]="!(shopsLoaded$ | async)"
                    mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <mat-checkbox [disabled]="!(shopsLoaded$ | async)"
                          [checked]="descendantsAllSelected(node)" color="primary"
                          [indeterminate]="descendantsPartiallySelected(node)"
                          (change)="filterItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>
