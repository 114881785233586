import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import * as _ from 'lodash';
import { BackendService } from '~/core/backend.service';
import { TerritorialEntities } from '~/core/models';

export interface SelectEntities {
  country: { uid: string; caption: string };
  county: { uid: string; caption: string };
  city: { uid: string; caption: string };
  postCode: { uid: string; caption: string };
}

@Component({
  selector: 'app-select-postcode',
  templateUrl: './select-postcode.component.html',
  styleUrls: ['./select-postcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPostcodeComponent implements OnInit, OnDestroy {
  @Input() shopId: string;
  @Input() defaultEntities: SelectEntities;
  @Output() selectionChange = new EventEmitter<SelectEntities>();
  countries$: Observable<TerritorialEntities>;
  counties$: Observable<TerritorialEntities>;
  cities$: Observable<TerritorialEntities>;
  postCodes$: Observable<TerritorialEntities>;
  selectedCountry: string;
  selectedCountryCaption: string;
  selectedCounty: string;
  selectedCountyCaption: string;
  selectedCity: string;
  selectedCityCaption: string;
  selectedPostCode: string;
  selectedPostCodeCaption: string;
  selectingCountry: BehaviorSubject<string>;
  selectingCounty: BehaviorSubject<string>;
  selectingCity: BehaviorSubject<string>;
  selectingPostCode: BehaviorSubject<string>;

  constructor(private backend: BackendService) {}

  ngOnInit(): void {
    this.selectingCountry = new BehaviorSubject(null);
    this.selectingCounty = new BehaviorSubject(null);
    this.selectingCity = new BehaviorSubject(null);
    this.selectingPostCode = new BehaviorSubject(null);

    this.countries$ = this.backend.shopCountries$(this.shopId).pipe(
      tap((entities) => {
        if (entities.ids.length === 1) {
          this.selectedCountry = entities.ids[0];
          this.selectedCountryCaption = entities.entities[this.selectedCountry].name;
          this.selectingCountry.next(entities.ids[0]);
        } else {
          if (entities.ids.indexOf(_.get(this.defaultEntities, 'country.uid')) !== -1) {
            this.selectedCountry = this.defaultEntities.country.uid;
            this.selectedCountryCaption = entities.entities[this.selectedCountry].name;
            this.selectingCountry.next(this.selectedCountry);
          } else {
            this.selectingCountry.next(null);
          }
        }
        // if (this.selectedCountry) {
        this.selectPostCode();
        // }
      }),
    );
    this.counties$ = this.selectingCountry.pipe(
      switchMap((selecting) => {
        if (selecting) {
          return this.backend.shopCounties$(this.shopId, selecting).pipe(
            tap((entities) => {
              if (entities.ids.length === 1) {
                this.selectedCounty = entities.ids[0];
                this.selectedCountyCaption = entities.entities[this.selectedCounty].name;
                this.selectingCounty.next(entities.ids[0]);
              } else if (entities.ids.length === 0) {
                this.selectedCounty = null;
                this.selectedCountyCaption = null;
                this.selectingCounty.next(selecting);
              } else {
                // check if a county matches the searchFormPostCode chain
                if (entities.ids.indexOf(_.get(this.defaultEntities, 'county.uid')) !== -1) {
                  this.selectedCounty = this.defaultEntities.county.uid;
                  this.selectedCountyCaption = entities.entities[this.selectedCounty].name;
                  this.selectingCounty.next(this.selectedCounty);
                } else {
                  this.selectingCounty.next(null);
                }
              }
              // if (this.selectedCounty) {
              this.selectPostCode();
              // }
            }),
          );
        } else {
          this.selectingCounty.next(null);
          return of({ ids: [], entities: {} });
        }
      }),
    );
    this.cities$ = this.selectingCounty.pipe(
      switchMap((selecting) => {
        if (selecting) {
          return this.backend.shopCities$(this.shopId, selecting).pipe(
            tap((entities) => {
              if (entities.ids.length === 1) {
                this.selectedCity = entities.ids[0];
                this.selectedCityCaption = entities.entities[this.selectedCity].name;
                this.selectingCity.next(entities.ids[0]);
              } else if (entities.ids.length === 0) {
                this.selectedCity = null;
                this.selectedCityCaption = null;
                this.selectingCity.next(selecting);
              } else {
                // check if a city matches the searchFormPostCode chain
                if (entities.ids.indexOf(_.get(this.defaultEntities, 'city.uid')) !== -1) {
                  this.selectedCity = this.defaultEntities.city.uid;
                  this.selectedCityCaption = entities.entities[this.selectedCity].name;
                  this.selectingCity.next(this.selectedCity);
                } else {
                  this.selectedCity = null;
                  this.selectingCity.next(null);
                }
              }
              // if (this.selectedCity) {
              this.selectPostCode();
              // }
            }),
          );
        } else {
          this.selectingCity.next(null);
          return of({ ids: [], entities: {} });
        }
      }),
    );
    this.postCodes$ = this.selectingCity.pipe(
      switchMap((selecting) => {
        if (selecting) {
          return this.backend.shopPostCodes$(this.shopId, selecting).pipe(
            tap((entities) => {
              if (entities.ids.length === 1) {
                this.selectedPostCode = entities.ids[0];
                this.selectedPostCodeCaption = entities.entities[this.selectedPostCode].name;
                this.selectingPostCode.next(entities.ids[0]);
              } else {
                // check if a postcode matches the searchFormPostCode chain
                if (entities.ids.indexOf(_.get(this.defaultEntities, 'postCode.uid')) !== -1) {
                  this.selectedPostCode = this.defaultEntities.postCode.uid;
                  this.selectedPostCodeCaption = entities.entities[this.selectedPostCode].name;
                  this.selectingPostCode.next(this.selectedPostCode);
                } else {
                  this.selectedPostCode = null;
                  this.selectingPostCode.next(null);
                }
              }
              // if (this.selectedPostCode) {
              this.selectPostCode();
              // }
            }),
          );
        } else {
          this.selectingPostCode.next(null);
          return of({ ids: [], entities: {} });
        }
      }),
    );
  }

  selectPostCodeEvent($event: MatSelectChange) {
    this.selectedPostCodeCaption = $event.source.triggerValue;
    this.selectPostCode();
  }

  selectPostCode() {
    this.selectionChange.emit({
      country: this.selectedCountry
        ? {
            uid: this.selectedCountry,
            caption: this.selectedCountryCaption,
          }
        : null,
      county: this.selectedCounty
        ? {
            uid: this.selectedCounty,
            caption: this.selectedCountyCaption,
          }
        : null,
      city: this.selectedCity
        ? {
            uid: this.selectedCity,
            caption: this.selectedCityCaption,
          }
        : null,
      postCode: this.selectedPostCode
        ? {
            uid: this.selectedPostCode,
            caption: this.selectedPostCodeCaption,
          }
        : null,
    });
  }

  ngOnDestroy() {
    this.selectingCountry.next(null);
    this.selectingCountry.complete();
    this.selectingCounty.next(null);
    this.selectingCounty.complete();
    this.selectingCity.next(null);
    this.selectingCity.complete();
    this.selectingPostCode.next(null);
    this.selectingPostCode.complete();
  }
}
