import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, map, switchMap } from 'rxjs/operators';

import { AppState } from '~/reducers';
import { FilterData } from '~/shop/models';
import {
  selectShopFilterData,
  selectShopPickupDates,
  selectShopDeliveryDates,
  selectShopFilterPickupOrDelivery,
  selectShopDeliveryPickupDates,
  ShopActionTypes,
} from '~/shop/store';
import * as moment from 'moment';
import * as _ from 'lodash';
// import { default as _rollupMoment} from 'moment';
// const moment = _rollupMoment || _moment;
import { WINDOW } from '~/core/window.service';
import { deliveryOrPickup, Shop } from '~/core/models';
import { SelectEntities } from '~/shared/select-postcode/select-postcode.component';
import { MomentTimerange } from '~/shared/models';
import { MatSelectChange } from '@angular/material/select';
import { SearchData } from '~/front/models';
import {
  CartActionTypes,
  selectCheckoutShippingCity,
  selectCheckoutShippingCountry,
  selectCheckoutShippingCounty,
  selectCheckoutShippingPostcode,
  selectCartShippingMethod,
} from '~/cart/store';
import { BackendService } from '~/core/backend.service';

@Component({
  selector: 'apym-shopfilter',
  templateUrl: './shopfilter.component.html',
  styleUrls: ['./shopfilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopfilterComponent implements OnInit {
  @Input() shop: Shop;
  @Input() postCode: string;
  defaultEntities$: Observable<SelectEntities>;
  PICKUP = deliveryOrPickup.PICKUP;
  DELIVERY = deliveryOrPickup.DELIVERY;
  selectedDate: Date;
  searchData$: Observable<SearchData>;
  searchEntityUid$: Observable<string | null>;
  filterData$: Observable<FilterData>;
  filterPickupOrDelivery$: Observable<string>;
  filterDateFunction$: Observable<Function>;
  today: moment.Moment = moment();
  dateValue: moment.Moment = null;
  showBubble = false;
  step: number;
  deliveryText$: Observable<{ caption: string; amount: number }>;
  timeRanges$: Observable<MomentTimerange[]>;

  constructor(
    private store: Store<AppState>,
    private backend: BackendService,
    @Inject(WINDOW) private window: Window,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // this.defaultEntities$ = this.postCode
    //   ? this.backend.postCodeChain$(this.shop['@id'], this.postCode).pipe(
    //       map((result: string[]) => ({
    //         country: _.nth(result, -1) ? { uid: _.nth(result, -1), caption: '' } : undefined,
    //         county: _.nth(result, -2) ? { uid: _.nth(result, -2), caption: '' } : undefined,
    //         city: _.nth(result, -3) ? { uid: _.nth(result, -3), caption: '' } : undefined,
    //         postCode: _.nth(result, -4) ? { uid: _.nth(result, -4), caption: '' } : undefined,
    //       })),
    //     )
    //   : this.store.pipe(
    //       select(selectShopFilterData),
    //       take(1),
    //       map((filterData) => ({
    //         country: filterData.country,
    //         county: filterData.county,
    //         city: filterData.city,
    //         postCode: filterData.postCode,
    //       })),
    //     );

    this.defaultEntities$ = this.store.pipe(
      select(selectCheckoutShippingPostcode),
      switchMap((postCodeUid) => {
        if (postCodeUid) {
          return combineLatest(
            this.store.pipe(select(selectCheckoutShippingPostcode)),
            this.store.pipe(select(selectCheckoutShippingCity)),
            this.store.pipe(select(selectCheckoutShippingCounty)),
            this.store.pipe(select(selectCheckoutShippingCountry)),
          ).pipe(
            map(([postCodeUid, cityUid, countyUid, countryUid]) => ({
              postCode: { uid: postCodeUid, caption: '' },
              city: { uid: cityUid, caption: '' },
              county: { uid: countyUid, caption: '' },
              country: { uid: countryUid, caption: '' },
            })),
          );
        } else if (this.postCode) {
          return this.backend.postCodeChain$(this.shop['@id'], this.postCode).pipe(
            map((result: string[]) => ({
              country: _.nth(result, -1) ? { uid: _.nth(result, -1), caption: '' } : undefined,
              county: _.nth(result, -2) ? { uid: _.nth(result, -2), caption: '' } : undefined,
              city: _.nth(result, -3) ? { uid: _.nth(result, -3), caption: '' } : undefined,
              postCode: _.nth(result, -4) ? { uid: _.nth(result, -4), caption: '' } : undefined,
            })),
          );
        } else {
          return this.store.pipe(
            select(selectShopFilterData),
            take(1),
            map((filterData) => ({
              country: filterData.country,
              county: filterData.county,
              city: filterData.city,
              postCode: filterData.postCode,
            })),
          );
        }
      }),
    );
    // .subscribe((result) => console.log('checkoutshipmentpostcode', result));

    this.filterData$ = this.store.pipe(select(selectShopFilterData));

    this.filterPickupOrDelivery$ = this.store.pipe(select(selectShopFilterPickupOrDelivery));

    this.filterDateFunction$ = this.store.pipe(
      select(selectShopFilterPickupOrDelivery),
      switchMap((curPickupOrDelivery) => {
        switch (curPickupOrDelivery) {
          case deliveryOrPickup.PICKUP:
            return this.store.select(selectShopPickupDates);
          case deliveryOrPickup.DELIVERY:
            return this.store.select(selectShopDeliveryDates);
          default:
            return this.store.select(selectShopDeliveryPickupDates);
        }
      }),
      map(
        (dates_) =>
          function (date_: moment.Moment) {
            return !!dates_.filter((d) => d.isSame(date_)).length;
          },
      ),
    );

    this.timeRanges$ = this.filterData$.pipe(
      filter((shopFilter) => shopFilter.date !== null && shopFilter.pickupOrDelivery !== null),
      switchMap((shopFilter) => {
        return this.backend.deliveryTimes$(this.shop['@id'], shopFilter.date, shopFilter.pickupOrDelivery);
      }),
    );

    this.deliveryText$ = combineLatest(
      this.store.pipe(select(selectShopFilterData)),
      this.store.pipe(select(selectCartShippingMethod)),
    ).pipe(
      map(([filterData, shippingMethod]) => {
        const entity = filterData.postCode || filterData.city || filterData.county || filterData.country;
        if (filterData.pickupOrDelivery === this.DELIVERY && entity && shippingMethod) {
          return { caption: entity.caption, amount: shippingMethod.amount };
        } else {
          return null;
        }
      }),
    );
  }

  showBubbleTimeout(msec: number): void {
    this.showBubble = true;
    setTimeout(() => {
      this.showBubble = false;
      this.cd.detectChanges();
    }, msec);
  }

  compareTimeRange_obsolete(tr1: MomentTimerange, tr2: MomentTimerange): boolean {
    return !_.isUndefined(tr2) && !_.isNull(tr2) && tr1.fromTime.isSame(tr2.fromTime) && tr1.toTime.isSame(tr2.toTime);
  }
  compareTimeRange(tr1: MomentTimerange, tr2: MomentTimerange): boolean {
    return (
      !_.isUndefined(tr2) &&
      !_.isNull(tr2) &&
      ((tr1.fromTime.isSame(tr2.fromTime) && tr1.toTime.isSame(tr2.toTime)) || (tr1.asap === true) === tr2.asap)
    );
  }

  setFilterDate(value: moment.Moment): void {
    this.window.scrollTo({ top: 0, behavior: 'smooth' });
    this.store.dispatch(ShopActionTypes.setFilterDate({ date: value }));
  }

  setFilterTimeRange($event: MatSelectChange): void {
    this.step = null;
    this.store.dispatch(ShopActionTypes.setFilterTimeRange({ timeRange: $event.value }));
  }

  setStep(index: number): void {
    this.step = index;
  }

  setFilterPickupOrDelivery($event: MatSelectChange): void {
    this.store.dispatch(ShopActionTypes.setFilterDate({ date: null }));
    this.store.dispatch(ShopActionTypes.setFilterTimeRange({ timeRange: null }));
    this.store.dispatch(ShopActionTypes.setFilterPickupOrDelivery({ deliveryOrPickup: $event.value }));
    this.store.dispatch(ShopActionTypes.setFilterPostCode({ filterEntity: null }));
    if ($event.value === deliveryOrPickup.PICKUP) {
      this.step = null;
    }
    if ($event.value === deliveryOrPickup.DELIVERY) {
      this.store.dispatch(CartActionTypes.setDelivery());
    }
  }

  setFilterPostCode(selectedEntities: SelectEntities): void {
    if (selectedEntities.postCode) {
      this.step = null;
    }
    this.store.dispatch(ShopActionTypes.setFilterEntities({ entities: selectedEntities }));
  }

  isToday(date_: moment.Moment): boolean {
    return moment(date_).isSame(this.today, 'day');
  }

  touchUi(): boolean {
    return this.window.innerWidth < 768;
  }
}
