import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PayAction } from '~/core/models';

@Component({
  selector: 'apym-paybutton',
  templateUrl: './paybutton.component.html',
  styleUrls: ['./paybutton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaybuttonComponent {
  @Input() action: PayAction;

  // constructor() {}
}
