import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Grange } from 'grange';

import { AppState } from '~/reducers';
import { CartActionTypes } from '~/cart/store';

@Component({
  selector: 'app-checkout-register',
  templateUrl: './checkout-register.component.html',
  styleUrls: ['./checkout-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutRegisterComponent {
  @Input() formGroup: FormGroup;
  loginError = false;

  constructor(private grange: Grange, private store: Store<AppState>) {}

  login(userName: string, passwd: string): void {
    this.grange.core.auth.login(userName, passwd).subscribe(
      (_result) => {
        this.loginError = false;
        this.store.dispatch(CartActionTypes.checkoutInitBuyerdata());
      },
      (_error) => {
        this.loginError = true;
      },
    );
  }
}
