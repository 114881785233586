<div>
    <mat-form-field class="full-width-form-field">
        <mat-select placeholder="{{ 'checkout-pickup.pickupAddress' | translate }}"
                    [ngrxFormControlState]="formGroup.controls.pickupAddress"
                    class="checkout-pickup-address">
            <mat-option *ngFor="let pickupAddress of pickupAddresses$ | async"
                        [value]="pickupAddress.uid">{{ pickupAddress.address }}, {{ pickupAddress.city }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
