<div class="coupon-wrapper">
    <h5>{{ 'coupon-discount.title' | translate }}</h5>
    <div class="coupon-form-wrapper">
        <mat-form-field>
            <mat-label>
                {{ 'coupon-discount.couponCode' | translate }}
            </mat-label>
            <input matInput
                   type="text"
                   maxlength="50"
                   #coupon
                   placeholder="{{ 'coupon-discount.enterCode' | translate }}"
            />
        </mat-form-field>
        <button mat-raised-button color="accent" *ngIf="!(hasCoupon$ | async)" mat-button (click)="applyCoupon(coupon.value)">
            {{ "coupon-discount.apply" | translate }}
        </button>
        <button mat-mini-fab color="warn" *ngIf="hasCoupon$ | async" mat-button (click)="coupon.value = ''; removeCoupon()">
            <mat-icon aria-hidden="false" aria-label="delete">delete</mat-icon>
        </button>
    </div>
    <div>
        <small *ngIf="couponRequested && (coupon$ | async)?.toLowerCase() !== coupon.value?.toLowerCase()"
               class="text-danger">
             <mat-icon aria-hidden="false" aria-label="error">error</mat-icon>
            {{ 'coupon-discount.invalid' | translate }}</small>
        <small *ngIf="couponRequested && (coupon$ | async)?.toLowerCase() == coupon.value?.toLowerCase()"
               class="text-success">
            <mat-icon aria-hidden="false" aria-label="check">check_circle</mat-icon>
            {{ 'coupon-discount.couponAccepted' | translate }}
        </small>
    </div>
<div>
