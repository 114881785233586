import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Grange } from 'grange';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '~/reducers';
import {
  UserActionTypes,
  AddressesCancelled,
  AddressesLoaded,
  AddressesRequested,
  ChangeUserPasswordChanged,
  ChangeUserPasswordError,
  ChangeUserPasswordRequest,
  CitiesCancelled,
  CitiesRequested,
  CountiesCancelled,
  CountiesRequested,
  CountriesCancelled,
  CountriesRequested,
  CSRFLoaded,
  EditAddress,
  OrdersCancelled,
  OrdersLoaded,
  OrdersRequested,
  PasswordResetCancelled,
  PasswordResetRequest,
  PasswordResetted,
  PostCodesCancelled,
  PostCodesRequested,
  RegisterUserCancelled,
  RegisterUserRegistered,
  RegisterUserRequest,
  RemoveAddress,
  SaveAddress,
  SaveAddressCancelled,
  UserInfoLoaded,
  UserInfoRequested,
  UserInfoSaveRequest,
  UserInfoSaveCancelled,
} from './user.actions';
import {
  selectCSRFToken,
  selectAddressEditForm,
  selectUserInfoEditForm,
  selectUserPasswordChangeForm,
  selectPasswordResetForm,
} from './user.selectors';
import { SetValueAction, SetUserDefinedPropertyAction } from 'ngrx-forms';
import { BackendService } from '~/core/backend.service';
import { CityEntities, CountyEntities, CountryEntities, PostCodeEntities, RegisteredUser } from '~/core/models';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private grange: Grange,
    private backend: BackendService,
    private store: Store<AppState>,
  ) {}

  @Effect()
  changeUserPasswordRequest$: Observable<Action> = this.actions$.pipe(
    ofType<ChangeUserPasswordRequest>(UserActionTypes.ChangeUserPasswordRequest),
    withLatestFrom(this.store.pipe(select(selectUserPasswordChangeForm))),
    switchMap(([_, changeForm]) =>
      this.grange.core.auth
        .passwordReset({
          oldPassword: changeForm.value.currentPassword,
          newPassword: changeForm.value.newPassword,
          login: this.grange.core.auth.getUsername(),
          //login: changeForm.value.login
        })
        .pipe(
          catchError((err) => {
            console.log('Error resetting password', err);
            return of({ message: err.message, type: err.type });
          }),
        ),
    ),
    map((result: any) => (_.isEmpty(result) ? new ChangeUserPasswordChanged() : new ChangeUserPasswordError(result))),
  );

  @Effect()
  citiesRequested$: Observable<Action> = this.actions$.pipe(
    ofType<CitiesRequested>(UserActionTypes.CitiesRequested),
    switchMap((action) =>
      this.backend.cities$(action.payload).pipe(
        catchError((err) => {
          console.log('error loading cities', err);
          this.store.dispatch(new CitiesCancelled());
          return of({ ids: [], entities: {} });
        }),
        map((result: CityEntities) => [action.payload, result.ids.map((id) => result.entities[id])]),
      ),
    ),
    withLatestFrom(this.store.pipe(select(selectAddressEditForm))),
    switchMap(([[parentId, cities], addressEditForm]: [[string, any], any]) => {
      const controlId = 'addresseditform.city';
      const actions: Action[] = [new SetUserDefinedPropertyAction(controlId, 'CITIES', _.sortBy(cities, ['name']))];
      if (cities.length === 0) {
        actions.push(new PostCodesRequested(parentId));
      } else if (cities.length === 1 && addressEditForm.value.city !== cities[0].uid) {
        actions.push(new SetValueAction(controlId, cities[0].uid));
      }
      return actions;
    }),
  );

  @Effect()
  city$: Observable<Action> = this.actions$.pipe(
    ofType<SetValueAction<string>>(SetValueAction.TYPE),
    filter((a: SetValueAction<string>) => a.controlId === 'addresseditform.city'),
    switchMap((a) => {
      const actions: Action[] = [new SetValueAction('addresseditform.postCode', null)];
      if (a.value) {
        actions.push(new PostCodesRequested(a.value));
      }
      return actions;
    }),
  );

  @Effect()
  countiesRequested$: Observable<Action> = this.actions$.pipe(
    ofType<CountiesRequested>(UserActionTypes.CountiesRequested),
    switchMap((action) =>
      this.backend.counties$(action.payload).pipe(
        catchError((err) => {
          console.log('error loading counties', err);
          this.store.dispatch(new CountiesCancelled());
          return of({ ids: [], entities: {} });
        }),
        map((result: CountyEntities) => [action.payload, result.ids.map((id) => result.entities[id])]),
      ),
    ),
    withLatestFrom(this.store.pipe(select(selectAddressEditForm))),
    switchMap(([[parentId, counties], addressEditForm]: [[string, any], any]) => {
      const controlId = 'addresseditform.county';
      const actions: Action[] = [new SetUserDefinedPropertyAction(controlId, 'COUNTIES', _.sortBy(counties, ['name']))];
      if (counties.length === 0) {
        actions.push(new CitiesRequested(parentId));
      } else if (counties.length === 1 && addressEditForm.value.county !== counties[0].uid) {
        actions.push(new SetValueAction(controlId, counties[0].uid));
      }
      return actions;
    }),
  );

  @Effect()
  county$: Observable<Action> = this.actions$.pipe(
    ofType<SetValueAction<string>>(SetValueAction.TYPE),
    filter((a: SetValueAction<string>) => a.controlId === 'addresseditform.county'),
    switchMap((a) => {
      const actions: Action[] = [new SetValueAction('addresseditform.city', null)];
      if (a.value) {
        actions.push(new CitiesRequested(a.value));
      }
      return actions;
    }),
  );

  @Effect()
  countriesRequested$: Observable<Action> = this.actions$.pipe(
    ofType<CountriesRequested>(UserActionTypes.CountriesRequested),
    switchMap(() =>
      this.backend.countries$().pipe(
        catchError((err) => {
          console.log('error loading countries', err);
          this.store.dispatch(new CountriesCancelled());
          return of({ ids: [], entities: {} });
        }),
        map((result: CountryEntities) => result.ids.map((id: string) => result.entities[id])),
      ),
    ),
    withLatestFrom(this.store.pipe(select(selectAddressEditForm))),
    switchMap(([countries, addressEditForm]: [any, any]) => {
      const controlId = 'addresseditform.country';
      const actions: Action[] = [
        new SetUserDefinedPropertyAction(controlId, 'COUNTRIES', _.sortBy(countries, ['name'])),
      ];
      if (countries.length === 1 && addressEditForm.value.country !== countries[0].uid) {
        actions.push(new SetValueAction(controlId, countries[0].uid));
      }
      return actions;
    }),
  );

  @Effect()
  country$: Observable<Action> = this.actions$.pipe(
    ofType<SetValueAction<string>>(SetValueAction.TYPE),
    filter((a: SetValueAction<string>) => a.controlId === 'addresseditform.country'),
    switchMap((a) => {
      const actions: Action[] = [new SetValueAction('addresseditform.county', null)];
      if (a.value) {
        actions.push(new CountiesRequested(a.value));
      }
      return actions;
    }),
  );

  @Effect()
  editAddress$: Observable<Action> = this.actions$.pipe(
    ofType<EditAddress>(UserActionTypes.EditAddress),
    switchMap((action) => {
      const actions: Action[] = [new CountriesRequested()];
      if (action.payload.country) {
        actions.push(new CountiesRequested(action.payload.country.uid));
      }
      if (action.payload.county) {
        actions.push(new CitiesRequested(action.payload.county.uid));
      }
      if (action.payload.city) {
        actions.push(new PostCodesRequested(action.payload.city.uid));
      }
      return actions;
    }),
  );

  @Effect()
  loadAddresses$: Observable<Action> = this.actions$.pipe(
    ofType<AddressesRequested>(UserActionTypes.AddressesRequested),
    switchMap(() =>
      this.grange.core.api.get('/user-shipping-addresses').pipe(
        concatMap((result: any) => [
          new CSRFLoaded({ csrfToken: result.csrfToken }),
          new AddressesLoaded({ addresses: result.addresses }),
        ]),
        catchError((_err) => of(new AddressesCancelled())),
      ),
    ),
  );

  @Effect()
  loadOrders$: Observable<Action> = this.actions$.pipe(
    ofType<OrdersRequested>(UserActionTypes.OrdersRequested),
    switchMap((action) =>
      this.backend.userOrders$(action.payload.index, action.payload.size).pipe(
        map((result) => {
          return new OrdersLoaded(result);
        }),
        catchError((_err) => of(new OrdersCancelled())),
      ),
    ),
  );

  @Effect()
  passwordReset$: Observable<Action> = this.actions$.pipe(
    ofType<PasswordResetRequest>(UserActionTypes.PasswordResetRequest),
    withLatestFrom(this.store.pipe(select(selectPasswordResetForm))),
    switchMap(([_, editForm]) =>
      this.backend
        .userResetPassword$(
          editForm.controls.email.value,
          editForm.controls.newPassword.value,
          editForm.controls.randomString.value,
        )
        .pipe(
          map((_) => new PasswordResetted()),
          catchError((err) => of(new PasswordResetCancelled(err))),
        ),
    ),
  );

  @Effect()
  postCodesRequested$: Observable<Action> = this.actions$.pipe(
    ofType<PostCodesRequested>(UserActionTypes.PostCodesRequested),
    switchMap((request) =>
      this.backend.postCodes$(request.payload).pipe(
        catchError((err) => {
          console.log('error loading postcodes', err);
          this.store.dispatch(new PostCodesCancelled());
          return of({ ids: [], entities: {} });
        }),
        map((result: PostCodeEntities) => result.ids.map((id) => result.entities[id])),
      ),
    ),
    withLatestFrom(this.store.pipe(select(selectAddressEditForm))),
    switchMap(([postCodes, addressEditForm]: [any, any]) => {
      const controlId = 'addresseditform.postCode';
      const actions: Action[] = [
        new SetUserDefinedPropertyAction(controlId, 'POSTCODES', _.sortBy(postCodes, ['name'])),
      ];
      if (postCodes.length === 1 && addressEditForm.value.postCode !== postCodes[0].uid) {
        actions.push(new SetValueAction(controlId, postCodes[0].uid));
      }
      return actions;
    }),
  );

  @Effect()
  registerUser$: Observable<Action> = this.actions$.pipe(
    ofType<RegisterUserRequest>(UserActionTypes.RegisterUserRequest),
    switchMap((action) =>
      this.backend
        .postUser$({
          fullname: action.payload.fullname,
          //username: action.payload.email,
          email: action.payload.email,
          send_newsletter: action.payload.sendNewsletter,
        })
        .pipe(
          map((result: RegisteredUser) => new RegisterUserRegistered(result)),
          catchError((err) => {
            console.log('error register', err);
            return of(new RegisterUserCancelled(err));
          }),
        ),
    ),
  );

  @Effect()
  removeAddress$: Observable<Action> = this.actions$.pipe(
    ofType<RemoveAddress>(UserActionTypes.RemoveAddress),
    switchMap((action) => this.backend.deleteUserShippingAddress$(action.payload)),
    map((_result) => new AddressesRequested()),
  );

  @Effect()
  saveAddress$: Observable<Action> = this.actions$.pipe(
    ofType<SaveAddress>(UserActionTypes.SaveAddress),
    withLatestFrom(this.store.pipe(select(selectCSRFToken)), this.store.pipe(select(selectAddressEditForm))),
    switchMap(([_, token, editForm]) =>
      this.backend
        .postUserShippingAddress$(
          {
            id: editForm.value.id,
            addressName: editForm.value.addressName,
            organization: editForm.value.organization,
            latitude: editForm.value.latitude,
            longitude: editForm.value.longitude,
            address: `${editForm.value.address} ${editForm.value.addressStreetNumber}`,
            addressComplement: editForm.value.addressComplement,
            postCode: { uid: editForm.value.postCode },
            city: { uid: editForm.value.city },
            county: { uid: editForm.value.county },
            country: { uid: editForm.value.country },
          },
          token,
        )
        .pipe(
          map((_) => new AddressesRequested()),
          catchError((_err) => of(new SaveAddressCancelled())),
        ),
    ),
  );

  @Effect()
  userInfoRequested$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.UserInfoRequested),
    switchMap((_action) => this.backend.getUserInfo$()),
    map((userInfo) => new UserInfoLoaded({ userInfo: userInfo })),
  );

  @Effect()
  userInfoSaveRequest$: Observable<Action> = this.actions$.pipe(
    ofType<UserInfoSaveRequest>(UserActionTypes.UserInfoSaveRequest),
    withLatestFrom(this.store.pipe(select(selectUserInfoEditForm))),
    switchMap(([_, editForm]) =>
      this.backend.putUserInfo$(editForm.value).pipe(
        map((_) => new UserInfoRequested()),
        catchError((_err) => of(new UserInfoSaveCancelled())),
      ),
    ),
  );
}
