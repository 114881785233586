<pa-dropdown-separator *ngIf="hasSeparator"></pa-dropdown-separator>
<li #listItem class="pa-dropdown-item" role="menuitem">
    <a [class]="!!mode && mode !== 'default' ? 'pa-dropdown-link pa-dropdown-link-' + mode : 'pa-dropdown-link'"
       [class.pa-dropdown-link-selected]="isSelected"
       [class.pa-dropdown-link-disabled]="isDisabled"
       (click)="click($event)" tabindex="0">
        <span [class.submenu-opened]="subMenuOpen" class="pa-dropdown-link-wrapper" tabindex="-1">
            <div class="pa-dropdown-flex">
                <ng-template #labelContent><ng-content></ng-content></ng-template>
                <ng-container *ngIf="!checkboxMode">
                    <div class="pa-dropdown-flex-item" *ngIf="!!_iconName && !isIconOnRight" [paTooltip]="tooltip">
                        <pa-icon *ngIf="_iconName && !isIconOnRight" [small]="isSmallIcon" [name]="_iconName"></pa-icon>
                    </div>
                    <div class="pa-dropdown-flex-item pa-dropdown-flex-item-push" translate>
                        <ng-container *ngTemplateOutlet="labelContent"></ng-container>
                    </div>
                    <div class="pa-dropdown-flex-item" *ngIf="!!_iconName && isIconOnRight" [paTooltip]="tooltip">
                        <pa-icon [small]="isSmallIcon" [name]="_iconName"></pa-icon>
                    </div>
                    <div *ngIf="subLevelItems" clas="pa-dropdown-flex-item">
                        <pa-button color="secondary" icon="right-key"
                                   (click)="clickSubMenu($event)"></pa-button>
                    </div>
                    <div class="pa-dropdown-flex-item" *ngIf="shortcut"><kbd>{{ shortcut }}</kbd></div>
                </ng-container>
                <pa-checkbox *ngIf="checkboxMode" noFocus
                             [icon]="_iconName || _icon"
                             [help]="subLabel"
                             [selected]="isSelected"
                             [disabled]="isDisabled"
                             (selection)="select($event)">
                    <ng-container *ngTemplateOutlet="labelContent"></ng-container>
                </pa-checkbox>
            </div>
        </span>
    </a>
    <pa-dropdown *ngIf="subLevelItems?.length > 0" [id]="id + '-sublevels'">
        <pa-dropdown-item *ngFor="let item of subLevelItems"
                          [id]="item.id"
                          [icon]="item.icon"
                          [mode]="item.mode"
                          [checkboxMode]="item.checkboxMode"
                          [isSelected]="item.isSelected"
                          (onSelection)="selectSubMenu(item)">
            {{item.text | translate}}
        </pa-dropdown-item>
    </pa-dropdown>
</li>
