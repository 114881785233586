import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Grange } from 'grange';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '~/reducers';
import { CartActionTypes, CartState, selectAmountToReachMinimum, selectCartState } from '~/cart/store';

@Component({
  selector: 'app-addtocart-bottomsheet',
  templateUrl: './addtocart-bottomsheet.component.html',
  styleUrls: ['./addtocart-bottomsheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class AddtocartBottomsheetComponent {
export class AddtocartBottomsheetComponent implements OnInit {
  public toReachMinimum$: Observable<{ delivery: number; pickup: number }>;
  public hasDelivery$: Observable<boolean>;
  public hasPickup$: Observable<boolean>;
  public cart$: Observable<CartState>;

  constructor(
    private grange: Grange,
    private store: Store<AppState>,
    private bottomSheetRef: MatBottomSheetRef<AddtocartBottomsheetComponent>,
  ) {}

  ngOnInit(): void {
    this.toReachMinimum$ = this.store.select(selectAmountToReachMinimum);

    this.cart$ = this.store.select(selectCartState);
    this.hasDelivery$ = this.cart$.pipe(map((cart) => cart.shop.hasDelivery));
    this.hasPickup$ = this.cart$.pipe(map((cart) => cart.shop.hasPickup));
  }

  goToCheckoutDelivery(): void {
    this.store.dispatch(CartActionTypes.setDelivery());
    this.bottomSheetRef.dismiss();
    this.grange.traverser.traverse('./@@checkout');
  }

  goToCheckoutPickup(): void {
    this.store.dispatch(CartActionTypes.setPickup());
    this.bottomSheetRef.dismiss();
    this.grange.traverser.traverse('./@@checkout');
  }

  continueBuying(): void {
    this.bottomSheetRef.dismiss();
  }
}
