import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroupState, SetValueAction } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { fromCart } from '~/cart/store';
import { AppState } from '~/reducers';
import { selectShopAddresses } from '~/shop/store';
import { ShopAddress } from '~/shop/models';

@Component({
  selector: 'app-checkout-pickup',
  templateUrl: './checkout-pickup.component.html',
  styleUrls: ['./checkout-pickup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutPickupComponent implements OnInit {
  @Input() formGroup: FormGroupState<fromCart.CheckoutPickupForm>;
  pickupAddresses$: Observable<ShopAddress[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.pickupAddresses$ = this.store.pipe(
      select(selectShopAddresses),
      tap((shopAddresses) => {
        if (shopAddresses.length === 1) {
          this.store.dispatch(new SetValueAction('CheckoutForm.pickup.pickupAddress', shopAddresses[0].uid));
        }
      }),
    );
  }
}
