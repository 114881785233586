import { Action, createReducer, on } from '@ngrx/store';
import * as ShopActionTypes from './shop.actions';
import { FilterData, StateShopInfo } from '~/shop/models';
import { Shop } from '~/core/models';
import * as _ from 'lodash';

export interface ShopState {
  readonly shop: Shop;
  readonly getShop: { loaded: boolean; loading: boolean; error: any };
  readonly shopInfo: StateShopInfo;
  readonly shopSchema: any;
  readonly articleSelected: string;
  readonly categorySelected: string;
  readonly filterData: FilterData;
}

const initialState: ShopState = {
  shop: undefined,
  getShop: {
    loaded: false,
    loading: false,
    error: null,
  },
  shopInfo: undefined,
  shopSchema: undefined,
  articleSelected: null,
  categorySelected: null,
  filterData: {
    date: null,
    timeRange: null,
    pickupOrDelivery: null,
    country: null,
    county: null,
    city: null,
    postCode: null,
  },
};

const shopReducer = createReducer(
  initialState,
  on(ShopActionTypes.clearShopState, () => initialState),
  on(ShopActionTypes.getArticleShopPending, (state) => ({
    ...state,
    getShop: {
      loading: true,
      loaded: false,
      error: null,
    },
  })),
  on(ShopActionTypes.getArticleShopSuccess, (state, { shop }) => ({
    ...state,
    shop,
    getShop: {
      loading: false,
      loaded: true,
      error: null,
    },
  })),
  on(ShopActionTypes.getArticleShopFail, (state, { error }) => ({
    ...state,
    shop: undefined,
    getShop: {
      loading: false,
      loaded: false,
      error,
    },
  })),
  on(ShopActionTypes.loadShop, (state, { shop }) => ({ ...state, shop: shop })),
  on(ShopActionTypes.selectArticle, (state, { articleUid }) => ({ ...state, articleSelected: articleUid })),
  on(ShopActionTypes.selectCategory, (state, { category }) => ({
    ...state,
    categorySelected: category,
    articleSelected: null,
  })),
  on(ShopActionTypes.setFilterDate, (state, { date }) => ({
    ...state,
    filterData: { ...state.filterData, date: date, timeRange: _.isNull(date) ? null : state.filterData.timeRange },
  })),
  on(ShopActionTypes.setFilterEntities, (state, { entities }) => ({
    ...state,
    filterData: { ...state.filterData, ...entities },
  })),
  on(ShopActionTypes.setFilterPickupOrDelivery, (state, { deliveryOrPickup }) => ({
    ...state,
    filterData: { ...state.filterData, pickupOrDelivery: deliveryOrPickup },
  })),
  on(ShopActionTypes.setFilterPostCode, (state, { filterEntity }) => ({
    ...state,
    filterData: { ...state.filterData, postCode: filterEntity },
  })),
  on(ShopActionTypes.setFilterTimeRange, (state, { timeRange }) => ({
    ...state,
    filterData: { ...state.filterData, timeRange: timeRange },
  })),
  on(ShopActionTypes.shopInfoLoaded, (state, { shopInfo }) => ({ ...state, shopInfo: shopInfo })),
  on(ShopActionTypes.shopSchemaLoaded, (state, { shopSchema }) => ({ ...state, shopSchema: shopSchema })),
  on(ShopActionTypes.unselectCategory, (state) => ({ ...state, categorySelected: null })),
);

export function reducer(state: ShopState | undefined, action: Action): ShopState {
  return shopReducer(state, action);
}
