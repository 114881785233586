<div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="(passwordResetted$ | async) as passwordResetted; else passwordResetForm">
    <div class="reset-ok" *ngIf="passwordResetted == prt.Ok">
        <h2><i class="fas fa-check-circle"></i>{{ 'password-reset.passwordResetted' | translate }}</h2>
        <p>{{ 'password-reset.passwordResettedHelp' | translate }}</p>
    </div>
    <div *ngIf="passwordResetted != prt.Ok" class="apym-error-msg">
        <div class="plone-system-error-advise">
            <div><i class="fas fa-exclamation-triangle"></i>
                {{ 'general.sorryError' | translate }}
            </div>
            <p><small>{{ 'general.helpError' | translate }}</small></p>
        </div>
        <p *ngIf="passwordResetted == prt.NotFound">{{ 'password-reset.notFound' | translate }}</p>
        <p *ngIf="passwordResetted == prt.UnknownToken">{{ 'password-reset.unknownToken' | translate }}</p>
        <p *ngIf="passwordResetted == prt.ExpiredToken">{{ 'password-reset.expiredToken' | translate }}</p>
        <p *ngIf="passwordResetted != prt.Ok && passwordResetted != prt.NotFound && passwordResetted != prt.UnknownToken && passwordResetted != prt.ExpiredToken">{{ 'password-reset.unknownError' | translate }}</p>
    </div>
</div>
<ng-template #passwordResetForm>
    <ng-container *ngIf="passwordResetFormState$ | async as formState">
        <form novalidate [ngrxFormState]="formState">
            <div class="plone-login">
                <h2>{{ 'password-reset.setYourPassword' | translate }}</h2>
                <p>{{ 'password-reset.setYourPasswordHelp' | translate }}</p>
                <mat-form-field>
                    <input matInput placeholder="{{ 'password-reset.email' | translate }}" type="email"
                           readonly
                           [ngrxFormControlState]="formState.controls.email">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="{{ 'password-reset.password' | translate }}" type="password"
                           required
                           [ngrxFormControlState]="formState.controls.newPassword">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="{{ 'password-reset.password2' | translate }}" type="password"
                           required
                           [ngrxFormControlState]="formState.controls.repeatNewPassword">
                    <small *ngIf="formState.controls.repeatNewPassword.errors?.equalTo && formState.controls.repeatNewPassword.isDirty" class="text-danger">{{ 'password-reset.passwordMismatch' | translate }}</small>
                </mat-form-field>
                <p><button mat-raised-button color="warn" class="btn" (click)="onSubmitPasswordReset()">{{ 'password-reset.save' | translate }}</button></p>
            </div >
        </form>
    </ng-container>
</ng-template>
