import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '~/reducers';
import { selectCartTotals } from '~/cart/store';
import { CartTotals } from '~/cart/models';

@Component({
  selector: 'app-carticon',
  templateUrl: './carticon.component.html',
  styleUrls: ['./carticon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarticonComponent implements OnInit {
  @Output() showCart = new EventEmitter();
  public totals$: Observable<CartTotals>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.totals$ = this.store.select(selectCartTotals);
  }

  showCartClick() {
    this.showCart.emit();
  }
}
