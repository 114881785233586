import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { CartState } from './cart.reducer';
import * as moment from 'moment';
import { deliveryOrPickup } from '~/core/models';
import { ShippingAddress } from '~/cart/models';
import { JsonToMomentTimerange } from '~/shared/models';

export const selectCartState = createFeatureSelector<CartState>('cart');

export const selectAvailablePaymentMethods = createSelector(
  selectCartState,
  (cartState) => cartState.availablePaymentMethods,
);

export const selectPaymentMethod = (id: string) =>
  createSelector(selectAvailablePaymentMethods, (paymentMethods) => {
    const paymentMethod = paymentMethods.filter((el) => el.id === id);
    if (paymentMethod.length > 0) {
      return paymentMethod[0];
    }
  });

export const selectCheckoutForm = createSelector(selectCartState, (cartState) => cartState.checkoutForm);

export const selectCheckingout = createSelector(selectCartState, (cartState) => cartState.checkingout);

export const selectCheckoutUserShippingAddress = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.userShippingAddress,
);

export const selectCheckoutShippingAdd = createSelector(
  selectCheckoutForm,
  (checkoutForm): ShippingAddress => ({
    shippingOrganization: checkoutForm.value.delivery.shippingOrganization,
    shippingAddress: checkoutForm.value.delivery.shippingAddress,
    shippingAddressStreetNumber: checkoutForm.value.delivery.shippingAddressStreetNumber,
    shippingAddressComplement: checkoutForm.value.delivery.shippingAddress,
    shippingCity: {
      uid: checkoutForm.value.delivery.shippingCity,
      title: checkoutForm.value.delivery.shippingCityCaption,
    },
    shippingPostcode: {
      uid: checkoutForm.value.delivery.shippingPostcode,
      title: checkoutForm.value.delivery.shippingPostcodeCaption,
    },
    shippingCounty: {
      uid: checkoutForm.value.delivery.shippingCounty,
      title: checkoutForm.value.delivery.shippingCountyCaption,
    },
    shippingCountry: {
      uid: checkoutForm.value.delivery.shippingCountry,
      title: checkoutForm.value.delivery.shippingCountryCaption,
    },
  }),
);

export const selectCheckoutShippingAddress = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.shippingAddress,
);

export const selectCheckoutShippingCountry = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.shippingCountry,
);

export const selectCheckoutCountries = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.controls.delivery.controls.shippingCountry.userDefinedProperties.COUNTRIES,
);

export const selectCheckoutShippingCounty = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.shippingCounty,
);

export const selectCheckoutCounties = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.controls.delivery.controls.shippingCounty.userDefinedProperties.COUNTIES,
);

export const selectCheckoutShippingCity = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.shippingCity,
);

export const selectCheckoutCities = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.controls.delivery.controls.shippingCity.userDefinedProperties.CITIES,
);

export const selectCheckoutShippingPostcode = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.delivery.shippingPostcode,
);

export const selectCheckoutPostcodes = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.controls.delivery.controls.shippingPostcode.userDefinedProperties.POSTCODES,
);

export const selectCheckoutDeliveryOrPickup = createSelector(
  selectCartState,
  (cartState) => cartState.deliveryOrPickup,
);

export const selectCheckoutIsPickup = createSelector(
  selectCartState,
  (cartState) => cartState.deliveryOrPickup === deliveryOrPickup.PICKUP,
);

export const selectCheckoutIsDelivery = createSelector(
  selectCartState,
  (cartState) => cartState.deliveryOrPickup === deliveryOrPickup.DELIVERY,
);

export const selectCheckoutDeliveryDate = createSelector(selectCheckoutForm, (checkoutForm) =>
  checkoutForm.value.deliveryDatetime.deliveryDate !== null
    ? moment(checkoutForm.value.deliveryDatetime.deliveryDate)
    : null,
);

export const selectCheckoutPaymentMethod = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.payment.paymentMethod,
);

export const selectCheckoutCoupon = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.payment.coupon,
);

export const selectCartShop = createSelector(selectCartState, (cartState) => cartState.shop);

export const selectCheckoutDeliveryTimerange = createSelector(
  selectCheckoutForm,
  (checkoutForm) =>
    _.isNull(checkoutForm.value.deliveryDatetime.deliveryTimeRange)
      ? null
      : JsonToMomentTimerange(checkoutForm.value.deliveryDatetime.deliveryTimeRange),
  // checkoutForm => {
  //   if (checkoutForm.value.deliveryDatetime.deliveryTimeRange !== null) {
  //     const timeranges = checkoutForm.value.deliveryDatetime.deliveryTimeRange.split('-');
  //     return {
  //       fromTime: moment(timeranges[0], 'HH:mm:ss'),
  //       toTime: moment(timeranges[1], 'HH:mm:ss')
  //     }
  //   }
  //   return null;
  // }
);

// export const selectCartDeliveryData = createSelector(
//     selectCartState,
//     cartState => cartState.deliveryData
// );

export const selectCheckoutBuyerData = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.buyerData,
);

export const selectCheckoutRecipientData = createSelector(
  selectCheckoutForm,
  (checkoutForm) => checkoutForm.value.recipientData,
);

export const selectCartItems = createSelector(selectCartState, (cartState) => cartState.cartItems);

export const selectCartDiscounts = createSelector(selectCartState, (cartState) => cartState.cartDiscounts);

export const selectCart = createSelector(selectCartState, (cartState) => {
  return {
    coupon: cartState.coupon,
    delivery: cartState.deliveryOrPickup === deliveryOrPickup.DELIVERY,
    pickup: cartState.deliveryOrPickup === deliveryOrPickup.PICKUP,
    cartItems: cartState.cartItems,
    cartDiscounts: cartState.cartDiscounts,
  };
});

// export const selectCartPickupData = createSelector( selectCartState,
//     cartState => cartState.pickupData || null
// );
// export const selectCartShippingData = createSelector(
//     selectCartState,
//     cartState => cartState.shippingData || null
// );

export const selectCartShippingMethod = createSelector(selectCartState, (cartState) => cartState.shippingMethod);

// export const selectCartCardMessage = createSelector(
//     selectCartDeliveryData,
//     (deliveryData: CartModel.DeliveryData) => deliveryData.cardMessage
// );

// export const selectCartComment = createSelector(
//     selectCartDeliveryData,
//     (deliveryData: CartModel.DeliveryData) => deliveryData.comment
// );

// export const selectCartPaymentMethod = createSelector(
//     selectCartState,
//     cartState => cartState.paymentMethod
// );

export const selectCartTotals = createSelector(selectCartState, (cartState) => {
  const itemsAmount: number = cartState.cartItems.reduce(
    (prevVal, currVal) => prevVal + currVal.quantity * currVal.price,
    0,
  );
  const discountsAmount: number = cartState.cartDiscounts.reduce((prevVal, currVal) => prevVal + currVal.amount, 0);
  const shippingAmount: number = cartState.shippingMethod ? cartState.shippingMethod.calculatedAmount || 0 : 0;
  const surchargeAmount: number = (cartState.paymentMethod && cartState.paymentMethod.surcharge) || 0;
  const total: number = itemsAmount - discountsAmount + shippingAmount + surchargeAmount;
  return {
    itemsAmount,
    discountsAmount,
    shippingAmount,
    surchargeAmount,
    total,
  };
});

export const selectPostedOrder = createSelector(selectCartState, (cartState) => cartState.postedOrder);

export const selectPayAction = createSelector(selectCartState, (cartState) => cartState.payAction);

export const selectDeliveryAmountToReachMinimum = createSelector(selectCartTotals, selectCartShop, (totals, shop) => {
  const toReach = shop.minimumAmountDeliveryOrder - totals.itemsAmount;
  if (toReach < 0) {
    return 0;
  } else {
    return toReach;
  }
});

export const selectPickupAmountToReachMinimum = createSelector(selectCartTotals, selectCartShop, (totals, shop) => {
  const toReach = shop.minimumAmountPickupOrder - totals.itemsAmount;
  if (toReach < 0) {
    return 0;
  } else {
    return toReach;
  }
});

export const selectAmountToReachMinimum = createSelector(selectCartTotals, selectCartShop, (totals, shop) => {
  if (shop) {
    const toReachDelivery = shop.minimumAmountDeliveryOrder - totals.itemsAmount;
    const toReachPickup = shop.minimumAmountPickupOrder - totals.itemsAmount;
    return { delivery: toReachDelivery < 0 ? 0 : toReachDelivery, pickup: toReachPickup < 0 ? 0 : toReachPickup };
  }
});
