import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseViewComponent } from '~/shared/base/base.component';
import { Grange } from 'grange';
import { SeoService } from '~/core/seo.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionComponent extends BaseViewComponent implements OnInit {
  constructor(public grange: Grange, public seo: SeoService) {
    super(grange, seo);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
