import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ItemresultComponent } from './plonesite/itemresult/itemresult.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { PloneSiteComponent } from './plonesite/plonesite.component';
import { SearchComponent } from './plonesite/search/search.component';
import { ShopfolderComponent } from './shopfolder/shopfolder.component';
import { ShopresultComponent } from './plonesite/shopresult/shopresult.component';
import { CartModule } from '~/cart/cart.module';
import { SharedModule } from '~/shared/shared.module';
import { RatingModule } from '~/rating/rating.module';
import { NgrxFormsModule } from 'ngrx-forms';
import { DocumentComponent } from './document/document.component';
import { FolderComponent } from './folder/folder.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { CollectionComponent } from './collection/collection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { fromFront, FrontEffects } from './store';
import { OrderfilterComponent } from './searchresult/orderfilter/orderfilter.component';

@NgModule({
  declarations: [
    DocumentComponent,
    FolderComponent,
    ItemresultComponent,
    MainNavComponent,
    PloneSiteComponent,
    SearchComponent,
    ShopfolderComponent,
    ShopresultComponent,
    SearchresultComponent,
    CollectionComponent,
    OrderfilterComponent,
  ],
  imports: [
    StoreModule.forFeature('front', fromFront.reducer),
    EffectsModule.forFeature([FrontEffects]),
    ReactiveFormsModule,
    NgrxFormsModule,
    SharedModule,
    TranslateModule.forChild(),
    CartModule,
    RatingModule,
  ],
  exports: [MainNavComponent],
  entryComponents: [
    DocumentComponent,
    FolderComponent,
    PloneSiteComponent,
    ShopfolderComponent,
    SearchresultComponent,
    MainNavComponent,
  ],
})
export class FrontModule {}
