import { Action, createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  disable,
  enable,
  formGroupReducer,
  FormGroupState,
  SetValueAction,
  updateGroup,
  validate,
  ValidationErrors,
} from 'ngrx-forms';
import * as _ from 'lodash';
import { email, equalTo, required, requiredTrue } from 'ngrx-forms/validation';
import * as CartActionTypes from './cart.actions';
import * as CartModel from '~/cart/models';
import * as SharedModel from '~/shared/models';
import * as CoreModel from '~/core/models';

export const ITEM_DELIVERY_DATES = 'ITEM_DELIVERY_DATES';
export const ITEM_AVAILABILITY_DATETIME = 'ITEM_AVAILABILITY_DATETIME';

declare type ExprFn<T> = (_t: T) => boolean;

export function requiredIf<T>(expr: ExprFn<T>): (_value: T | null) => ValidationErrors {
  return (value: T | null): ValidationErrors => {
    if (!expr(value)) {
      return {};
    }
    if (value !== null && (value as any).length !== 0) {
      return {};
    }
    return {
      required: {
        actual: value,
      },
    };
  };
}

// function validateItemsInDates<T>(itemDeliveryDates): (value: T | null) => ValidationErrors {
//   return (value: T | null): ValidationErrors => {
//     if (value === null) {
//       return {};
//     }

//     let datevalue;
//     if (value instanceof Date) {
//       value.setHours(0, 0, 0, 0);
//       datevalue = value.toISOString();
//     } else {
//       datevalue = value;
//     }
//     if (itemDeliveryDates) {
//       const notInDates = Object.keys(itemDeliveryDates).filter(
//         (uid) => itemDeliveryDates[uid].map((d) => d.toISOString()).indexOf(datevalue) === -1,
//       );
//       if (notInDates.length > 0) {
//         return { notInDates: notInDates };
//       }
//     }
//     return {};
//   };
// }

// function validateItemsInTimeRanges<T>(deliveryDate, itemDeliveryDatetime): (value: T | null) => ValidationErrors {
//   return (value: T | null): ValidationErrors => {
//     if (value === null) {
//       return {};
//     }

//     console.log('validateItemsInTimeRanges', deliveryDate, value, itemDeliveryDatetime);
//     if (itemDeliveryDatetime) {
//       const timeRange: SharedModel.MomentTimerange = JSON.parse(value as unknown as string);
//       const deliveryTime = moment(timeRange.asap ? timeRange.toTime : timeRange.fromTime);
//       const deliveryDatetime = moment(deliveryDate).hour(deliveryTime.hour()).minute(deliveryTime.minute());
//       console.log('validateItemsInTimeRanges 2', deliveryDatetime, deliveryTime.hour(), itemDeliveryDatetime);

//     }
//     return {};
//   };
// }

export interface CheckoutRegisterForm {
  loginContinue: string;
}

export interface CheckoutBuyerDataForm {
  billingName: string;
  billingPhone: string;
  billingEmail: string;
  confirmBillingEmail: string;
}

export interface CheckoutRecipientDataForm {
  hasRecipient: boolean;
  isASurprise: boolean;
  recipientName: string;
  recipientPhone: string;
  recipientEmail: string;
  confirmRecipientEmail: string;
}

export interface CheckoutPickupForm {
  pickup: boolean;
  pickupAddress: string;
}

export interface CheckoutDeliveryForm {
  delivery: boolean;
  userShippingAddress: string;
  shippingOrganization: string;
  shippingAddress: string;
  shippingAddressStreetNumber: string;
  shippingAddressComplement: string;
  shippingLatitude: string;
  shippingLongitude: string;
  shippingPostcode: string;
  shippingPostcodeCaption: string;
  shippingCity: string;
  shippingCityCaption: string;
  shippingCounty: string;
  shippingCountyCaption: string;
  shippingCountry: string;
  shippingCountryCaption: string;
}

export interface CheckoutDeliveryDatetimeForm {
  deliveryDate: string;
  deliveryTimeRange: string; // CommonModel.MomentTimerange;
  // deliveryTimeRange: Boxed<CommonModel.MomentTimerange>;
}

export interface CheckoutCardMessageForm {
  cardMessage: string;
}

export interface CheckoutMessageForShopForm {
  comment: string;
}

export interface CheckoutPaymentForm {
  paymentMethod: string;
  coupon: string;
}

export interface CheckoutReviewForm {
  hasBillingData: boolean;
  billingCompany: string;
  billingAddress: string;
  billingPostcode: string;
  billingCity: string;
  billingCounty: string;
  billingCountry: string;
  billingVATRegNo: string;
  acceptPolicy: boolean;
}

export interface CheckoutForm {
  register: CheckoutRegisterForm;
  buyerData: CheckoutBuyerDataForm;
  recipientData: CheckoutRecipientDataForm;
  pickup: CheckoutPickupForm;
  delivery: CheckoutDeliveryForm;
  deliveryDatetime: CheckoutDeliveryDatetimeForm;
  cardMessage: CheckoutCardMessageForm;
  messageForShop: CheckoutMessageForShopForm;
  payment: CheckoutPaymentForm;
  review: CheckoutReviewForm;
}

const FORM_ID = 'CheckoutForm';

const initialCheckoutFormState = createFormGroupState<CheckoutForm>(FORM_ID, {
  register: {
    loginContinue: null,
  },
  buyerData: {
    billingName: '',
    billingPhone: '',
    billingEmail: '',
    confirmBillingEmail: '',
  },
  recipientData: {
    hasRecipient: false,
    isASurprise: false,
    recipientName: '',
    recipientPhone: '',
    recipientEmail: '',
    confirmRecipientEmail: '',
  },
  pickup: {
    pickup: false,
    pickupAddress: '',
  },
  delivery: {
    delivery: false,
    userShippingAddress: null,
    shippingOrganization: '',
    shippingAddress: '',
    shippingAddressStreetNumber: '',
    shippingAddressComplement: '',
    shippingLatitude: null,
    shippingLongitude: null,
    shippingPostcode: null,
    shippingPostcodeCaption: '',
    shippingCity: null,
    shippingCityCaption: '',
    shippingCounty: null,
    shippingCountyCaption: '',
    shippingCountry: null,
    shippingCountryCaption: '',
  },
  deliveryDatetime: {
    deliveryDate: null,
    deliveryTimeRange: null,
  },
  cardMessage: {
    cardMessage: '',
  },
  messageForShop: {
    comment: '',
  },
  payment: {
    coupon: null,
    paymentMethod: null,
  },
  review: {
    acceptPolicy: false,
    hasBillingData: false,
    billingCompany: '',
    billingAddress: '',
    billingPostcode: '',
    billingCity: '',
    billingCounty: '',
    billingCountry: '',
    billingVATRegNo: '',
  },
});

const validateCheckoutFormState = updateGroup<CheckoutForm>({
  register: (register, _parent) =>
    updateGroup<CheckoutRegisterForm>(register, {
      loginContinue: (loginContinue, _parent) => {
        // console.log('loginContinue', loginContinue);
        return validate(loginContinue, required);
        // return validate(loginContinue, required, equalTo('anonymous'));
      },
    }),
  buyerData: (buyerData, _parent) =>
    updateGroup<CheckoutBuyerDataForm>(buyerData, {
      billingName: validate(required),
      //billingPhone: validate(required),
      // billingPhone: (billingPhone, parent) => {
      //   console.log('billingPhone', billingPhone, parent);
      //   return validate(billingPhone, required);
      // },
      billingEmail: (billingEmail, parent) =>
        validate(billingEmail, email, required, equalTo(parent.value.confirmBillingEmail)),
      confirmBillingEmail: (confirmBillingEmail, parent) =>
        validate(confirmBillingEmail, email, required, equalTo(parent.value.billingEmail)),
    }),
  recipientData: (recipientData, _parent) =>
    updateGroup<CheckoutRecipientDataForm>(recipientData, {
      recipientName: (recipientName, parent) =>
        parent.value.hasRecipient ? validate(enable(recipientName), required) : disable(recipientName),
      // recipientPhone: (recipientPhone, parent) =>
      // parent.value.hasRecipient ? validate(enable(recipientPhone), required) : disable(recipientPhone),
      recipientEmail: (recipientEmail, parent) =>
        validate(recipientEmail, email, equalTo(parent.value.confirmRecipientEmail)),
      confirmRecipientEmail: (confirmRecipientEmail, parent) =>
        validate(confirmRecipientEmail, email, equalTo(parent.value.recipientEmail)),
    }),
  pickup: (pickup, _parent) =>
    updateGroup<CheckoutPickupForm>(pickup, {
      pickupAddress: (pickupAddress, parent) =>
        parent.value.pickup ? validate(enable(pickupAddress), required) : disable(pickupAddress),
    }),
  delivery: (delivery, _parent) =>
    updateGroup<CheckoutDeliveryForm>(delivery, {
      shippingAddress: (shippingAddress, parent) =>
        parent.value.delivery ? validate(enable(shippingAddress), required) : disable(shippingAddress),
      shippingAddressStreetNumber: (shippingAddressStreetNumber, parent) =>
        parent.value.delivery
          ? validate(enable(shippingAddressStreetNumber), required)
          : disable(shippingAddressStreetNumber),
      shippingPostcode: (shippingPostcode, parent) =>
        parent.value.delivery ? validate(enable(shippingPostcode), required) : disable(shippingPostcode),
      shippingCity: (shippingCity, parent) =>
        parent.value.delivery ? validate(enable(shippingCity), required) : disable(shippingCity),
      shippingCounty: (shippingCounty, parent) =>
        parent.value.delivery ? validate(enable(shippingCounty), required) : disable(shippingCounty),
      shippingCountry: (shippingCountry, parent) =>
        parent.value.delivery ? validate(enable(shippingCountry), required) : disable(shippingCountry),
    }),
  deliveryDatetime: (deliveryDatetime, _parent) =>
    updateGroup<CheckoutDeliveryDatetimeForm>(deliveryDatetime, {
      deliveryDate: (deliveryDate, _parent) => validate(deliveryDate, required),
      deliveryTimeRange: (deliveryTimeRange, _parent) => validate(deliveryTimeRange, required),
    }),
  payment: (payment, _parent) =>
    updateGroup<CheckoutPaymentForm>(payment, {
      paymentMethod: validate(required),
    }),
  review: (review, _parent) =>
    updateGroup<CheckoutReviewForm>(review, {
      acceptPolicy: validate(requiredTrue),
    }),
});

export interface CartState {
  readonly checkingout: boolean;
  readonly checkoutForm: FormGroupState<CheckoutForm>;
  readonly shop: CartModel.CartShop;
  readonly deliveryOrPickup: CoreModel.deliveryOrPickup;
  readonly deliveryDate: number;
  readonly deliveryTimerange: SharedModel.MomentTimerange;
  readonly cartItems: CartModel.CartItem[];
  readonly cartDiscounts: CartModel.CartDiscount[];
  readonly billingData: CartModel.BillingData;
  readonly deliveryData: CartModel.DeliveryData;
  readonly pickupData?: CartModel.PickupData;
  readonly shippingMethod?: CartModel.ShippingMethod;
  readonly coupon: string;
  readonly paymentMethod: CartModel.PaymentMethod;
  readonly serviceSurcharge?: number;
  readonly availablePaymentMethods: CartModel.PaymentMethod[];
  readonly postedOrder: CoreModel.PostedOrder;
  readonly payAction: CoreModel.PayAction;
}

const initialState: CartState = {
  checkingout: false,
  checkoutForm: initialCheckoutFormState,
  shop: null,
  deliveryOrPickup: null,
  deliveryDate: null,
  deliveryTimerange: null,
  cartItems: [],
  cartDiscounts: [],
  billingData: null,
  deliveryData: null,
  coupon: null,
  paymentMethod: null,
  availablePaymentMethods: null,
  postedOrder: null,
  payAction: null,
};

const findCartItem = (cartItems: CartModel.CartItem[], cartItem: CartModel.CartItem): CartModel.CartItem => {
  // return the cartItem that matches with the current cartItems already in the cart
  const found = cartItems.filter(
    (ci) =>
      (ci.id === cartItem.id && _.isUndefined(ci.options) && _.isUndefined(cartItem.options)) ||
      (!_.isUndefined(ci.options) &&
        !_.isUndefined(cartItem.options) &&
        _.isEqual(
          _.flatten(ci.options.map((go) => go.options.map((op) => [op['@id'], op.quantity]))),
          _.flatten(cartItem.options.map((go) => go.options.map((op) => [op['@id'], op.quantity]))),
        )),
  );

  if (found) {
    return found[0];
  }
};

export const cartReducer = createReducer(
  initialState,
  on(CartActionTypes.addItem, (state, { cartItem }) => {
    const cartItems = state.cartItems.map((ci) => ({ ...ci }));
    const foundCartItem = findCartItem(cartItems, cartItem);
    if (foundCartItem) {
      foundCartItem.quantity += cartItem.quantity;
    } else {
      // insert item at the beginning
      cartItems.unshift(cartItem);
    }
    return { ...state, cartItems };
  }),
  on(CartActionTypes.checkingout, (state, { checkingout }) => ({ ...state, checkingout })),
  on(CartActionTypes.clearCart, (state) => ({ ...initialState, shop: state.shop })),
  on(CartActionTypes.clearCartAndCartShop, () => initialState),
  on(CartActionTypes.clearShippingMethod, (state) => ({ ...state, shippingMethod: undefined })),
  on(CartActionTypes.decrItem, (state, { cartItem }) => {
    const cartItems = state.cartItems.map((cartItem) => ({ ...cartItem }));
    cartItems.forEach((item) => {
      if (item.id === cartItem.id && item.options === cartItem.options) {
        item.quantity -= 1;
      }
    });
    return {
      ...state,
      cartItems: cartItems.filter((item) => item.quantity > 0),
    };
  }),
  on(CartActionTypes.incrItem, (state, { cartItem }) => {
    const cartItems = state.cartItems.map((cartItem) => ({ ...cartItem }));
    cartItems.forEach((item) => {
      if (item.id === cartItem.id && item.options === cartItem.options) {
        item.quantity += 1;
      }
    });
    return { ...state, cartItems };
  }),
  on(CartActionTypes.orderPosted, (state, { order }) => ({ ...state, postedOrder: order })),
  on(CartActionTypes.paymentMethodsLoaded, (state, { paymentMethods }) => ({
    ...state,
    availablePaymentMethods: paymentMethods,
  })),
  on(CartActionTypes.postOrder, (state) => ({ ...state, postedOrder: null, payAction: null })),
  on(CartActionTypes.removeCoupon, (state) => ({ ...state, coupon: null })),
  on(CartActionTypes.setDelivery, (state) => {
    const control = state.checkoutForm.controls.pickup;
    const checkoutFormState = formGroupReducer(
      state.checkoutForm,
      new SetValueAction(control.id, { ...control.value, pickup: false }),
    );
    const control2 = state.checkoutForm.controls.delivery;
    const checkoutFormState2 = formGroupReducer(
      checkoutFormState,
      new SetValueAction(control2.id, { ...control2.value, delivery: true }),
    );
    return {
      ...state,
      deliveryOrPickup: CoreModel.deliveryOrPickup.DELIVERY,
      checkoutForm: checkoutFormState2,
    };
  }),
  on(CartActionTypes.setPayAction, (state, { payAction }) => ({ ...state, payAction })),
  on(CartActionTypes.setPaymentMethod, (state, { paymentMethod }) => ({ ...state, paymentMethod })),
  on(CartActionTypes.setPickup, (state) => {
    const control = state.checkoutForm.controls.delivery;
    const checkoutFormState = formGroupReducer(
      state.checkoutForm,
      new SetValueAction(control.id, { ...control.value, delivery: false }),
    );
    const control2 = state.checkoutForm.controls.pickup;
    const checkoutFormState2 = formGroupReducer(
      checkoutFormState,
      new SetValueAction(control2.id, { ...control2.value, pickup: true }),
    );
    return {
      ...state,
      deliveryOrPickup: CoreModel.deliveryOrPickup.PICKUP,
      checkoutForm: checkoutFormState2,
    };
  }),
  on(CartActionTypes.setShippingAmount, (state, { shippingAmount }) => ({
    ...state,
    shippingMethod: {
      ...state.shippingMethod,
      calculatedAmount: shippingAmount,
    },
  })),
  on(CartActionTypes.setShippingMethod, (state, { shippingMethod }) => ({ ...state, shippingMethod })),
  on(CartActionTypes.setShop, (state, { shop }) => ({ ...state, shop })),
  on(CartActionTypes.setDiscounts, (state, { discounts }) => ({ ...state, cartDiscounts: discounts })),
);

export function reducer(state: CartState | undefined, action: Action): CartState {
  if (!_.isUndefined(state)) {
    const checkoutForm = validateCheckoutFormState(formGroupReducer(state.checkoutForm, action));
    if (checkoutForm !== state.checkoutForm) {
      state = { ...state, checkoutForm };
    }
  }
  return cartReducer(state, action);
}

// export function reducer(state = initialState, action: Actions): CartState {
//   const checkoutForm = validateCheckoutFormState(formGroupReducer(state.checkoutForm, action));
//   if (checkoutForm !== state.checkoutForm) {
//     state = { ...state, checkoutForm };
//   }
//   switch (action.type) {
//     case CartActionTypes.Checkingout:
//       return {
//         ...state,
//         checkingout: action.payload,
//       };
//     case CartActionTypes.SetShop:
//       return { ...state, shop: action.payload };
//     case CartActionTypes.ClearCart:
//       return {
//         ...initialState,
//         shop: state.shop,
//       };
//     case CartActionTypes.ClearCartAndCartShop:
//       return initialState;
//     case CartActionTypes.AddItem: {
//       const item: CartModel.CartItem = (<AddItem>action).payload;
//       const cartItems = state.cartItems.map((cartItem) => ({ ...cartItem }));
//       if (cartItems.filter((cartItem) => cartItem.id === item.id).length > 0) {
//         // item is already in cartItems
//         cartItems.forEach((cartItem) => {
//           if (cartItem.id === item.id) {
//             cartItem.quantity += item.quantity;
//           }
//         });
//       } else {
//         // insert item at the beginning
//         cartItems.unshift(item);
//       }
//       return { ...state, cartItems };
//     }
//     case CartActionTypes.ClearShippingMethod:
//       return { ...state, shippingMethod: undefined };
//     case CartActionTypes.IncrItem: {
//       const id: string = (<IncrItem>action).payload;
//       const cartItems = state.cartItems.map((cartItem) => ({ ...cartItem }));
//       cartItems.forEach((item) => {
//         if (item.id === id) {
//           item.quantity += 1;
//         }
//       });
//       return { ...state, cartItems };
//     }
//     case CartActionTypes.DecrItem: {
//       const id: string = (<DecrItem>action).payload;
//       const cartItems = state.cartItems.map((cartItem) => ({ ...cartItem }));
//       cartItems.forEach((item) => {
//         if (item.id === id) {
//           item.quantity -= 1;
//         }
//       });
//       return {
//         ...state,
//         cartItems: cartItems.filter((item) => item.quantity > 0),
//       };
//     }

//     // case CartActionTypes.SetPickup: {
//     //     return {...state, deliveryOrPickup: CartModel.deliveryOrPickup.pickup}
//     // }
//     case CartActionTypes.OrderPosted: {
//       const postedOrder = (<OrderPosted>action).payload.order;
//       return { ...state, postedOrder };
//     }
//     case CartActionTypes.PostOrder: {
//       return { ...state, postedOrder: null, payAction: null };
//     }
//     case CartActionTypes.SetPickup: {
//       const control = state.checkoutForm.controls.delivery;
//       const checkoutFormState = formGroupReducer(
//         state.checkoutForm,
//         new SetValueAction(control.id, { ...control.value, delivery: false }),
//       );
//       const control2 = state.checkoutForm.controls.pickup;
//       const checkoutFormState2 = formGroupReducer(
//         checkoutFormState,
//         new SetValueAction(control2.id, { ...control2.value, pickup: true }),
//       );
//       return {
//         ...state,
//         deliveryOrPickup: CoreModel.deliveryOrPickup.PICKUP,
//         checkoutForm: checkoutFormState2,
//       };
//     }

//     // case CartActionTypes.SetDelivery: {
//     //     return {...state, deliveryOrPickup: CartModel.deliveryOrPickup.delivery}
//     // }
//     case CartActionTypes.SetDelivery: {
//       const control = state.checkoutForm.controls.pickup;
//       const checkoutFormState = formGroupReducer(
//         state.checkoutForm,
//         new SetValueAction(control.id, { ...control.value, pickup: false }),
//       );
//       const control2 = state.checkoutForm.controls.delivery;
//       const checkoutFormState2 = formGroupReducer(
//         checkoutFormState,
//         new SetValueAction(control2.id, { ...control2.value, delivery: true }),
//       );
//       return {
//         ...state,
//         deliveryOrPickup: CoreModel.deliveryOrPickup.DELIVERY,
//         checkoutForm: checkoutFormState2,
//       };
//     }

//     // case CartActionTypes.ClearDiscounts:
//     //     return {...state, cartDiscounts: []};
//     // case CartActionTypes.SetDiscounts: {
//     //     const cartDiscounts: CartModel.CartDiscount[] = (<SetDiscounts>action).discounts;
//     //     return {...state, cartDiscounts: cartDiscounts};
//     // }
//     // case CartActionTypes.SetDeliveryData: {
//     //     const deliveryData: CartModel.DeliveryData = (<SetDeliveryData>action).deliveryData;
//     //     return {...state, deliveryData: deliveryData};
//     // }
//     // case CartActionTypes.SetDeliveryDatetime: {
//     //     const datetime: CartModel.DeliveryDatetime = (<SetDeliveryDatetime>action).datetime;
//     //     return {...state, deliveryDate: datetime.deliveryDate, deliveryTimerange: datetime.deliveryTimerange};
//     // }

//     case CartActionTypes.PaymentMethodsLoaded: {
//       const availablePaymentMethods = (<PaymentMethodsLoaded>action).payload.paymentMethods;
//       return { ...state, availablePaymentMethods };
//     }
//     case CartActionTypes.SetShippingMethod: {
//       const shippingMethod: CartModel.ShippingMethod = (<SetShippingMethod>action).payload;
//       return { ...state, shippingMethod: shippingMethod };
//     }
//     case CartActionTypes.SetShippingAmount: {
//       const amount: number = (<SetShippingAmount>action).payload;
//       return {
//         ...state,
//         shippingMethod: {
//           ...state.shippingMethod,
//           calculatedAmount: amount,
//         },
//       };
//     }
//     // case CartActionTypes.SetBillingData: {
//     //     const billingData: CartModel.BillingData = (<SetBillingData>action).billingData;
//     //     return {...state, billingData: billingData};
//     // }
//     // case CartActionTypes.SetCardMessage: {
//     //     const cardMessage: string = (<SetCardMessage>action).message;
//     //     return {...state, deliveryData: {...state.deliveryData, cardMessage: cardMessage}};
//     // }
//     // case CartActionTypes.SetComment: {
//     //     const comment: string = (<SetComment>action).comment;
//     //     return {...state, deliveryData: {...state.deliveryData, comment: comment}};
//     // }
//     case CartActionTypes.SetPayAction: {
//       const payAction = (<SetPayAction>action).payload;
//       return { ...state, payAction };
//     }
//     case CartActionTypes.SetPaymentMethod: {
//       const paymentMethod: CartModel.PaymentMethod = (<SetPaymentMethod>action).payload;
//       return { ...state, paymentMethod };
//     }
//     // case CartActionTypes.SetServiceSurcharge: {
//     //     const serviceSurcharge: number = (<SetServiceSurcharge>action).surcharge;
//     //     return {...state, serviceSurcharge: serviceSurcharge};
//     // }
//     default:
//       return state;
//   }
// }
