import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '~/reducers';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ShopAddress, ShopInfoShopInfo, ShopInfoTimeTablesVM } from '~/shop/models';
import { AvailabilityDatetimeText, Shop } from '~/core/models';
import {
  selectDeliveryAvailabilityDatetimeText,
  selectPickupAvailabilityDatetimeText,
  selectShopAddresses,
  selectShopDeliveryCities,
  selectShopShopInfo,
  selectTimetables,
} from '~/shop/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CookieService } from 'ngx-cookie-service';
import { ZoomArticleDialog } from '~/shared/zoom-article/zoom-article-dialog';
import { ShopDetailedInfoComponent } from '~/shop/shop-header/shop-detailed-info/shop-detailed-info.component';

@Component({
  selector: 'app-shop-header',
  templateUrl: './shop-header.component.html',
  styleUrls: ['./shop-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopHeaderComponent implements OnInit {
  @Input() shop: Shop;
  timetables$: Observable<ShopInfoTimeTablesVM>;
  shopAddresses$: Observable<ShopAddress[]>;
  deliveryCities$: Observable<string[]>;
  shopInfo$: Observable<ShopInfoShopInfo>;
  pickupAvailabilityDatetimeText$: Observable<AvailabilityDatetimeText>;
  deliveryAvailabilityDatetimeText$: Observable<AvailabilityDatetimeText>;
  minWidth768$: Observable<boolean>;

  constructor(
    public store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.minWidth768$ = this.breakpointObserver.observe(['(min-width: 768px)']).pipe(map((state) => state.matches));

    this.timetables$ = this.store.select(selectTimetables);

    this.shopAddresses$ = this.store.select(selectShopAddresses);

    this.deliveryCities$ = this.store.select(selectShopDeliveryCities);

    this.shopInfo$ = this.store.select(selectShopShopInfo);

    this.pickupAvailabilityDatetimeText$ = this.store.select(selectPickupAvailabilityDatetimeText);
    this.deliveryAvailabilityDatetimeText$ = this.store.select(selectDeliveryAvailabilityDatetimeText);

    const warningCookie = 'warningMessage_' + this.shop.UID;
    if (this.shop.warning_message && !this.cookieService.get(warningCookie)) {
      const from_date = this.shop.warning_from_date;
      const to_date = this.shop.warning_to_date;
      const today = moment(new Date().toISOString().slice(0, 10));
      if (
        (_.isNull(from_date) || moment(from_date).isSameOrBefore(today)) &&
        (_.isNull(to_date) || moment(to_date).isSameOrAfter(today))
      ) {
        this.displayWarningMessageDialog(this.shop.warning_message.data, warningCookie);
      }
    }
  }

  displayWarningMessageDialog(warningMessage: string, warningCookie: string): void {
    const dialogRef = this.dialog.open(WarningMessageDialogComponent, {
      width: 'auto',
      data: { message: warningMessage },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.cookieService.set(warningCookie, '1', { expires: 1, path: '/' });
      // console.log(`Dialog result: ${result} ${warningCookie}`);
    });
  }

  openZoom(title: string, url: string) {
    this.dialog.open(ZoomArticleDialog, {
      data: { title, url },
    });
  }

  openDetailedInfo() {
    this.dialog.open(ShopDetailedInfoComponent, {
      data: { shop: this.shop },
    });
  }
}

@Component({
  selector: 'warning-message',
  templateUrl: './warning-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningMessageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WarningMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
