<div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<div class="content-wrapper">
    <mat-tab-group>
        <mat-tab label="{{ 'user-info.userInformation' | translate}}">
            <div class="user-tab-content">
                <div *ngIf="!(editing$ | async); else editFormTemplate">
                    <div *ngIf="userInfo$ | async as userInfo">
                        <mat-list>
                            <mat-list-item><label>{{ 'user-info.nameColonSpace' | translate }}</label><span class="user-data">{{ userInfo.fullname }}</span></mat-list-item>
                            <mat-list-item><label>{{ 'user-info.emailColonSpace' | translate }}</label><span class="user-data">{{ userInfo.email }}</span></mat-list-item>
                            <mat-list-item><label>{{ 'user-info.mobileNumberColonSpace' | translate }}</label><span class="user-data">{{ userInfo.mobileNo }}</span></mat-list-item>
                            <mat-list-item><label>{{ 'user-info.sendNewsletterColonSpace' | translate }}</label>
                                <span *ngIf="!userInfo.sendNewsletter">{{ 'user-info.noThanks' | translate }}</span>
                                <span *ngIf="userInfo.sendNewsletter">{{ 'user-info.yesPlease' | translate }}</span>
                            </mat-list-item>
                        </mat-list>
                        <button mat-raised-button color="warn" (click)="edit(userInfo)">{{ 'user-info.edit' | translate }}</button>
                    </div>
                </div>
                <ng-template #editFormTemplate>
                    <ng-container *ngIf="(editUserInfoFormState$ | async) as formState">
                        <form novalidate [ngrxFormState]="formState">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'user-info.nameAndSurname' | translate }}" type="text"
                                       required
                                       [ngrxFormControlState]="formState.controls.fullname">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="{{ 'user-info.email' | translate }}" type="email"
                                       required
                                       [ngrxFormControlState]="formState.controls.email">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="{{ 'user-info.mobilePhone' | translate }}" type="tel"
                                       [ngrxFormControlState]="formState.controls.mobileNo">
                            </mat-form-field>
                            <div class="checkbox-wrapper">
                                <mat-checkbox [ngrxFormControlState]="formState.controls.sendNewsletter">
                                    {{ 'user-info.iAgreeToReceiveNewsletter' | translate }}
                                </mat-checkbox>
                            </div>
                            <button mat-raised-button color="basic" class="btn" (click)="cancelEditUserInfo()">{{ 'user-info.cancel' | translate }}</button>
                            <button mat-raised-button color="warn" class="btn" (click)="onSubmitUserInfo()">{{ 'user-info.save' | translate }}</button>
                        </form>
                    </ng-container>
                </ng-template>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'user-info.changePassword' | translate }}">
            <div class="user-tab-content">
                <div *ngIf="changedPassword$ | async">
                    <div class="user-notification"><i class="fas fa-check"></i> {{ 'user-info.changedPassword' | translate }}</div>
                </div>
                <button mat-raised-button color="warn" *ngIf="!(changingPassword$ | async); else changePasswordFormTemplate"
                        (click)="changePassword()">{{ 'user-info.changePassword' | translate }}</button>
                <div *ngIf="changePasswordError$ | async as changePasswordError">
                    <div class="apym-error-msg">
                        <div class="plone-system-error-advise">
                            <div><i class="fas fa-exclamation-triangle"></i>
                                {{ 'general.sorryError' | translate }}
                            </div>
                            <p><small>{{ 'general.helpError' | translate }}</small></p>
                        </div>
                        <p *ngIf="changePasswordError.message === 'The password passed as \'old_password\' is wrong.'">{{ 'user-info.wrongOldPassword' | translate }}</p>
                        <p *ngIf="changePasswordError.type === 'Wrong password'">{{ 'user-info.wrongPassword' | translate }}</p>
                        <p *ngIf="changePasswordError.type !== 'Wrong password'">{{ changePasswordError.message }}</p>
                    </div>
                </div>
                <ng-template #changePasswordFormTemplate>
                    <ng-container *ngIf="(changeUserPasswordFormState$ | async) as formState">
                        <form novalidate [ngrxFormState]="formState">
                            <mat-form-field>
                                <input matInput placeholder="Current password" type="password"
                                       required
                                       [ngrxFormControlState]="formState.controls.currentPassword">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="New password" type="password"
                                       required
                                       [ngrxFormControlState]="formState.controls.newPassword">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Confirm new password"
                                       type="password"
                                       required
                                       [ngrxFormControlState]="formState.controls.repeatNewPassword">
                                <small *ngIf="formState.controls.repeatNewPassword.errors?.equalTo && formState.controls.repeatNewPassword.isDirty" class="text-danger">{{ 'user-info.newPasswordMismatch' | translate }}</small>
                            </mat-form-field>
                            <!-- <div *ngIf="f().newPasswordConfirm.errors && f().newPasswordConfirm.errors.mustMatch">Password must match</div> -->
                            <button mat-raised-button color="basic" (click)="cancelChangeUserPassword()">{{ 'user-info.cancel: ' | translate }}</button>
                            <button mat-raised-button color="warn" (click)="onSubmitUserPassword()" [disabled]="!formState.isValid">{{ 'user-info.savePassword' | translate }}</button>
                        </form>
                    </ng-container>
                </ng-template>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>
