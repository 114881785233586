import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { LocaleService } from '~/core/locale.service';

@Component({
  selector: 'apym-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent {
  constructor(public translate: TranslateService, private localeService: LocaleService) {}

  ngOnInit() {
    this.localeService.setLanguage(this.translate.currentLang);
  }

  changeLang($event: MatButtonToggleChange) {
    this.localeService.setLanguage($event.value);
    this.translate.use($event.value);
  }
}
