import { Action } from '@ngrx/store';
import { RegisteredUser, UserInfo, UserShippingAddress, UserOrders } from '~/core/models';
import { RegisterForm } from '~/user/models';
import { PasswordResettedType, RegisterUserError } from './user.reducer';

export enum UserActionTypes {
  AddressesCancelled = '[Backend API] Addresses cancelled',
  AddressesLoaded = '[Backend API] Addresses loaded',
  AddressesRequested = '[View Addresses] Addresses requested',
  ChangeUserPassword = '[View UserInfo] Change Password',
  ChangeUserPasswordCancelled = '[View UserInfo] Change Password cancelled',
  ChangeUserPasswordRequest = '[View UserInfo] Change user password request',
  ChangeUserPasswordError = '[Backend API] Change User Password error',
  ChangeUserPasswordChanged = '[View UserInfo] Change User Password changed',
  CitiesCancelled = '[Backend API] Cities cancelled',
  CitiesRequested = '[Edit Address] Cities requested',
  CountiesCancelled = '[Backend API] Counties cancelled',
  CountiesRequested = '[Edit Address] Counties requested',
  CountriesCancelled = '[Backend API] Countries cancelled',
  CountriesRequested = '[Edit Address] Countries requested',
  CSRFLoaded = '[Backend API] CSRF loaded',
  EditAddress = '[View Addresses] Edit address',
  EditAddressCancelled = '[Edit Address] Edit address cancelled',
  EditUserInfo = '[View UserInfo] Edit user info',
  EditUserInfoCancelled = '[Edit UserInfo] Edit user info cancelled',
  OrdersCancelled = '[Backend API] Orders cancelled',
  OrdersLoaded = '[Backend API] Orders loaded',
  OrdersRequested = '[View Orders] Orders requested',
  PasswordResetCancelled = '[Backend API] Password reset cancelled',
  PasswordResetRequest = '[View PasswordReset] Password reset request',
  PasswordResetted = '[Backend API] Password resetted',
  PostCodesCancelled = '[Backend API] Postcodes cancelled',
  PostCodesRequested = '[Edit Address] Postcodes requested',
  RegisterUserCancelled = '[Backend API] Register user cancelled',
  RegisterUserClear = '[View Register] Register user clear',
  RegisterUserRegistered = '[Backend API] User registered',
  RegisterUserRequest = '[View Register] Register user request',
  RemoveAddress = '[View Addresses] Remove address',
  SaveAddress = '[Edit Address] Save address',
  SaveAddressCancelled = '[Backend API] Save address cancelled',
  SetLoading = '[View] Set loading',
  UserInfoSaveRequest = '[View UserInfo] User Info save request',
  UserInfoSaveCancelled = '[Backend API] User Info save cancelled',
  UserInfoEditPassword = '[View UserInfo] Edit Password',
  UserInfoLoaded = '[Backend API] UserInfo loaded',
  UserInfoRequested = '[View UserInfo] UserInfo requested',
}

export class AddressesCancelled implements Action {
  readonly type = UserActionTypes.AddressesCancelled;
}

export class AddressesLoaded implements Action {
  readonly type = UserActionTypes.AddressesLoaded;
  constructor(public payload: { addresses: UserShippingAddress[] }) {}
}

export class AddressesRequested implements Action {
  readonly type = UserActionTypes.AddressesRequested;
}

export class ChangeUserPassword implements Action {
  readonly type = UserActionTypes.ChangeUserPassword;
}

export class ChangeUserPasswordCancelled implements Action {
  readonly type = UserActionTypes.ChangeUserPasswordCancelled;
}

export class ChangeUserPasswordChanged implements Action {
  readonly type = UserActionTypes.ChangeUserPasswordChanged;
}

export class ChangeUserPasswordError implements Action {
  readonly type = UserActionTypes.ChangeUserPasswordError;
  constructor(public payload: { message: string; type: string }) {}
}

export class ChangeUserPasswordRequest implements Action {
  readonly type = UserActionTypes.ChangeUserPasswordRequest;
}

export class CitiesCancelled implements Action {
  readonly type = UserActionTypes.CitiesCancelled;
}

export class CitiesRequested implements Action {
  readonly type = UserActionTypes.CitiesRequested;
  constructor(public payload: string) {}
}

export class CountiesCancelled implements Action {
  readonly type = UserActionTypes.CountiesCancelled;
}

export class CountiesRequested implements Action {
  readonly type = UserActionTypes.CountiesRequested;
  constructor(public payload: string) {}
}

export class CountriesCancelled implements Action {
  readonly type = UserActionTypes.CountriesCancelled;
}

export class CountriesRequested implements Action {
  readonly type = UserActionTypes.CountriesRequested;
}

export class CSRFLoaded implements Action {
  readonly type = UserActionTypes.CSRFLoaded;
  constructor(public payload: { csrfToken: string }) {}
}

export class EditAddress implements Action {
  readonly type = UserActionTypes.EditAddress;
  constructor(public payload: UserShippingAddress) {}
}

export class EditAddressCancelled implements Action {
  readonly type = UserActionTypes.EditAddressCancelled;
}

export class EditUserInfo implements Action {
  readonly type = UserActionTypes.EditUserInfo;
  constructor(public payload: UserInfo) {}
}

export class EditUserInfoCancelled implements Action {
  readonly type = UserActionTypes.EditUserInfoCancelled;
}

export class OrdersCancelled implements Action {
  readonly type = UserActionTypes.OrdersCancelled;
}

export class OrdersLoaded implements Action {
  readonly type = UserActionTypes.OrdersLoaded;
  constructor(public payload: UserOrders) {}
}

export class OrdersRequested implements Action {
  readonly type = UserActionTypes.OrdersRequested;
  constructor(public payload: { index: number; size: number }) {}
}

export class PasswordResetRequest implements Action {
  readonly type = UserActionTypes.PasswordResetRequest;
}

export class PasswordResetted implements Action {
  readonly type = UserActionTypes.PasswordResetted;
}

export class PostCodesCancelled implements Action {
  readonly type = UserActionTypes.PostCodesCancelled;
}

export class PostCodesRequested implements Action {
  readonly type = UserActionTypes.PostCodesRequested;
  constructor(public payload: string) {}
}

export class RegisterUserClear implements Action {
  readonly type = UserActionTypes.RegisterUserClear;
}

export class RegisterUserCancelled implements Action {
  readonly type = UserActionTypes.RegisterUserCancelled;
  constructor(public payload: RegisterUserError) {}
}

export class RegisterUserRegistered implements Action {
  readonly type = UserActionTypes.RegisterUserRegistered;
  constructor(public payload: RegisteredUser) {}
}

export class RegisterUserRequest implements Action {
  readonly type = UserActionTypes.RegisterUserRequest;
  constructor(public payload: RegisterForm) {}
}

export class RemoveAddress implements Action {
  readonly type = UserActionTypes.RemoveAddress;
  constructor(public payload: number) {}
}

export class SaveAddress implements Action {
  readonly type = UserActionTypes.SaveAddress;
}

export class SaveAddressCancelled implements Action {
  readonly type = UserActionTypes.SaveAddressCancelled;
}

export class PasswordResetCancelled implements Action {
  readonly type = UserActionTypes.PasswordResetCancelled;

  constructor(public payload: PasswordResettedType) {}
}

export class SetLoading implements Action {
  readonly type = UserActionTypes.SetLoading;
  constructor(public payload: boolean) {}
}

export class UserInfoEditPassword implements Action {
  readonly type = UserActionTypes.UserInfoEditPassword;
  constructor(public payload: boolean) {}
}

export class UserInfoLoaded implements Action {
  readonly type = UserActionTypes.UserInfoLoaded;
  constructor(public payload: { userInfo: UserInfo }) {}
}

export class UserInfoRequested implements Action {
  readonly type = UserActionTypes.UserInfoRequested;
}

export class UserInfoSaveRequest implements Action {
  readonly type = UserActionTypes.UserInfoSaveRequest;
}

export class UserInfoSaveCancelled implements Action {
  readonly type = UserActionTypes.UserInfoSaveCancelled;
}

export type Actions =
  AddressesCancelled
  | AddressesLoaded
  | AddressesRequested
  | ChangeUserPassword
  | ChangeUserPasswordCancelled
  | ChangeUserPasswordChanged
  | ChangeUserPasswordError
  | ChangeUserPasswordRequest
  | CitiesCancelled
  | CitiesRequested
  | CountiesCancelled
  | CountiesRequested
  | CountriesCancelled
  | CountriesRequested
  | CSRFLoaded
  | EditAddress
  | EditAddressCancelled
  | EditUserInfo
  | EditUserInfoCancelled
  | OrdersCancelled
  | OrdersLoaded
  | OrdersRequested
  | PasswordResetCancelled
  | PasswordResetRequest
  | PasswordResetted
  | PostCodesCancelled
  | PostCodesRequested
  | RegisterUserCancelled
  | RegisterUserClear
  | RegisterUserRegistered
  | RegisterUserRequest
  | RemoveAddress
  | SaveAddress
  | SaveAddressCancelled
  | SetLoading
  | UserInfoEditPassword
  | UserInfoLoaded
  | UserInfoRequested
  | UserInfoSaveRequest
  | UserInfoSaveCancelled;
