import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppJsonConfig } from '~/core/appjsonconfig.service';

@Component({
  selector: 'apym-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  poweredBy: String;
  poweredByHref: String;

  constructor(private appConfig: AppJsonConfig) {
  }

  ngOnInit(): void {
    this.poweredBy = this.appConfig.getConfig('poweredBy', '');
    this.poweredByHref = this.appConfig.getConfig('poweredByHref', '');
  }
}
