<nav aria-label="breadcrumb" role="navigation">
    <h2 class="pa-sr" id="breadcrumbs-youarehere" translate>breadcrumbs.here</h2>
    <ol class="breadcrumb" aria-labeledby="breadcrumbs-youarehere">
        <li>
            <pa-button-link traverseTo="/" icon="home" [hasButtonDisplay]="true" paTooltip="workingbar.home" paTooltipType="action">
            </pa-button-link>
        </li>
        <ng-container *ngFor="let ancestor of (ancestors | async)">
            <li class="breadcrumb-item">
                <pa-button-link [traverseTo]="ancestor['@id']">
                    {{ ancestor.title }}
                </pa-button-link>
            </li>
        </ng-container>
    </ol>
</nav>