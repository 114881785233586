<form [formGroup]="searchForm"
      (submit)="search()">
    <div class="apym-search-form-wrapper">
        <div class="search-form-field">
            <mat-form-field>
                <input type="text" matInput [matAutocomplete]="searchPostCodeOptions"
                       (keyup.enter)="chooseFirstOption(true)"
                       (blur)="chooseFirstOption(false)"
                       formControlName="entity" placeholder="{{ 'search.postCodeOrCity' | translate }}">
                <span  matSuffix><i class="fas fa-map-marker-alt"></i></span>
            </mat-form-field>
            <mat-autocomplete #searchPostCodeOptions="matAutocomplete" [displayWith]="getPostCode"
                              autoActiveFirstOption="true">
                <mat-option *ngFor="let pc of filteredPostCodes$ | async" [value]="pc">
                    {{ pc.label }}
                </mat-option>
            </mat-autocomplete>
        </div>
        <div class="search-form-field">
            <mat-form-field>
                <input matInput [matDatepicker]="deliveryDatepicker"
                       [min]="doMinDate" [max]="doMaxDate"
                       placeholder="{{ 'search.deliveryOrPickupDate' | translate }}"
                       formControlName="date"
                       (click)="deliveryDatepicker.open()">
                <mat-datepicker-toggle matSuffix [for]="deliveryDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #deliveryDatepicker startView="month" [startAt]="doMinDate" touchUi="touchUi()"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="search-form-field">
            <mat-form-field>
                <input matInput placeholder="{{ 'search.searchProduct' | translate }}"
                       formControlName="item">
                <span  matSuffix><i class="fas fa-gift"></i></span>
            </mat-form-field>
        </div>
        <div class="search-form-field">
            <button mat-raised-button color="warn" type="submit"
                    [disabled]="!searchForm.valid">
                {{ 'search.search' | translate }}
            </button>
        </div>
    </div>
</form>
