import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LastratingsComponent } from './lastratings/lastratings.component';
import { RatingComponent } from './rating/rating.component';
import { RatingslistComponent } from './ratingslist/ratingslist.component';
import { RatingstatsComponent } from './ratingstats/ratingstats.component';
import { RatingFormComponent } from './ratingform/rating-form.component';
import { StarsComponent } from './stars/stars.component';
import { SharedModule } from '~/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LastratingsComponent,
    RatingComponent,
    RatingslistComponent,
    RatingstatsComponent,
    RatingFormComponent,
    StarsComponent,
  ],
  imports: [SharedModule, TranslateModule.forChild(), FormsModule],
  exports: [LastratingsComponent, RatingstatsComponent, StarsComponent],
  entryComponents: [RatingslistComponent, RatingFormComponent],
})
export class RatingModule {}
