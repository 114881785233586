import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RatingslistComponent } from '~/rating/ratingslist/ratingslist.component';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarsComponent {
  @Input() maxRating: number;
  @Input() rating: number;
  @Input() quantityRatings: number;
  @Input() word: string;
  @Input() path: string;
  @Input() compact: boolean;

  constructor(public dialog: MatDialog) {}

  openRatings($event): boolean {
    $event.stopPropagation();
    const ratingsListRef = this.dialog.open(RatingslistComponent, {
      data: { path: this.path },
    });
    return false;
  }
}
